import React from "react";
import { DFMInfoType } from "types/ModelTypes";
import { FunctionComponent, useContext, useMemo, useState } from "react";
import AppStateContext from "../../contexts/AppStateContext";
import CustomIconButton from "../Buttons/CustomIconButton";
import { ReactComponent as InfoIcon } from "../../assets/svg/dashboard/info.svg";

type PropsType = {
  className?: string;
  style?: any;
  dfmErrors?: DFMInfoType;
};

const DFMErrors: FunctionComponent<PropsType> = ({
  className,
  style,
  dfmErrors,
}) => {
  const { strings } = useContext(AppStateContext);
  const [percentDone, setPercentDone] = useState<number>(0);
  const [showControls, setShowControls] = useState<boolean>(false);

  const noErrors = useMemo(() => {
    return (
      dfmErrors?.edgeAccessibility.numInaccessibleEdges === 0 &&
      dfmErrors?.boundingAnalysis.bboxDx <= dfmErrors?.boundingAnalysis.refDx &&
      dfmErrors?.boundingAnalysis.bboxDy <= dfmErrors?.boundingAnalysis.refDy &&
      dfmErrors?.boundingAnalysis.bboxDz <= dfmErrors?.boundingAnalysis.refDz &&
      dfmErrors?.vertexAccessibility.numInaccessibleVertices === 0 &&
      dfmErrors?.faceAccessibility.numInaccessibleFaces === 0 &&
      dfmErrors?.thicknessAnalysis.minThickness >= 0.5 &&
      dfmErrors?.clearanceAnalysis.minClearance >= 0.5 &&
      dfmErrors?.boundingAnalysis.bboxDx >= 8 &&
      dfmErrors?.boundingAnalysis.bboxDy >= 9 &&
      dfmErrors?.boundingAnalysis.bboxDz >= 2
    );
  }, [dfmErrors]);

  return (
    <>
      <CustomIconButton
        icon={InfoIcon}
        tooltipThread={
          !noErrors && dfmErrors ? (
            <div>
              {false && <div>{strings.DFMErrorNoSolidsTitle}</div>}
              {dfmErrors.edgeAccessibility.numInaccessibleEdges > 0 && (
                <div>{strings.DFMErrorEdgeTitle}</div>
              )}
              {(dfmErrors?.boundingAnalysis.bboxDx >
                dfmErrors.boundingAnalysis.refDx ||
                dfmErrors?.boundingAnalysis.bboxDy >
                  dfmErrors.boundingAnalysis.refDy ||
                dfmErrors?.boundingAnalysis.bboxDz >
                  dfmErrors.boundingAnalysis.refDz) && (
                <div>{strings.DFMErrorOverdimensionTitle}</div>
              )}
              {false && <div>{strings.DFMErrorMultipleSolidsTitle}</div>}
              {(dfmErrors?.boundingAnalysis.bboxDx < 8 ||
                dfmErrors?.boundingAnalysis.bboxDy < 9 ||
                dfmErrors?.boundingAnalysis.bboxDz < 2) && (
                <div>{strings.DFMErrorUnderdimensionTitle}</div>
              )}
              {dfmErrors.vertexAccessibility.numInaccessibleVertices > 0 && (
                <div>{strings.DFMErrorPointTitle}</div>
              )}
              {dfmErrors?.faceAccessibility.numInaccessibleFaces && (
                <div>{strings.DFMErrorFaceTitle}</div>
              )}
              {dfmErrors?.thicknessAnalysis.minThickness <
                dfmErrors?.thicknessAnalysis.refThickness && (
                <div>{strings.DFMErrorThinPlaneTitle}</div>
              )}
              {false && <div>{strings.DFMErrorDeepTitle}</div>}
              {dfmErrors?.clearanceAnalysis.minClearance <
                dfmErrors?.clearanceAnalysis.refClearance && (
                <div>{strings.DFMErrorClearanceTitle}</div>
              )}
            </div>
          ) : (
            ""
          )
        }
      />
    </>
  );
};
export default DFMErrors;
