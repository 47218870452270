import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  FormEvent,
} from "react";
import lottie from "lottie-web";
import AnimatedAvatar from "assets/lottie/animatedAvater.json";
import SweetAlert from "react-bootstrap-sweetalert";
import CompConfigContext from "contexts/CompConfigContext";
import {
  REGEX_ADDRESS,
  REGEX_CITY,
  REGEX_CVRNUMBER,
  REGEX_EMAIL,
  REGEX_FIRMNAME,
  REGEX_NAME,
  REGEX_PHONENUMBER,
  REGEX_ZIPCODE,
} from "util/RegExUtil";
import AppStateContext from "contexts/AppStateContext";
import { Box, CircularProgress, Grid, InputAdornment } from "@mui/material";
import { apiGetUser, apiUpdateUser } from "util/network/Users";
import { UserType } from "types/users/UserTypes";
import { UserUpdateType } from "types/users/UserCommandsType";
import CustomInput from "../../../components/Input/CustomInput";
import CustomButton from "../../../components/Buttons/CustomButton";
import CustomCheckbox from "components/Checkbox/CustomCheckbox";
import styles from "./myProfile.module.css";
import stylesButton from "../../../components/Buttons/customButton.module.css";

const MyProfile = () => {
  const { strings, token } = useContext(AppStateContext);
  const [inputErr, setInputErr] = useState<boolean>(false);
  const { showLogoutModal, setShowLogoutModal } = useContext(CompConfigContext);

  const avatarContainer = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);

  const [userId, setUserId] = useState<number>();
  const [userFirstname, setUserFirstname] = useState<string>("");
  const [userLastname, setUserLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [vat, setVat] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [deliveryEmail, setDeliveryEmail] = useState<string>("");
  const [deliveryCompany, setDeliveryCompany] = useState<string>("");
  const [deliveryVat, setDeliveryVat] = useState<string>("");
  const [deliveryFirstname, setDeliveryFirstname] = useState<string>("");
  const [deliveryLastname, setDeliveryLastname] = useState<string>("");
  const [deliveryPhone, setDeliveryPhone] = useState<string>("");
  const [deliveryStreet, setDeliveryStreet] = useState<string>("");
  const [deliveryPostalCode, setDeliveryPostalCode] = useState<string>("");
  const [deliveryCity, setDeliveryCity] = useState<string>("");
  const [user, setUser] = useState<UserType | null>();
  const [useAlt, setUseAlt] = useState<boolean>(false);

  useEffect(() => {
    apiGetUser(token).then((res) => {
      console.log(res);
      setUser(res);
      setUserFirstname(res.firstName);
      setUserLastname(res.lastName);
      const address = res.defaultBillingAddress;
      const shippingAdd = res.defaultShippingAddress;
      if (address) {
        setCompany(address.company);
        setFirstname(address.firstName);
        setLastname(address.lastName);
        setEmail(address.email);
        setVat(address.vat);
        setPhone(address.phone);
        setStreet(address.street);
        setPostalCode(address.postalCode);
        setCity(address.city);
      }
      if (shippingAdd) {
        if (shippingAdd.firstName !== "") {
          setUseAlt(true);
        }

        setDeliveryCompany(shippingAdd.company);
        setDeliveryVat(shippingAdd.vat);
        setDeliveryFirstname(shippingAdd.firstName);
        setDeliveryLastname(shippingAdd.lastName);
        setDeliveryEmail(shippingAdd.email);
        setDeliveryPhone(shippingAdd.phone);
        setDeliveryStreet(shippingAdd.street);
        setDeliveryPostalCode(shippingAdd.postalCode);
        setDeliveryCity(shippingAdd.city);
      }
      setUserId(res.id);
    });
  }, [token]);

  // Loads the lottie upon render and when data is fetched
  // - limits the frames, so that it does not end with a blank
  useEffect(() => {
    let anim = lottie.loadAnimation({
      // @ts-ignore
      container: avatarContainer.current,
      renderer: "svg",
      loop: !userId,
      autoplay: !loading,
      animationData: AnimatedAvatar,
    });
    anim.addEventListener("enterFrame", function (animation) {
      if (animation.currentTime > anim.totalFrames - 50) {
        anim.pause();
      }
    });
    return () => {
      anim.removeEventListener("enterFrame", function (animation) {
        if (animation.currentTime > anim.totalFrames - 50) {
          anim.pause();
        }
      });
      anim.destroy();
    };
  }, [loading]);

  const formTest = useCallback(() => {
    return (
      (company === "" || REGEX_FIRMNAME.test(company)) &&
      (!vat || REGEX_CVRNUMBER.test(vat)) &&
      (firstname === "" || REGEX_NAME.test(firstname)) &&
      (lastname === "" || REGEX_NAME.test(lastname)) &&
      (!phone || REGEX_PHONENUMBER.test(phone)) &&
      //(nyEmail === "" || REGEX_EMAIL.test(nyEmail)) &&
      (street === "" || REGEX_ADDRESS.test(street)) &&
      //   `${nyAddressName} ${nyAddressNumber}` === " " ||
      //   REGEX_ADDRESS.test(`${nyAddressName} ${nyAddressNumber}`)) &&
      (!postalCode || REGEX_ZIPCODE.test(postalCode)) &&
      (city === "" || REGEX_CITY.test(city))
    );
  }, [company, vat, firstname, lastname, phone, street, postalCode, city]);

  useEffect(() => {
    if (formTest()) {
      setInputErr(false);
    } else {
      setInputErr(true);
    }
  }, [formTest]);

  // Resets form after submitting
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!userId) return;
    setLoading(true);
    let updateData: UserUpdateType = {
      firstName: userFirstname,
      lastName: userLastname,
      billingAddress: {
        firstName: firstname,
        lastName: lastname,
        email: email,
        street: street,
        company: company,
        phone: phone,
        postalCode: postalCode,
        city: city,
        countryId: "DK",
        vat: vat,
      },
    };
    if (useAlt) {
      updateData.shippingAddress = {
        firstName: deliveryFirstname,
        lastName: deliveryLastname,
        email: deliveryEmail,
        street: deliveryStreet,
        company: deliveryCompany,
        phone: deliveryPhone,
        postalCode: deliveryPostalCode,
        city: deliveryCity,
        countryId: "DK",
        vat: deliveryVat,
      };
    }
    apiUpdateUser(token, userId, updateData).then((res) => {
      const address = res.defaultBillingAddress;
      const shippingAdd = res.defaultShippingAddress;
      setUser(res);

      setCompany(address.company);
      setVat(address.vat);
      setFirstname(address.firstName);
      setLastname(address.lastName);
      setEmail(address.email);
      setPhone(address.phone);
      setStreet(address.street);
      setPostalCode(address.postalCode);
      setCity(address.city);
      if (shippingAdd) {
        setDeliveryCompany(shippingAdd.company);
        setDeliveryVat(shippingAdd.vat);
        setDeliveryFirstname(shippingAdd.firstName);
        setDeliveryLastname(shippingAdd.lastName);
        setDeliveryEmail(shippingAdd.email);
        setDeliveryPhone(shippingAdd.phone);
        setDeliveryStreet(shippingAdd.street);
        setDeliveryPostalCode(shippingAdd.postalCode);
        setDeliveryCity(shippingAdd.city);
      }

      setLoading(false);
    });
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.container__account}`}>
          {loading ? (
            <SweetAlert
              custom
              style={{ position: "relative" }}
              title="Vent venligst..."
              allowEscape={false}
              showConfirm={false}
              customIcon={<CircularProgress size={"80px"} />}
              onConfirm={() => {}}
            >
              Opdaterer dine oplysninger...
            </SweetAlert>
          ) : null}
          <div>
            <h1 className={`${styles.title}`}>{strings.MyProfileSmaller}</h1>
            <div className={`${styles.profile__info}`}>
              <h5 className={`${styles.title__info}`}>
                {strings.MyProfileCompanyInfo}
              </h5>
              <h6>
                {strings.FirmNameInput}
                {": "}
                <b>
                  {user?.defaultBillingAddress?.company != ""
                    ? user?.defaultBillingAddress?.company
                    : strings.MyProfileNotSpecified}
                </b>
              </h6>
              <h6>
                {strings.CVR}
                {": "}
                <b>
                  {user?.defaultBillingAddress?.vat != ""
                    ? user?.defaultBillingAddress?.vat
                    : strings.MyProfileNotSpecified}
                </b>
              </h6>
              <h5 className={`${styles.title__info}`}>
                {strings.MyProfileContactPerson}
              </h5>
              <h6>
                <b>
                  {user?.firstName} {user?.lastName}
                </b>
              </h6>
              <h5 className={`${styles.title__info}`}>
                {strings.MyProfileContactInformation}:
              </h5>
              <h6>
                {strings.MyProfilePhone}
                <b>
                  {user?.defaultBillingAddress?.phone != ""
                    ? user?.defaultBillingAddress?.phone
                    : strings.MyProfileNotSpecified}
                </b>
                <br />
                {strings.Email}:{" "}
                <b>{user?.email ?? strings.MyProfileNotSpecified}</b>
              </h6>
              <h5 className={`${styles.title__info}`}>
                {strings.InvoiceInfoDeliveryAddress}:
              </h5>
              <h6 style={{ fontWeight: "bold" }}>
                {user?.defaultBillingAddress?.street !== "" ? (
                  <div>
                    {user?.defaultBillingAddress?.street &&
                    user?.defaultBillingAddress?.postalCode &&
                    user?.defaultBillingAddress?.city
                      ? `${user?.defaultBillingAddress?.street}, ${user?.defaultBillingAddress?.postalCode} ${user?.defaultBillingAddress?.city}`
                      : strings.MyProfileNotSpecified}
                    {/* {`${addressName} ${addressNumber}`}, {postnummer}{" "} */}
                  </div>
                ) : (
                  strings.MyProfileNotSpecified
                )}
              </h6>
            </div>
          </div>
        </div>
        <div className={`${styles.container__addresses}`}>
          <form onSubmit={(event) => handleSubmit(event)}>
            <h3 className={`${styles.address__headers}`}>
              {strings.MyProfileBaseInfo}
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "40%",
                }}
              >
                <CustomInput
                  label={strings.FirstName}
                  id="base_fornavn"
                  type="text"
                  value={userFirstname}
                  required={true}
                  error={userFirstname && !REGEX_NAME.test(userFirstname)}
                  onChange={(e: any) => setUserFirstname(e.target.value)}
                  maxLength={30}
                />
              </div>
              <div
                style={{
                  width: "40%",
                }}
              >
                <CustomInput
                  label={strings.LastName}
                  id="base_efternavn"
                  type="text"
                  value={userLastname}
                  required={true}
                  error={userLastname && !REGEX_NAME.test(userLastname)}
                  onChange={(e: any) => setUserLastname(e.target.value)}
                  maxLength={30}
                />
              </div>
            </div>
            <h3 className={`${styles.address__headers}`}>
              {strings.invoiceAddress}
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "40%",
                }}
              >
                <CustomInput
                  label={strings.FirmNameInput}
                  id="Firmanavn"
                  type="text"
                  value={company}
                  required={true}
                  error={!REGEX_FIRMNAME.test(company) && company !== ""}
                  onChange={(e: any) => {
                    if (e.target.value.length < 30) {
                      setCompany(e.target.value);
                    }
                    formTest();
                  }}
                />
                <CustomInput
                  label={strings.FirstName}
                  id="fornavn"
                  type="text"
                  value={firstname}
                  required={true}
                  error={firstname && !REGEX_NAME.test(firstname)}
                  onChange={(e: any) => setFirstname(e.target.value)}
                  maxLength={30}
                />
                <CustomInput
                  label={strings.PhoneInput}
                  id="Telefonnummer"
                  type="number"
                  pattern="[0-9]*"
                  value={phone}
                  required={true}
                  error={phone && !REGEX_PHONENUMBER.test(phone)}
                  onChange={(e: any) => {
                    if (e.target.value.length < 9) {
                      setPhone(e.target.value);
                    }
                  }}
                />
                <CustomInput
                  label={strings.ZipInput}
                  id="postnummer"
                  value={postalCode}
                  type="number"
                  pattern="[0-9]*"
                  required={true}
                  error={postalCode && !REGEX_ZIPCODE.test(postalCode)}
                  onChange={(e: any) => {
                    if (e.target.value.length < 5) {
                      setPostalCode(e.target.value);
                    }
                  }}
                />
                <CustomInput
                  label={strings.Email}
                  id="email"
                  value={email}
                  type="string"
                  required={true}
                  error={email && !REGEX_EMAIL.test(email)}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </div>

              <div
                style={{
                  width: "40%",
                }}
              >
                <CustomInput
                  label={strings.CVRInput}
                  id="cvr"
                  type="number"
                  pattern="[0-9]*"
                  value={vat}
                  required={true}
                  error={vat && !REGEX_CVRNUMBER.test(vat)}
                  onChange={(e: any) => {
                    if (e.target.value.length < 9) {
                      setVat(e.target.value);
                    }
                  }}
                />
                <CustomInput
                  label={strings.LastName}
                  id="efternavn"
                  type="text"
                  value={lastname}
                  required={true}
                  error={lastname !== "" && !REGEX_NAME.test(lastname)}
                  onChange={(e: any) => setLastname(e.target.value)}
                  maxLength={30}
                />
                <CustomInput
                  label={strings.RoadInput}
                  id="vej"
                  type="address"
                  value={street}
                  required={true}
                  error={street !== "" && !REGEX_ADDRESS.test(street)}
                  onChange={(e: any) => setStreet(e.target.value)}
                  maxLength={50}
                />
                <CustomInput
                  label={strings.CityInput}
                  id="by"
                  type="text"
                  value={city}
                  required={true}
                  error={city !== "" && !REGEX_CITY.test(city)}
                  onChange={(e: any) => setCity(e.target.value)}
                  maxLength={50}
                />
              </div>
            </div>
            <div className={`${styles.checkbox}`}>
              <CustomCheckbox
                label={strings.MyProfileCheckbox}
                type={"checkbox"}
                checked={useAlt}
                onChange={(e) => setUseAlt((e) => !e)}
              />
            </div>
            {useAlt ? (
              <>
                <h3 className={`${styles.address__headers}`}>
                  {strings.deliveryAddress}
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                    }}
                  >
                    <CustomInput
                      label={strings.FirmNameInput}
                      id="levering_Firmanavn"
                      type="text"
                      value={deliveryCompany}
                      required={true}
                      error={
                        !REGEX_FIRMNAME.test(deliveryCompany) &&
                        deliveryCompany !== ""
                      }
                      onChange={(e: any) => {
                        if (e.target.value.length < 30) {
                          setDeliveryCompany(e.target.value);
                        }
                        formTest();
                      }}
                    />
                    <CustomInput
                      label={strings.FirstName}
                      id="levering_fornavn"
                      type="text"
                      value={deliveryFirstname}
                      required={true}
                      error={
                        deliveryFirstname && !REGEX_NAME.test(deliveryFirstname)
                      }
                      onChange={(e: any) =>
                        setDeliveryFirstname(e.target.value)
                      }
                      maxLength={30}
                    />
                    <CustomInput
                      label={strings.PhoneInput}
                      id="levering_Telefonnummer"
                      type="number"
                      pattern="[0-9]*"
                      value={deliveryPhone}
                      required={true}
                      error={
                        deliveryPhone && !REGEX_PHONENUMBER.test(deliveryPhone)
                      }
                      onChange={(e: any) => {
                        if (e.target.value.length < 9) {
                          setDeliveryPhone(e.target.value);
                        }
                      }}
                    />
                    <CustomInput
                      label={strings.ZipInput}
                      id="levering_postnummer"
                      value={deliveryPostalCode}
                      type="number"
                      pattern="[0-9]*"
                      required={true}
                      error={
                        deliveryPostalCode &&
                        !REGEX_ZIPCODE.test(deliveryPostalCode)
                      }
                      onChange={(e: any) => {
                        if (e.target.value.length < 5) {
                          setDeliveryPostalCode(e.target.value);
                        }
                      }}
                    />
                    <CustomInput
                      label={strings.Email}
                      id="levering_email"
                      value={deliveryEmail}
                      type="email"
                      required={true}
                      error={deliveryEmail && !REGEX_EMAIL.test(deliveryEmail)}
                      onChange={(e: any) => setDeliveryEmail(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      width: "40%",
                    }}
                  >
                    <CustomInput
                      label={strings.CVRInput}
                      id="levering_cvr"
                      type="number"
                      pattern="[0-9]*"
                      value={deliveryVat}
                      required={true}
                      error={deliveryVat && !REGEX_CVRNUMBER.test(deliveryVat)}
                      onChange={(e: any) => {
                        if (e.target.value.length < 9) {
                          setDeliveryVat(e.target.value);
                        }
                      }}
                    />
                    <CustomInput
                      label={strings.LastName}
                      id="levering_efternavn"
                      type="text"
                      value={deliveryLastname}
                      required={true}
                      error={
                        deliveryLastname !== "" &&
                        !REGEX_NAME.test(deliveryLastname)
                      }
                      onChange={(e: any) => setDeliveryLastname(e.target.value)}
                      maxLength={30}
                    />
                    <CustomInput
                      label={strings.RoadInput}
                      id="levering_vej"
                      type="address"
                      value={deliveryStreet}
                      required={true}
                      onChange={(e: any) => setDeliveryStreet(e.target.value)}
                      maxLength={50}
                    />
                    <CustomInput
                      label={strings.CityInput}
                      id="levering_by"
                      type="text"
                      value={deliveryCity}
                      required={true}
                      error={
                        deliveryCity !== "" && !REGEX_CITY.test(deliveryCity)
                      }
                      onChange={(e: any) => setDeliveryCity(e.target.value)}
                      maxLength={50}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div
              // style={{ display: "flex", flexDirection: "row" }}
              className={`${styles.button__container}`}
            >
              <CustomButton
                buttonText={strings.LogOut}
                onClick={() => setShowLogoutModal(true)}
                buttonClass={`${stylesButton.button__logout}`}
                iconClass={`${stylesButton.logout__icon}`}
              />
              <CustomButton
                disabled={inputErr}
                type={"submit"}
                buttonText={strings.MyProfileUpdateButton}
                buttonClass={`${stylesButton.button__register} ${stylesButton.button__update}`}
                iconClass={`${stylesButton.register__icon}`}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
