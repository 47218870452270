import React, { useContext, useEffect } from "react";
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews, { SwipeableViewsContext } from "react-swipeable-views";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/creativeTim/components/navPillsStyle.js";
import styleNav from "./css/navPills.module.css";
const useStyles = makeStyles(styles);

const CustomTab = ({ tabContent }) => {
  const { slideUpdateHeight } = useContext(SwipeableViewsContext);

  useEffect(() => {
    slideUpdateHeight();
  });

  return <div className={`${styleNav.tab__content}`}>{tabContent}</div>;
};

const NavPills = (props) => {
  const [active, setActive] = React.useState(props.active);
  const handleChange = (active) => {
    setActive(active);
  };
  console.log(active);
  const handleChangeIndex = (index) => {
    setActive(index);
  };
  const classes = useStyles();
  const { tabs, direction, horizontal } = props;
  // const flexContainerClasses = classNames({
  //   [classes.flexContainer]: true,
  //   [classes.horizontalDisplay]: horizontal !== undefined,
  // });
  const tabButtons = tabs.map((prop, key) => {
    return (
      <button
        label={prop.tabButton}
        key={key}
        style={{
          border: "none",
          background: "transparent",
          // color: key == active ? "red" : "rgb(0,255,0)",
          cursor: "pointer",
          fontWeight: key == active ? "bold" : "normal",
          borderBottom: key == active ? "5px solid #008B80" : "none",
          paddingBottom: "0.4rem",
          // display: "flex",
          // justifyContent: "center",
        }}
        disabled={prop.disabled}
        title={prop.tabButton}
        onClick={(e) => setActive(key)}
      >
        {prop.tabButton}
      </button>
    );
  });

  const tabContent = (
    <div className={`${styleNav.tab__wrapper}`}>
      <SwipeableViews
        // axis={direction === "rtl" ? "x-reverse" : "x"}
        index={active}
        animateHeight
        onChangeIndex={handleChangeIndex}
      >
        {tabs.map((prop, key) => {
          return <CustomTab key={key} tabContent={prop.tabContent} />;
        })}
      </SwipeableViews>
    </div>
  );
  return (
    <>
      {tabButtons}
      {tabContent}
    </>
  );
};
// display: flex;
// flex-wrap: wrap;
// justify-content: space-evenly;

export default NavPills;

NavPills.defaultProps = {
  active: 0,
  color: "primary",
};

NavPills.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object,
  }),
  alignCenter: PropTypes.bool,
};
