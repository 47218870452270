import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { primaryColor } from "../../../assets/jss/material-dashboard-pro-react";
import SweetAlert from "react-bootstrap-sweetalert";
import * as ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import CompConfigContext from "contexts/CompConfigContext";
import AppStateContext from "contexts/AppStateContext";
import { ModelDataType, SelectedThreadsInfoType } from "types/RenderTypes";
import CNC from "components/Configurator/CNC";
import {
  LeadOptionsType,
  LeadTimeSelectorType,
  ThreadType,
} from "types/ConfigTypes";
import {
  apiEstimateProductDemo,
  apiGetDemoModelInfo,
  apiGetDemoPreviewImage,
} from "util/network/Demo";
import EcommerceContext from "contexts/EcommerceContext";
import {
  apiEstimateProduct,
  apiGetModelInfo,
  apiGetPreviewImage,
  apiUpdateCNCProduct,
  apiGetProduct,
} from "util/network/Products";
import { apiGetThreadsList } from "util/network/Models";
import {
  CNCProductDetailsType,
  UpdateCNCProductType,
} from "types/products/ProductCommandsType";
import { CNCEstimateType } from "types/products/ProductTypes";

import { Button, CircularProgress } from "@mui/material";
import ConfiguratorPriceSummary from "../../../vaerksComponents/configuratorComponents/ConfiguratorPriceSummary";

import styles from "./css/newComponentConfigurator.module.css";

const CNCConfigurator = () => {
  const { strings, token } = useContext(AppStateContext);
  const {
    cartId,
    cartItems,
    leadtimeOption,
    leadTimeOptions,
    changeLeadTime,
    addCartItem,
    updateCartItem,
  } = useContext(EcommerceContext);
  const { state } = useLocation<any>();
  const history = useHistory();
  const { trackEvent } = useMatomo();

  const [selectedLeadTime, setSelectedLeadTime] = useState<LeadOptionsType>(
    leadtimeOption ?? "STANDARD"
  );

  const [priceLoading, setPriceLoading] = useState(false);

  const [configuration, setConfiguration] = useState<
    CNCProductDetailsType & {
      name: string;
      weight: number;
    }
  >();

  const [fileName, setFileName] = useState<string | null>(null);

  const {
    cncProductId,
    setCNCProductId,
    setModelLoading,
    setDfmLoading,
    previewImageLoading,
    setPreviewImageLoading,
    showLogoutModal,
  } = useContext(CompConfigContext);

  const demo = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const demo = urlParams.get("demo");
    return demo !== null;
  }, []);

  const [prices, setPrices] = useState<{
    ECONOMIC: number;
    STANDARD: number;
    EXPRESS: number;
  }>({
    EXPRESS: 0,
    STANDARD: 0,
    ECONOMIC: 0,
  });

  const [totalLeadTimeOptions, setTotalLeadTimeOptions] =
    useState<LeadTimeSelectorType>({
      EXPRESS: {
        days: 0,
        price: 0,
      },
      STANDARD: {
        days: 0,
        price: 0,
      },
      ECONOMIC: {
        days: 0,
        price: 0,
      },
    });

  const [previewImage, setPreviewImage] = useState<string>();

  const [addingToCart, setAddingtoCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);

  const [currentInfo, setCurrentInfo] = useState<ModelDataType>();
  const [threadList, setThreadList] = useState<ThreadType[]>([]);

  const [reconfiguration, setReconfiguration] = useState<boolean>(false);
  const [existingCartItemId, setExistingCartItemId] = useState<number>();

  const updateConfiguration = (
    newConfiguration: CNCProductDetailsType & {
      name: string;
      weight: number;
    }
  ) => {
    if (cncProductId) {
      setPriceLoading(true);
      const command = {
        quantity: newConfiguration.quantity,
        material: newConfiguration.material,
        finish: newConfiguration.finish.join(","),
        blueprint: newConfiguration.blueprint,
        comment: newConfiguration.comment,
        threads: newConfiguration.threads,
      };
      apiEstimateProduct(token, cncProductId, command, cartId).then((res) => {
        const cncProduct = {
          ...res.product.details,
          finish: (res.product.details?.finish as any).split(","),
        } as CNCProductDetailsType;
        const newPrices = {
          EXPRESS: res.product.priceExpress,
          STANDARD: res.product.priceStandard,
          ECONOMIC: res.product.priceEconomic,
        };
        setPrices(newPrices);
        setTotalLeadTimeOptions({
          ECONOMIC: {
            days: res.leadTimeDays.ECONOMIC,
            price: res.product.priceEconomic * cncProduct.quantity,
          },
          STANDARD: {
            days: res.leadTimeDays.STANDARD,
            price: res.product.priceStandard * cncProduct.quantity,
          },
          EXPRESS: {
            days: res.leadTimeDays.EXPRESS,
            price: res.product.priceExpress * cncProduct.quantity,
          },
        });
        setConfiguration({
          ...cncProduct,
          name: res.product.name,
          weight: res.product.weight,
        });
        setPriceLoading(false);
      });
    }
  };

  const updateDemoConfiguration = (
    newConfiguration: CNCProductDetailsType & {
      name: string;
      weight: number;
    }
  ) => {
    if (demo) {
      setPriceLoading(true);
      const command = {
        quantity: newConfiguration.quantity,
        material: newConfiguration.material,
        finish: newConfiguration.finish.join(","),
        blueprint: newConfiguration.blueprint,
        comment: newConfiguration.comment,
        threads: newConfiguration.threads,
      };
      apiEstimateProductDemo(command).then((res) => {
        const productDetails = {
          ...res.product.details,
          finish: (res.product.details?.finish as any).split(","),
        } as CNCProductDetailsType;
        const newPrices = {
          EXPRESS: res.product.priceExpress,
          STANDARD: res.product.priceStandard,
          ECONOMIC: res.product.priceEconomic,
        };
        setPrices(newPrices);
        setTotalLeadTimeOptions({
          ECONOMIC: {
            days: res.leadTimeDays.ECONOMIC,
            price: res.product.priceEconomic * productDetails.quantity,
          },
          STANDARD: {
            days: res.leadTimeDays.STANDARD,
            price: res.product.priceStandard * productDetails.quantity,
          },
          EXPRESS: {
            days: res.leadTimeDays.EXPRESS,
            price: res.product.priceExpress * productDetails.quantity,
          },
        });
        setConfiguration({
          ...productDetails,
          name: res.product.name,
          weight: res.product.weight,
        });
        setPriceLoading(false);
      });
    }
  };

  const onUnloadMsg = reconfiguration
    ? strings.WarningChangesNotSaved
    : strings.WarningLoseConfig;

  const resetState = useCallback(() => {
    setAddedToCart(false);
    setAddedToCart(false);
    setCurrentInfo(undefined);
    setPreviewImage("");
    setPriceLoading(false);
    setPreviewImageLoading(false);
    setFileName("");
    setConfiguration(undefined);
    sessionStorage.removeItem("configuration");
    sessionStorage.removeItem("reconfigSku");
    setCNCProductId(null);
  }, [setFileName, setCNCProductId]);

  const initModelDemo = useCallback(() => {
    setPriceLoading(true);
    setModelLoading(true);
    setDfmLoading(true);

    const command = {
      quantity: 1,
      material: "alu-6082",
      finish: "standard",
      blueprint: null,
      comment: null,
      threads: [],
    };
    apiEstimateProductDemo(command).then((res) => {
      setFileName(res.product.name);
      const productDetails = {
        ...res.product.details,
        finish: (res.product.details?.finish as any).split(","),
      } as CNCProductDetailsType;
      const newPrices = {
        EXPRESS: res.product.priceExpress,
        STANDARD: res.product.priceStandard,
        ECONOMIC: res.product.priceEconomic,
      };
      setPrices(newPrices);
      setTotalLeadTimeOptions({
        ECONOMIC: {
          days: res.leadTimeDays.ECONOMIC,
          price: res.product.priceEconomic * productDetails.quantity,
        },
        STANDARD: {
          days: res.leadTimeDays.STANDARD,
          price: res.product.priceStandard * productDetails.quantity,
        },
        EXPRESS: {
          days: res.leadTimeDays.EXPRESS,
          price: res.product.priceExpress * productDetails.quantity,
        },
      });
      setConfiguration({
        ...productDetails,
        name: res.product.name,
        weight: res.product.weight,
      });

      apiGetDemoPreviewImage().then((img) => {
        setPreviewImage(img);
        setPreviewImageLoading(false);
      });
      apiGetDemoModelInfo().then((dat) => {
        setCurrentInfo(dat);
        setPriceLoading(false);
      });
    });
  }, [setPreviewImageLoading]);

  const initModel = useCallback(
    (id) => {
      if (cartId === 0) return;
      setPriceLoading(true);
      setModelLoading(true);
      setDfmLoading(true);
      apiGetProduct(token, id).then((product) => {
        setFileName(product.name);
        const conf = {
          ...product.details,
          finish: (product.details?.finish as any).split(","),
        } as CNCProductDetailsType;
        setConfiguration({
          ...conf,
          name: product.name,
          weight: product.weight,
        });
        setPrices({
          ECONOMIC: product.priceEconomic,
          STANDARD: product.priceStandard,
          EXPRESS: product.priceExpress,
        });
        apiGetPreviewImage(token, conf.modelId).then((res) => {
          setPreviewImage(res);
          setPreviewImageLoading(false);
        });
        apiGetModelInfo(token, conf.modelId).then((dat) => {
          setCurrentInfo(dat);
        });
        apiEstimateProduct(
          token,
          id,
          {
            quantity: conf.quantity,
            finish: conf.finish.join(","),
            material: conf.material,
            threads: conf.threads,
            blueprint: conf.blueprint,
            comment: conf.comment,
          },
          cartId
        )
          .then((res) => {
            const cncProduct = {
              ...res.product.details,
              finish: (res.product.details?.finish as any).split(","),
            } as unknown as CNCProductDetailsType;
            return {
              id: res.product.id,
              name: res.product.name,
              priceExpress: res.product.priceExpress,
              priceStandard: res.product.priceStandard,
              priceEconomic: res.product.priceEconomic,
              weight: res.product.weight,
              modelId: cncProduct.modelId,
              model: cncProduct.model,
              quantity: cncProduct.quantity,
              material: cncProduct.material,
              finish: cncProduct.finish,
              blueprint: cncProduct.blueprint,
              comment: cncProduct.comment,
              threads: cncProduct.threads,
              leadTimeDays: res.leadTimeDays,
            } as CNCEstimateType;
          })
          .then((res) => {
            setTotalLeadTimeOptions({
              ECONOMIC: {
                days: res.leadTimeDays.ECONOMIC,
                price: res.priceEconomic * res.quantity,
              },
              STANDARD: {
                days: res.leadTimeDays.STANDARD,
                price: res.priceStandard * res.quantity,
              },
              EXPRESS: {
                days: res.leadTimeDays.EXPRESS,
                price: res.priceExpress * res.quantity,
              },
            });
            setPriceLoading(false);
          });
      });
    },
    [cartId, leadTimeOptions, setPreviewImageLoading, token]
  );

  // Initial load
  useEffect(() => {
    apiGetThreadsList().then((res) => setThreadList(res));
    if (state?.reconfigure) {
      console.log("Reconfiguration");
      setReconfiguration(state?.reconfigure);
      setExistingCartItemId(state.cartItemId);
      setCNCProductId(state.productId);
    }
  }, [state]);

  // On productId change
  useEffect(() => {
    if (cncProductId && !demo) {
      // console.log("Init with id: ", productId);
      initModel(cncProductId);
    } else if (demo) {
      initModelDemo();
    }
  }, [demo, initModel, initModelDemo, cncProductId, setCNCProductId]);

  // Used to prevent people from accidentially closing or leaving the page without saving
  useEffect(() => {
    const beforeUnload = (e: any) => {
      e.preventDefault();
      // Hacky solution to force chrome to show the alert when closing the tab or reloading the page
      e.returnValue = "";
      return true;
    };
    const onUnload = (e: any) => {
      e.preventDefault();
      resetState();
      return true;
    };
    if (
      cncProductId &&
      cncProductId !== 0 &&
      !demo &&
      !addedToCart &&
      !showLogoutModal
    ) {
      window.addEventListener("unload", onUnload);
      window.addEventListener("beforeunload", beforeUnload);
    }
    return () => {
      window.removeEventListener("unload", onUnload);
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [
    addedToCart,
    demo,
    cncProductId,
    onUnloadMsg,
    resetState,
    showLogoutModal,
  ]);

  useEffect(() => {
    // Google Analytics Event
    console.log("Prices", prices);
    if (!demo && cncProductId) {
      if (prices["STANDARD"] !== 0) {
        ReactGA.event({
          category: "Component Calculations",
          action: "Price Updated",
          label: cncProductId + ":" + fileName,
          value: prices[selectedLeadTime],
          nonInteraction: true,
        });
        trackEvent({
          category: "Component Configuration",
          action: "Price Updated",
          name: cncProductId + ":" + fileName,
          value: prices[selectedLeadTime],
        });
      }
    }
  }, [prices]);

  // Runs when the user is done configurering and decides to add it to the cart
  // - changes depending on being in "RECONFIGURE MODE" or not.
  const handleFinishedComponent = async () => {
    if (token && cncProductId && configuration) {
      const finalThreads: SelectedThreadsInfoType[] = [];
      configuration.threads?.forEach((thread) => {
        if (thread.name !== "none") {
          const threadObj = threadList?.find((t) => t.name === thread.name);
          finalThreads.push({
            ...thread,
            color: threadObj?.color,
          });
        }
      });
      setAddingtoCart(true);

      if (selectedLeadTime !== leadtimeOption) {
        try {
          await changeLeadTime(selectedLeadTime);
        } catch (e) {
          console.log(e);
        }
      }

      const command = {
        quantity: configuration.quantity,
        material: configuration.material,
        finish: configuration.finish.join(","),
        blueprint: configuration.blueprint,
        comment: configuration.comment,
        threads: finalThreads,
      } as UpdateCNCProductType;

      apiUpdateCNCProduct(token, cncProductId, command).then(
        (updatedProduct) => {
          if (updatedProduct.id) {
            if (reconfiguration) {
              let item = cartItems.find(
                (item) => item.id === existingCartItemId
              );
              if (item) {
                item.quantity = configuration.quantity;
                updateCartItem(item).then((res) => {
                  if (res) {
                    ReactGA.event({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      label: cncProductId + ":" + fileName,
                    });
                    trackEvent({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      name: cncProductId + ":" + fileName,
                    });
                    setAddingtoCart(false);
                    setAddedToCart(true);
                  }
                });
              }
            } else {
              addCartItem(updatedProduct.id, configuration.quantity).then(
                (res) => {
                  if (res) {
                    ReactGA.event({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      label: cncProductId + ":" + fileName,
                    });
                    trackEvent({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      name: cncProductId + ":" + fileName,
                    });
                    setAddingtoCart(false);
                    setAddedToCart(true);
                  }
                }
              );
            }
          }
        }
      );
    }
  };

  // When a configuration has been added to the users cart,
  // they can either chose to go the their cart or create a new configuration in the <Sweetalert>.
  // The page reloads for a new config since this is easier.
  const handleGoToCart = () => {
    resetState();
    history.push("/indkoebskurv", undefined);
    //clearModelContext();
  };

  const handleNewComponent = () => {
    resetState();
    history.push("/ny-komponent/cnc", undefined);
    window.location.reload();
  };

  return (
    <div>
      {addedToCart ? (
        <SweetAlert
          success
          title={
            !reconfiguration
              ? strings.CompConfigSucces
              : strings.CompConfigUpdated
          }
          allowEscape={false}
          timeout={reconfiguration ? 2000 : undefined}
          showConfirm={!reconfiguration}
          customButtons={
            !reconfiguration ? (
              <p
                style={{
                  display: "flex",
                  zIndex: 1,
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Button
                  color="warning"
                  onClick={handleGoToCart}
                  sx={{ color: "#E0A900" }}
                >
                  <b>{strings.CompConfigUpdatedGoToBasketButton}</b>
                </Button>
                <Button
                  color="primary"
                  onClick={handleNewComponent}
                  sx={{ color: "#008A80" }}
                >
                  <b>{strings.AddNewCompButton?.toUpperCase()}</b>
                </Button>
              </p>
            ) : null
          }
          onConfirm={handleGoToCart}
        >
          <span style={{ fontWeight: "400" }}>
            {!reconfiguration
              ? strings.CompConfigCompAddedToBasket
              : strings.CompConfigReturningToBasket}
          </span>
        </SweetAlert>
      ) : null}
      {addingToCart ? (
        <SweetAlert
          custom
          title={strings.PleaseWait}
          allowEscape={false}
          showConfirm={false}
          customIcon={
            <CircularProgress
              size={"80px"}
              style={{ marginLeft: "40%", color: primaryColor[0] }}
            />
          }
          onConfirm={() => {}}
        >
          <span style={{ fontWeight: "400" }}>
            {!reconfiguration
              ? strings.CompConfigAddedingToBasket
              : strings.CompConfigUpadateingComp}
          </span>
        </SweetAlert>
      ) : null}
      <div className={`${styles.container}`}>
        <div>
          <CNC
            productId={cncProductId}
            onChange={demo ? updateDemoConfiguration : updateConfiguration}
            configuration={configuration}
            threadList={threadList}
            demo={demo}
            reconfiguration={reconfiguration}
            onUpload={(id) => {
              resetState();
              setCNCProductId(id);
            }}
            confReset={resetState}
          />
        </div>

        <div>
          <ConfiguratorPriceSummary
            disabled={
              !cncProductId ||
              cncProductId === 1 ||
              priceLoading ||
              addingToCart
            }
            isReconfiguration={reconfiguration}
            configuration={configuration}
            previewImage={previewImage}
            dimensions={
              (currentInfo?.bboxDx.toFixed(1) ?? 0) +
                "mm x " +
                (currentInfo?.bboxDy.toFixed(1) ?? 0) +
                "mm x " +
                (currentInfo?.bboxDz.toFixed(1) ?? 0) +
                "mm" ?? ""
            }
            priceLoading={priceLoading}
            loadingImages={previewImageLoading}
            addingToCart={addingToCart}
            prices={prices}
            setQuantity={(quantity: number) =>
              configuration &&
              (demo
                ? updateDemoConfiguration({ ...configuration, quantity })
                : updateConfiguration({ ...configuration, quantity }))
            }
            setComment={(comment: string) =>
              configuration &&
              (demo
                ? updateDemoConfiguration({ ...configuration, comment })
                : updateConfiguration({ ...configuration, comment }))
            }
            handleFinishedComponent={handleFinishedComponent}
            selectedLeadTime={selectedLeadTime}
            setSelectedLeadTime={setSelectedLeadTime}
            leadTimeOptions={totalLeadTimeOptions}
            resetState={resetState}
          />
        </div>
      </div>
    </div>
  );
};
export default CNCConfigurator;
