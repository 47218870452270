import React, { useContext } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { Route, Switch } from "react-router";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/homeStyle.js";
import Background from "assets/img/background1.jpg";
import AppStateContext from "contexts/AppStateContext";
import LoginPage from "views/authPages/LoginPage";
import ResetPassword from "views/authPages/ResetPassword";
import { createStyles } from "@mui/material";
import NotificationBanner from "components/NotificationBanner";
import AcceptQuote from "views/dashboardPages/AcceptQuote";
import MaintenancePage from "views/homePages/MaintenancePage";

const useStyles = makeStyles(createStyles(styles));

const HomeLayout = () => {
  // styles
  const classes = useStyles();

  const { strings } = useContext(AppStateContext);
  let { path, url } = useRouteMatch();

  return (
    <div className={classes.wrapper}>
      {/* <NotificationBanner /> */}
      <AuthNavbar brandText={"EasyPartz"} />
      <div
        className={classes.fullPage}
        style={{
          backgroundImage: "url(" + Background + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
        }}
      >
        {/*@ts-ignore*/}
        <Switch>
          {/*@ts-ignore*/}
          <Route path={`${path}/quote/:quoteId`} component={AcceptQuote} />
          <Route path={`${path}/maintenance`} component={MaintenancePage} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

export default HomeLayout;
