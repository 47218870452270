import { Reducer } from "react";

export enum ListReducerEnum {
  ADD,
  UPDATE,
  REMOVE,
  CLEAR,
  SET,
}

export type actionType<T> = {
  value: T | T[];
  type: ListReducerEnum;
};

export default <T>(key: keyof T): Reducer<T[], actionType<T>> =>
  (state: T[], action: actionType<T>) => {
    switch (action.type) {
      case ListReducerEnum.SET:
        let setState: T[] = [];
        if (Array.isArray(action.value)) {
          setState = action.value;
        } else {
          setState.push(action.value);
        }
        return setState;
      case ListReducerEnum.ADD:
        if (Array.isArray(action.value)) {
          return [...state, ...(action.value as T[])];
        } else {
          return [...state, action.value as T];
        }

      case ListReducerEnum.UPDATE:
        if (Array.isArray(action.value)) {
          const updateState = state.filter(
            (item: T) =>
              (action.value as T[]).find((i) => i[key] == item[key]) ==
              undefined
          );
          return [...updateState, ...(action.value as T[])];
        } else {
          const updateState = state.filter(
            (item: T) => item[key] != (action.value as T)[key]
          );
          return [...updateState, action.value as T];
        }

      case ListReducerEnum.REMOVE:
        if (Array.isArray(action.value)) {
          return state.filter(
            (item: T) =>
              (action.value as T[]).find((i) => i[key] == item[key]) ==
              undefined
          );
        } else {
          return state.filter(
            (item: T) => item[key] != (action.value as T)[key]
          );
        }

      case ListReducerEnum.CLEAR:
        return [];
      default:
        return state;
    }
  };
