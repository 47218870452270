import { FetchError } from "exceptions/exceptions";
import { OrderListType, OrderType } from "types/OrderTypes";
import { API_URL } from "./Common";

const ENDPOINT = `${API_URL}/v1/ecommerce/orders`;

export const apiGetOrders = (
  token: string,
  page: number,
  pageSize: number
): Promise<OrderListType> => {
  return fetch(`${ENDPOINT}?page=${page}&pageSize=${pageSize}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetOrder = (
  token: string,
  orderID: string
): Promise<OrderType> => {
  return fetch(`${ENDPOINT}/${orderID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetOrderInvoice = (
  token: string,
  orderID: number
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderID}/invoice`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    console.log(res);
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiSendInvoiceEmail = (
  token: string,
  orderID: string
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderID}/invoice/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
