import { ThreeEvent } from "@react-three/fiber";
import {
  forwardRef,
  FunctionComponent,
  MutableRefObject,
  ReactNode,
  Ref,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  BackSide,
  BufferGeometry,
  Color,
  DoubleSide,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Vector3,
} from "three";
import { staticColors } from "util/ColorUtil";

type PropsType = {
  mesh: Mesh;
  edgeMeshes?: Line[];
  transparent?: boolean;
  showRawEdges?: boolean;
  color?: Color;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  onClick?: (event: ThreeEvent<MouseEvent>) => void;
  modelGroupRef?: MutableRefObject<Group | undefined>;
};

const RegularModel: FunctionComponent<PropsType> = (
  {
    mesh,
    edgeMeshes,
    transparent,
    showRawEdges,
    color,
    onMouseEnter,
    onMouseLeave,
    onClick,
    modelGroupRef,
  },
  ref
) => {
  const edgeRef = useRef<LineSegments>();
  const modelRef = useRef<Mesh>();

  const meshMaterial = useMemo(() => {
    const mat = new MeshBasicMaterial();
    mat.color.set(color ?? staticColors.default);
    //mat.specular.set(new Color(0xf3f3f3));
    //mat.shininess = 200;
    //mat.side = DoubleSide
    return (
      <meshBasicMaterial
        {...mat}
        polygonOffset
        polygonOffsetFactor={1}
        polygonOffsetUnits={4}
      />
    );
  }, [color]);

  const outlineMaterial = useMemo(() => {
    return (
      <meshBasicMaterial
        side={BackSide}
        color={staticColors.black}
        polygonOffset
        polygonOffsetFactor={-1}
        polygonOffsetUnits={4}
      />
    );
  }, []);

  const lineMaterial = useMemo(() => {
    return (
      <lineBasicMaterial
        color={staticColors.edge}
        transparent
        opacity={0.25}
        polygonOffset
        polygonOffsetFactor={1}
        polygonOffsetUnits={8}
      />
    );
  }, []);

  const edgesGeometry = useMemo(() => {
    const geometry = new BufferGeometry();

    //const positions: number[] = [];
    const indices: number[] = [];
    const points: Vector3[] = [];
    let startIndex = 0;
    edgeMeshes?.forEach((edge, key) => {
      const edgePositions = edge.geometry.getAttribute("position");
      //if (key < 10) console.log(edge.geometry);
      const size = edgePositions.itemSize;
      let number = edgePositions.array.length;
      for (let i = 0; i < number; i += size) {
        points.push(
          new Vector3(
            edgePositions.array[i],
            edgePositions.array[i + 1],
            edgePositions.array[i + 2]
          )
        );
        indices.push(startIndex, startIndex + 1);
        startIndex++;
      }
      points.push(new Vector3(NaN, NaN, NaN));
      startIndex++;
      //positions.push(...Array.from(edgePositions.array));
    });
    //console.log(positions.length / 3, indices);
    //#################### TESTING #####################
    var geom = geometry.setFromPoints(points);
    geom.setIndex(indices);
    var line = new LineSegments(geom);
    //scene.add(line);

    //####################

    // geometry.setAttribute("position", new Float32BufferAttribute(positions, 3));
    // geometry.setIndex(indices);
    // geometry.computeBoundingSphere();

    return <lineSegments {...line}>{lineMaterial}</lineSegments>;
  }, [edgeMeshes, lineMaterial]);

  //console.log(scene);

  // const edgeModel = useMemo(() => {
  //   if (edgeMeshes) {
  //     return (
  //       <group name="edges" ref={edgeRef} matrixAutoUpdate={false}>
  //         {edgeMeshes.map((edge) => {
  //           // if (modelCenter)
  //           //   edge.position.set(
  //           //     1 - modelCenter?.x,
  //           //     1 - modelCenter?.y,
  //           //     1 - modelCenter?.z
  //           //   );
  //           return (
  //             <line_ key={edge.id} {...edge} matrixAutoUpdate={false}>
  //               <bufferGeometry {...edge.geometry} />
  //               {lineMaterial}
  //             </line_>
  //           );
  //         })}
  //       </group>
  //     );
  //   }
  // }, [edgeMeshes, lineMaterial]);

  const edgeModel = useMemo(() => {
    if (mesh) {
      return (
        <lineSegments
          name={mesh.uuid + "line"}
          ref={edgeRef}
          position={mesh.position}
          //matrixAutoUpdate={false}
        >
          <edgesGeometry args={[mesh.geometry, 5]} />
          {lineMaterial}
        </lineSegments>
      );
    }
    return;
  }, [lineMaterial, mesh]);

  const prepModels = useMemo(() => {
    if (mesh) {
      return (
        <mesh {...mesh} matrixAutoUpdate={false} ref={modelRef}>
          <bufferGeometry {...mesh.geometry} />
          {meshMaterial}
        </mesh>
      );
    }
    return;
  }, [mesh, meshMaterial]);

  const outlineModel = useMemo(() => {
    if (mesh) {
      return (
        <mesh {...mesh} matrixAutoUpdate={false} scale={1.1}>
          <bufferGeometry {...mesh.geometry} />
          {outlineMaterial}
        </mesh>
      );
    }
    return;
  }, [mesh, outlineMaterial]);

  useEffect(() => {
    if (transparent) {
      // if (edgeRef.current) {
      //   edgeRef.current.visible = true;
      // }
      if (modelRef?.current) {
        modelRef.current.visible = false;
      }
    } else {
      // if (edgeRef.current) {
      //   edgeRef.current.visible = false;
      // }
      if (modelRef?.current) {
        modelRef.current.visible = true;
      }
    }
  }, [transparent]);

  return (
    <group
      name="models"
      ref={modelGroupRef}
      matrixAutoUpdate={false}
      onClick={onClick}
    >
      {showRawEdges && edgeModel}
      {/* {prepModels} */}
      {/* {prepModels}
      {outlineModel} */}
      {edgesGeometry}
      <group matrixAutoUpdate={false} ref={modelRef}>
        {prepModels}
        {outlineModel}
      </group>
    </group>
  );
};
export default RegularModel;
