import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const RouteChangeTracker = ({}) => {
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  trackPageView({ documentTitle: history.location.pathname });
  ReactGA.set({ page: history.location.pathname });
  ReactGA.pageview(history.location.pathname);
  console.log("page changed", history.location.pathname);

  //  useEffect(() => {
  //     return history.listen((location) => {
  //       ReactGA.set({ page: location.pathname });
  //       ReactGA.pageview(location.pathname);
  //       trackPageView({documentTitle: location.pathname});
  //     })
  //  },[history])

  return <></>;
};

export default withRouter(RouteChangeTracker);
