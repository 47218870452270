import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiGetOrders } from "util/network/Orders";
import useWindowDimensions from "../../../vaerksComponents/useWindowDimensions";
import { OrderType } from "types/OrderTypes";
import AppStateContext from "contexts/AppStateContext";
import SweetAlert from "react-bootstrap-sweetalert";
import styleTable from "../../vaerksComponents/checkoutComponents/styles/payment.module.css";
import styles from "./css/newOrderHistory.module.css";
import OrderHistoryRow from "./OrderHistoryRow";
import OrderHistoryDetails from "vaerksComponents/orderHistoryRowComponents/OrderHistoryDetails";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Button from "../../../components/CustomButtons/Button";
import { Grid, LinearProgress, TablePagination } from "@mui/material";

const OrderHistory = () => {
  const { strings, token } = useContext(AppStateContext);
  const [selectedOrder, setSelectedOrder] = useState<OrderType>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(15);
  const [total, setTotal] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [emailSent, setEmailSent] = useState<boolean | string>(false);

  const [orders, setOrders] = useState<OrderType[]>([]);

  const sortOrders = useCallback(
    (orders: OrderType[], sortKey: keyof OrderType, sortDirection: boolean) => {
      return orders.sort((a, b) => {
        const aVal = a[sortKey];
        const bVal = b[sortKey];
        if (aVal == undefined || bVal == undefined) {
          return 0;
        }
        if (aVal < bVal) {
          return sortDirection ? 1 : -1;
        }
        if (aVal > bVal) {
          return sortDirection ? -1 : 1;
        }
        return 0;
      });
    },
    []
  );

  useEffect(() => {
    setLoading(true);
    apiGetOrders(token, page + 1, pageSize).then((res) => {
      setTotal(res.total);
      setOrders(sortOrders(res.items, "orderNo", true));
    });
  }, [page, pageSize, sortOrders, token]);

  const { width } = useWindowDimensions();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  function handleClose(itemsAdded: boolean) {
    setSelectedOrder(undefined);
  }

  function openOrderDetails(row: OrderType) {
    setSelectedOrder(row);
  }

  const rows = useMemo(() => {
    setLoading(true);
    if (!orders) return [];
    const rowsList = orders.map((order, key) => {
      return (
        <OrderHistoryRow
          key={order.id}
          order={order}
          onClick={openOrderDetails}
          setLoading={setLoading}
          setEmailSent={setEmailSent}
        />
      );
    });
    setLoading(false);
    return rowsList;
  }, [orders]);

  return (
    <Grid container justifyContent="center" marginTop={"1.8em"}>
      {selectedOrder && <OrderHistoryDetails order={selectedOrder} />}
      <Grid item xs={12} sm={12} md={11} lg={11}>
        {emailSent ? (
          <SweetAlert
            success
            title={strings.OrderHistoryResendt}
            closeOnClickOutside={true}
            onCancel={() => setEmailSent(false)}
            customButtons={
              //@ts-ignore
              <Button color="success" onClick={() => setEmailSent(false)}>
                {strings.ButtonOk}
              </Button>
            }
            onConfirm={() => {}}
          >
            {strings.OrderHistoryCheckSpamMsg}
          </SweetAlert>
        ) : null}
        {emailSent === "ERROR" ? (
          <SweetAlert
            danger
            closeOnClickOutside={true}
            allowEscape={false}
            onCancel={() => setEmailSent(false)}
            title={strings.ErrorMsg}
            customButtons={
              //@ts-ignore
              <button
                onClick={() => setEmailSent(false)}
                style={{
                  border: "none",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                  color: "#008A80",
                  background: "transparent",
                  borderBottom: "2px solid #008A80",
                }}
              >
                OKAY
                {strings.ButtonOK}
              </button>
            }
            onConfirm={() => {}}
          >
            <span style={{ fontWeight: "400" }}>
              {strings.OrderHistoryResendtError}
            </span>
          </SweetAlert>
        ) : null}
        <h1 className={`${styles.header}`}>{strings.OrderHistorySmall}</h1>
        <div className={`${styles.container}`}>
          {loading ? <LinearProgress /> : null}

          <table
            style={{
              width: "80%",
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <tr>
              <td align="left" className={`${styles.table__header}`}>
                {strings.OrderHistoryOrderNumber} / {strings.OrderHistoryDate}
              </td>
              <td align={"left"} className={`${styles.table__header}`}>
                {strings.OrderHistoryStatus}
              </td>
              <td align={"left"} className={`${styles.table__header}`}>
                {strings.Delivery.toUpperCase()}
              </td>
              <td align={"left"} className={`${styles.table__header}`}>
                {strings.ThreadCardQuantity.toUpperCase()}
              </td>
              <td align={"right"} className={`${styles.table__header}`}>
                {strings.Total?.toUpperCase()}
              </td>
              <td align={"right"} className={`${styles.table__header}`}></td>
            </tr>
            <tr>
              <td
                align="left"
                colSpan={6}
                className={`${styles.line__container_solid}`}
              >
                <div className={`${styles.line_solid}`}></div>
              </td>
            </tr>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div style={{ paddingRight: "10rem" }}>
          <TablePagination
            component={"div"}
            count={total}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[15, 30, 50]}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default OrderHistory;
