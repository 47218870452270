import React, { useState } from "react";
import ReactDOM from "react-dom";
//@ts-ignore
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CookieBot from "react-cookiebot";
import ReactGA from "react-ga";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import "typeface-roboto";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import magentoReducer from "./reducers/magentoReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import RouteChangeTracker from "./vaerksComponents/RouteChangeTracker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CookiesProvider } from "react-cookie";
import {
  dangerColor,
  primaryColor,
  whiteColor,
} from "./assets/jss/material-dashboard-pro-react";
import { CompConfigProvider } from "contexts/CompConfigContext";
import { TRACKING_ID } from "util/GAUtil";
import "styles/global.css";
import "styles/overrides.css";
import { AppStateProvider } from "contexts/AppStateContext";
import { EcommerceProvider } from "contexts/EcommerceContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomeLayout from "layouts/HomeLayout";

import AuthLayout from "layouts/AuthLayout";
import RegisterPage from "views/authPages/RegisterPage";
import RegisterLayout from "layouts/RegisterLayout";
import Footer from "components/Footer/Footer";
import Navbar from "components/Navbars/Navbar";
import DashboardLayout from "layouts/DashboardLayout";
import NewFooter from "components/Footer/NewFooter";

const App = () => {
  // Used for styling the primary and secondary color options used by Material UI
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1320,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: primaryColor[0],
        contrastText: whiteColor,
      },
      secondary: {
        main: dangerColor[1],
        contrastText: whiteColor,
      },
    },
  });

  const store = createStore(
    //@ts-ignore
    magentoReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );

  //ReactGA.initialize(TRACKING_ID);
  ReactGA.initialize("UA-207027030-1", {
    gaOptions: {
      cookieDomain: "auto",
    },
    //debug: false,
    //siteSpeedSampleRate: 100,
  });

  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: "https://a6774cb43b6e42d294783ab10466063d@o994889.ingest.sentry.io/5760874",
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({ console: false }),
      ],
      release: "easypartz-frontend@" + process.env.REACT_APP_VERSION,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  const matomoUrl =
    process.env.NODE_ENV === "development"
      ? "https://matomo-dev.vaerks.com/"
      : "https://matomo.vaerks.com/";

  // Matomo User tracking
  const matomoInstance = createInstance({
    urlBase: matomoUrl,
    // process.env.NODE_ENV === "development"
    //   ? "https://matomo-dev.vaerks.com"
    //   : "https://matomo.vaerks.com",
    siteId: 1,
    //userId: 'UID76903202', // optional, default value: `undefined`.
    linkTracking: false,
  });

  const [hasCookieBot, setHasCookieBot] = useState(undefined);

  const domainGroupId = "ccb3e3f5-f528-4f7a-b984-cc2400f46f37";

  return (
    <div>
      <CookieBot domainGroupId={domainGroupId} />
      <MatomoProvider value={matomoInstance}>
        <CookiesProvider>
          <AppStateProvider>
            <Navbar />
            {/*@ts-ignore*/}
            <BrowserRouter basename={"/"}>
              <Provider store={store}>
                {/*@ts-ignore*/}
                <RouteChangeTracker />
                <ThemeProvider theme={theme}>
                  {/*@ts-ignore*/}
                  <Switch>
                    {/*@ts-ignore*/}
                    <Redirect exact from="/" to="auth" />
                    {/*@ts-ignore*/}
                    <Route path="/auth">
                      {/* <AuthLayout /> */}
                      <AuthLayout />
                    </Route>
                    <Route path="/register">
                      <RegisterLayout />
                    </Route>
                    {/*@ts-ignore*/}
                    <Route path="/info">
                      <HomeLayout />
                    </Route>
                    {/*@ts-ignore*/}
                    <Route path="/">
                      <EcommerceProvider>
                        <CompConfigProvider>
                          {/* <DashboardLayout /> */}
                          <DashboardLayout />
                        </CompConfigProvider>
                      </EcommerceProvider>
                    </Route>
                  </Switch>
                </ThemeProvider>
              </Provider>
            </BrowserRouter>
          </AppStateProvider>
        </CookiesProvider>
      </MatomoProvider>
      <NewFooter />
    </div>
  );
};

export default App;
