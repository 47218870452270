import {
  cardTitle,
  container,
  dangerColor,
  grayColor,
  primaryColor,
  successColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/creativeTim/components/modalStyle.js";
import { createStyles } from "@material-ui/core";

const loginPageStyle = (theme) =>
  createStyles({
    container: {
      ...container,
      zIndex: "4",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "100px",
      },
    },
    cardTitle: {
      ...cardTitle,
      color: whiteColor,
      marginBottom: "-5px",
      fontWeight: "bold",
    },
    textCenter: {
      textAlign: "center",
    },
    right: {
      textAlign: "right",
    },
    left: {
      textAlign: "left",
    },
    marginRight: {
      marginRight: "5px",
    },
    modalSectionTitle: {
      marginTop: "30px",
    },
    justifyContentCenter: {
      justifyContent: "center !important",
    },
    customButtonClass: {
      "&,&:focus,&:hover": {
        color: whiteColor,
      },
      marginLeft: "5px",
      marginRight: "5px",
    },
    inputAdornment: {
      marginRight: "18px",
    },
    inputAdornmentIcon: {
      color: grayColor[6],
    },
    cardHidden: {
      opacity: "0",
      transform: "translate3d(0, -60px, 0)",
    },
    cardHeader: {
      background: "linear-gradient(to bottom , #00b28d 7px, #607d8b 8px)",
    },
    loginParagraph: {
      hyphens: "auto",
      wordBreak: "break-word",
    },
    forgottenPassword: {
      hyphens: "auto",
      wordBreak: "break-word",
      "&:hover": {
        cursor: "pointer",
      },
    },
    dangerText: {
      color: dangerColor[0],
      fontWeight: "bold",
    },
    successText: {
      color: successColor[0],
      fontWeight: "bold",
    },
    socialLine: {
      padding: "0.9375rem 0",
    },
    circularSpinner: {
      marginLeft: "40%",
      color: primaryColor[0],
    },
    currentProfileDataContainer: {
      backgroundColor: "#eee",
      borderRadius: "10px",
      textAlign: "center",
      paddingBottom: "5%",
    },
    avatarAnimation: {
      marginBottom: "-5%",
      height: "200px",
    },
    niceText: {
      hyphens: "auto",
      wordBreak: "break-word",
    },
    totalIncVat: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: "inherit",
    },
    number: {
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    table: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      tableLayout: "fixed",
      width: "100%",
    },
    td: {
      display: "table-cell",
      padding: "1em",
      fontSize: "0.875rem",
      textAlign: "left",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: "1.43",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      letterSpacing: "0.01071em",
      verticalAlign: "inherit",
      //maxWidth: "15em",
    },
    th: {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
    textEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    confirmPurchase: {
      float: "right",
      display: "flex",
      fontWeight: "bold",
    },
    goBack: {
      float: "left",
      display: "flex",
      fontWeight: "bold",
    },
    buttonGroup: {
      fontWeight: "bold",
      marginBottom: "1em",
      float: "right",
      padding: "1em",
    },
    confirmBox: {
      float: "right",
      padding: ".5em 1em 0 1em",
    },
    button: {
      marginLeft: "1em !important",
    },
    ...modalStyle(theme),
  });

export default loginPageStyle;
