import { FetchError } from "exceptions/exceptions";
import { Line, Mesh } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { DFMInfoType } from "types/ModelTypes";
import {
  CNCProductDetailsType,
  CreateCNCProductType,
  CreatePrint3DProductType,
  EstimateProductType,
  ProductTypeEnum,
  ProductVMType,
  UpdateCNCProductType,
} from "types/products/ProductCommandsType";
import { UpdatePrint3DProductType } from "types/products/ProductCommandsType";
import {
  CNCEstimateType,
  CNCProductType,
  ProductOptionsType,
} from "types/products/ProductTypes";
import { ModelDataType, ReceivedModelType } from "types/RenderTypes";
import { checkGltfChild, modelLoader } from "util/ModelLoader";
import { API_URL } from "./Common";

const BASE_URL = `${API_URL}/v1/ecommerce/products`;

export const apiGetProduct = (
  token: string,
  id: number
): Promise<ProductVMType> => {
  return fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiCreateProduct = (
  token: string,
  command: CreateCNCProductType | CreatePrint3DProductType
): Promise<ProductVMType> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(command),
  };

  return fetch(`${BASE_URL}`, requestOptions).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiCreateCNCProduct = (
  token: string,
  command: CreateCNCProductType
): Promise<CNCProductType> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(command),
  };

  return fetch(`${BASE_URL}`, requestOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res: ProductVMType) => {
      if (res.productType === ProductTypeEnum.CNC) {
        const cncProduct = res.details as any;
        return {
          id: res.id,
          name: res.name,
          priceExpress: res.priceExpress,
          priceStandard: res.priceStandard,
          priceEconomic: res.priceEconomic,
          weight: res.weight,
          modelId: cncProduct.modelId,
          model: cncProduct.model,
          quantity: cncProduct.quantity,
          material: cncProduct.material,
          finish: cncProduct.finish.split(","),
          blueprint: cncProduct.blueprint,
          comment: cncProduct.comment,
          threads: cncProduct.threads,
        } as CNCProductType;
      }
      throw new Error("Product is not CNC");
    });
};

export const apiUpdateProduct = (
  token: string,
  productId: number,
  command: UpdateCNCProductType | UpdatePrint3DProductType
): Promise<ProductVMType> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(command),
  };

  return fetch(`${BASE_URL}/${productId}`, requestOptions).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiUpdateCNCProduct = (
  token: string,
  productId: number,
  command: UpdateCNCProductType
): Promise<CNCProductType> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(command),
  };

  return fetch(`${BASE_URL}/${productId}`, requestOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res: ProductVMType) => {
      console.log(res);
      if (res.productType === ProductTypeEnum.CNC) {
        const cncProduct = res.details as any;
        return {
          id: res.id,
          name: res.name,
          priceExpress: res.priceExpress,
          priceStandard: res.priceStandard,
          priceEconomic: res.priceEconomic,
          weight: res.weight,
          modelId: cncProduct.modelId,
          model: cncProduct.model,
          quantity: cncProduct.quantity,
          material: cncProduct.material,
          finish: cncProduct.finish.split(","),
          blueprint: cncProduct.blueprint,
          comment: cncProduct.comment,
          threads: cncProduct.threads,
        } as CNCProductType;
      }
      throw new Error("Product is not CNC");
    });
};

export const apiEstimateProduct = (
  token: string,
  productId: number,
  productOptions: UpdateCNCProductType | UpdatePrint3DProductType,
  cartId?: number
): Promise<EstimateProductType> => {
  const command = {
    ...productOptions,
    cartId: cartId,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(command),
  };

  return fetch(`${BASE_URL}/${productId}/estimate`, requestOptions).then(
    (res) => {
      if (res.ok) {
        return res.json();
      }
    }
  );
};

export const apiGetProductOptions = (
  token: string,
  productId: number,
  material: string
): Promise<ProductOptionsType> => {
  let query = "";
  if (material) {
    query = `?material=${material}`;
  }
  return fetch(`${BASE_URL}/${productId}/options${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiGetBlueprint = (
  token: string,
  productId: number
): Promise<Blob> => {
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    console.log(res);
    return res.blob();
  });
};

export const apiAddBlueprint = (
  token: string,
  productId: number,
  file: File
) => {
  if (file.type !== "application/pdf") {
    throw new Error("File is not PDF");
  }
  const data = new FormData();
  data.append("file", file);
  data.append("filename", file.name);
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => {
    if (res.ok) {
      return res.text();
    }
  });
};

export const apiRemoveBlueprint = (token: string, productId: number) => {
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.text();
    }
  });
};

const wait = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

function fetchRetry(
  url: string,
  delay: number,
  tries: number,
  requestOptions?: RequestInit
): Promise<any> {
  return fetch(url, requestOptions)
    .then((res) => {
      if (res.ok) return res;
      else throw new FetchError(res.statusText, res.status);
    })
    .catch((err) => {
      if (tries === 0) {
        throw err;
      }
      return wait(delay).then(() =>
        fetchRetry(url, delay * 2, tries - 1, requestOptions)
      );
    });
}

export const apiGetPreviewImage = (token: string, id: number) => {
  return fetch(` ${API_URL}/v1/ecommerce/models/${id}/preview`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.blob();
    })
    .then((img) => {
      const imageObjURL = URL.createObjectURL(img);
      return imageObjURL;
    });
};

export const apiGetGLTF = (
  token: string,
  id: number
): Promise<ReceivedModelType> => {
  const requestOptionss: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(requestOptionss);
  return fetch(
    `${API_URL}/v1/ecommerce/models/${id}/file/generated`,
    requestOptionss
  )
    .then((data) => {
      return modelLoader(token, data.url);
    })
    .then((gltf: GLTF) => {
      let res: ReceivedModelType = {
        models: [],
        edges: [],
      };
      const test = checkGltfChild(gltf.scene);
      test.forEach((item) => {
        if (item instanceof Line) {
          res.edges.push(item);
        }
        if (item instanceof Mesh) {
          res.models.push(item);
        }
      });
      // console.log(test);
      // gltf.scene.children.forEach((item) => {
      //   let mod = item;
      //   if (mod.name === "COMPOUND" || mod.name === "SOLID") {
      //     if (mod.children.length === 1) {
      //       mod = mod.children[0];
      //     }
      //     mod.children.forEach((child) => {
      //       if (child.name === "EDGES") {
      //         res.edges = child.children as Line[];
      //       }
      //       if (child.name === "FACES") {
      //         res.models = child.children as Mesh[];
      //       }
      //     });
      //   }
      // });
      return res;
    });
};

export const apiGetModelInfo = (
  token: string,
  id: number
): Promise<ModelDataType> => {
  return fetch(`${API_URL}/v1/ecommerce/models/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.features) {
        const shaftsData = data.features.shafts.map((shaft: any) => ({
          ...shaft,
          depth: shaft.length,
        }));
        return {
          ...data,
          features: { ...data.features, shafts: shaftsData },
        };
      } else {
        return data;
      }
    })
    .catch((e) => console.log("error", e));
};

export const apiGetErrorGLTFModel = (token: string, id: number) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch(`${API_URL}/v1/ecommerce/models/${id}/file/dfm`, requestOptions)
    .then((res) => {
      return res.url;
    })
    .then((data) => modelLoader(token, data));
  // .then(async (gltf: GLTF) => {
  //   let res = {
  //     face: null,
  //     edge: null,
  //     vertex: null,
  //     thickness: null,
  //     clearance: null,
  //   } as {
  //     face: null | Mesh;
  //     edge: null | LineSegments;
  //     vertex: null | Points;
  //     thickness: null | Mesh;
  //     clearance: null | Mesh;
  //   };
  //   gltf.scene.children.forEach((mod) => {
  //     if (mod.name === "DFM_Analysis") {
  //       mod.children.forEach((child) => {
  //         if (child.name === "Face_issues") {
  //           res.face = child as Mesh;
  //           //res.rotation.set(0, 0, Math.PI / 2);
  //         }
  //         if (child.name === "Vertex_issues") {
  //           res.vertex = child as Points;
  //           //res.rotation.set(0, 0, Math.PI / 2);
  //         }
  //         if (child.name === "Edge_issues") {
  //           res.edge = child as LineSegments;
  //           //res.rotation.set(0, 0, Math.PI / 2);
  //         }
  //         if (child.name === "Thickness_mesh") {
  //           res.thickness = child as Mesh;
  //         }
  //         if (child.name === "Thickness_issues") {
  //           res.thickness = child as Mesh;
  //         }
  //         if (child.name === "Clearance_issues") {
  //           res.clearance = child as Mesh;
  //         }
  //       });
  //     }
  //   });
  //   return res;
  // })
  // .catch((err) => {
  //   window.alert("Der skete en fejl: " + err);
  // });
};

export const apiGetDfmInfo = (
  token: string,
  id: number
): Promise<DFMInfoType> => {
  return fetch(`${API_URL}/v1/ecommerce/models/${id}/dfm`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((e) => console.log("error", e));
};
