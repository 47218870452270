import React, { useContext } from "react";
import Cards from "components/NewCard/Cards";
import CardsNote from "components/NewCard/CardsNote";
import styles from "./css/newLoginPageStyles.module.css";
import AppStateContext from "contexts/AppStateContext";
import CustomButton from "components/Buttons/CustomButton";
import { useHistory } from "react-router";
import stylesCard from "../../components/NewCard/cards.module.css";

const LoginNote = () => {
  const { strings } = useContext(AppStateContext);

  const history = useHistory();

  const handleButtonRegister = () => {
    history.push("/register");
  };

  return (
    <CardsNote
      className={`${stylesCard.card__login_note} ${stylesCard.card__login_note_s}`}
    >
      <h2 className={`${styles.demo__title}`}>Før vi starter</h2>
      <p className={`${styles.demo__text}`}>
        Hvis du vil se mulighederne inde du opretter en bruger så klik herfor at
        afprøve vores demo model. {strings.BeforeWeStartText}
      </p>

      <CustomButton
        buttonText={strings.DemoButton}
        buttonClass={`${styles.button__demo}`}
        iconClass={`${styles.demo__icon}`}
        id="demoOpen"
        onClick={() =>
          history.replace({
            pathname: "ny-komponent",
            search: "?demo=true",
          })
        }
      />

      <div className={`${styles.line__container}`}>
        <hr className={`${styles.line} `}></hr>
      </div>
      <h2 className={`${styles.demo__title}`}>Opret en konto</h2>
      <p className={`${styles.demo__text}`}>
        Opret en bruger og følg med i din ordrestatus, genbestil tidligere
        konfigurationer samt hurtig bestilling af nye komponenter.
      </p>

      <CustomButton
        buttonText={strings.CreateUserButton}
        buttonClass={`${styles.button__register}`}
        iconClass={`${styles.register__icon}`}
        id="demoOpen"
        onClick={handleButtonRegister}
      />
    </CardsNote>
  );
};

export default LoginNote;
