import { FetchError } from "exceptions/exceptions";
import { ThreadType } from "types/ConfigTypes";
import { ModelType, UploadedModelsType } from "types/ModelTypes";
import { ProductTypeEnum } from "types/products/ProductCommandsType";
import { API_URL } from "./Common";

const BASE_URL = `${API_URL}/v1/ecommerce/models`;

export const apiGetThreadsList = (): Promise<ThreadType[]> => {
  return fetch(`${BASE_URL}/threads`).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiUploadModelFile = (
  token: string,
  file: File,
  type: ProductTypeEnum
): Promise<UploadedModelsType> => {
  const data = new FormData();
  data.append("file", file);
  data.append("filename", file.name);
  data.append("type", type);
  return fetch(`${BASE_URL}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => res.json());
};
