import { FunctionComponent } from "react";
import styles from "./buttonLoader.module.css";

type PropsType = {
  styleLoader?: string;
};
const ButtonLoader: FunctionComponent<PropsType> = ({ styleLoader }) => {
  return <div className={`${styles.loader} ${styleLoader || ""}`}></div>;
};
export default ButtonLoader;
