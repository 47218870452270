import { FunctionComponent, useContext } from "react";
import AppStateContext from "contexts/AppStateContext";
import styles from "../checkoutComponents/styles/payment.module.css";
import { ReactComponent as MailActive } from "../../assets/svg/dashboard/mail_active.svg";
import { ReactComponent as Mail } from "../../assets/svg/dashboard/mail.svg";
import { ReactComponent as CreditCard } from "../../assets/svg/dashboard/creditCard.svg";
import { ReactComponent as CreditCardActive } from "../../assets/svg/dashboard/creditCardActive.svg";
import { ReactComponent as EanPay } from "../../assets/svg/dashboard/eanPay.svg";
import { ReactComponent as EanPayActive } from "../../assets/svg/dashboard/eanPayActive.svg";
import { ReactComponent as BankPay } from "../../assets/svg/dashboard/bankpay.svg";
import { ReactComponent as BankPayActive } from "../../assets/svg/dashboard/bankpayActive.svg";
import PaymentCard from "./PaymentCard";

type PropsType = {
  paymentMethod: string;
  setPaymentMethod: Function;
  hideOffer?: boolean;
};

const PaymentMethods: FunctionComponent<PropsType> = ({
  paymentMethod,
  setPaymentMethod,
  hideOffer,
}) => {
  const { strings } = useContext(AppStateContext);

  const IconBank = paymentMethod === "banktransfer" ? BankPayActive : BankPay;
  const IconCreditCard =
    paymentMethod === "card" ? CreditCardActive : CreditCard;
  const IconEan = paymentMethod === "ean" ? EanPayActive : EanPay;
  const IconMail = paymentMethod === "quote" ? MailActive : Mail;

  return (
    <div className={`${styles.payment__container}`}>
      <h4 className={`${styles.payment__title}`}>
        {strings.PaymentMethodLower}
      </h4>

      <div style={{ padding: "1rem" }}>
        <div
          style={{
            width: "100%",
          }}
        >
          <PaymentCard
            title={strings.PaymentBankTransfer}
            subtitle={strings.PaymentBankTransferSubTitle}
            icon={IconBank}
            active={paymentMethod === "banktransfer"}
            onClick={() => setPaymentMethod("banktransfer")}
          />
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <PaymentCard
            title={strings.PaymentCardTransfer}
            icon={IconCreditCard}
            active={paymentMethod === "card"}
            onClick={() => setPaymentMethod("card")}
            rowText={`${styles.payment__row_text_credit}`}
          />
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <PaymentCard
            title={strings.PaymentEAN}
            subtitle={strings.PaymentEANSubtitle}
            icon={IconEan}
            active={paymentMethod === "ean"}
            onClick={() => setPaymentMethod("ean")}
            iconStyles={`${styles.icon__ean_pay}`}
            rowText={`${styles.payment__row_text_ean}`}
          />
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          {!hideOffer ? (
            <PaymentCard
              title={strings.PaymentOffer}
              subtitle={strings.PaymentOfferSubtitle}
              icon={IconMail}
              active={paymentMethod === "quote"}
              onClick={() => setPaymentMethod("quote")}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default PaymentMethods;
