import { FunctionComponent } from "react";
import styles from "./RenderControls.module.css";
import { OpacityOutlined, FilterCenterFocus } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

type PropsType = {
  isTransparent: boolean;
  setIsTransparent: Function;
  centerCamFunc: Function | null;
  /*showErrorModelsState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];*/
};

const RenderControls: FunctionComponent<PropsType> = ({
  isTransparent,
  setIsTransparent,
  centerCamFunc,
  //showErrorModelsState,
}) => {
  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        size="small"
        orientation={"vertical"}
        aria-label="toggle"
      >
        {/* {showErrorModelsState && (
            <Tooltip title={"Skjul fejl"} placement={"left"}>
              <ToggleButton
                value="hideErrors"
                aria-label="hideErrors"
                onClick={() => {
                  showErrorModelsState?.[1]((prevState) => !prevState);
                }}
              >
                <ErrorOutline
                  htmlColor={!showErrorModelsState?.[0] ? "#ff704d" : "red"}
                />
              </ToggleButton>
            </Tooltip>
          )} */}
        {setIsTransparent && (
          <ToggleButton
            value="transparent"
            aria-label="transparent"
            onClick={() => {
              setIsTransparent((prevState: boolean) => !prevState);
            }}
          >
            <OpacityOutlined color={isTransparent ? "disabled" : "action"} />
          </ToggleButton>
        )}
        {centerCamFunc && (
          <ToggleButton
            value="centercam"
            aria-label="centercam"
            onClick={() => centerCamFunc()}
          >
            <FilterCenterFocus color={"action"} />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </div>
  );
};

export default RenderControls;
