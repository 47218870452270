import { createStyles } from "@mui/material";

const selectionCardStyle = createStyles({
  tooltipText: {
    color: "white",
    wordBreak: "break-word",
    hyphens: "auto",
  },
  cardSelected: {
    backgroundColor: "#008A80",
    color: "white",
    marginTop: "5%",
    marginBottom: "2%",
    border: "2px solid",
    borderRadius: "4px",
    borderColor: "#008A80",
    "&:hover": {
      cursor: "pointer",
      // transform: "scale(1.02)",
      // -webkit-font-smoothing: antialiased;
      // -moz-osx-font-smoothing: grayscale;
      // text-rendering: optimizeLegibility;
      boxShadow: " 1px 1px 4px #E9F5F2",
    },
  },
  cardNotSelected: {
    backgroundColor: "#e9f5f2",
    fontWeight: "400",
    color: "black",
    marginTop: "5%",
    marginBottom: "2%",
    transition: "transform .2s",
    "&:hover": {
      cursor: "pointer",
      // transform: "scale(1.05)",
    },
  },
  cardDisabled: {
    backgroundColor: "#eeeeee",
    // color: "grey !important",
    marginTop: "5%",
    marginBottom: "5%",
    transition: "transform .2s",
    // filter: "grayscale(100%) opacity(50%)",
  },
  icon: {
    float: "right",
    borderRadius: "4px",
    backgroundColor: "#e9f5f2",
    padding: "0.1em",
    width: "1.1em",
    height: "1.1em",
  },
  headline: {
    fontSize: "1rem",
    // marginLeft: "-10px",
  },
  delayMed: {
    color: "#ff9800",
  },
  delayDays: {
    float: "right",
    display: "flex",
    fontSize: "20px !important",
    whiteSpace: "post",
  },
});

export default selectionCardStyle;
