import { useEffect, useContext, FunctionComponent, useState } from "react";
import AppStateContext from "contexts/AppStateContext";
import { Materials, Finishes } from "util/MaterialUtils";
import { useHistory } from "react-router";
import { LeadOptionsType, LeadTimeSelectorType } from "types/ConfigTypes";
import style from "./css/newConfiguratorPriceSummary.module.css";
import { ReactComponent as ThreeDIcon } from "../../assets/svg/dashboard/3dIcon.svg";
import CustomButton from "components/Buttons/CustomButton";
import stylesButton from "../../components/Buttons/customButton.module.css";
import { ReactComponent as ErrorIcon } from "../../assets/svg/dashboard/error.svg";
import LeadTimeSelector from "vaerksComponents/checkoutComponents/LeadTimeSelector";
import AmountInput from "vaerksComponents/AmountInput";

import { CircularProgress, LinearProgress } from "@mui/material";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
} from "types/products/ProductCommandsType";
import ButtonLoader from "components/Loaders/ButtonLoader";

type PropTypes = {
  disabled: boolean;
  isReconfiguration: boolean;
  configuration?:
    | (CNCProductDetailsType & {
        name: string;
        weight: number;
      })
    | (Print3DProductDetailsType & {
        name: string;
        weight: number;
      });
  dimensions: {};
  previewImage?: string;
  validDFM?: boolean;
  priceLoading: boolean;
  loadingImages: boolean;
  addingToCart: boolean;
  prices: {
    EXPRESS: number;
    STANDARD: number;
    ECONOMIC: number;
  };
  setQuantity: Function;
  setComment: Function;
  handleFinishedComponent: Function;
  selectedLeadTime: LeadOptionsType;
  setSelectedLeadTime: Function;
  leadTimeOptions?: LeadTimeSelectorType;
  resetState: Function;
};

const ConfiguratorPriceSummary: FunctionComponent<PropTypes> = ({
  disabled,
  isReconfiguration,
  configuration,
  dimensions,
  previewImage,
  validDFM,
  priceLoading,
  loadingImages,
  addingToCart,
  prices,
  setQuantity,
  setComment,
  handleFinishedComponent,
  selectedLeadTime,
  setSelectedLeadTime,
  leadTimeOptions,
  resetState,
}) => {
  const { strings } = useContext(AppStateContext);
  const [showComment, setShowComment] = useState<boolean>(false);
  const [itemComment, setItemComment] = useState<string>("");

  const currencyFormatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  const history = useHistory();

  // Ensure material and finish information are loaded
  useEffect(() => {
    Materials.loadMaterials();
    Finishes.loadFinishes();
  }, []);

  useEffect(() => {
    if (
      configuration &&
      configuration.comment &&
      configuration.comment !== ""
    ) {
      setItemComment(configuration.comment);
      setShowComment(true);
    }
  }, [configuration]);

  // Returns the customer to their cart,
  // if they are reconfiguring a component and decided to cancel their changes.
  const cancelReconfigure = () => {
    resetState("CancelReconfigure");
    history.push("/indkoebskurv");
  };

  return (
    <div className={`${style.container}`}>
      <div className={`${style.container__summary}`}>
        <h4 className={`${style.title}`}>
          {strings.ConfiguratorPriceSummaryTitelSmall}
        </h4>
        <h5 className={`${style.title__model}`}>
          {strings.ConfSummaryModel?.toUpperCase()}
        </h5>
        <div>
          {!previewImage ? (
            <div
              className={`${style.img__square}`}
              style={{ lineHeight: loadingImages ? "150px" : "normal" }}
            >
              {loadingImages ? (
                // <CircularProgress color={"primary"} />
                <ButtonLoader />
              ) : (
                <ThreeDIcon />
              )}
            </div>
          ) : (
            <img
              src={previewImage}
              alt={configuration?.name ?? "Preview"}
              className={`${style.img__preview}`}
            />
          )}
          <div>
            <p className={`${style.file__name}`}>
              {configuration?.name ?? "-"}
            </p>
            <p className={`${style.noParameters}`}>
              {dimensions ? dimensions : "-"}
            </p>
          </div>
        </div>
        <h5 className={`${style.title__model}`}>
          {strings.ConfSummaryConfiguration?.toUpperCase()}
        </h5>
        <div>
          <div className={`${style.config__container}`}>
            <div className={`${style.config__container_titles}`}>
              <p className={`${style.configuration__title}`}>
                {strings.CompConfigMaterialButton}
              </p>
              <p className={`${style.configuration__title}`}>Finish</p>
              <p className={`${style.configuration__title}`}>
                {strings.ConfSummaryQuantity}
              </p>
            </div>
            <div className={`${style.config__conatiner_options}`}>
              <p className={`${style.configuration__text}`}>
                {configuration
                  ? Materials.getById(configuration.material)
                    ? //@ts-ignore
                      Materials.getById(configuration.material)[strings.code]
                        .materialTitle
                    : configuration.material
                  : "-"}
              </p>
              <p
                className={`${style.configuration__text}`}
                style={{ textTransform: "capitalize" }}
              >
                {configuration
                  ? configuration.finish.length > 1
                    ? configuration.finish
                        .filter((i) => i !== "standard")
                        .join("+")
                    : configuration.finish
                  : "-"}
              </p>
              <p className={`${style.configuration__text_amount}`}>
                <AmountInput
                  initVal={configuration?.quantity ?? 1}
                  onChange={setQuantity}
                  disabled={priceLoading}
                />
              </p>
            </div>
          </div>
          <div className={`${style.comment__container}`}>
            {/* <span> */}
            {/* {showComment || isReconfiguration ? (
                <>
                  <label htmlFor="">Comment</label>
                  {isReconfiguration ? <br /> : null}
                  <textarea
                    className={`${style.comment__input}`}
                    value={itemComment}
                    onBlur={() => setComment(itemComment)}
                    onChange={(e) => setItemComment(e.target.value)}
                    maxLength={400}
                  />
                  <label className={`${style.label}`}>
                    {itemComment.length.toString() + "/400"}{" "}
                  </label>
                </>
              ) : null} */}
            {/* </span> */}
            <span>
              <label htmlFor="" style={{ fontWeight: "600", color: "black" }}>
                Comment
              </label>
              <textarea
                className={`${style.comment__input}`}
                value={itemComment}
                onBlur={() => setComment(itemComment)}
                onChange={(e) => setItemComment(e.target.value)}
                maxLength={400}
              />
              <label className={`${style.label}`}>
                {itemComment.length.toString() + "/400"}{" "}
              </label>
            </span>
            {/* {previewImage && !isReconfiguration ? ( */}
            {/* <div className={`${style.button__container}`}>
                <button
                  onClick={() => setShowComment((e) => !e)}
                  className={`${style.button}`}
                >
                  {!showComment ? "Add Comment" : "Cancel"}
                  {/* {showComment} */}
            {/* </button>
              </div> */}
            {/* // ) : null} */}
          </div>
        </div>

        <h5 className={`${style.title__model}`}>
          {`${strings.ConfSummaryPrice} ${
            leadTimeOptions ? " & " + strings.ConfSummaryDelivery : ""
          }`.toUpperCase()}
        </h5>
        {leadTimeOptions && (
          <div className={`${style.production__container}`}>
            <div className={`${style.production__container_text}`}>
              <p className={`${style.production__time}`}>
                {strings.ConfSummaryDelivery}
              </p>
              <span
                className={`${style.production__additional_text}`}
              >{` (${strings.ConfLeadTimeSubText})`}</span>
            </div>
            <div className={`${style.production__select}`}>
              <LeadTimeSelector
                value={selectedLeadTime}
                onChange={(value) => setSelectedLeadTime(value)}
                leadTimeOptions={leadTimeOptions}
                disabled={disabled || Object.keys(leadTimeOptions).length === 0}
              />
            </div>
          </div>
        )}
        <div className={`${style.price__container}`}>
          <div>
            <p className={`${style.price__total}`}>
              {strings.ConfSummaryPriceTotal}
            </p>
          </div>
          <div className={`${style.price__container_text}`}>
            <p className={`${style.price__total_text}`}>
              {currencyFormatter.format(
                (prices ? prices[selectedLeadTime] : 0) *
                  (configuration?.quantity ?? 1)
              )}
            </p>
            <p className={`${style.price__total}`}>
              {" " +
                currencyFormatter.format(
                  prices ? prices[selectedLeadTime] : 0
                ) +
                " " +
                strings.ConfiguratorPriceEachPiece}
            </p>
          </div>
          {priceLoading ? <LinearProgress /> : ""}
        </div>
        <CustomButton
          onClick={() => handleFinishedComponent()}
          buttonText={
            !isReconfiguration
              ? strings.AddToBasket?.toUpperCase()
              : strings.UpdateConfig
          }
          disabled={disabled}
          buttonClass={`${stylesButton.button__login}`}
          iconClass={`${stylesButton.login__icon}`}
        />
        {isReconfiguration ? (
          <CustomButton
            onClick={() => cancelReconfigure()}
            buttonText={strings.CancelButton.toUpperCase()}
            buttonClass={`${stylesButton.button__cancel}`}
            iconClass={`${stylesButton.cancel__icon}`}
          />
        ) : null}
        <div
          style={{
            display: validDFM === false ? "inline" : "none",
          }}
          className={`${style.error__container}`}
        >
          <div className={`${style.error__container_box}`}>
            <div className={`${style.error__container_display}`}>
              <span className={`${style.error__icon}`}>
                <ErrorIcon />
              </span>
              <span>{strings.ConfiguratorPriceErrorMsg}</span>
            </div>
          </div>
          <div className={`${style.error__conatiner_issues}`}>
            <div className={`${style.error__conatiner_issues_text}`}>
              {strings.ConfiguratorPriceCanNotGuarantee}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfiguratorPriceSummary;
