import { Dispatch, SetStateAction, useEffect, useState } from "react";

function useStateWithSessionStorage<S>(
  initialState: S | (() => S),
  key: string
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState);

  useEffect(() => {
    const sessionStorageState = sessionStorage.getItem(key);

    if (sessionStorageState) {
      setState(JSON.parse(sessionStorageState));
    }
  }, [key]);

  useEffect(() => {
    if (state) {
      sessionStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
}

export default useStateWithSessionStorage;
