import { FunctionComponent } from "react";
import { createContext, useState } from "react";
import { Color } from "three";

type CompConfigContextType = {
  cncProductId: number | null;
  setCNCProductId: Function;
  print3DProductId: number | null;
  setPrint3DProductId: Function;
  slowLoading: boolean;
  setSlowLoading: Function;
  modelLoading: boolean;
  setModelLoading: Function;
  dfmLoading: boolean;
  setDfmLoading: Function;
  previewImageLoading: boolean;
  setPreviewImageLoading: Function;
  showLogoutModal: boolean;
  setShowLogoutModal: Function;
  specialOffer: boolean;
  setSpecialOffer: Function;
  setPleaseLoginModal: Function;
  pleaseLoginModal: boolean;
};

export type HighlightType = {
  uuid: string;
  color: Color | string | number;
};

const contextDefaultValues: CompConfigContextType = {
  cncProductId: null,
  setCNCProductId: () => {},
  print3DProductId: null,
  setPrint3DProductId: () => {},
  slowLoading: false,
  setSlowLoading: () => {},
  modelLoading: false,
  setModelLoading: () => {},
  dfmLoading: false,
  setDfmLoading: () => {},
  previewImageLoading: false,
  setPreviewImageLoading: () => {},
  showLogoutModal: false,
  setShowLogoutModal: () => {},
  specialOffer: false,
  setSpecialOffer: () => {},
  setPleaseLoginModal: () => {},
  pleaseLoginModal: false,
};

const CompConfigContext =
  createContext<CompConfigContextType>(contextDefaultValues);

export const CompConfigProvider: FunctionComponent = ({ children }) => {
  const [cncProductId, setCNCProductId] = useState<number | null>(null);
  const [print3DProductId, setPrint3DProductId] = useState<number | null>(null);

  const [modelLoading, setModelLoading] = useState(false);
  const [dfmLoading, setDfmLoading] = useState(false);
  const [previewImageLoading, setPreviewImageLoading] = useState(false);
  const [slowLoading, setSlowLoading] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [specialOffer, setSpecialOffer] = useState<boolean>(false);
  const [pleaseLoginModal, setPleaseLoginModal] = useState<boolean>(false);

  return (
    <CompConfigContext.Provider
      value={{
        cncProductId,
        setCNCProductId,
        print3DProductId,
        setPrint3DProductId,
        slowLoading,
        setSlowLoading,
        modelLoading,
        setModelLoading,
        dfmLoading,
        setDfmLoading,
        previewImageLoading,
        setPreviewImageLoading,
        showLogoutModal,
        setShowLogoutModal,
        specialOffer,
        setSpecialOffer,
        setPleaseLoginModal,
        pleaseLoginModal,
      }}
    >
      {children}
    </CompConfigContext.Provider>
  );
};
export default CompConfigContext;
