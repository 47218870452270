import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
  Fragment,
  useReducer,
} from "react";
import CompConfigContext from "contexts/CompConfigContext";
import { TAX_RATE } from "util/CompanyInfoUtil";
import LoadingTableRow from "vaerksComponents/checkoutComponents/LoadingTableRow";
import ListReducer, { ListReducerEnum } from "util/ListReducer";
import { apiCreateProduct, apiGetModelInfo } from "util/network/Products";
import { CartItemType } from "types/CartTypes";
import { apiUploadModelFile } from "util/network/Models";
import EcommerceContext from "contexts/EcommerceContext";
import CNCCartTableRow from "vaerksComponents/checkoutComponents/CNCCartTableRow";
import { useHistory, useLocation } from "react-router-dom";
import AppStateContext from "contexts/AppStateContext";
import useWindowDimensions from "../../../vaerksComponents/useWindowDimensions";
import { Materials, Finishes } from "util/MaterialUtils";
import LeadTimeSelector from "vaerksComponents/checkoutComponents/LeadTimeSelector";
import { formatter } from "util/TextUtil";
import CustomButton from "components/Buttons/CustomButton";
import buttonStyles from "../../../components/Buttons/customButton.module.css";
import styles from "./css/newCheckout.module.css";
import lineStyles from "../../../vaerksComponents/checkoutComponents/styles/cncCartTableRow.module.css";
import Payment from "vaerksComponents/checkoutComponents/Payment";
import {
  CNCProductDetailsType,
  CreateCNCProductType,
  CreatePrint3DProductType,
  Print3DProductDetailsType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import Print3DCartTableRow from "vaerksComponents/checkoutComponents/Print3DCartTableRow";

type GhostType = {
  name: string;
  file: File;
  type: ProductTypeEnum;
};

const Checkout = () => {
  const { strings, token } = useContext(AppStateContext);
  const {
    cart,
    cartId,
    cartTotals,
    cartItems,
    leadtimeOption,
    leadTimeOptions,
    updateCartItem,
    removeCartItem,
    clearCartItems,
    changeLeadTime,
    addCartItem,
    cartInfoLoading,
  } = useContext(EcommerceContext);
  const history = useHistory();
  const location = useLocation();
  const { specialOffer, setSpecialOffer } = useContext(CompConfigContext);

  const { width } = useWindowDimensions();

  const [ghostCart, ghostCartDispatch] = useReducer(
    ListReducer<GhostType>("name"),
    location.state?.files ?? []
  );

  const [offerShowed, setOfferShowed] = useState(false);
  const [shippingIndex, setShippingIndex] = useState(0);
  const [toPayment, setToPayment] = useState(false);

  // Ensure material and finish information are loaded
  useEffect(() => {
    Materials.loadMaterials();
    Finishes.loadFinishes();
  }, []);

  // User returning to checkout view from shipping site
  const cancelPayment = () => {
    setToPayment(false);
  };

  const removeGhost = (ghost: GhostType) => {
    ghostCartDispatch({
      value: ghost,
      type: ListReducerEnum.REMOVE,
    });
  };

  /***  Basket Actions ***/
  /* Clears Basket */
  const clearBasket = useCallback(() => {
    if (token) {
      clearCartItems();
    }
  }, [token, cartId]);

  /* Confirms Order */
  const updateProductOrder = useCallback(
    (status, orderId, isQuote) => {
      if (status === "Init" && orderId) {
        //@ts-ignore
        window.gtag("event", "purchase", {
          transaction_id: orderId,
          currency: "DKK",
          shipping: cartTotals?.shipping,
          value: cartTotals?.total,
          items: cartItems.map((item, key) => {
            return {
              item_id: item.product.id,
              item_name: item.name,
              affiliation: "EasyPartz",
              currency: "DKK",
              index: key,
              price: item.pricePerUnit,
              quantity: item.quantity,
            };
          }),
        });
      }
    },
    [history]
  );

  useEffect(() => {
    if (!token) return;
    const ghostToOrder = () => {
      if (!token) return;
      if (ghostCart) {
        history.replace(window.location.pathname, {});
        return Promise.all(
          // First create model
          ghostCart.map(async (ghost, index) => {
            return apiUploadModelFile(token, ghost.file, ghost.type).then(
              (model) => {
                return Promise.all(
                  model.filesList.map((item) => {
                    apiGetModelInfo(token, item.id).then((modelInfo) => {
                      if (modelInfo.type == "step") {
                        const createProductCommand: CreateCNCProductType = {
                          modelId: item.id,
                          quantity: 1,
                          material: "alu-6082",
                          finish: "standard",
                          threads: [],
                          productType: ProductTypeEnum.CNC,
                          blueprint: null,
                          comment: null,
                        };
                        return apiCreateProduct(
                          token,
                          createProductCommand
                        ).then((product) => {
                          return addCartItem(product.id, 1).then((added) => {
                            removeGhost(ghost);
                          });
                        });
                      } else if (modelInfo.type == "print3d") {
                        const createProductCommand: CreatePrint3DProductType = {
                          modelId: item.id,
                          quantity: 1,
                          material: "MDP-120",
                          finish: "standard",
                          threads: [],
                          productType: ProductTypeEnum.PRINT3D,
                          comment: null,
                        };
                        return apiCreateProduct(
                          token,
                          createProductCommand
                        ).then((product) => {
                          return addCartItem(product.id, 1).then((added) => {
                            removeGhost(ghost);
                          });
                        });
                      }
                    });
                  })
                );
              }
            );
          })
        );
      }
    };
    if (ghostCart) {
      ghostToOrder();
    }
  }, [cartId, token]);

  // Relocates user to the ComponentConfigurator in "Reconfigure"-mode,
  // with the neccesary details on the selected component to be reconfigured
  // included with the history.push as state:
  const reconfigureItem = useCallback(
    (cartItem: CartItemType) => {
      history.push({
        pathname: "ny-komponent/" + cartItem.product.productType.toLowerCase(),
        state: {
          productId: cartItem.product.id,
          reconfigure: true,
          cartItemId: cartItem.id,
        },
      });
    },
    [history]
  );

  function handleGoToPayment() {
    let showModal = false;

    cartItems.forEach((row) => {
      if (row.quantity >= 30) {
        showModal = true;
      }

      if ((row.total ?? 0) >= 25000) {
        showModal = true;
      }
    });

    if (showModal && !offerShowed) {
      setSpecialOffer(true);
      setOfferShowed(true);
    } else {
      //@ts-ignore
      // TODO
      window.gtag("event", "begin_checkout", {
        currency: "DKK",
        value: cartTotals?.total,
        items: cartItems.map((item, key) => {
          return {
            item_id: item.product.id,
            item_name: item.name,
            affiliation: "EasyPartz",
            currency: "DKK",
            index: key,
            price: item.pricePerUnit,
            quantity: item.quantity,
          };
        }),
      });
      setToPayment(true);
    }
  }

  const onItemChange = useCallback(
    (updateItem: CartItemType) => {
      return updateCartItem(updateItem);
    },
    [updateCartItem]
  );

  const cncTableItems = useMemo(() => {
    return cartItems
      .sort((a, b) => {
        return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
      })
      .map((item, key) => {
        if (item.product.productType === ProductTypeEnum.CNC) {
          const details = {
            ...item.product.details,
            finish: (item.product.details?.finish as any).split(","),
          } as CNCProductDetailsType;
          return (
            <CNCCartTableRow
              key={item.id}
              item={item}
              details={details}
              loading={false}
              onChange={onItemChange}
              reconfigure={reconfigureItem}
            />
          );
        } else {
          return null;
        }
      });
  }, [cartItems, onItemChange, reconfigureItem, ghostCart]);

  const print3dTableItems = useMemo(() => {
    return cartItems
      .sort((a, b) => {
        return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
      })
      .map((item, key) => {
        if (item.product.productType === ProductTypeEnum.PRINT3D) {
          const details = {
            ...item.product.details,
            finish: (item.product.details?.finish as any).split(","),
          } as Print3DProductDetailsType;
          return (
            <Print3DCartTableRow
              key={item.id}
              item={item}
              details={details}
              loading={false}
              onChange={onItemChange}
              reconfigure={reconfigureItem}
            />
          );
        } else {
          return null;
        }
      });
  }, [cartItems, onItemChange, reconfigureItem]);

  const loadingItems = useMemo(() => {
    if (ghostCart) {
      const ghosts = ghostCart.map((ghost, key) => {
        return <LoadingTableRow product={ghost.file} key={key} />;
      });
      return ghosts;
    }
    return null;
  }, [ghostCart]);

  const feesRow = useMemo(() => {
    const feeItem = cartItems.find(
      (i) => i.product.productType === ProductTypeEnum.FEE
    );
    if (feeItem) {
      return (
        <>
          <tr>
            <td align="left">
              <div className={`${styles.subtotal__text}`}>
                {strings.BasketSummaryFees.toUpperCase()}
              </div>
            </td>
            <td align="right">
              <div className={`${styles.subtotal__text}`}>
                {formatter.format(feeItem.total)}
              </div>
            </td>
          </tr>
          <tr>
            <td align="left" className={`${styles.line__container_solid}`}>
              <div className={`${styles.line_solid}`}></div>
            </td>
          </tr>
        </>
      );
    }
    return null;
  }, [cartItems, strings.BasketSummaryFees]);

  const checkoutTable = useMemo(() => {
    return !toPayment ? (
      <Fragment>
        <table className={`${styles.table__items}`}>
          <tbody className={`${styles.table__items}`}>
            {cncTableItems}
            {print3dTableItems}
            {ghostCart && loadingItems}
          </tbody>
        </table>
        <table className={`${styles.table__subtotal}`}>
          <tbody>
            {feesRow}
            {/* Shipping Time Row */}
            <tr>
              <td align="left">
                <div className={`${styles.subtotal__text}`}>
                  {strings.BasketSummaryLeadtimeHeader}
                </div>
                <i
                  className={`${styles.subtotal__text_sum}`}
                >{`(${strings.BasketSummaryLeadTimeSubHeader})`}</i>
              </td>
              <td align="right">
                {leadtimeOption && (
                  <LeadTimeSelector
                    value={leadtimeOption}
                    onChange={changeLeadTime}
                    leadTimeOptions={leadTimeOptions}
                    disabled={
                      cartInfoLoading ||
                      Object.keys(leadTimeOptions).length === 0
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td align="left" className={`${styles.line__container_solid}`}>
                <div className={`${styles.line_solid}`}></div>
              </td>
            </tr>
            {/* <tr>
              <td align="left" className={`${styles.subtotal__text}`}>
                {strings.Subtotal}
              </td>
              <td align="right" className={`${styles.subtotal__text_price}`}>
                {formatter.format(cartTotals?.subtotal ?? 0)}
              </td>
            </tr>
            <tr>
              <td align="left" className={`${styles.line__container_dashed}`}>
                <div className={`${styles.line_dashed}`}></div>
              </td>
            </tr> */}
            {/* Shipping Cost Row */}
            {/* <tr>
              <td align="left" className={`${styles.subtotal__text}`}>
                {strings.Delivery}
              </td>
              <td align="right" className={`${styles.subtotal__text_price}`}>
                {formatter.format(cartTotals?.shipping ?? 0)}
              </td>
            </tr>
            <tr>
              <td align="left" className={`${styles.line__container_solid}`}>
                <div className={`${styles.line_solid}`}></div>
              </td>
            </tr> */}
            {/* Totals Row */}
            <tr>
              <td align="left">
                <h4 className={`${styles.subtotal}`}>
                  {strings.Total?.toUpperCase()}{" "}
                  <span>- {strings.MomsExcluded.toUpperCase()}</span>
                </h4>
                <h6 className={`${styles.subtotal__text}`}>
                  {`${strings.MomsIncluded} - ${(TAX_RATE * 100).toFixed(0)}%`}
                </h6>
              </td>
              <td align="right">
                <h4 className={`${styles.subtotal__price}`}>
                  {formatter.format(cartTotals?.total ?? 0)}
                </h4>
                <h6 className={`${styles.subtotal__text}`}>
                  {formatter.format(
                    (cartTotals?.total ?? 0) + (cartTotals?.totalTax ?? 0)
                  )}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
        {/* Proceed Button */}
        <div className={`${styles.button__proceed}`}>
          <CustomButton
            disabled={cartItems.length === 0 || cartInfoLoading}
            onClick={() => handleGoToPayment()}
            buttonText={strings.NextButton?.toUpperCase()}
            buttonClass={`${buttonStyles.button__basket} `}
            iconClass={`${buttonStyles.basket__icon}`}
          />
        </div>
      </Fragment>
    ) : (
      <Payment
        cancelPayment={cancelPayment}
        invoiceSubtotal={cartTotals?.subtotal ?? 0}
        invoiceTaxes={cartTotals?.totalTax ?? 0}
        invoiceTotal={cartTotals?.total ?? 0}
        invoiceShipping={cartTotals?.shipping ?? 0}
        onOrdered={updateProductOrder}
      />
    );
  }, [
    toPayment,
    width,
    strings.ProductName,
    strings.Material,
    strings.Finish,
    strings.Amount,
    strings.Price,
    strings.EmptyBasket,
    strings.BasketSummaryLeadtimeHeader,
    strings.BasketSummaryLeadTimeSubHeader,
    strings.Subtotal,
    strings.Delivery,
    strings.Total,
    strings.MomsExcluded,
    strings.MomsIncluded,
    strings.NextButton,
    ghostCart,
    cartInfoLoading,
    cncTableItems,
    print3dTableItems,
    loadingItems,
    leadtimeOption,
    changeLeadTime,
    leadTimeOptions,
    cartTotals?.subtotal,
    cartTotals?.shipping,
    cartTotals?.total,
    cartTotals?.totalTax,
    cartItems.length,
    updateProductOrder,
    clearBasket,
    handleGoToPayment,
  ]);

  const emptyBasket = useMemo(() => {
    return (
      <h1 className={`${styles.basket__empty}`}>
        {strings.BasketEmpty}
        <div
          className={`${styles.basket__empty_click}`}
          onClick={() =>
            history.push({
              pathname: "ny-komponent",
            })
          }
        >
          {strings.AddComponent}
        </div>
      </h1>
    );
  }, [history, strings]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          style={{
            width: "75vw",
            maxWidth: "75vw",
            // boxShadow: "1px 1px 4px rgba(0,0,0,0.3)",
          }}
        >
          <div style={{ backgroundColor: "#fff" }}>
            <div style={{ padding: "1rem" }}>
              <h4
                // style={{ textTransform: "uppercase", margin: "0" }}
                className={`${styles.title}`}
              >
                {toPayment ? strings.Checkout : strings.Basket}
              </h4>
            </div>
          </div>
          <div style={{ padding: "1rem" }}>
            <tr>
              <td align="left" className={`${lineStyles.line__container}`}>
                <span className={`${lineStyles.item__line_solid}`}></span>
              </td>
            </tr>
            {cartInfoLoading ? (
              <div style={{ height: "4px", backgroundColor: "blue" }}></div>
            ) : null}
            {cartItems.length > 0 || ghostCart?.length > 0
              ? checkoutTable
              : emptyBasket}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
