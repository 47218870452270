import { primaryColor } from "assets/jss/material-dashboard-pro-react.js";

const dropzoneStyle = {
  dropzoneUpload: {
    width: "90%",
    height: "500px",
    overflow: "visible",
    transition: "transform .2s",
    "&:hover": {
      background: primaryColor[0],
    },
  },
  dropzoneContainer: {
    height: "500px",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dropzoneUploadText: {
    marginTop: "2em",
    fontSize: "32px",
  },
  dropzoneChange: {
    width: "90%",
    height: "200px",
    position: "relative",
    transition: "transform .2s",
    "&:hover": {
      background: primaryColor[0],
    },
  },
  dropzoneChangeText: {
    marginTop: "75px",
    fontSize: "32px",
  },
  pdfTemp: {
    height: "600px",
    position: "relative",
    margin: "500px 500px 500px 500px",
  },
  pdfNavigation: {
    display: "flex",
    justifyContent: "center",
    margin: "-12px 0 12px 0",
    position: "relative",
  },
  pdfPageText: {
    textAlign: "center",
    padding: "13px 10px 0 10px",
  },
  pdfDeleteButton: {
    display: "flex",
    flexDirection: "column",

    float: "right",
  },

  /* dropzoneSuccess: {
    "&:hover": {
      opacity: 0.8,
    },
    background: primaryColor[0],
    width: "80%",
    height: "400px",
  },
  dropzoneSuccessText: {
    marginTop: "15%",
    fontSize: "32px",
    fontWeight: "bold",
    color: whiteColor,
  },*/
};

export default dropzoneStyle;
