import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { formatter } from "util/TextUtil";
import AppStateContext from "contexts/AppStateContext";
import { useContext } from "react";
import { CartItemType } from "types/CartTypes";
import styles from "../checkoutComponents/styles/payment.module.css";
import { LeadOptionsType } from "types/ConfigTypes";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";

type PropsType = {
  items: CartItemType[];
  invoiceSubtotal: number;
  invoiceTotal: number;
  shippingPrice: number;
  invoiceTaxes: number;
  leadTime?: LeadOptionsType;
  leadTimeDays?: number;
};

const OrderDetails: FunctionComponent<PropsType> = ({
  items,
  invoiceSubtotal,
  invoiceTotal,
  shippingPrice,
  invoiceTaxes,
  leadTime,
  leadTimeDays,
}) => {
  const TAX_RATE = 0.25;
  const { strings } = useContext(AppStateContext);

  const feesRow = useMemo(() => {
    const feeItem = items.find(
      (i) => i.product.productType === ProductTypeEnum.FEE
    );
    if (feeItem) {
      return (
        <>
          <tr>
            <td colSpan={2}></td>

            <td>
              <div className={`${styles.order__subtotal}`}>
                {strings.BasketSummaryFees.toUpperCase()}
              </div>
            </td>
            <td align="right">
              <span className={`${styles.order__subtotal}`}>
                {formatter.format(feeItem.total + feeItem.totalTax)}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td
              align="left"
              colSpan={2}
              className={`${styles.line__container_dashed_leadTime}`}
            >
              <div className={`${styles.line_dashed}`}></div>
            </td>
          </tr>
        </>
      );
    }
    return null;
  }, [items]);

  return (
    <div className={`${styles.order__container}`}>
      <h4 className={`${styles.order__title}`}>
        {strings.OrderDetailsTitelLower}
      </h4>

      <table className={`${styles.table__items}`}>
        <tr className={`${styles.table__header}`}>
          <th colSpan={1} align="center">
            {strings.ProductName}
          </th>
          <th colSpan={1}>{strings.OrderDetailsMatFin}</th>
          <th colSpan={1}>{strings.Amount}</th>
          <th colSpan={1} style={{ textAlign: "center" }}>
            {strings.Total}
          </th>
        </tr>
        <tr>
          <td
            colSpan={6}
            align="left"
            className={`${styles.line__container_solid_header}`}
          >
            <div className={`${styles.line_solid}`}></div>
          </td>
        </tr>

        {items
          .filter((i) => i.product.productType != ProductTypeEnum.FEE)
          .map((item: CartItemType) => {
            const details =
              item.product.productType === ProductTypeEnum.CNC
                ? ({
                    ...item.product.details,
                    finish: (item.product.details?.finish as any).split(","),
                  } as unknown as CNCProductDetailsType)
                : ({
                    ...item.product.details,
                    finish: (item.product.details?.finish as any).split(","),
                  } as unknown as Print3DProductDetailsType);

            return (
              <>
                <tr key={item.id}>
                  <td colSpan={1}>
                    <div className={`${styles.name__tooltip}`}>
                      <span className={`${styles.name__tooltip_text}`}>
                        {item.name}
                      </span>
                      <span className={`${styles.item__name}`}>
                        {item.name}
                      </span>
                    </div>
                  </td>
                  <td colSpan={1} align="center">
                    <span className={`${styles.item__material}`}>
                      {details.material +
                        " / " +
                        (details.finish.length > 1
                          ? details.finish
                              .filter((i: string) => i !== "standard")
                              .join(", ")
                          : details.finish)}
                    </span>
                  </td>
                  <td colSpan={1} align="center">
                    <span className={`${styles.item__quantity}`}>
                      {item.quantity}
                    </span>
                  </td>
                  <td colSpan={1} align="center">
                    <div className={`${styles.item__total}`}>
                      {formatter.format(item.total)}
                    </div>
                    <div className={`${styles.item__perUnit}`}>
                      {formatter.format(item.pricePerUnit)}
                      {"/pcs"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={6}
                    align="left"
                    className={`${styles.line__container_solid}`}
                  >
                    <div className={`${styles.line_solid}`}></div>
                  </td>
                </tr>
              </>
            );
          })}

        <tbody>
          {feesRow}
          <tr>
            <td colSpan={2}></td>

            <td>
              <div className={`${styles.order__subtotal}`}>
                {strings.BasketSummaryLeadtimeHeader.toUpperCase()}
              </div>
              <i
                className={`${styles.order__subtotal_sum}`}
              >{`(${strings.BasketSummaryLeadTimeSubHeader})`}</i>
            </td>
            <td align="right">
              <span className={`${styles.order__subtotal}`}>
                {leadTime} {leadTimeDays} {strings.ConfSummaryDays}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td
              align="left"
              colSpan={2}
              className={`${styles.line__container_dashed_leadTime}`}
            >
              <div className={`${styles.line_dashed}`}></div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>

            <td>
              <span className={`${styles.order__subtotal}`}>
                {strings.Subtotal?.toUpperCase()}
              </span>
            </td>
            <td align="right">
              <span className={`${styles.order__subtotal}`}>
                {formatter.format(invoiceSubtotal)}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td
              align="left"
              colSpan={2}
              className={`${styles.line__container_dashed}`}
            >
              <div className={`${styles.line_dashed}`}></div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td>
              <span className={`${styles.order__subtotal}`}>
                {strings.Delivery?.toUpperCase()}
              </span>
            </td>
            <td align="right">
              <span className={`${styles.order__subtotal}`}>
                {formatter.format(shippingPrice)}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td
              align="left"
              colSpan={2}
              className={`${styles.line__container_solid}`}
            >
              <div className={`${styles.line_solid}`}></div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td>
              <span style={{ fontWeight: "bold" }}>
                {strings.Total?.toUpperCase()}&nbsp;
                <span>{strings.ExcludingMoms}</span>
              </span>
            </td>
            <td align="right">
              <span style={{ fontWeight: "bold" }}>
                {formatter.format(invoiceTotal)}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td align="left">
              <span className={`${styles.order__subtotal}`}>
                {strings.MomsIncluded}
              </span>
              <span className={`${styles.order__subtotal}`}>
                - {`${(TAX_RATE * 100).toFixed(0)} %`}
              </span>
            </td>
            <td align="right">
              <span className={`${styles.order__subtotal}`}>
                {formatter.format(invoiceTotal + invoiceTaxes)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default OrderDetails;
