import { FunctionComponent, useEffect, useState, useContext } from "react";
import AppStateContext from "contexts/AppStateContext";

type PropsType = {
  product: File;
};

const LoadingTableRow: FunctionComponent<PropsType> = ({ product }) => {
  const { strings } = useContext(AppStateContext);
  const [code, setCode] = useState<"dk" | "gb">(strings.code);
  const [updated, setUpdated] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [itemComment, setItemComment] = useState<string>("");
  const maxCommentLength = 400;

  useEffect(() => {
    setCode(strings.code);
  }, [strings.code]);

  return (
    <tr key={product.name}>
      {/* Preview Image Cell */}
      <td align="center">
        <div className="circular-progress"></div>
      </td>
      {/* Item + Configuration Cell */}
      <td align="left">
        <div
        // className={classes.name}
        >
          {product.name}
        </div>
      </td>
      <td>
        {/* <div>
          <span className="skeleton-loader-background"></span>
        </div>
        <br />
        <div>
          <span className="skeleton-loader-gradient"></span>
        </div>
        <br />
        <div>
          <span className="skeleton-loader"></span>
        </div> */}
      </td>
      {/* Quantity Cell */}
      <td align="left"></td>
      {/* Price Cell */}

      <td align="right">{"Beregner.."}</td>

      {/* Actions Cell */}
      <td align="center">
        {/* Edit Button */}
        <span
        // title={<h5>REDIGER</h5>}
        //   placement="bottom"
        >
          <span>
            <button aria-label={"Edit"} color={"primary"} disabled>
              Edit
            </button>
          </span>
        </span>
        {/* Delete Button */}
        <span
        // title={<h5>SLET</h5>} placement="bottom"
        >
          <span>
            <button aria-label={"Delete"} color={"secondary"} disabled>
              Delete
            </button>
          </span>
        </span>
      </td>
    </tr>
  );
};
export default LoadingTableRow;
