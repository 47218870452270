import AppStateContext from "contexts/AppStateContext";
import { FunctionComponent, useContext, useMemo } from "react";
import styles from "assets/jss/material-dashboard-pro-react/creativeTim/views/checkoutStyle";
import textStyles from "styles/Text.module.css";

import { formatter } from "util/TextUtil";
import { TAX_RATE } from "util/CompanyInfoUtil";

import { OrderType } from "types/OrderTypes";
import stylesTable from "../checkoutComponents/styles/payment.module.css";
import { ProductTypeEnum } from "types/products/ProductCommandsType";

type PropsType = {
  order: OrderType;
};

const OrderSummaryTable: FunctionComponent<PropsType> = ({ order }) => {
  const { strings } = useContext(AppStateContext);

  const feesRow = useMemo(() => {
    const feeItem = order.items.find(
      (i) => i.product.productType === ProductTypeEnum.FEE
    );
    if (feeItem) {
      return (
        <>
          <tr>
            <td align="left">
              <div className={`${stylesTable.order__subtotal}`}>
                {strings.BasketSummaryFees.toUpperCase()}
              </div>
            </td>
            <td align="right">
              <span className={`${stylesTable.order__subtotal}`}>
                {formatter.format(feeItem.price)}
              </span>
            </td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={2}
              className={`${stylesTable.line__container_dashed_leadTime}`}
            >
              <div className={`${stylesTable.line_dashed}`}></div>
            </td>
          </tr>
        </>
      );
    }
    return null;
  }, [order.items, strings.BasketSummaryFees]);

  return (
    <table>
      <tbody>
        {feesRow}
        <tr>
          <td align="left">
            <div className={`${stylesTable.order__subtotal}`}>
              {strings.BasketSummaryLeadtimeHeader?.toUpperCase()}
            </div>
            <i
              className={`${stylesTable.order__subtotal_sum}`}
            >{` (${strings.BasketSummaryLeadTimeSubHeader})`}</i>
          </td>
          <td align="right">
            <span className={`${stylesTable.order__subtotal}`}>
              {order.leadTimeOption} {order.leadTimeDays} dage
            </span>
          </td>
        </tr>
        <tr>
          <td
            align="left"
            colSpan={2}
            className={`${stylesTable.line__container_dashed_leadTime}`}
          >
            <div className={`${stylesTable.line_dashed}`}></div>
          </td>
        </tr>
        {/* COUPON ROW */}
        {(order.discount ?? 0) > 0 && (
          <tr>
            <td>{strings.Discount?.toUpperCase()}</td>

            <td align="right">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  float: "right",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  {formatter.format(order.discount ?? 0)}
                </div>
              </div>
            </td>
          </tr>
        )}
        {/* Subtotal Row */}
        <tr>
          <td>
            <span className={`${stylesTable.order__subtotal}`}>
              {strings.Subtotal?.toUpperCase()}
            </span>
          </td>
          <td align="right">
            <span className={`${stylesTable.order__subtotal}`}>
              {formatter.format(order?.subtotal ?? 0)}
            </span>
          </td>
        </tr>
        <tr>
          <td
            align="left"
            colSpan={2}
            className={`${stylesTable.line__container_dashed_leadTime}`}
          >
            <div className={`${stylesTable.line_dashed}`}></div>
          </td>
        </tr>
        {/* Shipping Cost Row */}
        <tr>
          <td align="left">
            <div className={`${stylesTable.order__subtotal}`}>
              {strings.Delivery?.toUpperCase()}
            </div>
          </td>
          <td align="right">
            <span className={`${stylesTable.order__subtotal}`}>
              {formatter.format(order.shipping)}
            </span>
          </td>
        </tr>
        <tr>
          <td
            align="left"
            colSpan={2}
            className={`${stylesTable.line__container_solid}`}
          >
            <div className={`${stylesTable.line_solid}`}></div>
          </td>
        </tr>
        {/* Totals Row */}
        <tr>
          <td align="left">
            <span style={{ fontWeight: "bold" }}>
              {strings.Total?.toUpperCase()}{" "}
              <span>- {strings.MomsExcluded.toUpperCase()}</span>
            </span>
          </td>

          <td align="right">
            <span className={`${stylesTable.order__subtotal}`}>
              {formatter.format(order.subtotal + order.shipping)}
            </span>
          </td>
        </tr>
        <tr>
          <td align="left">
            <span className={`${stylesTable.order__subtotal}`}>
              {`${strings.MomsIncluded} - ${(TAX_RATE * 100).toFixed(0)}%`}
            </span>
          </td>
          <td align="right">
            <span className={`${stylesTable.order__subtotal}`}>
              {formatter.format(order.total + order.totalTax)}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default OrderSummaryTable;
