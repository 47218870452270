import { FunctionComponent, useContext } from "react";
import { OrderType } from "types/OrderTypes";
import { reformatDateTime } from "util/DateUtil";
import { stringConverter } from "util/TextUtil";
import AppStateContext from "contexts/AppStateContext";
import { ReactComponent as Brand } from "../../assets/img/newLogo.svg";
import styles from "./css/order.module.css";
import stylesTable from "../../vaerksComponents/checkoutComponents/styles/payment.module.css";

import OrderSummaryTable from "vaerksComponents/orderConfirmationComponents/OrderSummaryTable";
import OrderItemRow from "./OrderItemRow";
import { Grid } from "@mui/material";
import { ProductTypeEnum } from "types/products/ProductCommandsType";

type PropsType = {
  order: OrderType;
};

const Order: FunctionComponent<PropsType> = ({ order }) => {
  const { strings } = useContext(AppStateContext);
  return (
    <div className={`${styles.container}`}>
      <div style={{ padding: "4em" }}>
        <div style={{ textAlign: "center" }}>
          <Brand />
        </div>
        <br />
        <h2 className={`${styles.title}`}>{strings.OrderTitle}</h2>
        <br />
        <p className={`${styles.subtext}`}>{strings.OrderSubtext}</p>
        <br />
        <h2 className={`${styles.details}`}>{strings.details}:</h2>
        <br />

        <Grid container spacing={12}>
          <Grid item xs>
            <table>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>{strings.orderNumber}:</p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>{order.orderNo}</p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>{strings.date}:</p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>
                    {reformatDateTime(order.created)}
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>{strings.email}:</p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>{order.email}</p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>
                    {strings.paymentMethod}:
                  </p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>
                    {stringConverter(strings, order.paymentMethod)}
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>
                    {strings.deliveryMethod}:
                  </p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>
                    {stringConverter(strings, order.shippingMethod)}
                  </p>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs>
            <p className={`${styles.summary}`}>{strings.invoiceAddress}:</p>
            <p className={`${styles.summary__info}`}>
              <b>Att:</b>{" "}
              {order.billingAddress.firstName +
                " " +
                order.billingAddress.lastName}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.phone}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.email}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.street}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.postalCode +
                " " +
                order.billingAddress.city}
            </p>
            <br />
            <p className={`${styles.summary}`}>{strings.deliveryAddress}:</p>
            <p className={`${styles.summary__info}`}>
              <b>Att:</b>{" "}
              {order.shippingAddress.firstName +
                " " +
                order.shippingAddress.lastName}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.phone}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.email}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.street}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.postalCode +
                " " +
                order.shippingAddress.city}
            </p>
          </Grid>
        </Grid>

        <div>
          <h2 className={`${styles.title}`}>{strings.products}:</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <th></th>
              <th align="left">{strings.name}</th>
              <th align="left">
                {strings.material}/{strings.finish}
              </th>
              <th align="center">{strings.quantity}</th>
              <th align="center">{strings.price}</th>
            </tr>
            <tr>
              <td
                colSpan={6}
                align="left"
                className={`${stylesTable.line__container_solid}`}
              >
                <div className={`${stylesTable.line_solid}`}></div>
              </td>
            </tr>
            {order.items
              .filter((i) => i.product.productType !== ProductTypeEnum.FEE)
              .map((item) => (
                <OrderItemRow key={item.id} item={item} />
              ))}
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <OrderSummaryTable order={order} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Order;
