import { useMatomo } from "@datapunt/matomo-tracker-react";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import {
  FunctionComponent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { createContext, useState } from "react";
import { AddressType } from "types/AddressTypes";
import {
  CartItemType,
  CartTotalsType,
  CartType,
  ShippingMethodType,
} from "types/CartTypes";
import { LeadOptionsType, LeadTimeSelectorType } from "types/ConfigTypes";
import { UserType } from "types/users/UserTypes";
import ListReducer, { ListReducerEnum } from "util/ListReducer";
import {
  apiAddCartItem,
  apiClearCartItems,
  apiCreateCart,
  apiGetCart,
  apiGetCartLeadTime,
  apiGetCarts,
  apiGetShippingMethods,
  apiRemoveCartItem,
  apiUpdateCartInfo,
  apiUpdateCartItem,
  apiUpdateCartLeadTime,
} from "util/network/Carts";
import AppStateContext from "./AppStateContext";

type EcommerceContextType = {
  cartId: number;
  setCartId: Function;
  cart?: CartType;
  setCart: Function;
  cartItems: CartItemType[];
  cartTotals?: CartTotalsType;
  setCartTotals: Function;
  cartBillingAddress?: AddressType;
  setCartBillingAddress: Function;
  cartShippingAddress?: AddressType;
  setCartShippingAddress: Function;
  shippingMethod?: string;
  setShippingMethod: Function;
  cartPONumber?: string;
  setCartPONumber: Function;
  invoiceEmail?: string;
  setInvoiceEmail: Function;
  leadtimeOption: LeadOptionsType;
  leadTimeOptions: LeadTimeSelectorType;
  changeLeadTime: (leadtimeOption: LeadOptionsType) => Promise<boolean>;
  cartWeight: number;
  addCartItem: (productId: number, quantity?: number) => Promise<boolean>;
  updateCartItem: (item: CartItemType) => Promise<boolean>;
  removeCartItem: (item: CartItemType) => Promise<boolean>;
  clearCartItems: () => Promise<boolean>;
  cartInfoLoading: boolean;
  clearState: () => void;
};

const contextDefaultValues: EcommerceContextType = {
  cartId: 0,
  setCartId: () => {},
  setCart: () => {},
  cartItems: [],
  setCartTotals: () => {},
  setCartBillingAddress: () => {},
  setCartShippingAddress: () => {},
  setShippingMethod: () => {},
  setCartPONumber: () => {},
  setInvoiceEmail: () => {},
  leadtimeOption: "STANDARD",
  leadTimeOptions: {},
  changeLeadTime: (leadtimeOption: LeadOptionsType) => Promise.resolve(true),
  cartWeight: 0,
  addCartItem: (productId: number, quantity?: number) => Promise.resolve(true),
  updateCartItem: (item: CartItemType) => Promise.resolve(true),
  removeCartItem: (item: CartItemType) => Promise.resolve(true),
  clearCartItems: () => Promise.resolve(true),
  cartInfoLoading: false,
  clearState: () => {},
};

const EcommerceContext =
  createContext<EcommerceContextType>(contextDefaultValues);

export const EcommerceProvider: FunctionComponent = ({ children }) => {
  const { token, logUserOut, customer } = useContext(AppStateContext);
  const { trackEvent } = useMatomo();

  const clearState = () => {
    sessionStorage.clear();
    setCartId(0);
    setCart(undefined);
    setCartItems([]);
    setCartTotals(undefined);
    setCartBillingAddress(undefined);
    setCartShippingAddress(undefined);
    setCartPONumber(undefined);
    setLocalShippingMethod("pickup");
    setInvoiceEmail(undefined);
    setLeadtimeOption("STANDARD");
    setCartWeight(0);
  };

  const [cartId, setCartId] = useStateWithSessionStorage<number>(0, "cartId");
  const [cart, setCart] = useState<CartType>();

  const [cartItems, cartItemsDispatch] = useReducer(
    ListReducer<CartItemType>("id"),
    []
  );
  const [cartTotals, setCartTotals] = useState<CartTotalsType>();

  const [cartBillingAddress, setCartBillingAddress] = useState<AddressType>();
  const [cartShippingAddress, setCartShippingAddress] = useState<AddressType>();
  const [cartPONumber, setCartPONumber] = useState<string>();
  const [shippingMethod, setLocalShippingMethod] = useState<string>("pickup");
  const [invoiceEmail, setInvoiceEmail] = useState<string>();
  const [leadtimeOption, setLeadtimeOption] =
    useState<LeadOptionsType>("STANDARD");
  const [cartWeight, setCartWeight] = useState<number>(0);

  const [cartInfoLoading, setCartInfoLoading] = useState<boolean>(false);

  const [leadTimeOptions, setLeadTimeOptions] = useState<LeadTimeSelectorType>({
    EXPRESS: {
      days: 0,
      price: 0,
    },
    STANDARD: {
      days: 0,
      price: 0,
    },
    ECONOMIC: {
      days: 0,
      price: 0,
    },
  });

  const setCartItems = useCallback((items: CartItemType[]) => {
    cartItemsDispatch({ type: ListReducerEnum.SET, value: items });
  }, []);

  const setShippingMethod = useCallback(
    (method: string) => {
      if (cartId === 0) return Promise.resolve(false);
      setLocalShippingMethod(method);
      return apiUpdateCartInfo(
        token,
        cartId,
        undefined,
        undefined,
        undefined,
        undefined,
        method,
        undefined
      ).then((res) => {
        return updateCartInfo(cartId);
      });
    },
    [cartId, token]
  );

  const setCartData = useCallback(
    (cart: CartType) => {
      setCartItems(cart.items);
      const totalsInfo: CartTotalsType = {
        discount: 0,
        shippingTax: cart.shippingTax,
        shipping: cart.shipping,
        subtotal: cart.subtotal,
        subtotalTax: cart.subtotalTax,
        total: cart.total,
        totalTax: cart.totalTax,
      };
      setCartTotals(totalsInfo);
      setCartBillingAddress(cart.billingAddress);
      setCartShippingAddress(cart.shippingAddress);
      setCartPONumber(cart.poNumber);
      setShippingMethod(cart.shippingMethod);
      setInvoiceEmail(cart.invoiceEmail);
      apiGetCartLeadTime(token, cart.id).then((data) => {
        setLeadTimeOptions(data);
      });
      setLeadtimeOption(cart.leadTimeOption);
      setCartWeight(cart.weight);
      setCartInfoLoading(false);
    },
    [setCartItems, setShippingMethod, token]
  );

  const updateCartInfo = useCallback(
    (cartId: number) => {
      setCartInfoLoading(true);
      if (cartId !== 0) {
        return apiGetCart(token, cartId)
          .then((cart) => {
            apiGetCartLeadTime(token, cart.id).then((data) => {
              setLeadTimeOptions(data);
            });
            setCart(cart);
            setCartItems(cart.items);
            const totalsInfo: CartTotalsType = {
              discount: 0,
              shippingTax: cart.shippingTax,
              shipping: cart.shipping,
              subtotal: cart.subtotal,
              subtotalTax: cart.subtotalTax,
              total: cart.total,
              totalTax: cart.totalTax,
            };
            setCartTotals(totalsInfo);
            setCartWeight(cart.weight);
            setLeadtimeOption(cart.leadTimeOption);
            setCartInfoLoading(false);
            return true;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              logUserOut();
            } else if (
              err.response.status === 400 ||
              err.response.status === 404
            ) {
              return apiCreateCart(token).then((res) => {
                setCartId(res.id);
                setCartData(res);
              });
            }
          });
      }
    },
    [token]
  );

  useEffect(() => {
    if (token && cartId !== 0) {
      trackEvent({
        category: "Info",
        action: "Cart id updated",
        name: "Cart ID",
        value: cartId,
      });
    }
  }, [cartId, token, trackEvent]);

  const addCartItem = useCallback(
    (productId: number, quantity?: number) => {
      return apiAddCartItem(token, cartId, productId, quantity)
        .then((item) => {
          cartItemsDispatch({ type: ListReducerEnum.ADD, value: item });
          updateCartInfo(cartId);
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    [cartId, token]
  );

  const removeCartItem = useCallback(
    (item: CartItemType) => {
      return apiRemoveCartItem(token, cartId, item.id)
        .then((res) => {
          cartItemsDispatch({
            type: ListReducerEnum.REMOVE,
            value: item,
          });
          updateCartInfo(cartId);
          return true;
        })
        .catch((err) => false);
    },
    [cartId, token]
  );

  const updateCartItem = useCallback(
    (item: CartItemType) => {
      return apiUpdateCartItem(token, cartId, item.id, item.quantity)
        .then((res) => {
          cartItemsDispatch({ type: ListReducerEnum.UPDATE, value: res });
          updateCartInfo(cartId);
          return true;
        })
        .catch((err) => false);
    },
    [cartId, token, updateCartInfo]
  );

  const clearCartItems = useCallback(() => {
    return apiClearCartItems(token, cartId)
      .then((res) => {
        cartItemsDispatch({ type: ListReducerEnum.CLEAR, value: [] });
        updateCartInfo(cartId);
        return true;
      })
      .catch((err) => false);
  }, [cartId, token]);

  const changeLeadTime = useCallback(
    (leadTime: LeadOptionsType) => {
      if (cartId === 0) return Promise.resolve(false);
      setCartInfoLoading(true);
      return apiUpdateCartLeadTime(token, cartId, leadTime)
        .then((res) => {
          setCartData(res);
          return true;
        })
        .catch((err) => {
          return false;
        });
    },
    [token, cartId, setCartData]
  );

  useEffect(() => {
    if (token && (cartId === 0 || !cartId) && customer) {
      apiGetCarts(token)
        .then((res) => {
          let found = false;
          if (res.length > 0) {
            res.forEach((cart) => {
              if (
                cart.author &&
                cart.author?.id === customer.id &&
                cart.status === "OPEN" &&
                !found
              ) {
                setCartId(cart.id);
                setCartData(cart);
                found = true;
              }
            });
          }
          if (!found) {
            console.log("No open cart found, creating new one");
            return apiCreateCart(token).then((res) => {
              setCartId(res.id);
              setCartData(res);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (cartId) {
      updateCartInfo(cartId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, customer?.id, cartId]);

  return (
    <EcommerceContext.Provider
      value={{
        cartId,
        setCartId,
        cart,
        setCart,
        cartItems,
        cartTotals,
        setCartTotals,
        cartBillingAddress,
        setCartBillingAddress,
        cartShippingAddress,
        setCartShippingAddress,
        shippingMethod,
        setShippingMethod,
        cartPONumber,
        setCartPONumber,
        invoiceEmail,
        setInvoiceEmail,
        leadtimeOption,
        leadTimeOptions,
        changeLeadTime,
        cartWeight,
        addCartItem,
        updateCartItem,
        removeCartItem,
        clearCartItems,
        cartInfoLoading,
        clearState,
      }}
    >
      {children}
    </EcommerceContext.Provider>
  );
};
export default EcommerceContext;
