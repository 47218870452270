// @material-ui/icons
import {
  AccountCircle,
  History,
  PlusOne,
  ShoppingBasket,
} from "@mui/icons-material";
import Print3DConfigurator from "./views/dashboardPages/Configurator/Print3DConfigurator";
import MyProfile from "./views/dashboardPages/MyProfile/MyProfile";
import OrderHistory from "views/dashboardPages/OrderHistory/OrderHistory";
import CNCConfigurator from "views/dashboardPages/Configurator/CNCConfigurator";
import Checkout from "views/dashboardPages/Basket/Checkout";

const dashRoutes = (strings) => {
  return [
    {
      path: "ny-komponent/cnc",
      name: strings.ConfiguratorCNC?.toUpperCase(),
      icon: PlusOne,
      component: CNCConfigurator,
      layout: "/",
    },
    {
      path: "ny-komponent/print3d",
      name: strings.ConfiguratorPrint3D?.toUpperCase(),
      icon: PlusOne,
      component: Print3DConfigurator,
      layout: "/",
    },
    {
      path: "indkoebskurv",
      name: strings.Basket,
      icon: ShoppingBasket,
      component: Checkout,
      layout: "/",
    },
    {
      path: "orders",
      name: strings.OrderHistory,
      icon: History,
      component: OrderHistory,
      layout: "/",
    },
    {
      path: "profile",
      name: strings.MyProfile?.toUpperCase(),
      icon: AccountCircle,
      component: MyProfile,
      layout: "/",
    },

    // {
    //   path: "orderConfirm/:orderId",
    //   name: strings.OrderHistory,
    //   // icon: History,
    //   component: OrderConfirmationPage,
    //   layout: "/",
    //   invisible: true,
    // },
    // {
    //   path: "guide",
    //   name: strings.DesignGuide,
    //   // icon: MenuBook,
    //   component: DesignGuide,
    //   layout: "/",
    //   invisible: true,
    // },
    // {
    //   path: "hjaelp",
    //   name: strings.NeedHelp,
    //   // icon: Help,
    //   component: ConfiguratorGuide,
    //   layout: "/",
    //   invisible: true,
    // },

    //leave commented below
    // {
    //   path: "/profile",
    //   name: "MIN PROFIL",
    //   icon: AccountCircle,
    //   component: MyProfile,
    //   layout: "/",
    //   invisible: true,
    // },

    // {
    //   path: "error",
    //   name: "Fejl",
    //   mini: "F",
    //   component: ErrorPage,
    //   layout: "/",
    //   invisible: true,
    // },
  ];
};
export default dashRoutes;
