import React, { useContext, useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { Route, Switch, useHistory } from "react-router";
import AppStateContext from "contexts/AppStateContext";
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer.js";
import LoginPage from "views/authPages/LoginPage";
import ResetPassword from "views/authPages/ResetPassword";
import Navbar from "../components/Navbars/Navbar";
import NewFooter from "components/Footer/NewFooter";
import RegisterPage from "views/authPages/RegisterPage";
import styles from "./css/layout.module.css";

const RegisterLayout = () => {
  const history = useHistory();

  const { strings, token } = useContext(AppStateContext);
  let { path, url } = useRouteMatch();

  useEffect(() => {
    if (token) {
      history.replace("/auth");
    }
  });

  return (
    <div className={`${styles.layout__register}`}>
      {/* <Navbar /> */}
      <div>
        <Switch>
          <Route exact path={path} component={RegisterPage} />
          {/* <Route path={`${path}/auth`} component={NewLoginPage} /> */}
        </Switch>
      </div>
      {/* <NewFooter /> */}
    </div>
  );
};
export default RegisterLayout;
