import { API_URL } from "./Common";

export const apiCreatePayment = (token: string, orderId: number) =>
  fetch(`${API_URL}/v1/ecommerce/orders/${orderId}/payment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());

export const apiGetPayment = (token: string, orderId: number) =>
  fetch(`${API_URL}/v1/ecommerce/orders/${orderId}/payment`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());
