import { useContext, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/creativeTim/components/footerStyle.js";

import "assets/css/CustomSelect.css";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { IconButton, Select, MenuItem, ListItemIcon } from "@mui/material";
import Flags from "country-flag-icons/react/3x2";

import {
  COMPANY_NAME,
  COUNTRY_SHORT,
  CVR,
  ADDRESS,
  ZIP,
  CITY,
  COMPANY_MAIL_CAPS,
  COMPANY_MAIL,
} from "util/CompanyInfoUtil";
import AppStateContext from "contexts/AppStateContext";
import CookieBot from "react-cookiebot";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white } = props;
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });

  const { strings, updateLanguage } = useContext(AppStateContext);

  const [chosenLanguage, setChosenLanguage] = useState(strings?.code ?? "dk");

  const handleChange = (event) => {
    setChosenLanguage(event.target.value);
    updateLanguage(event.target.value);
  };

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div
          style={{ display: "flex", alignItems: "center", width: "inherit" }}
        >
          <IconButton
            aria-label={"Facebook"}
            edge={"start"}
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://www.facebook.com/EasyPartz",
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          >
            <Facebook fontSize={"large"} className={classes.facebook} />
          </IconButton>
          <IconButton
            aria-label={"LinkedIn"}
            edge={"start"}
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://www.linkedin.com/company/easypartz",
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          >
            <LinkedIn fontSize={"large"} className={classes.linkedIn} />
          </IconButton>

          <Select
            value={chosenLanguage}
            onChange={handleChange}
            label="Language"
            variant="standard"
            disableUnderline
          >
            <MenuItem value={"dk"}>
              <ListItemIcon
                style={{
                  minWidth: "1.5em",
                  width: "1.5em",
                  height: "1.5em",
                  marginRight: "0.5em",
                }}
              >
                <Flags.DK />
              </ListItemIcon>
              DK
            </MenuItem>
            <MenuItem value={"gb"}>
              <ListItemIcon
                style={{
                  minWidth: "1.5em",
                  width: "1.5em",
                  height: "1.5em",
                  marginRight: "0.5em",
                }}
              >
                <Flags.GB />
              </ListItemIcon>
              EN
            </MenuItem>
          </Select>

          <a
            className={classes.cookiebot}
            id="cookieSettings"
            title={strings.CookiePreferencesChange}
            href="#"
            onClick={() => CookieBot.renew()}
          >
            &nbsp;&nbsp;&nbsp;{strings.CookiePreferences}
          </a>
        </div>
        <p className={classes.right} style={{ width: "100%" }}>
          &copy; {1900 + new Date().getYear() + "\xa0"}
          {COMPANY_NAME + " - "}
          <a
            href={
              "https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=39408872&soeg=easypartz"
            }
            target={"_blank"}
            rel="noreferrer"
          >
            CVR: {CVR}
          </a>
          <br />
          <a
            href={"https://goo.gl/maps/pkwD4EPo3huTC2hA7"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {ADDRESS}, {ZIP} {CITY}, {COUNTRY_SHORT}
          </a>
          <br />
          <a href="mailto: info@easypartz.com">{COMPANY_MAIL}</a>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};
