import { FetchError } from "exceptions/exceptions";
import { UserType } from "types/users/UserTypes";
import { API_URL } from "./Common";

const ENDPOINT = `${API_URL}/v1/ecommerce/auth`;

export const apiLogin = (
  username: string,
  password: string
): Promise<string> => {
  const body = JSON.stringify({
    username: username,
    password: password,
  });
  return fetch(`${ENDPOINT}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiRegister = (
  email: string,
  firstname: string,
  lastname: string,
  phone: string,
  company: string,
  password: string
): Promise<UserType> => {
  const body = JSON.stringify({
    customer: {
      email: email,
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      company: company,
    },
    password: password,
  });
  return fetch(`${ENDPOINT}/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((res) => {
      //changed from alwayss returning res to avoid attempted login after failed user creation
      console.log(res);
      return res.json();
      // else throw new FetchError(res.statusText, res.status);
    })
    .then((res) => {
      if (res.message) throw new FetchError(res.message, 400);
      return res;
    });
};

export const apiRequestPasswordReset = (email: string): Promise<string> => {
  const body = JSON.stringify({
    email: email,
  });
  return fetch(`${ENDPOINT}/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiResetPassword = (
  email: string,
  newPassword: string,
  token: string
): Promise<string> => {
  const body = JSON.stringify({
    email: email,
    newPassword: newPassword,
    resetToken: token,
  });
  console.log(body);
  return fetch(`${ENDPOINT}/confirm-reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCheckPasswordToken = (
  token: string,
  id: string
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/valid-reset-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
      id: id,
    }),
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
