import { FunctionComponent, useContext } from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import CompConfigContext from "contexts/CompConfigContext";
import AppStateContext from "contexts/AppStateContext";
import { Check, Clear } from "@mui/icons-material";

const LogOutDialog: FunctionComponent = () => {
  const { showLogoutModal, setShowLogoutModal } = useContext(CompConfigContext);
  const { strings, logUserOut } = useContext(AppStateContext);

  return (
    <Dialog
      open={showLogoutModal}
      fullWidth={false}
      onBackdropClick={() => setShowLogoutModal(false)}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {strings.LogoutBody}
      </DialogTitle>

      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Check />}
          onClick={() => logUserOut()}
        >
          {strings.YesButton?.toUpperCase()}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Clear />}
          onClick={() => setShowLogoutModal(false)}
        >
          {strings.NoButton?.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogOutDialog;
