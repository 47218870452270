import React, { FunctionComponent, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-dashboard-pro-react/vaerksComponents/selectionCardStyle.js";
import AppStateContext from "contexts/AppStateContext";
import { Box, Card, CardActionArea, CardMedia, Tooltip } from "@mui/material";
import { LocalShipping } from "@mui/icons-material";

const useStyles: any = makeStyles(styles);

type PropsType = {
  materialTitle: string;
  materialDescription: string;
  materialImage: string;
  isSelected: boolean;
  shipping?: number;
};

const MaterialCard: FunctionComponent<PropsType> = ({
  materialTitle,
  materialDescription,
  materialImage,
  isSelected,
  shipping,
}) => {
  const classes = useStyles();
  const { strings } = useContext(AppStateContext);
  return (
    <Tooltip
      title={
        <div>
          <div>
            <p
              className={classes.tooltipText}
              style={{ fontSize: "1rem", fontFamily: "inherit" }}
            >
              {shipping !== undefined &&
                (shipping === 0
                  ? strings.MaterialCardInStock
                  : strings.MaterialCardNotInStock)}
            </p>
            <p
              className={classes.tooltipText}
              style={{ fontSize: "0.9rem", fontFamily: "inherit" }}
            >
              {shipping !== undefined &&
                (shipping === 0
                  ? strings.MaterialCardDeliveryLow
                  : strings.MaterialCardDeliveryMed +
                    "+" +
                    shipping +
                    " " +
                    strings.Days +
                    ".")}
            </p>
          </div>
          <div>
            <p
              style={{ fontSize: "0.9rem", fontFamily: "inherit" }}
              className={classes.tooltipText}
            >
              {materialDescription}
            </p>
          </div>
        </div>
      }
      placement={"bottom"}
    >
      <Box boxShadow={3}>
        <Card
          className={
            isSelected ? classes.cardSelected : classes.cardNotSelected
          }
          style={{
            opacity: "1",
            background: isSelected ? "#008A80" : "#e9f5f2",
          }}
        >
          <CardActionArea>
            <CardMedia
              style={{ height: isSelected ? 117 : 120 }}
              image={materialImage}
              title={materialTitle}
            />
            <div
              style={{ fontFamily: "inherit", width: "100%", height: "10vh" }}
            >
              <p
                // variant="h5"
                className={classes.headline}
                style={{
                  fontWeight: isSelected ? "bold" : "normal",
                  display: "flex",
                  justifyContent: "space-between",
                  fontFamily: "inherit",
                  textTransform: "uppercase",
                  padding: "0.5rem 0.5rem 0 0.5rem",
                  color: isSelected ? "#fff" : "#000",
                }}
              >
                {materialTitle}
                <div
                  style={{
                    display: "flex",
                    float: "right",
                    justifyContent: "space-between",
                    fontFamily: "inherit",
                    // padding: "0.5rem 0.5rem 0 0",
                  }}
                >
                  <div
                    style={{
                      marginRight: "10px",
                      fontSize: "70%",
                      alignSelf: "center",
                      fontFamily: "inherit",
                    }}
                  >
                    {shipping !== undefined &&
                      (shipping === 0
                        ? strings.MaterialCardStatus1
                        : shipping === 1
                        ? "+" + strings.MaterialCardStatus2
                        : "+" + shipping + " " + strings.Days)}
                  </div>
                  <div style={{}}>
                    {shipping !== undefined &&
                      (shipping === 0 ? (
                        <LocalShipping
                          className={classes.icon}
                          color="primary"
                        />
                      ) : (
                        <LocalShipping
                          className={`${classes.icon} ${classes.delayMed}`}
                        />
                      ))}
                  </div>
                </div>
              </p>
            </div>
          </CardActionArea>
        </Card>
      </Box>
    </Tooltip>
  );
};

export default MaterialCard;
