import React, { FunctionComponent } from "react";
import styles from "./customIconButton.module.css";
import Delete from "../../assets/svg/dashboard/delete.svg";
import Edit from "../../assets/svg/dashboard/edit.svg";
import UploadPdf from "../../assets/svg/dashboard/uploadpdf.svg";

type PropsType = {
  onClick?: Function;
  buttonClass?: string;
  id?: string;
  tooltipText?: string;
  tooltipThread?: React.ReactNode;
  disabled?: boolean;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  tooltipClass?: string;
  styling?: string;
};

const IconButton: FunctionComponent<PropsType> = ({
  onClick,
  buttonClass,
  id,
  tooltipText,
  disabled,
  icon: Icon,
  tooltipThread,
  tooltipClass,
  styling,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className={`${styles.icon_button}`}>
      <div className={`${styles.tooltip} ${styling || ""}`}>
        <div style={{ zIndex: 9999 }}>
          <span className={`${styles.tooltip__text} ${tooltipClass || ""}`}>
            {tooltipText} {tooltipThread}
          </span>
        </div>
        <button
          className={`${styles.noButton} ${buttonClass || ""}`}
          onClick={handleClick}
          id={id}
          disabled={disabled}
        >
          <span className={`${styles.icon}`}>
            <Icon />
          </span>
        </button>
      </div>
    </div>
  );
};
export default IconButton;
