import React, { useContext, useState } from "react";
import MagentoLogin from "../../vaerksComponents/loginLogout/MagentoLogin";
import AppStateContext from "contexts/AppStateContext";
import LoginNote from "vaerksComponents/loginLogout/LoginNote";
import styles from "../../components/NewCard/cards.module.css";
import ForgotPassModal from "components/Modal/ForgotPassModal";

const LoginPage = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className={`${styles.layout}`}>
      <ForgotPassModal open={showModal} setOpen={setShowModal} />
      <div className={`${styles.layout__container}`}>
        <div className={`${styles.layout} ${styles.layout__login}`}>
          <MagentoLogin setShowForgotPass={setShowModal} />
        </div>
        <div className={`${styles.layout} ${styles.layout__note}`}>
          <LoginNote />
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
