export const reformatDate = (date: any) => {
  let reformat = new Date(date);
  return reformat.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
export const reformatDateTime = (date: any) => {
  let reformat = new Date(date);
  return reformat.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
