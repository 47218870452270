import React, { FunctionComponent, useContext } from "react";
import { Button, Dialog, DialogActions } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import CompConfigContext from "contexts/CompConfigContext";
import AppStateContext from "contexts/AppStateContext";
import { useHistory } from "react-router";
import styles from "vaerksComponents/loginLogout/CreateUserModal.module.css";

const PleaseLoginModal: FunctionComponent = () => {
  const { pleaseLoginModal, setPleaseLoginModal } =
    useContext(CompConfigContext);
  const { strings } = useContext(AppStateContext);

  const history = useHistory();

  const handleClick = (choice: boolean) => {
    if (choice) {
      history.push("auth");
    }
    setPleaseLoginModal(false);
  };

  return (
    <div>
      <Dialog
        open={pleaseLoginModal}
        fullWidth={false}
        onBackdropClick={() => setPleaseLoginModal(false)}
      >
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <b>{strings.UserModalTitle}</b>
          </div>
          <div className={styles.textContainer}>{strings.PleaseLoginText}</div>

          <br />
          <DialogActions
            style={{
              justifyContent: "space-evenly",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<Check style={{ marginBottom: "0.2em" }} />}
              onClick={() => handleClick(true)}
            >
              {strings.LoginOrRegister}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<Close style={{ marginBottom: "0.2em" }} />}
              onClick={() => handleClick(false)}
            >
              {strings.StayDEMO}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default PleaseLoginModal;
