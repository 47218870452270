import { FetchError } from "exceptions/exceptions";
import { UserUpdateType } from "types/users/UserCommandsType";
import { UserType } from "types/users/UserTypes";
import { API_URL } from "./Common";

const ENDPOINT = `${API_URL}/v1/ecommerce/users`;

export const apiGetUser = (token: string): Promise<UserType> => {
  return fetch(`${ENDPOINT}/me`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiUpdateUser = (
  token: string,
  userId: number,
  command: UserUpdateType
): Promise<UserType> => {
  const body = JSON.stringify(command);
  return fetch(`${ENDPOINT}/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiSubscribeNewsletter = (token: string, userId: number) => {
  return fetch(`${ENDPOINT}/newsletter/${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiUnsubscribeNewsletter = (token: string, userId: string) => {
  return fetch(`${ENDPOINT}/newsletter/${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
