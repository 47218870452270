import React, { FunctionComponent, useContext } from "react";
import AppStateContext from "contexts/AppStateContext";
import styles from "../checkoutComponents/styles/payment.module.css";
import { ReactComponent as ArrowExpand } from "../../assets/svg/dashboard/arrowExpand.svg";

type PropsType = {
  shippingTitle: string;
  shippingPrice: number;
  shippingDescription: string;
  shippingLogo: string;
  shippingTime: string;
  isSelected: boolean;
};

const ShippingRow: FunctionComponent<PropsType> = ({
  shippingTitle,
  shippingPrice,
  shippingDescription,
  shippingLogo,
  shippingTime,
  isSelected,
}) => {
  const [expanded, setExpanded] = React.useState(true);
  const { strings } = useContext(AppStateContext);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  return (
    <div
      onClick={handleExpandClick}
      className={`${styles.shipping__container}`}
    >
      <div
        className={`${styles.shipping__row}`}
        style={{
          background: isSelected ? "#008A80" : "white",
        }}
      >
        <img
          className={`${styles.shipping__img}`}
          src={shippingLogo}
          title={shippingTitle}
          alt="SHIPPING_COMPANY"
        />
        <div>
          <p
            style={{
              color: isSelected ? "white" : "black",
            }}
            className={`${styles.shipping__title}`}
          >
            {shippingTitle.toUpperCase()}
          </p>
        </div>

        <div
          style={{
            color: isSelected ? "white" : "black",
          }}
          className={`${styles.shipping__price}`}
        >
          {formatter.format(shippingPrice)}
        </div>
      </div>
      {isSelected ? (
        <>
          <div className={`${styles.selected__container}`}>
            <div className={`${styles.selected__container_content}`}>
              <ArrowExpand />
              <div className={`${styles.selected__description}`}>
                <p className={`${styles.selected__description_content}`}>
                  {shippingDescription}
                </p>
                <p>
                  {strings.ShippingCardTime}
                  {shippingTime}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default ShippingRow;
