import React from "react";
import AppStateContext from "contexts/AppStateContext";
import { FunctionComponent, useContext } from "react";
import { formatter } from "util/TextUtil";
import { LeadOptionsType, LeadTimeSelectorType } from "types/ConfigTypes";
import CustomSelect from "../../components/Select/CustomSelect";

type PropsType = {
  value: LeadOptionsType;
  onChange: (val: LeadOptionsType) => Promise<boolean>;
  leadTimeOptions?: LeadTimeSelectorType;
  disabled?: boolean;
};

const LeadTimeSelector: FunctionComponent<PropsType> = ({
  value,
  onChange,
  leadTimeOptions,
  disabled,
}) => {
  const { strings } = useContext(AppStateContext);

  // Lead Time option values - Days
  function totalLeadTimeText(leadtimeOption: LeadOptionsType) {
    let leadtime: number | string = "...";

    if (leadTimeOptions) {
      leadtime = leadTimeOptions[leadtimeOption]?.days ?? 0;
    }

    return `${leadtime} ${strings.BasketSummaryLeadtimeText}`;
  }

  // Lead Time option values - Cost
  function totalLeadtimeCostText(leadtimeOption: LeadOptionsType) {
    if (leadtimeOption !== value) {
      let leadtimeCost = 0;
      if (leadTimeOptions) {
        leadtimeCost =
          (leadTimeOptions[leadtimeOption]?.price ?? 0) -
          (leadTimeOptions[value]?.price ?? 0);
      }

      return `| ${leadtimeCost < 0 ? " " : " +"}${formatter.format(
        leadtimeCost
      )}`;
    } else {
      return "";
    }
  }

  return (
    // <select
    //   value={value}
    //   onChange={(e) => onChange(e.target.value as LeadOptionsType)}
    //   disabled={disabled}
    // >
    //   <option value="" disabled>
    //     Please select an option
    //   </option>
    //   <option value={"EXPRESS"}>
    //     {totalLeadTimeText("EXPRESS")} | {totalLeadtimeCostText("EXPRESS")}
    //   </option>
    //   <option value={"STANDARD"}>
    //     {totalLeadTimeText("STANDARD")} | {totalLeadtimeCostText("STANDARD")}
    //   </option>
    //   <option value={"ECONOMIC"}>
    //     {totalLeadTimeText("ECONOMIC")} | {totalLeadtimeCostText("ECONOMIC")}
    //   </option>
    // </select>
    <div>
      <CustomSelect
        value={value}
        onChange={(e: any) => onChange(e.target.value as LeadOptionsType)}
        disabled={disabled}
      >
        <option value="" disabled style={{ color: "var(--primary-dashed)" }}>
          Please select an option
        </option>
        {leadTimeOptions &&
          Object.entries(leadTimeOptions)
            .sort(([keyA, valA], [keyB, valB]) => {
              return valA.days - valB.days;
            })
            .map(([key, val]) => {
              const leadtimeOption = key as LeadOptionsType;
              return (
                <option value={leadtimeOption}>
                  {totalLeadTimeText(leadtimeOption)}{" "}
                  {totalLeadtimeCostText(leadtimeOption)}
                </option>
              );
            })}
        {/*  <option value={"EXPRESS"}>
           {totalLeadTimeText("EXPRESS")} {totalLeadtimeCostText("EXPRESS")}
         </option>
         <option value={"STANDARD"}>
           {totalLeadTimeText("STANDARD")} {totalLeadtimeCostText("STANDARD")}
         </option>
         <option value={"ECONOMIC"}>
           {totalLeadTimeText("ECONOMIC")} {totalLeadtimeCostText("ECONOMIC")}
         </option> */}
      </CustomSelect>
    </div>
  );
};
export default LeadTimeSelector;
