export const COMPANY_NAME = "EasyPartz ApS";
export const CVR = "39408872";
export const ADDRESS = "Maskinvej 5";
export const CITY = "Søborg";
export const ZIP = "2860";
export const COUNTRY = "Danmark";
export const COUNTRY_SHORT = "DK";
export const MADS_NUMMER = "+45 60 85 80 75";
export const MORTEN_NUMMER = "+45 22 85 80 77";
export const MORTEN_MAIL = "moh@vaerks.com";
export const COMPANY_MAIL = "info@easypartz.com";
export const COMPANY_MAIL_CAPS = "INFO@EASYPARTZ.COM";
export const OPENING_HOURS = "Man - Fre 08:00 - 17:00";
export const TAX_RATE = 0.25;
