import { OrderType } from "types/OrderTypes";
import { API_URL } from "./Common";

export const apiQuoteAccept = (
  token: string,
  quoteId: string,
  info: any
): Promise<OrderType> =>
  fetch(`${API_URL}/v1/ecommerce/quotes/${quoteId}/accept`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    method: "POST",
    body: JSON.stringify(info),
  }).then((res) => res.json());

export const apiGetQuote = (token: string, quoteId: string) => {
  return fetch(`${API_URL}/v1/ecommerce/quotes/${quoteId}`, {
    headers: {
      "Content-Type": "application/file",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else if (res.status == 302) return "True";
  });
};

export const apiGetQuoteOrderId = (token: string, orderId: number) => {
  return fetch(`${API_URL}/v1/ecommerce/quotes/order/${orderId}`, {
    headers: {
      "Content-Type": "application/file",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else if (res.status == 302) return "True";
  });
};
