import { Line, Mesh, Object3D } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export function modelLoader(token: string, url: string): Promise<GLTF> {
  console.log("loading model", url);
  const loader = new GLTFLoader();
  loader.setRequestHeader({ Authorization: "Bearer " + token });
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath("three/examples/js/libs/draco");
  loader.setDRACOLoader(dracoLoader);
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      (data) => resolve(data),
      function (xhr) {
        //console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      reject
    );
  });
}

export const checkGltfChild = (child: Object3D): Object3D[] | [] => {
  if (child instanceof Mesh) {
    return [child];
  }
  if (child instanceof Line) {
    return [child];
  }
  if (child instanceof Object3D && child.children.length > 0) {
    const newChildren = child.children
      .map((c) => {
        return checkGltfChild(c);
      })
      .flat();
    return newChildren;
  }

  return [];
};
