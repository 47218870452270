import React, { useContext, useState } from "react";
import { ReactComponent as Brand } from "../../assets/img/newLogo.svg";
import styles from "./css/navbar.module.css";
import AppStateContext from "contexts/AppStateContext";
import { NavLink, useHistory, useLocation } from "react-router-dom";

const Navbar = () => {
  const { strings, updateLanguage } = useContext(AppStateContext);
  const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL ?? "/";

  const [chosenLanguage, setChosenLanguage] = useState<string>(
    strings?.code ?? "dk"
  );

  const activeRoute = (routeName: string) => {
    return window.location.href.indexOf(routeName) > -1;
  };

  //   const [activeLanguage, setActiveLanguage] = useState("dk");

  const handleDkClick = () => {
    setChosenLanguage("dk");
    updateLanguage("dk");
    // setActiveLanguage("dk");
  };

  const handleEnClick = () => {
    setChosenLanguage("gb");
    updateLanguage("gb");
    // setActiveLanguage("gb");
  };

  const handleChange = (event: React.ChangeEvent<HTMLButtonElement>) => {
    setChosenLanguage(event.target.value);
    updateLanguage(event.target.value);
  };

  return (
    <div className={`${styles.navbar__container}`}>
      <nav>
        <div className={`${styles.navbar__left}`}>
          <Brand
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = FRONTEND_URL)}
          ></Brand>
        </div>
        <div className={`${styles.navbar__right}`}>
          {/* <select value={chosenLanguage} onChange={handleChange}>
              <option value="gb">EN</option>
              <option value="dk">DK</option>
            </select> */}
          <button
            onChange={handleChange}
            onClick={handleEnClick}
            className={`${styles.language_button} ${
              chosenLanguage === "gb" ? styles.activeLanguageButton : ""
            }`}
          >
            EN
          </button>
          <span className={`${styles.line}`}></span>
          <button
            onChange={handleChange}
            onClick={handleDkClick}
            className={`${styles.language_button} ${
              chosenLanguage === "dk" ? styles.activeLanguageButton : ""
            }`}
          >
            DK
          </button>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
