import {
  blackColor,
  container,
  containerFluid,
  defaultFont,
  grayColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

const footerStyle = (theme) => ({
  block: {},
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    margin: "-15px -15px -15px 0",
    fontSize: "14px",
    float: "right!important",
    textAlign: "right!important",
    padding: "15px",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "bold!important",
  },
  footer: {
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    padding: "15px 0",
    ...defaultFont,
    zIndex: 4,
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    //width: "70% !important",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto",
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: blackColor,
    },
  },
  linkedIn: {
    color: "#ffffff",
    backgroundColor: "#0e76a8",
    borderRadius: "5px",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.4)",
    },
  },
  facebook: {
    color: "#ffffff",
    backgroundColor: "#3b5998",
    borderRadius: "5px",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.4)",
    },
  },
  cookiebot: {
    fontWeight: "bold",
  },
});
export default footerStyle;
