import React, { FunctionComponent, useContext } from "react";
import { NavLink, useRouteMatch, Route, useLocation } from "react-router-dom";
import styles from "./css/dashboardNavbar.module.css";
import AppStateContext from "contexts/AppStateContext";
import EcommerceContext from "contexts/EcommerceContext";
import { ReactComponent as AmountIcon } from "../../assets/svg/dashboard/amountBadge.svg";
import { ProductTypeEnum } from "types/products/ProductCommandsType";

const DashboardNavbar: FunctionComponent = ({}) => {
  // const basketItemCount = basket.items.length;
  const location = useLocation();
  const isActiveTab = (path: string) => {
    return location.pathname === path;
  };

  const { strings } = useContext(AppStateContext);
  const { cartItems } = useContext(EcommerceContext);

  let match = useRouteMatch();
  const getActiveRoute = (routes: any) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: number) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          // @ts-ignore
          <Route
            path={match.url + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={`${styles.tabs}`}>
      <ul className={`${styles.nav}`}>
        <li key={"ny-komponent/cnc"}>
          <NavLink
            to={`/ny-komponent/cnc`}
            activeClassName={`${styles.activeTab}`}
            className={`${styles.tabs__components} `}
          >
            {strings.ConfiguratorCNC?.toUpperCase()}
          </NavLink>
        </li>
        <li key={"ny-komponent/print3d"}>
          <NavLink
            to={`/ny-komponent/print3d`}
            activeClassName={`${styles.activeTab}`}
            className={`${styles.tabs__components} `}
          >
            {strings.ConfiguratorPrint3D?.toUpperCase()}
          </NavLink>
        </li>
        <li key={"basket"} style={{ display: "flex" }}>
          <NavLink
            to={`/indkoebskurv`}
            activeClassName={`${styles.activeTab}`}
            className={`${styles.tabs__components} `}
          >
            {strings.Basket?.toUpperCase()}
            <div
              style={{
                padding: "0.2em",
                width: "1.3em",
                height: "1.3em",
                background: "#008B80",
                borderRadius: "50%",
                marginLeft: "0.3em",
              }}
            >
              <span
                style={{
                  position: "relative",
                  color: "white",
                  bottom: "0.1em",
                  fontWeight: "400",
                }}
              >
                {
                  cartItems.filter(
                    (i) => i.product.productType !== ProductTypeEnum.FEE
                  ).length
                }
              </span>
            </div>
          </NavLink>
        </li>
        <li key={"orderhistory"}>
          <NavLink
            to={`/ordrehistorik`}
            activeClassName={`${styles.activeTab}`}
            className={`${styles.tabs__components} `}
          >
            {strings.OrderHistory?.toUpperCase()}
          </NavLink>
        </li>
        <li key={"profile"}>
          <NavLink
            to={`/profile`}
            activeClassName={`${styles.activeTab}`}
            className={`${styles.tabs__components} `}
          >
            {strings.MyProfile?.toUpperCase()}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default DashboardNavbar;
