import React from "react";
import Register from "../../vaerksComponents/loginLogout/Register";
import styles from "../../components/NewCard/cards.module.css";
import RegisterNote from "vaerksComponents/loginLogout/RegisterNote";

const RegisterPage = () => {
  return (
    <div className={`${styles.layout}`}>
      <div className={`${styles.layout__container}`}>
        <div className={`${styles.layout} ${styles.layout__login}`}>
          <Register />
        </div>
        <div className={`${styles.layout} ${styles.layout__note}`}>
          <RegisterNote />
        </div>
      </div>
    </div>
  );
};
export default RegisterPage;
