import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./checkoutComponents/styles/cncCartTableRow.module.css";

type PropsType = {
  initVal: number;
  onChange: Function;
  disabled: boolean;
};

const AmountInput: FunctionComponent<PropsType> = ({
  initVal,
  onChange,
  disabled,
}) => {
  const [value, setValue] = useState<number>(initVal);
  const [tempAmount, setTempAmount] = useState<any>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (tempAmount === null) {
      inputRef.current?.blur();
    }
  }, [tempAmount]);

  useEffect(() => {
    setValue(initVal);
  }, [initVal]);

  const updateAmount = useCallback(
    (amount) => {
      if (timer) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout(() => {
        if (amount !== value) {
          onChange(amount);
        }
      }, 500);
      setTimer(newTimer);
    },
    [onChange, timer]
  );

  function decreaseOnClick() {
    updateAmount(value - 1);
    setValue((old) => old - 1);
    // setIsDisabled(true);
    setTempAmount(null);
  }

  function increaseOnClick() {
    updateAmount(value + 1);
    setValue((old) => old + 1);
    setTempAmount(null);
  }

  function inputOnblur(e: any) {
    if (Number.parseInt(e.target.value) === value) return;
    if (isNaN(parseInt(e.target.value))) {
      setTempAmount(1);
      setValue(1);
      setTempAmount(null);
      onChange(1);
    } else if (parseInt(e.target.value) !== 0) {
      if (parseInt(e.target.value) < 0) {
        setValue(1);
        setTempAmount(null);
        onChange(1);
      } else if (parseInt(e.target.value) > 1000) {
        setValue(1000);
        setTempAmount(null);
        onChange(1000);
      } else {
        setValue(Number.parseInt(e.target.value));
        setTempAmount(null);
        onChange(Number.parseInt(e.target.value));
      }
    }
    // setIsDisabled(true);
  }

  function inputOnChange(e: any) {
    if (isNaN(parseInt(e.target.value))) {
      setTempAmount(e.target.value);
    } else {
      if (parseInt(e.target.value) < 1) {
        setTempAmount(1);
      } else if (parseInt(e.target.value) > 1000) {
        setTempAmount(1000);
      } else {
        setTempAmount(e.target.value);
      }
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        height: "2.5em",
        borderRadius: "6px",
        border: "none",
        boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.160784)",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "2.5em",
          height: "100%",
          border: "none",
          backgroundColor: "white",
          cursor: "pointer",
        }}
        aria-label={"Decrease"}
        color={"secondary"}
        disabled={value <= 1 || isDisabled}
        onClick={() => decreaseOnClick()}
      >
        -
      </button>
      <input
        style={{
          width: "3em",
          height: "100%",
          textAlign: "center",
          border: "none",
          backgroundColor: "white",
        }}
        className={`${styles.input_active}`}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            inputRef.current?.blur();
          }
          if (e.key === "Escape") {
            setTempAmount(null);
          }
          if (e.key === "." || e.key === ",") {
            e.preventDefault();
          }
        }}
        onBlur={(e) => inputOnblur(e)}
        onChange={(e) => inputOnChange(e)}
        value={tempAmount ?? value}
        disabled={isDisabled}
        type="number"
        id="increaseNum"
        step="1"
      />
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "2.5em",
          height: "100%",
          border: "none",
          backgroundColor: "white",
          cursor: "pointer",
        }}
        aria-label={"Increase"}
        color={"primary"}
        disabled={isDisabled || value >= 1000}
        onClick={() => increaseOnClick()}
      >
        +
      </button>
    </div>
  );
};
export default AmountInput;
