import PreviewImage from "components/PreviewImage";
import AppStateContext from "contexts/AppStateContext";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { OrderItemType } from "types/OrderTypes";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import { apiGetPreviewImage } from "util/network/Products";
import styles from "./css/order.module.css";
import stylesTable from "../../vaerksComponents/checkoutComponents/styles/payment.module.css";

type PropsType = {
  item: OrderItemType;
};

const OrderItemRow: FunctionComponent<PropsType> = ({ item }) => {
  const { strings, token } = useContext(AppStateContext);

  const [previewImage, setPreviewImage] = useState<string>();

  const details =
    item.product.productType === ProductTypeEnum.CNC
      ? ({
          ...item.product.details,
          finish: (item.product.details?.finish as any).split(","),
        } as unknown as CNCProductDetailsType)
      : ({
          ...item.product.details,
          finish: (item.product.details?.finish as any).split(","),
        } as unknown as Print3DProductDetailsType);

  useEffect(() => {
    apiGetPreviewImage(token, details.modelId).then((res) => {
      if (res) {
        setPreviewImage(res);
      }
    });
  }, [token]);

  return (
    <>
      <tr>
        <td align="left">
          <PreviewImage src={previewImage} alt={item.name} size={5} />
        </td>
        <td align="left">
          <div className={`${stylesTable.item__name}`}>{item.name}</div>
          <div className={`${stylesTable.item__name}`}>
            ({item.product.productType})
          </div>
        </td>
        <td align="left">
          <span className={`${stylesTable.item__material}`}>
            {details.material}/<br />
            {details.finish.length > 1
              ? details.finish.filter((i) => i !== "standard").join(", ")
              : details.finish}
          </span>
        </td>
        <td align="center">
          <span className={`${stylesTable.item__quantity}`}>
            {item.quantity}
          </span>
        </td>
        <td align="center">
          <span className={`${stylesTable.item__total}`}>{item.price}</span>
          <br />
          <span className={`${stylesTable.item__perUnit}`}>
            {item.unitPrice}/{strings.unit}
          </span>
        </td>
      </tr>
      <tr>
        <td
          colSpan={5}
          align="left"
          className={`${stylesTable.line__container_solid}`}
        >
          <div className={`${stylesTable.line_solid}`}></div>
        </td>
      </tr>
    </>
  );
};
export default OrderItemRow;
