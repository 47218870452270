let defaultState = {
  userCreated: undefined,
  loginStatus: undefined,
  registrationStatus: undefined,
  guestID: localStorage.getItem("guestID"),
  cartID: undefined,
  guestCartContent: [],
  cartContent: [],
  shippingCost: undefined,
  orderConfirmed: null,
  orderHistory: [],
  componentPrice: undefined,
  userData: undefined,
  guestMode: false,
  guestUpgrade: false,
  cartInfo: undefined,
  couponConfirmed: undefined,
};

const magentoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "LOG_IN":
      return {
        ...state,
        guestMode: false,
        guestID: null,
        loginStatus: action.payload,
      };
    case "LOG_IN_ERROR":
      return {
        ...state,
        loginStatus: action.payload,
      };
    case "CREATE_USER":
      return {
        ...state,
        userCreated: true,
        guestMode: false,
        registrationStatus: action.payload,
        shippingCost: state.shippingCost,
      };
    case "CREATE_USER_ERROR":
      return {
        ...state,
        userCreated: false,
        registrationStatus: action.payload,
        shippingCost: state.shippingCost,
        guestMode: true,
        guestID: state.guestID,
        guestCartContent: state.guestCartContent,
      };
    case "SET_GUEST_ID":
      return {
        ...state,
        guestID: action.payload,
        guestMode: true,
        guestCartContent: state.guestCartContent,
      };
    case "SET_GUEST_CART_CONTENT":
      return {
        ...state,
        guestID: state.guestID,
        guestMode: true,
        guestCartContent: action.payload,
      };
    case "SET_GUEST_SHIPPING_COST":
      return {
        ...state,
        shippingCost: action.payload,
        guestMode: true,
        guestID: state.guestID,
        guestCartContent: state.guestCartContent,
      };
    case "SET_GUEST_UPGRADE":
      return {
        ...state,
        guestUpgrade: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
        guestMode: false,
      };
    case "SET_CART_ID":
      return {
        ...state,
        cartID: action.payload,
        guestMode: false,
        cartContent: state.cartContent,
        orderHistory: state.orderHistory,
      };
    case "SET_CART_CONTENT":
      return {
        ...state,
        cartContent: action.payload,
        guestMode: false,
        orderHistory: state.orderHistory,
        cartID: state.cartID,
        shippingCost: state.shippingCost,
      };
    case "SET_SHIPPING_COST":
      return {
        ...state,
        shippingCost: action.payload,
        guestMode: false,
        cartID: state.cartID,
        cartContent: state.cartContent,
      };
    case "CONFIRM_ORDER":
      return {
        ...state,
        orderConfirmed: action.payload,
        guestMode: false,
      };
    case "SET_ORDER_HISTORY":
      return {
        ...state,
        orderHistory: action.payload,
        guestMode: false,
        cartID: state.cartID,
        cartContent: state.cartContent,
      };
    case "SET_INVOICE_RESEND":
      return {
        ...state,
        invoiceResend: action.payload,
        guestMode: false,
        orderHistory: state.orderHistory,
        cartID: state.cartID,
      };
    case "SET_COMPONENT_PRICE":
      return {
        ...state,
        componentPrice: action.payload,
        guestMode: state.guestMode,
        guestID: state.guestID,
        guestCartContent: state.guestCartContent,
        cartID: state.cartID,
        cartContent: state.cartContent,
        orderHistory: state.orderHistory,
      };
    case "SET_GUEST_MODE":
      return {
        ...state,
        guestMode: action.payload,
      };
    case "LOG_OUT":
      return {
        ...state,
        loggedIn: false,
        loginStatus: undefined,
        guestID: undefined,
        guestCartContent: [],
        cartID: undefined,
        cartContent: [],
        shippingCost: undefined,
        orderConfirmed: null,
        orderHistory: [],
        componentPrice: undefined,
        userData: undefined,
        guestMode: undefined,
        guestUpgrade: undefined,
      };
    case "SET_CART_INFO":
      return {
        ...state,
        cartInfo: action.payload,
      };
    case "SET_COUPON":
      return {
        ...state,
        couponConfirmed: action.payload,
      };
    default:
      return state;
  }
};

export default magentoReducer;
