import React, { FunctionComponent } from "react";
import styles from "./customButton.module.css";

type PropsType = {
  buttonText?: string;
  buttonClass?: string;
  iconClass?: string;
  onClick?: Function;
  form?: string;
  type?: "submit" | "reset" | "button";
  id?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const CustomButton: FunctionComponent<PropsType> = ({
  buttonText,
  buttonClass,
  iconClass,
  onClick,
  form,
  type = "button",
  id,
  disabled,
  children,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={`${styles.button__container}`}>
      <button
        className={`${styles.button} ${buttonClass || ""}`}
        onClick={handleClick}
        form={form}
        type={type}
        id={id}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <>
            <span className={`${styles.button__title}`}>{buttonText}</span>
            <i className={`${styles.button__icon} ${iconClass || ""}`}>
              <svg viewBox="0 0 13 23">
                <path d="M12.555 11.014C12.54 10.5613 12.3594 10.13 12.0472 9.802L3.18242 0.567883C2.72453 0.0914688 2.04748 -0.104294 1.40617 0.0538731C0.764858 0.21204 0.256519 0.70059 0.0728273 1.33517C-0.110602 1.96975 0.0581188 2.65407 0.516 3.13048L8.14602 11.083L0.516 19.0355C0.0581188 19.512 -0.110602 20.1963 0.0728273 20.8309C0.256519 21.4655 0.764858 21.9536 1.40617 22.1122C2.04748 22.2704 2.72453 22.0743 3.18242 21.5982L12.0472 12.3641C12.3927 12.0013 12.5758 11.5143 12.555 11.014Z" />
              </svg>
            </i>
          </>
        )}
      </button>
    </div>
  );
};

export default CustomButton;
