import { FunctionComponent } from "react";
import styles from "./PreviewImage.module.css";

type PropsType = {
  src?: string;
  alt: string;
  size?: number;
};

const PreviewImage: FunctionComponent<PropsType> = ({ src, alt, size }) => {
  return (
    <img
      className={styles.previewImage}
      style={{ width: `${size ?? 7}em` }}
      src={src}
      alt={alt}
    />
  );
};

export default PreviewImage;
