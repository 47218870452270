import React, {
  FunctionComponent,
  useContext,
  useState,
  useMemo,
  useRef,
} from "react";

import styles from "./css/newLoginPageStyles.module.css";
import Cards from "../../components/NewCard/Cards";
import AppStateContext from "contexts/AppStateContext";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { apiLogin, apiRequestPasswordReset } from "util/network/Auth";
import CustomButton from "components/Buttons/CustomButton";
import CustomInput from "components/Input/CustomInput";
import { REGEX_EMAIL } from "util/RegExUtil";
import CustomCheckbox from "components/Checkbox/CustomCheckbox";
import stylesCard from "../../components/NewCard/cards.module.css";
import ForgotPassModal from "components/Modal/ForgotPassModal";
import stylesBtn from "./../../components/Buttons/customButton.module.css";
import NavigateButton from "components/Buttons/NavigateButton";

type PropsType = {
  setShowForgotPass: (arg: boolean) => void;
};

const MagentoLogin: FunctionComponent<PropsType> = ({ setShowForgotPass }) => {
  const { pushInstruction, trackEvent } = useMatomo();
  const dispatch = useDispatch();

  const { strings, showLoginModal, setShowLoginModal, setToken } =
    useContext(AppStateContext);

  //const loginStatus = useSelector((state) => state.loginStatus);
  const [loginStatus, setLoginStatus] = useState<string>();

  const history = useHistory();
  const location = useLocation();

  const [magentoEmail, setMagentoEmail] = useState<string>("");
  const [magentoPassword, setMagentoPassword] = useState<string>("");
  const [resetPassword, setResetPassword] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [loginText, setLoginText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>(null);

  // const [inputValue, setInputValue] = useState("");
  const [shake, setShake] = useState(false);

  // Submits login information to Magento
  function onSubmit(e: any) {
    e.preventDefault();
    setError(false);

    if (!magentoEmail || !magentoPassword) {
      setError(true);
      setLoginText(strings.LoginMissingInfo);

      // setShake(true);
      // setTimeout(() => {
      //   setShake(false);
      // }, 500);

      return;
    } else if (magentoEmail || magentoPassword) {
      setLoginText(undefined);
    }

    if (!loading) {
      setLoading(true);
    }

    // setLoginText(strings.LoginWaitMsg);

    apiLogin(magentoEmail, magentoPassword)
      .then((res) => {
        if (res) {
          sessionStorage.clear();
          localStorage.removeItem("guestID");
          localStorage.setItem("token", res);
          setToken(res);
          //@ts-ignore
          window.gtag("event", "login");

          pushInstruction("setUserId", magentoEmail);
          trackEvent({
            category: "User authorization",
            action: "User Logged In",
          });
          if (location.state?.sendBack) {
            history.goBack();
          } else {
            history.replace("ny-komponent");
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        if (e.message === "UNAUTHORIZED") {
          setLoginText(strings.LoginWrongCredentials);
        } else {
          setLoginText(e.message);
        }
      });
  }

  const demoText = useMemo(() => {
    const text = strings.BeforeWeStartTryDemo.split(" %s ");
    // console.log(text);
    return (
      <div className="">
        {text[0]}
        <button
          // className={buttonStyles.linkBtn}
          onClick={() =>
            history.replace({
              pathname: "ny-komponent",
              search: "?demo=true",
            })
          }
        >
          {strings.here}
        </button>
        {text[1]}
      </div>
    );
  }, [history, strings.BeforeWeStartTryDemo, strings.here]);

  return (
    <>
      <Cards className={`${stylesCard.card__login}`}>
        <h1 className={`${styles.title}`}>Log in</h1>
        <div className={`${styles.form__container}`}>
          <form id={"login"} onSubmit={onSubmit}>
            <div className={`${styles.input__container}`}>
              <div className={`${styles.error__text}`}>{loginText}</div>
              <CustomInput
                label={strings.Email}
                type="email"
                placeholder={strings.Email}
                error={!REGEX_EMAIL.test(magentoEmail) && magentoEmail !== ""}
                value={magentoEmail}
                id="loginEmail"
                onChange={(e: any) => setMagentoEmail(e.target.value)}
                className={shake ? "shake" : ""}
                pattern={`${REGEX_EMAIL}`}
                required
              />
              <CustomInput
                label={strings.Password}
                type="password"
                placeholder={strings.Password}
                value={magentoPassword}
                id="loginPassword"
                onChange={(e: any) => setMagentoPassword(e.target.value)}
                required
              />
              <div style={{ paddingTop: "1rem" }}>
                <CustomCheckbox label={strings.RememberMe} type={"checkbox"} />
              </div>

              <NavigateButton
                buttonText={strings.LoginButton}
                buttonClass={`${stylesBtn.button__login}`}
                iconClass={`${stylesBtn.login__icon}`}
                type="submit"
                id="loginSubmit"
                loading={loading}
              />
            </div>
            <div className={`${styles.submit__forgotPassword}`}>
              <a
                onClick={() => setShowForgotPass(true)}
                style={{ cursor: "pointer" }}
                id="forgotPassword"
              >
                {strings.ForgottenPassword}
              </a>
            </div>
            {/* <div
            style={{
              width: "20em",
              height: "20em",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "black",
            }}
          ></div> */}
          </form>
        </div>
      </Cards>
    </>
  );
};

export default MagentoLogin;
