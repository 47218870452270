import {
  FunctionComponent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/vaerksComponents/dropzoneStyle.js";
//@ts-ignore
import { Document, Page, pdfjs } from "react-pdf";
import SweetAlert from "react-bootstrap-sweetalert";
import useWindowDimensions from "../../useWindowDimensions";
import CompConfigContext from "contexts/CompConfigContext";
import cssStyles from "styles/components/ReuploadButtons.module.css";
import {
  dangerColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react";
import AppStateContext from "contexts/AppStateContext";
import * as ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
//@ts-ignore
import { SwipeableViewsContext } from "react-swipeable-views";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Delete,
  FindReplace,
  PictureAsPdf,
  UploadFile,
} from "@mui/icons-material";
import { createStyles, makeStyles } from "@material-ui/core";
import UploadDropzone from "components/UploadDropzone";
import textStyles from "styles/Text.module.css";
import { API_URL } from "util/network/Common";
import CustomIconButton from "../../../components/Buttons/CustomIconButton";

import { ReactComponent as DeleteIcon } from "../../../assets/svg/dashboard/deletePdf.svg";
import { ReactComponent as UploadPdf } from "../../../assets/svg/dashboard/uploadBlack.svg";
import { ReactComponent as BlueprintIcon } from "../../../assets/svg/dashboard/blueprintUpload.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles: any = makeStyles(createStyles(() => styles));

type PropsType = {
  productId?: number;
  blueprint: string | null;
  onChange: (filename: string | null) => void;
};

const TechnicalUpload: FunctionComponent<PropsType> = ({
  productId,
  blueprint,
  onChange,
}) => {
  const { strings, token } = useContext(AppStateContext);
  //@ts-ignore
  const { slideUpdateHeight } = useContext(SwipeableViewsContext);
  const { trackEvent } = useMatomo();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { height, width } = useWindowDimensions();

  /* Function for new model upload */
  const handleSubmit = async (file: any) => {
    // Create new form with uploaded data
    if (file) {
      const data = new FormData();
      data.append("file", file);
      data.append("filename", file.name);

      if (file.type === "application/pdf") {
        // Post to API
        let url = `${API_URL}/v1/ecommerce/products/${productId}/blueprint`;

        const pdfResponse = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        });

        const blueprintName = await pdfResponse.text();
        // If successfull, update to temporary image and check if new image is generated in 500ms
        if (blueprintName) {
          // const newFileName = responsePDF[1];
          onChange(blueprintName);
          setPageNumber(1);
        } else
          window.alert("Der skete en fejl under upload af din arbejdstegning");
      }

      // Google Analytics Event
      ReactGA.event({
        category: "Component Configuration",
        action: "Technical Drawing Submit",
        label: file.name,
      });
      trackEvent({
        category: "Component Configuration",
        action: "Technical Drawing Submit",
        name: file.name,
      });
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    console.log("PDF LOADED");
    setNumPages(numPages);
    setTimeout(() => {
      slideUpdateHeight();
    }, 500);
  }

  const deletePDF = async () => {
    let url = `${API_URL}/v1/ecommerce/products/${productId}/blueprint`;
    const pdfDelete = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const deletePDF = await Boolean(pdfDelete.text());

    // If successfull, update to temporary image and check if new image is generated in 500ms
    if (deletePDF) {
      onChange(null);
      setPageNumber(1);
      setNumPages(1);
    } else window.alert("Din arbejdstegning blev ikke slettet korrekt...");

    // Google Analytics Event
    ReactGA.event({
      category: "Component Configuration",
      action: "Technical Drawing Delete",
    });
    trackEvent({
      category: "Component Configuration",
      action: "Technical Drawing Delete",
    });
  };

  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = async (event: any) => {
    const file = event.target.files[0];
    await handleSubmit(file);
  };

  const classes = useStyles();
  return (
    <div>
      <Grid container justifyContent="center">
        {blueprint ? (
          <div style={{ fontWeight: "400" }}>
            {/*@ts-ignore*/}
            <Document
              file={
                blueprint
                  ? {
                      url: `${API_URL}/v1/ecommerce/products/${productId}/blueprint`,
                      httpHeaders: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  : null
              }
              error={"Ingen arbejdstegning uploadet"}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={() => {
                console.log("PDF ERROR");
                setShowAlert(true);
              }}
            >
              {/*@ts-ignore*/}
              <Page
                pageNumber={pageNumber}
                width={width * 0.45}
                loading={<div className={classes.pdfTemp} />}
              />
            </Document>
            <div
              className={classes.pdfNavigation}
              style={{ paddingTop: "1rem" }}
            >
              <IconButton
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1 || !blueprint}
                color={"primary"}
              >
                <ArrowBack />
              </IconButton>
              <p className={classes.pdfPageText}>
                Side <b>{pageNumber}</b> af <b>{numPages}</b>
              </p>
              <IconButton
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numPages || !blueprint}
                color={"primary"}
              >
                <ArrowForward />
              </IconButton>
            </div>
          </div>
        ) : null}

        {blueprint && (
          <div className={classes.pdfDeleteButton}>
            <CustomIconButton
              onClick={() => {
                handleClick();
              }}
              tooltipText="Upload ny arbejdstegning"
              icon={UploadPdf}
            />
            <CustomIconButton
              onClick={() => deletePDF()}
              tooltipText="Slet arbejdstegning"
              icon={DeleteIcon}
            />
          </div>
        )}

        {showAlert ? (
          <SweetAlert
            style={{ marginTop: "-250px", position: "fixed" }}
            info
            allowEscape={false}
            closeOnClickOutside={true}
            onCancel={() => setShowAlert(false)}
            title="Bemærk!"
            onConfirm={() => setShowAlert(false)}
            showCancel={false}
            customButtons={
              <Button
                {...{ onClick: () => setShowAlert(false) }}
                color="primary"
              >
                <b>MODTAGET</b>
              </Button>
            }
          >
            Du har uploadet en anden model - tjek venligst om din arbejdstegning
            fortsat er relevant!
          </SweetAlert>
        ) : null}
        <div className={classes.dropzoneContainer}>
          {!blueprint ? (
            <UploadDropzone
              onSubmit={(files) => handleSubmit(files[0])}
              fileType={".pdf"}
            >
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "var(--black)",
                }}
              >
                <p className={textStyles.titleText}>
                  {strings.UploadBlueprint}
                </p>

                <p
                  className={textStyles.bodyText}
                  style={{ textAlign: "center" }}
                >
                  {strings.PdfSubtitle}
                </p>
                <BlueprintIcon />
              </div>
            </UploadDropzone>
          ) : null}

          <div>
            <input
              type="file"
              accept="application/pdf"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};
export default TechnicalUpload;
