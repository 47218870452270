export const REGEX_FIRMNAME = new RegExp("\\w{1,45}", "g");
export const REGEX_CVRNUMBER = new RegExp("^[0-9]{8}$");
export const REGEX_NAME = new RegExp("\\w{1,45}", "g");
export const REGEX_PHONENUMBER = new RegExp("^[0-9]{8}$");
export const REGEX_EMAIL = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const REGEX_ADDRESS = new RegExp(/\b.{1,50}\b/);
export const REGEX_ADDRESSNUMBER = new RegExp(/\b\d{1,10}\b/);
export const REGEX_ZIPCODE = new RegExp("^[0-9]{4}$");
export const REGEX_CITY = new RegExp(/\b.{1,50}\b/);
export const REGEX_EAN = new RegExp(/\b\d{13}\b/);
//export const REGEX_COMMENT = new RegExp();
