import { Line, Mesh } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { DFMInfoType } from "types/ModelTypes";
import {
  CNCProductDetailsType,
  EstimateProductType,
  UpdateCNCProductType,
  UpdatePrint3DProductType,
} from "types/products/ProductCommandsType";
import { CNCEstimateType } from "types/products/ProductTypes";
import { ModelDataType, ReceivedModelType } from "types/RenderTypes";
import { checkGltfChild, modelLoader } from "util/ModelLoader";
import { API_URL } from "./Common";

const BASE_URL = `${API_URL}/v1/ecommerce/demos`;

export const apiEstimateProductDemo = (
  productOptions: UpdateCNCProductType | UpdatePrint3DProductType
): Promise<EstimateProductType> => {
  const command = {
    ...productOptions,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(command),
  };

  return fetch(`${BASE_URL}/estimate`, requestOptions).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const apiGetDemoPreviewImage = () => {
  return fetch(` ${BASE_URL}/preview`)
    .then((res) => {
      return res.blob();
    })
    .then((img) => {
      const imageObjURL = URL.createObjectURL(img);
      return imageObjURL;
    });
};

export const apiGetDemoGLTF = (): Promise<ReceivedModelType> => {
  const requestOptionss: RequestInit = {
    method: "GET",
  };
  console.log(requestOptionss);
  return fetch(`${BASE_URL}/file/generated`, requestOptionss)
    .then((data) => {
      return modelLoader("", data.url);
    })
    .then((gltf: GLTF) => {
      let res: ReceivedModelType = {
        models: [],
        edges: [],
      };
      const test = checkGltfChild(gltf.scene);
      test.forEach((item) => {
        if (item instanceof Line) {
          res.edges.push(item);
        }
        if (item instanceof Mesh) {
          res.models.push(item);
        }
      });
      return res;
    });
};

export const apiGetDemoModelInfo = (): Promise<ModelDataType> => {
  return fetch(`${BASE_URL}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      const shaftsData = data.features.shafts.map((shaft: any) => ({
        ...shaft,
        depth: shaft.length,
      }));
      return {
        ...data,
        features: { ...data.features, shafts: shaftsData },
      };
    })
    .catch((e) => console.log("error", e));
};

export const apiGetDemoErrorGLTFModel = () => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${API_URL}/v1/ecommerce/demos/file/dfm`, requestOptions)
    .then((res) => {
      return res.url;
    })
    .then((data) => modelLoader("", data));
};

export const apiGetDemoDfmInfo = (): Promise<DFMInfoType> => {
  return fetch(`${BASE_URL}/dfm`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((e) => console.log("error", e));
};
