import React, { FunctionComponent } from "react";
import styles from "./cards.module.css";

type PropsType = {
  className?: string;
  children: React.ReactNode;
};

const Card: FunctionComponent<PropsType> = ({ children, className }) => {
  return <div className={`${styles.card} ${className}`}>{children}</div>;
};

export default Card;
