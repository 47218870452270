import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from "@mui/material";

const MaintenancePage = () => {
  return (
    <Card sx={{ padding: "4.5em" }}>
      <CardContent>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h2" color="#00b28d">
            Down for maintenance
          </Typography>
          <Typography variant="h5" color="#607d8b">
            We are very sorry for the inconvenience and thank you for your
            patience
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default MaintenancePage;
