import { FunctionComponent } from "react";
import { useCallback, useContext, useEffect, useState } from "react";

// core components
import AppStateContext from "contexts/AppStateContext";
import { ThreadType } from "types/ConfigTypes";
import {
  ModelDataType,
  ReceivedModelType,
  SelectedThreadsInfoType,
} from "types/RenderTypes";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import ThreadSelector from "components/ThreadSelector";
import {
  apiGetDfmInfo,
  apiGetErrorGLTFModel,
  apiGetGLTF,
  apiGetModelInfo,
} from "util/network/Products";
import { DFMInfoType } from "types/ModelTypes";
import {
  apiGetDemoDfmInfo,
  apiGetDemoErrorGLTFModel,
  apiGetDemoGLTF,
  apiGetDemoModelInfo,
} from "util/network/Demo";
import { CNCProductDetailsType } from "types/products/ProductCommandsType";
import MaterialSelectionLocal from "vaerksComponents/tabs/materialTab/MaterialSelectionLocal";
import FinishSelectionLocal from "../../vaerksComponents/tabs/finishTab/FinishSelectionLocal";
import styles from "./configurator.module.css";
import { ReactComponent as Lock } from "../../assets/svg/dashboard/lock.svg";

// core components
import NavPills from "components/NavPills/NavPills";
import ThreeDModelTab from "../../vaerksComponents/tabs/ThreeDModelTab/ThreeDModelTab";
import TechnicalUpload from "../../vaerksComponents/tabs/bluePrintTab/TechnicalUpload";

type PropsType = {
  productId: number | null;
  onChange: (
    configuration: CNCProductDetailsType & {
      name: string;
      weight: number;
    }
  ) => void;
  demo?: boolean;
  configuration?: CNCProductDetailsType & {
    name: string;
    weight: number;
  };
  threadList: ThreadType[];
  reconfiguration?: boolean;
  onUpload: (id: number) => void;
  confReset: Function;
};

const CNC: FunctionComponent<PropsType> = ({
  productId,
  onChange,
  demo,
  configuration,
  threadList,
  reconfiguration,
  onUpload,
  confReset,
}) => {
  const { strings, token } = useContext(AppStateContext);

  const [material, setMaterial] = useState<string>(
    configuration?.material ?? "alu-5083"
  );

  const [finish, setFinish] = useState<string[]>(configuration?.finish ?? []);
  const [threads, setThreads] = useState<SelectedThreadsInfoType[]>(
    configuration?.threads ?? []
  );
  const [blueprint, setBlueprint] = useState<string | null>(
    configuration?.blueprint ?? null
  );

  useEffect(() => {
    if (configuration) {
      setMaterial(configuration.material);
      setFinish(configuration.finish);
      setThreads(configuration.threads);
      setBlueprint(configuration.blueprint);
    }
  }, [configuration]);

  const [model, setModel] = useState<ReceivedModelType>();
  const [info, setInfo] = useState<ModelDataType>();
  const [dfmModel, setDfmModel] = useState<GLTF>();
  const [dfmInfo, setDfmInfo] = useState<DFMInfoType>();

  const resetState = useCallback(() => {
    setModel(undefined);
    setInfo(undefined);
    setDfmInfo(undefined);
    setDfmModel(undefined);
    setThreads([]);
  }, []);

  const onUploadFunc = useCallback(
    (id: number) => {
      resetState();
      onUpload(id);
    },
    [onUpload, resetState]
  );

  useEffect(() => {
    if (configuration?.modelId) {
      if (demo) {
        apiGetDemoGLTF().then((res) => {
          setModel(res);
        });
        apiGetDemoModelInfo().then((dat) => {
          setInfo(dat);
          apiGetDemoDfmInfo().then((res) => {
            setDfmInfo(res);
          });
        });
        apiGetDemoErrorGLTFModel().then((res) => {
          setDfmModel(res);
        });
      } else {
        apiGetGLTF(token, configuration?.modelId).then((res) => {
          setModel(res);
        });
        apiGetModelInfo(token, configuration?.modelId).then((dat) => {
          setInfo(dat);
          apiGetDfmInfo(token, configuration?.modelId).then((res) => {
            setDfmInfo(res);
          });
        });
        apiGetErrorGLTFModel(token, configuration?.modelId).then((res) => {
          setDfmModel(res);
        });
      }
    }
  }, [demo, configuration?.modelId, resetState, token]);

  const changeThreads = useCallback(
    (val: SelectedThreadsInfoType[]) => {
      setThreads(val);
      if (configuration) {
        onChange({
          ...configuration,
          finish: finish,
          material: material,
          blueprint: blueprint,
          threads: val,
        });
      }
    },
    [configuration, onChange, finish, material, blueprint]
  );

  const changeFinish = useCallback(
    (fin: string[]) => {
      setFinish(fin);
      if (configuration) {
        onChange({
          ...configuration,
          finish: fin,
          material: material,
          blueprint: blueprint,
          threads: threads,
        });
      }
    },
    [configuration, onChange, material, blueprint, threads]
  );

  const changeMaterial = useCallback(
    (mat: string) => {
      setMaterial(mat);
      if (configuration) {
        onChange({
          ...configuration,
          finish: ["standard"],
          material: mat,
          blueprint: blueprint,
          threads: threads,
        });
      }
    },
    [blueprint, configuration, onChange, threads]
  );

  const updatePDF = useCallback(
    (filename: string | null) => {
      if (configuration) {
        setBlueprint(filename);
        onChange({
          ...configuration,
          finish: finish,
          material: material,
          blueprint: filename,
          threads: threads,
        });
      }
    },
    [configuration, onChange, finish, material, threads]
  );

  return (
    // <Grid item xs={12} sm={12} md={12} lg={8}>
    <div className={`${styles.container__configurator}`}>
      <h1 className={`${styles.title}`}>{strings.Configurator}</h1>
      <div
        style={{
          margin: "1em",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <NavPills
          alignCenter
          tabs={[
            {
              tabButton: strings.ThreeDModelButton?.toUpperCase(),
              tabContent: (
                <ThreeDModelTab
                  model={model}
                  dfmModel={dfmModel}
                  info={info}
                  dfmInfo={dfmInfo}
                  demo={demo}
                  onUpload={onUploadFunc}
                  disableUpload={reconfiguration || !dfmInfo}
                  confReset={() => {
                    confReset();
                    resetState();
                  }}
                />
              ),
            },
            {
              tabButton: strings.Thread,
              tabContent: (
                <ThreadSelector
                  model={model}
                  info={info}
                  onChange={changeThreads}
                  threadList={threadList ?? []}
                  existing={configuration?.threads}
                />
              ),
              disabled:
                !configuration ||
                !info ||
                (info?.features?.holes.length === 0 &&
                  info?.features?.shafts.length === 0 &&
                  info?.features?.threads.length === 0), //|| modelHoles.length === 0,
            },
            {
              tabButton: strings.CompConfigBlueprintButton?.toUpperCase(),
              tabContent: productId != null && !demo && (
                <TechnicalUpload
                  productId={productId}
                  blueprint={blueprint}
                  onChange={updatePDF}
                />
              ),
              disabled: !configuration || configuration?.modelId === 1,
            },
            {
              tabButton: strings.CompConfigMaterialButton?.toUpperCase(),
              tabContent: (
                <MaterialSelectionLocal
                  material={material}
                  setMaterial={changeMaterial}
                />
              ),
              disabled: !configuration,
            },
            {
              tabButton: strings.Finish.toUpperCase(),
              tabContent: (
                <div>
                  <FinishSelectionLocal
                    material={material}
                    finish={finish}
                    setFinish={changeFinish}
                  />
                </div>
              ),
              disabled: !configuration,
            },
          ]}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          color: "#E02C4F",
          padding: "1em 0 2em 1rem",
          fontWeight: "400",
        }}
      >
        <span>
          <Lock />
        </span>
        <span style={{ paddingLeft: "0.5rem", paddingTop: "0.1rem" }}>
          {strings.CompConfigConfidentiality}
        </span>
      </div>
    </div>
  );
};
export default CNC;
