import React, { FunctionComponent, useContext, useState } from "react";
import styles from "./forgotPassModal.module.css";
import stylesButton from "../Buttons/customButton.module.css";
import AppStateContext from "contexts/AppStateContext";
import CustomButton from "components/Buttons/CustomButton";
import { apiLogin, apiRequestPasswordReset } from "util/network/Auth";
import CustomInput from "components/Input/CustomInput";
import { REGEX_EMAIL } from "util/RegExUtil";

type PropsType = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const ForgotPassModal: FunctionComponent<PropsType> = ({ open, setOpen }) => {
  const { strings, showLoginModal, setShowLoginModal, setToken } =
    useContext(AppStateContext);
  const [resetPassword, setResetPassword] = useState("");
  const [error, setError] = useState<boolean>(false);

  function submitReset(e: any) {
    e.preventDefault();
    apiRequestPasswordReset(resetPassword).catch((e) => {
      console.log(e);
    });
    setOpen(false);
  }
  return open ? (
    <div id="dialog" className={`${styles.dialog} `}>
      <div className={`${styles.dialog__content}`}>
        <h2 className={`${styles.dialog__title}`}>
          {strings.ForgottenPassword}
        </h2>
        <p className={`${styles.dialog__message}`}>
          {strings.ForgottenPasswordMsg}
        </p>
        <form
          className={`${styles.dialog__title}`}
          id={"resetPasswordEmailForm"}
          onSubmit={submitReset}
        >
          <CustomInput
            label={strings.Email}
            type="email"
            placeholder={strings.Email}
            error={!REGEX_EMAIL.test(resetPassword)}
            value={resetPassword}
            id="resetEmail"
            required
            onChange={(e: any) => setResetPassword(e.target.value)}
          />
        </form>
        <div className={`${styles.dialog__buttons}`}>
          <div className={`${styles.forgot_password__cancel}`}>
            <CustomButton
              onClick={() => setOpen(false)}
              buttonText={strings.CancelButton}
              buttonClass={`${stylesButton.button__demo}`}
              iconClass={`${stylesButton.demo__icon}`}
              id="closeReset"
            />
          </div>
          <div className={`${styles.forgot_password__send}`}>
            <CustomButton
              type="submit"
              form={"resetPasswordEmailForm"}
              buttonText={strings.SendLinkButton}
              buttonClass={`${stylesButton.button__register} `}
              iconClass={`${stylesButton.register__icon}`}
              id="sendReset"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default ForgotPassModal;
