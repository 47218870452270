import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { formatter } from "util/TextUtil";
import AppStateContext from "contexts/AppStateContext";
import { CartItemType } from "types/CartTypes";
import {
  apiAddBlueprint,
  apiGetDfmInfo,
  apiGetPreviewImage,
  apiRemoveBlueprint,
  apiUpdateCNCProduct,
} from "util/network/Products";
import EcommerceContext from "contexts/EcommerceContext";
import {
  CNCProductDetailsType,
  ProductTypeEnum,
  UpdateCNCProductType,
} from "types/products/ProductCommandsType";
import { DFMInfoType } from "types/ModelTypes";
import AmountInput from "vaerksComponents/AmountInput";
import styles from "../checkoutComponents/styles/cncCartTableRow.module.css";
import tooltipStyles from "../../components/Buttons/customIconButton.module.css";
import buttonStyles from "../../components/Buttons/customButton.module.css";
import CustomIconButton from "components/Buttons/CustomIconButton";
import { ReactComponent as DeleteIcon } from "../../assets/svg/dashboard/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/dashboard/edit.svg";
import { ReactComponent as UploadPdfIcon } from "../../assets/svg/dashboard/uploadpdf.svg";
import { ReactComponent as UploadedPdfIcon } from "../../assets/svg/dashboard/uploadedpdf.svg";
import { ReactComponent as CommentIcon } from "../../assets/svg/dashboard/comment.svg";
import { ReactComponent as CommentedIcon } from "../../assets/svg/dashboard/commented.svg";
import { ReactComponent as ThreadsIcon } from "../../assets/svg/dashboard/threads.svg";
import { ReactComponent as ThreadsIconUploaded } from "../../assets/svg/dashboard/threadsUploaded.svg";
import DFMErrors from "components/DFMErrorsIcon/DFMErrors";
import ThreadDisplay from "components/ThreadDisplay/ThreadDisplay";
import CustomButton from "components/Buttons/CustomButton";
import { CircularProgress } from "@mui/material";
import FinishSelectorLocal from "./FinishSelectorLocal";
import MatSelectorLocal from "./MatSelectorLocal";

type PropsType = {
  item: CartItemType;
  details: CNCProductDetailsType;
  loading: boolean;
  onChange: (item: CartItemType) => void;
  reconfigure: (cartItem: CartItemType) => void;
};

const CNCCartTableRow: FunctionComponent<PropsType> = ({
  item,
  details,
  loading,
  onChange,
  reconfigure,
}) => {
  const { strings, token } = useContext(AppStateContext);
  const { removeCartItem } = useContext(EcommerceContext);
  const [code, setCode] = useState<"dk" | "gb">(strings.code);
  const [updated, setUpdated] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [disableFinish, setDisableFinish] = useState<boolean>(false);
  const [pdfLoad, setPdfLoad] = useState<boolean>(false);
  const [mouseOverPdf, setMouseOverPdf] = useState<boolean>(false);
  const [mouseOverComment, setMouseOverComment] = useState<boolean>(false);
  const [mouseOverThread, setMouseOverThread] = useState<boolean>(false);
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [dataDFM, setDataDFM] = useState<DFMInfoType>();
  const [previewImage, setPreviewImage] = useState<string>();

  const [finish, setFinish] = useState<string[]>(details.finish ?? []);
  const [material, setMaterial] = useState<string>(details.material);
  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [comment, setComment] = useState<string | null>(details.comment);
  const [blueprint, setBlueprint] = useState<string | null>(details.blueprint);

  useEffect(() => {}, [quantity, finish, material, comment, blueprint]);

  const hiddenFileInput = useRef<any>(null);
  const handlePdfClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = async (event: any) => {
    // console.log(event);
    const file = event.target.files[0];
    handleSubmit(file);
  };

  const handleUpdate = useCallback((updateCommand: UpdateCNCProductType) => {
    if (item.product.productType === ProductTypeEnum.CNC) {
      apiUpdateCNCProduct(token, item.product.id, updateCommand).then((res) => {
        onChange({
          ...item,
          quantity: updateCommand.quantity,
        });
      });
    }
  }, []);

  const handleSubmit = async (file: any) => {
    if (!token) return;
    setUpdated(true);
    setDisable(true);
    const data = new FormData();
    data.append("file", file);
    data.append("filename", file.name);

    if (file.type === "application/pdf") {
      if (details.blueprint) {
        return;
      } else {
        apiAddBlueprint(token, item.product.id, file)
          .then((res) => {
            console.log(res);
            if (res) {
              setPdfLoad(false);
              setBlueprint(res);
              onChange(item);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    setUpdated(false);
    setDisable(false);
  }, [item]);

  const updateQuantity = useCallback(
    (amount) => {
      const updateCommand: UpdateCNCProductType = {
        quantity: amount,
        finish: finish.join(","),
        material: material,
        comment: comment,
        blueprint: blueprint,
        threads: details.threads,
      };
      console.log(updateCommand);
      setUpdated(true);
      setDisable(true);
      setQuantity(amount);
      handleUpdate(updateCommand);
    },
    [blueprint, comment, details.threads, finish, handleUpdate, material]
  );

  const updateMaterial = useCallback(
    (material) => {
      const updateCommand: UpdateCNCProductType = {
        quantity: quantity,
        finish: "standard",
        material: material,
        comment: comment,
        blueprint: blueprint,
        threads: details.threads,
      };
      setUpdated(true);
      setDisable(true);
      setFinish(["standard"]);
      setMaterial(material);
      handleUpdate(updateCommand);
    },
    [blueprint, comment, details.threads, finish, handleUpdate, quantity]
  );

  const updateFinish = useCallback(
    (finish: string[]) => {
      const updateCommand: UpdateCNCProductType = {
        quantity: quantity,
        finish: finish.join(","),
        material: material,
        comment: comment,
        blueprint: blueprint,
        threads: details.threads,
      };
      setUpdated(true);
      setDisable(true);
      setFinish(finish);
      handleUpdate(updateCommand);
    },
    [blueprint, comment, details.threads, handleUpdate, material, quantity]
  );

  const updateComment = useCallback(
    (comment) => {
      const updateCommand: UpdateCNCProductType = {
        quantity: quantity,
        finish: finish.join(","),
        material: material,
        comment: comment,
        blueprint: blueprint,
        threads: details.threads,
      };
      setUpdated(true);
      setDisable(true);
      setComment(comment);
      handleUpdate(updateCommand);
    },
    [blueprint, details.threads, finish, handleUpdate, material, quantity]
  );

  // const handleChanged = (event) => {
  //   const { value } = event.target;
  //   if (value.length <= MAX_COMMENT_LENGTH) {
  //     setComment(value);
  //   }
  // };

  useEffect(() => {
    if (!material.startsWith("a") && !material.startsWith("s")) {
      setDisableFinish(true);
    } else if (material.startsWith("a") || material.startsWith("s")) {
      setDisableFinish(false);
    }
  }, [material]);

  //DFM checker
  useEffect(() => {
    if (!token) return;

    apiGetPreviewImage(token, details.modelId).then((res) => {
      if (res) {
        setPreviewImage(res);
      }
    });
    apiGetDfmInfo(token, details.modelId)
      .then((tempDFM) => {
        setDataDFM(tempDFM);
      })
      .catch((error) => {
        window.alert(strings.ErrorOccurred + error);
      });
  }, [item, strings, token]);

  // Create configuration string
  // const configText = useMemo(() => {
  //   let matName = "...";
  //   const materialType = Materials.getById(material);
  //   if (materialType && materialType[code]) {
  //     matName = materialType[code].materialTitle;
  //   }

  //   let finName = "...";
  //   const finishType = Finishes.getById(finish);
  //   if (finishType && finishType[code]) {
  //     finName = finishType[code].finishTitle;
  //   }

  //   return `${matName} / ${finName}`;
  // }, [code, finish, material]);

  useEffect(() => {
    setCode(strings.code);
  }, [strings.code]);

  return (
    <>
      <tr key={item.id}>
        {/* Preview Image Cell */}
        <td align="center">
          <div style={{ position: "relative", display: "inline-block" }}>
            {previewImage ? (
              <img style={{ width: "5vw" }} src={previewImage} alt={"Mini"} />
            ) : (
              <div />
            )}
            <div
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                zIndex: "99",
              }}
            >
              <DFMErrors dfmErrors={dataDFM} />
            </div>
          </div>
        </td>
        {/* Item + Configuration Cell */}
        <td align="left">
          <div className={`${styles.file_name}`}>{item.name} (CNC)</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <span
              onMouseEnter={() => setMouseOverComment(true)}
              onMouseLeave={() => setMouseOverComment(false)}
              title={comment ?? ""}
              style={{ padding: "1rem 0 0 0" }}
            >
              {mouseOverComment ? (
                <CustomIconButton
                  onClick={() => {
                    setOpenComment(true);
                  }}
                  tooltipText={strings.CLickAddComment}
                  icon={CommentIcon}
                />
              ) : comment ? (
                <CustomIconButton
                  onClick={() => {
                    setOpenComment(true);
                  }}
                  icon={CommentedIcon}
                />
              ) : (
                <CustomIconButton
                  onClick={() => {
                    setOpenComment(true);
                  }}
                  icon={CommentIcon}
                />
              )}
            </span>
            <dialog
              open={openComment}
              style={{
                boxShadow: "1px 1px 4px rgba(0,0,0,0.3)",
                border: "none",
                borderRadius: "6px",
                padding: "1rem",
                position: "absolute",
                left: "2.5%",
                top: "30%",
              }}
            >
              <div>
                <div
                  style={{
                    // backgroundColor: "var(--new-primary1)",
                    // color: "yellow",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    // padding: "3px",
                  }}
                >
                  <h4
                    style={{
                      color: "black",
                      fontWeight: "500",
                      marginBottom: "-2px",
                    }}
                  >
                    {strings.Comment}
                  </h4>
                </div>
              </div>
              <div>
                <textarea
                  // label={(comment ? comment?.length.toString() : "0") + "/400"}
                  // multiline
                  // fullWidth
                  rows={5}
                  // inputProps={{ maxLength: 400 }}
                  style={{ width: "400", height: "20%" }}
                  // value={comment}
                  onBlur={() => updateComment(comment)}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
                <CustomButton
                  type="submit"
                  buttonText={strings.AddComment}
                  buttonClass={`${buttonStyles.button__register} `}
                  iconClass={`${buttonStyles.register__icon}`}
                  onClick={() => setOpenComment(false)}
                  id="submitComment"
                />
              </div>
            </dialog>{" "}
            {details.threads.length > 0 ? (
              <span
                onMouseEnter={() => setMouseOverThread(true)}
                onMouseLeave={() => setMouseOverThread(false)}
                // style={{ padding: "1rem 0.5rem 0 0" }}
              >
                {mouseOverThread ? (
                  <CustomIconButton
                    icon={ThreadsIcon}
                    tooltipThread={<ThreadDisplay specs={details.threads} />}
                    tooltipClass={`${tooltipStyles.tooltipClass}`}
                    // tooltipThread={<ThreadDisplay specs={details.threads} />}
                  />
                ) : (
                  <CustomIconButton
                    icon={ThreadsIconUploaded}
                    // tooltipThread={<ThreadDisplay specs={details.threads} />}
                    tooltipThread={<ThreadDisplay specs={details.threads} />}
                    tooltipClass={`${tooltipStyles.tooltipClass}`}
                  />
                )}
              </span>
            ) : (
              <CustomIconButton
                tooltipText={strings.ThreadCardNoThreads}
                icon={ThreadsIcon}
              />
            )}
            {pdfLoad ? (
              <CircularProgress />
            ) : blueprint ? (
              <span
                onMouseEnter={() => setMouseOverPdf(true)}
                onMouseLeave={() => setMouseOverPdf(false)}
                title={blueprint}
                // style={{ padding: "1rem 0 0 1rem" }}
              >
                {mouseOverPdf ? (
                  <CustomIconButton
                    onClick={() => {
                      apiRemoveBlueprint(token, item.product.id)
                        .then((res) => {
                          console.log(res);
                          if (res) {
                            setBlueprint(null);
                            onChange(item);
                          }
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }}
                    icon={DeleteIcon}
                    tooltipText={strings.Delete}
                  />
                ) : (
                  <CustomIconButton icon={UploadedPdfIcon} />
                )}
              </span>
            ) : (
              <CustomIconButton
                onClick={() => {
                  handlePdfClick();
                }}
                tooltipText={strings.ClickUploadPdf}
                icon={UploadPdfIcon}
              />
            )}
            <input
              type="file"
              accept="application/pdf"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
          </div>
        </td>
        {/* MatSelecter Cell */}
        <td align="center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <MatSelectorLocal
              currentSel={material}
              onChange={updateMaterial}
              disabled={loading}
            />
            <FinishSelectorLocal
              material={material}
              currentSel={finish}
              onChange={updateFinish}
              disabled={loading || disableFinish}
            />
          </div>
        </td>
        {/* FinishSelector Cell */}
        {/* Quantity Cell */}
        <td align="center">
          <AmountInput
            initVal={item.quantity}
            onChange={updateQuantity}
            disabled={loading || disable}
          />
        </td>
        {/* Price Cell */}
        {updated ? (
          <td align="right">
            <div>{strings.fetchingPrice}</div>
          </td>
        ) : (
          <td align="right">
            <div className={`${styles.item__total}`}>
              {formatter.format(item.total)}
            </div>
            <div className={`${styles.item__perUnit}`}>
              {formatter.format(item.pricePerUnit)}
              {"/pcs"}
            </div>
          </td>
        )}
        <td align="center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingLeft: "15px",
            }}
          >
            <div style={{ paddingBottom: "30px" }}>
              <CustomIconButton
                tooltipText={strings.Delete}
                disabled={loading || disable}
                onClick={() => removeCartItem(item)}
                icon={DeleteIcon}
              />
            </div>
            <div>
              <CustomIconButton
                disabled={loading || disable}
                onClick={() => reconfigure(item)}
                icon={EditIcon}
                tooltipText={strings.Edit}
              />
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td align="left" className={`${styles.line__container_item}`}>
          <span className={`${styles.item__line_solid}`}></span>
        </td>
      </tr>
    </>
  );
};
export default CNCCartTableRow;
