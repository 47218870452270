import { createStyles } from "@material-ui/core";
import {
  blackColor,
  boxShadow,
  container,
  dangerColor,
  defaultBoxShadow,
  defaultFont,
  drawerWidth,
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
  successColor,
  transition,
  warningColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";

const pagesHeaderStyle = (theme) => ({
  appBar: {
    backgroundColor: grayColor[13],
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: infoColor[0],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  container: {
    ...container,
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    backgroundColor: "inherit",
    fontSize: "2.8rem",
    textTransform: "none",
    color: "inherit",
    letterSpacing: "unset",
    transition: "transform .2s",
    "&:hover,&:focus": {
      background: "inherit",
      color: "inherit",
      transform: "scale(1.1)",
    },
  },
  centerTitle: {
    ...defaultFont,
    lineHeight: "30px",
    backgroundColor: "inherit",
    fontSize: "3rem",
    textTransform: "none",
    color: "inherit",
    letterSpacing: "unset",
    transition: "transform .2s",
    width: "100%",
    cursor: "pointer",
    "&:hover,&:focus": {
      background: "inherit",
      color: "inherit",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  white: {
    backgroundColor: whiteColor,
    color: "inherit",
    ...defaultBoxShadow,
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    float: "right",
    marginRight: "0px",
    paddingLeft: "0",
    listStyle: "none",
    color: infoColor[0],
    paddingTop: "0",
    paddingBottom: "0",
  },
  listItem: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "-5px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      zIndex: "999",
      width: "100%",
      paddingRight: "15px",
    },
  },
  navLink: {
    color: infoColor[0],
    margin: "0 5px",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    textDecoration: "none",
    "&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
    },
  },
  listItemIcon: {
    marginTop: "-3px",
    top: "0px",
    position: "relative",
    marginRight: "3px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle",
    color: primaryColor[0],
    display: "inline-block",
  },
  listItemText: {
    flex: "none",
    padding: "0",
    minWidth: "0",
    margin: 0,
    display: "inline-block",
    position: "relative",
    whiteSpace: "nowrap",
  },
  navLinkActive: {
    color: whiteColor,
    backgroundColor: "rgba(" + hexToRgb(infoColor[0]) + ", 0.45)",
    "&:focus": {
      color: whiteColor,
      backgroundColor: "rgba(" + hexToRgb(infoColor[0]) + ", 0.45)",
    },
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0",
    },
    "&:after": {
      background: blackColor,
      opacity: ".8",
    },
  },
  sidebarButton: {
    "&,&:hover,&:focus": {
      color: whiteColor,
    },
    top: "-2px",
  },
  logoNEM: {
    fontFamily: "Racing Sans One",
    color: primaryColor[0],
  },
  logoCNC: {
    fontFamily: "Racing Sans One",
  },
  logoNEMtest: {
    fontFamily: "Orbitron",
    color: primaryColor[0],
  },
  logoCNCtest: {
    fontFamily: "Orbitron",
  },
  highlightButton: {
    backgroundColor: primaryColor[0],
    transition: "transform .2s",
    "&,&:hover,&:focus": {
      color: whiteColor,
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  listItemIconWhite: {
    marginTop: "-3px",
    top: "0px",
    position: "relative",
    marginRight: "3px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle",
    color: whiteColor,
    display: "inline-block",
  },
});

export default pagesHeaderStyle;
