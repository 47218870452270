import React, { useContext, useEffect, useRef } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import routes from "routes.js";
import CompConfigContext from "contexts/CompConfigContext";
import LogOutDialog from "../vaerksComponents/loginLogout/LogOutDialog";
import AppStateContext from "contexts/AppStateContext";
import SpecialOffer from "vaerksComponents/loginLogout/SpecialOffer";
import EcommerceContext from "contexts/EcommerceContext";
import { useHistory, useLocation } from "react-router";
import OrderHistoryDetails from "vaerksComponents/orderHistoryRowComponents/OrderHistoryDetails";
import OrderHistoryPage from "views/dashboardPages/OrderHistory/OrderHistoryPage";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import OrderConfirmationPage from "../views/dashboardPages/OrderConfirmationPage";

const DashboardLayout = (props: any) => {
  const { cartItems } = useContext(EcommerceContext);
  const location = useLocation();
  const history = useHistory();
  const { strings, token } = useContext(AppStateContext);
  const { showLogoutModal, setShowLogoutModal, specialOffer } =
    useContext(CompConfigContext);

  let match = useRouteMatch();

  const getActiveRoute = (routes: any) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: number) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          // @ts-ignore
          <Route
            path={match.url + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    // If we are at /ny-komponent, redirect to /ny-komponent/cnc
    if (location.pathname === "/ny-komponent") {
      history.replace("/ny-komponent/cnc");
    }
    if (location.pathname === "/ordrehistorik") {
      history.replace("/orders");
    }
    const urlParams = new URLSearchParams(window.location.search);
    const demo = urlParams.get("demo");
    if (Number(location.state?.model_id) !== 1 && !demo && !token) {
      console.log("redirecting to auth");
      history.replace("/auth");
    }
  });

  return (
    <div>
      <DashboardNavbar
      // brandText={getActiveRoute(routes(strings))}
      ></DashboardNavbar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/*@ts-ignore*/}
        <Switch>
          <Route path="/orders/:orderId">
            <OrderHistoryPage />
          </Route>
          {getRoutes(routes(strings))}
          <Route
            path={"/orderConfirm/:orderId"}
            component={OrderConfirmationPage}
            key={"orderconfirmationpage"}
          />
        </Switch>
      </div>
      <LogOutDialog />
    </div>
  );
};
export default DashboardLayout;
