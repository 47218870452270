import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import GridItem from "../../../components/Grid/GridItem";
import MaterialCard from "./MaterialCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/dashboardPagesStyles/quotePageStyle";
import * as PropTypes from "prop-types";
import AppStateContext from "contexts/AppStateContext";
import * as ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { fetchPublic, PUBLIC_BASE_URL } from "util/network/Common";
import { Grid } from "@mui/material";
import { MaterialType } from "types/NetworkTypes";

const useStyles: any = makeStyles(styles);

type PropsType = {
  material: string;
  setMaterial: Function;
  materialFile?: string;
};

const MaterialSelectionLocal: FunctionComponent<PropsType> = ({
  material,
  setMaterial,
  materialFile,
}) => {
  const { strings, customerEmail } = useContext(AppStateContext);
  const [data, setData] = useState([]);
  const { trackEvent } = useMatomo();

  useEffect(() => {
    fetchPublic(materialFile ? `/${materialFile}` : "/cncMaterials.json").then(
      (res) => {
        setData(res);
      }
    );
  }, [materialFile]);

  const onClick = useCallback(
    (id: string) => {
      if (id !== "missing") {
        setMaterial(id);

        // Google Analytics Event
        ReactGA.event({
          category: "Component Configuration",
          action: "Material Change",
          label: id,
        });
        trackEvent({
          category: "Component Configuration",
          action: "Material Change",
          name: id,
        });
      }
    },
    [setMaterial, trackEvent]
  );

  const classes = useStyles();
  return (
    <div>
      {data?.map((materialCategory: MaterialType, key) => (
        <Grid key={key} container columns={3} columnSpacing={3}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <h3 style={{ marginBottom: "-20px" }}>
              {materialCategory.title[strings.code].toUpperCase()}
            </h3>
            <hr className={classes.selectionDivider} />
          </Grid>
          {materialCategory.variants.map((materialOptions, key) => (
            <Grid item key={key} xs={1} sm={1} md={1} lg={1}>
              <div
                onClick={() => {
                  onClick(materialOptions.id);
                }}
              >
                <MaterialCard
                  materialTitle={materialOptions[strings.code].materialTitle}
                  materialDescription={
                    materialOptions[strings.code].materialDescription
                  }
                  materialImage={`${materialOptions?.materialImageURL}`}
                  isSelected={material === materialOptions.id}
                  shipping={
                    materialOptions.id === "missing"
                      ? undefined
                      : materialOptions.shipping
                  }
                />
              </div>
            </Grid>
          ))}
        </Grid>
      ))}
    </div>
  );
};

export default MaterialSelectionLocal;
