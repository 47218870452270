import { FunctionComponent } from "react";
import { useCallback, useContext, useEffect, useState } from "react";

// core components
import ThreeDModelTab from "../../vaerksComponents/tabs/ThreeDModelTab/ThreeDModelTab";
import FinishSelection from "../../vaerksComponents/tabs/finishTab/FinishSelection";
import AppStateContext from "contexts/AppStateContext";
import { ThreadType } from "types/ConfigTypes";
import { ReactComponent as Lock } from "../../assets/svg/dashboard/lock.svg";
import {
  ModelDataType,
  ReceivedModelType,
  SelectedThreadsInfoType,
} from "types/RenderTypes";
import { apiGetGLTF, apiGetModelInfo } from "util/network/Products";
import { apiGetDemoGLTF, apiGetDemoModelInfo } from "util/network/Demo";
import { Print3DProductDetailsType } from "types/products/ProductCommandsType";
import {
  FinishOptionCategoryType,
  MaterialOptionCategoryType,
  ProductOptionsType,
} from "types/products/ProductTypes";
import styles from "./configurator.module.css";
import MaterialSelectionLocal from "vaerksComponents/tabs/materialTab/MaterialSelectionLocal";
import NavPills from "components/NavPills/NavPills";

type PropsType = {
  productId: number | null;
  onChange: (
    configuration: Print3DProductDetailsType & {
      name: string;
      weight: number;
    }
  ) => void;
  demo?: boolean;
  configuration?: Print3DProductDetailsType & {
    name: string;
    weight: number;
  };
  options?: ProductOptionsType;
  threadList: ThreadType[];
  reconfiguration?: boolean;
  onUpload: (id: number) => void;
  confReset: Function;
};

const Print3D: FunctionComponent<PropsType> = ({
  productId,
  onChange,
  demo,
  configuration,
  options,
  threadList,
  reconfiguration,
  onUpload,
  confReset,
}) => {
  const { strings, token } = useContext(AppStateContext);

  const [material, setMaterial] = useState<string>(
    configuration?.material ?? "alu-5083"
  );

  const [finish, setFinish] = useState<string[]>(
    configuration?.finish ?? ["standard"]
  );
  const [threads, setThreads] = useState<SelectedThreadsInfoType[]>(
    configuration?.threads ?? []
  );

  const [materialOptions, setMaterialOptions] = useState<
    MaterialOptionCategoryType[]
  >([]);
  const [finishOptions, setFinishOptions] = useState<
    FinishOptionCategoryType[]
  >([]);

  useEffect(() => {
    setMaterialOptions(options?.materials ?? []);
    setFinishOptions(options?.finishes ?? []);
  }, [options]);

  useEffect(() => {
    if (configuration) {
      setMaterial(configuration.material);
      setFinish(configuration.finish);
      setThreads(configuration.threads);
    }
  }, [configuration]);

  const [model, setModel] = useState<ReceivedModelType>();
  const [info, setInfo] = useState<ModelDataType>();

  const resetState = useCallback(() => {
    setModel(undefined);
    setInfo(undefined);
    setThreads([]);
  }, []);

  const onUploadFunc = useCallback(
    (id: number) => {
      resetState();
      onUpload(id);
    },
    [onUpload, resetState]
  );

  useEffect(() => {
    if (configuration?.modelId) {
      if (demo) {
        apiGetDemoGLTF().then((res) => {
          setModel(res);
        });
        apiGetDemoModelInfo().then((dat) => {
          setInfo(dat);
        });
      } else {
        apiGetGLTF(token, configuration?.modelId).then((res) => {
          setModel(res);
        });
        apiGetModelInfo(token, configuration?.modelId).then((dat) => {
          setInfo(dat);
        });
      }
    }
  }, [demo, configuration?.modelId, resetState, token]);

  const changeThreads = useCallback(
    (val: SelectedThreadsInfoType[]) => {
      setThreads(val);
      if (configuration) {
        onChange({
          ...configuration,
          finish: finish,
          material: material,
          threads: val,
        });
      }
    },
    [configuration, onChange, finish, material]
  );

  const changeFinish = useCallback(
    (fin: string[]) => {
      setFinish(fin);
      if (configuration) {
        onChange({
          ...configuration,
          finish: fin,
          material: material,
          threads: threads,
        });
      }
    },
    [configuration, onChange, material, threads]
  );

  const changeMaterial = useCallback(
    (mat: string) => {
      setMaterial(mat);
      if (configuration) {
        onChange({
          ...configuration,
          finish: ["standard"],
          material: mat,
          threads: threads,
        });
      }
    },
    [configuration, onChange, threads]
  );

  return (
    <div className={`${styles.container__configurator}`}>
      <h1 className={`${styles.title}`}>{strings.Configurator}</h1>
      <div
        style={{
          margin: "1em",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <NavPills
          alignCenter
          tabs={[
            {
              tabButton: strings.ThreeDModelButton?.toUpperCase(),
              tabContent: (
                <ThreeDModelTab
                  model={model}
                  info={info}
                  dfmInfo={null}
                  demo={demo}
                  onUpload={onUploadFunc}
                  disableUpload={reconfiguration}
                  confReset={() => {
                    confReset();
                    resetState();
                  }}
                />
              ),
            },
            // {
            //   tabButton: strings.Thread,
            //   tabContent: (
            //     <ThreadSelector
            //       model={model}
            //       info={info}
            //       onChange={changeThreads}
            //       threadList={threadList ?? []}
            //       existing={configuration?.threads}
            //     />
            //   ),
            //   disabled:
            //     configuration?.modelId == 0 ||
            //     !info ||
            //     (info?.features?.holes.length === 0 &&
            //       info?.features?.shafts.length === 0 &&
            //       info?.features?.threads.length === 0), //|| modelHoles.length === 0,
            // },
            {
              tabButton: strings.CompConfigMaterialButton?.toUpperCase(),
              tabContent: (
                <MaterialSelectionLocal
                  material={material}
                  setMaterial={changeMaterial}
                  materialFile={"print3DMaterials.json"}
                />
              ),
              disabled: !configuration,
            },
            {
              tabButton: strings.Finish.toUpperCase(),
              tabContent: (
                <div>
                  <FinishSelection
                    material={material}
                    finish={finish}
                    setFinish={changeFinish}
                    finishFile={"print3DFinish.json"}
                  />
                </div>
              ),
              disabled: !configuration,
            },
          ]}
        />
      </div>
      {/* <NewNavPills tabs={tabs} /> */}
      <div
        style={{
          pointerEvents: "none",
          paddingLeft: "1rem",
          fontWeight: "400",
        }}
      >
        {strings.SupportsStepFilesNew}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "left",
          color: "#E02C4F",
          padding: "1em 0 2em 1rem",
          fontWeight: "400",
        }}
      >
        <span>
          <Lock />
        </span>
        <span style={{ paddingLeft: "0.5rem", paddingTop: "0.2rem" }}>
          {strings.CompConfigConfidentiality}
        </span>
      </div>
    </div>
  );
};

export default Print3D;
