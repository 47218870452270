import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router";
import styles from "assets/jss/material-dashboard-pro-react/vaerksComponents/dropzoneStyle.js";
import CompConfigContext from "contexts/CompConfigContext";
import AppStateContext from "contexts/AppStateContext";
import * as ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import LoaderComponent from "./LoaderComponent";
import UploadDropzone from "components/UploadDropzone";
import { apiUploadModelFile } from "util/network/Models";
import { apiCreateProduct } from "util/network/Products";
import { apiCreateCNCProduct } from "util/network/Products";
import {
  CreateCNCProductType,
  CreatePrint3DProductType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import EcommerceContext from "contexts/EcommerceContext";
import { ReactComponent as ThreeDIcon } from "../../assets/svg/dashboard/uploadIconBlack.svg";
import SweetAlert from "react-bootstrap-sweetalert/dist/components/SweetAlert";

import stylesUpload from "./css/modelUpload.module.css";
import { createStyles, makeStyles } from "@material-ui/core";
const useStyles: any = makeStyles(createStyles(() => styles));

type PropsType = {
  hiddenFileInput?: MutableRefObject<any>;
  reuploadState?: boolean;
  onFileUploaded: (productId: number) => void;
  confReset: Function;
};

const ModelUpload: FunctionComponent<PropsType> = ({
  hiddenFileInput,
  reuploadState,
  onFileUploaded,
  confReset,
}) => {
  const [uploading, setUploading] = useState(false);
  const [tempID, setTempID] = useState<number>();
  const { addCartItem } = useContext(EcommerceContext);
  const { strings, token } = useContext(AppStateContext);
  const { setModelLoading, setDfmLoading, setPreviewImageLoading } =
    useContext(CompConfigContext);

  const [showUploadStatus, setShowUploadStatus] = useState<boolean>(false);
  const [uploadStatusMsg, setUploadStatusMsg] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<boolean | null>(null);
  const { trackEvent } = useMatomo();
  const [uploadText, setUploadText] = useState<string>("Loading..");
  const [fileError, setFileError] = useState<boolean>(false);

  const productType = useMemo(() => {
    // If we are at /CNC, then we are creating a CNC product
    if (window.location.pathname.includes("/cnc")) {
      return ProductTypeEnum.CNC;
    }
    // If we are at /3D-Print, then we are creating a 3D Print product
    if (window.location.pathname.includes("/print3d")) {
      return ProductTypeEnum.PRINT3D;
    }
    // Default to cnc
    return ProductTypeEnum.CNC;
  }, []);

  // const [currentInfo, setCurrentInfo] = useState<ModelDataType>();

  const classes = useStyles();
  const history = useHistory();

  const maxSize = 10000000;
  const fileMax = 9;

  const onUpload = (number: number) => {
    if (number >= 0 && number <= 11) setUploadText("Receiving..");
    else if (number > 12 && number <= 30) setUploadText("Preparing model..");
    else if (number > 30 && number <= 67) setUploadText("Loading geometry..");
    else if (number > 67 && number < 99)
      setUploadText("Initializing 3D viewer..");
    else if (number >= 99) setUploadText("Finished");
  };

  const onClick = (event: any) => {
    const existingId = window.localStorage.getItem("token");
    if (!existingId) {
      history.replace("/auth");
      event.preventDefault();
      return false;
    }
  };

  const handleSubmit = (files: File[]) => {
    console.log("new submit");
    setTempID(undefined);
    if (files.length > fileMax) {
      window.alert(`For mange filer uploaded - max ${fileMax} filer ad gangen`);
      return Promise.reject(false);
    }
    if (files.length > 1) {
      // console.log(files);
      history.push(`/indkoebskurv`, {
        files: files.map((f) => ({
          name: f.name,
          file: f,
          type: productType,
        })),
      });
      return Promise.resolve(true);
    }
    const file = files[0];
    // window.history.replaceState({}, document.title, window.location.pathname);
    if (file) {
      // if (uploading) return Promise.reject(false);
      // Google Analytics Event
      ReactGA.event({
        category: "Component Configuration",
        action: "Model Submit",
        label: file.name,
      });
      trackEvent({
        category: "Component Configuration",
        action: "Model Submit",
      });

      if (file.size > maxSize) {
        window.alert(
          `Filen er for stor - max ${maxSize / 1000000}MB og kun .STEP-filer!`
        );

        // Google Analytics Event
        ReactGA.event({
          category: "Component Configuration",
          action: "Model Submit Error",
          label: "Model size " + file.size + " > " + maxSize,
          nonInteraction: true,
        });
        trackEvent({
          category: "Component Configuration",
          action: "Model Submit Error",
          name: `Model size ${file.size} > ${maxSize}`,
        });
        return Promise.reject(false);
      }

      setUploading(true);
      setModelLoading(true);
      setDfmLoading(true);
      setPreviewImageLoading(true);

      return apiUploadModelFile(token, file, productType)
        .then((res) => {
          setUploadStatusMsg(strings.UploadSuccessMsg);
          setUploadStatus(true);
          setShowUploadStatus(true);
          //@ts-ignore
          window.gtag("event", "conversion", {
            send_to: "AW-10831075758/2UfzCMCZ8qIDEK6z1Kwo",
          });
          console.log("modelUpload", res);
          if (res.filesAdded > 1) {
            Promise.all(
              res.filesList.map((item) => {
                if (productType === ProductTypeEnum.CNC) {
                  const command: CreateCNCProductType = {
                    modelId: item.id,
                    quantity: 1,
                    material: "alu-6082",
                    finish: "standard",
                    blueprint: null,
                    comment: null,
                    threads: [],
                    productType: productType,
                  };
                  return apiCreateCNCProduct(token, command).then((res) => {
                    return addCartItem(res.id, 1);
                  });
                } else if (productType === ProductTypeEnum.PRINT3D) {
                  const command: CreatePrint3DProductType = {
                    modelId: item.id,
                    quantity: 1,
                    material: "MDP-120",
                    finish: "standard",
                    comment: null,
                    threads: [],
                    productType: productType,
                  };
                  return apiCreateProduct(token, command).then((res) => {
                    return addCartItem(res.id, 1);
                  });
                }
              })
            ).then((res) => {
              setUploading(false);
              setModelLoading(false);
              setDfmLoading(false);
              setPreviewImageLoading(false);

              history.push(`/indkoebskurv`);
            });
          } else {
            let command:
              | CreateCNCProductType
              | CreatePrint3DProductType
              | null = null;
            if (productType === ProductTypeEnum.PRINT3D) {
              command = {
                modelId: res.filesList[0].id,
                quantity: 1,
                material: "MDP-120",
                finish: "standard",
                comment: null,
                threads: [],
                productType: productType,
              } as CreatePrint3DProductType;
            } else if (productType === ProductTypeEnum.CNC) {
              command = {
                modelId: res.filesList[0].id,
                quantity: 1,
                material: "alu-6082",
                finish: "standard",
                blueprint: null,
                comment: null,
                threads: [],
                productType: productType,
              } as CreateCNCProductType;
            }

            if (!command) return Promise.reject("No command");

            return apiCreateProduct(token, command).then((createdProduct) => {
              if (reuploadState) {
                onFileUploaded(createdProduct.id);
              } else setTempID(createdProduct.id);
              //setTempID(createdProduct.id);
            });
          }
        })
        .catch((err) => {
          setUploading(false);
          setUploadStatusMsg(
            "Filen kunne ikke lægges op. Prøv igen senere eller kontakt os."
          );
          setUploadStatus(false);
          setShowUploadStatus(true);
          ReactGA.event({
            category: "Component Configuration",
            action: "Model Submit Error",
            label: file.name,
            nonInteraction: true,
          });
          trackEvent({
            category: "Component Configuration",
            action: "Model Submit Error",
            name: file.name,
          });
          setUploading(false);
          setFileError(true);
          // window.alert(strings.ErrorOnUpload);
          confReset();
          return err;
        });
    }
    return Promise.reject("No file");
  };

  const onDone = useCallback(() => {
    if (tempID) {
      onFileUploaded(tempID);
      setTempID(undefined);
    }

    setUploading(false);
  }, [tempID, onFileUploaded]);

  return (
    <div>
      {fileError && (
        <SweetAlert
          custom
          title={strings.Error}
          allowEscape={true}
          showConfirm={true}
          onConfirm={() => {
            setFileError(false);
          }}
        >
          {strings.ErrorOnUpload}
        </SweetAlert>
      )}
      {!reuploadState ? (
        <div className={classes.dropzoneContainer}>
          <UploadDropzone
            onSubmit={(files) => handleSubmit(files)}
            fileType={
              productType === ProductTypeEnum.PRINT3D
                ? ".stl, .STL, .step,.STEP,.STP,.stp"
                : ".step,.STEP,.STP,.stp"
            }
            // fileMax={fileMax}
            disabled={uploading}
          >
            {uploading ? (
              <div className={`${stylesUpload.container}`}>
                <div className={`${stylesUpload.container__loader}`}>
                  <LoaderComponent
                    isLoading={!tempID}
                    isDone={onDone}
                    onChange={onUpload}
                  />
                </div>
                <h6 className={`${stylesUpload.loader__text}`}>{uploadText}</h6>
              </div>
            ) : (
              <div className={`${stylesUpload.upload__container}`}>
                <ThreeDIcon />
                <p
                  style={{
                    paddingTop: "1em",
                    fontWeight: "500",
                    fontSize: "1rem",
                  }}
                  className={`${stylesUpload.upload__container_texts}`}
                >
                  {strings.UploadThreeDModel}
                </p>
              </div>
            )}
          </UploadDropzone>
          <div
            style={{
              position: "absolute",
              bottom: "5%",
              pointerEvents: "none",
              fontWeight: "400",
            }}
          >
            {strings.SupportsStepFilesNew}
          </div>
          {/* <div
            style={{
              pointerEvents: "none",
              paddingLeft: "1rem",
              fontWeight: "400",
            }}
          >
            {strings.SupportsStepFilesNew}
          </div> */}
        </div>
      ) : (
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={(event: any) => handleSubmit(event.target.files)}
          onClick={onClick}
          accept={".step, .STEP, .stp, .STP"}
          style={{ display: "none" }}
        />
      )}
    </div>
  );
};
export default ModelUpload;
