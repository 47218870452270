import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import AppStateContext from "contexts/AppStateContext";
import { OrderType } from "types/OrderTypes";
import { apiSendInvoiceEmail } from "util/network/Orders";
import { reformatDate } from "util/DateUtil";
import CustomIconButton from "../../../components/Buttons/CustomIconButton";
import { ReactComponent as InvoiceActive } from "../../../assets/svg/dashboard/InvoiceActive.svg";
import { ReactComponent as Invoice } from "../../../assets/svg/dashboard/Invoice.svg";
import iconStyles from "../../components/Buttons/customIconButton.module.css";
import styles from "./css/newOrderHistory.module.css";
import { useHistory } from "react-router-dom";

import {
  dangerColor,
  primaryColor,
  successColor,
  warningColor,
} from "assets/jss/material-dashboard-pro-react";

type PropsType = {
  order: OrderType;
  onClick: Function;
  setLoading: Function;
  setEmailSent: Function;
};

const OrderHistoryRow: FunctionComponent<PropsType> = ({
  order,
  onClick,
  setLoading,
  setEmailSent,
}) => {
  const { strings, token } = useContext(AppStateContext);
  const [status, setStatus] = useState<string>();
  const [statusColor, setStatusColor] = useState<string>();
  const [invoiced, setInvoiced] = useState<boolean>(false);
  const history = useHistory();
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: "DKK",
      }),
    []
  );

  useEffect(() => {
    if (order.invoiceId) {
      setInvoiced(true);
    }
    console.log(order.status);
    switch (order.status) {
      case "awaiting_payment":
        setStatusColor(dangerColor[0]);
        setStatus(strings.OrderStatusUnpaid);
        break;
      case "pending":
        setStatusColor(warningColor[0]);
        setStatus(strings.OrderStatusPending);
        break;
      case "canceled":
        setStatusColor(dangerColor[0]);
        setStatus(strings.OrderStatusCancelled);
        break;
      case "cancelled":
        setStatusColor(dangerColor[0]);
        setStatus(strings.OrderStatusCancelled);
        break;
      case "processing":
        setStatusColor(primaryColor[0]);
        setStatus(strings.OrderStatusProcessing);
        break;
      case "complete":
        setStatusColor(successColor[0]);
        setStatus(strings.OrderStatusComplete);
        break;
      case "quote":
        setStatusColor(warningColor[0]);
        setStatus(strings.OrderStatusQuote);
        break;
      default:
        setStatusColor(dangerColor[0]);
        setStatus("Error");
        break;
    }
  }, [order, token, strings]);

  // Resends the selected invoice
  const resendInvoice = async (quoteID: any) => {
    if (!token) return;
    setLoading(true);
    const response = await apiSendInvoiceEmail(token, quoteID);

    if (response.ok) {
      setEmailSent(true);
      setLoading(false);
    } else {
      setLoading(false);
      setEmailSent("ERROR");
    }
  };

  const totalQuantity = order.items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  return (
    <>
      <tr className={`${styles.order__row}`}>
        <td
          key={`#${order.orderNo}`}
          align={"left"}
          className={`${styles.row__border}`}
          onClick={() => {
            history.push(`/orders/${order.id}`);
          }}
          style={{ width: "25%" }}
        >
          <span className={`${styles.order__number}`}>#{order.orderNo}</span>
          <br />
          <span className={`${styles.order__date}`}>
            {reformatDate(order.created)}
          </span>
        </td>
        <td
          key={`${order.id}status`}
          align={"left"}
          style={{
            color: statusColor,
            fontWeight: "bold",
            textTransform: "uppercase",
            border: "none",
          }}
          onClick={() => {
            history.push(`/orders/${order.id}`);
          }}
        >
          {status}
        </td>
        <td
          align={"left"}
          onClick={() => {
            history.push(`/orders/${order.id}`);
          }}
          // style={{ width: "%" }}
        >
          <span className={`${styles.order__leadTimeOption}`}>
            {order.leadTimeOption}
          </span>
          <br />
          <span className={`${styles.order__date}`}>
            {reformatDate(order.leadTimeEndDate)}
          </span>
        </td>
        <td
          onClick={() => {
            history.push(`/orders/${order.id}`);
          }}
          align={"left"}
          style={{ width: "10%" }}
        >
          <span className={`${styles.order__leadTimeOption}`}>
            {order.items.map((order) => <span>{order.quantity}</span>).length}{" "}
            items
          </span>
          <br />
          <span className={`${styles.order__ink_moms}`}>
            {totalQuantity} parts
          </span>
        </td>
        <td
          key={`total${order.total}`}
          align={"right"}
          style={{
            fontWeight: "bold",
            width: "15%",
          }}
          onClick={() => {
            history.push(`/orders/${order.id}`);
          }}
        >
          <div>
            <span className={`${styles.order__total}`}>
              {formatter.format(order.total)}
            </span>
            <br />
            <span className={`${styles.order__ink_moms}`}>
              {formatter.format(order.total + order.totalTax)}
              {/* &nbsp; */}
              {/* {strings.MomsIncludedSmall} */}
            </span>
          </div>
        </td>
        <td align="left" className={`${styles.order__invoice}`}>
          {!invoiced ? (
            <CustomIconButton icon={Invoice} tooltipText={strings.NotReady} />
          ) : (
            <CustomIconButton
              icon={InvoiceActive}
              tooltipText={strings.OrderHistoryResend}
              onClick={(e: any) => {
                // e.stopPropagation();
                resendInvoice(order.id);
              }}
            />
          )}
        </td>
      </tr>
      <tr>
        <td
          align="left"
          colSpan={6}
          className={`${styles.line__container_solid}`}
        >
          <div className={`${styles.line_solid}`}></div>
        </td>
      </tr>
    </>
  );
};
export default OrderHistoryRow;
