import { FetchError } from "exceptions/exceptions";
import * as Sentry from "@sentry/react";
import {
  CartItemType,
  CartType,
  PaymentMethodsType,
  ShippingMethodType,
} from "types/CartTypes";
import { AddressType } from "types/AddressTypes";
import { OrderType } from "types/OrderTypes";
import { API_URL } from "./Common";
import { LeadOptionsType, LeadTimeSelectorType } from "types/ConfigTypes";

const ENDPOINT = `${API_URL}/v1/ecommerce/carts`;

export const apiGetCarts = (token: string): Promise<CartType[]> => {
  return fetch(`${ENDPOINT}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    //console.log(res);
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetCart = (
  token: string,
  cartID: number
): Promise<CartType> => {
  return fetch(`${ENDPOINT}/${cartID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetCartItems = (
  token: string,
  cartID: number
): Promise<CartItemType[]> => {
  return fetch(`${ENDPOINT}/${cartID}/items`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateCart = (token: string): Promise<CartType> => {
  return fetch(`${ENDPOINT}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiAddCartItem = (
  token: string,
  cartID: number,
  productId: number,
  quantity?: number
): Promise<CartItemType> => {
  const body = JSON.stringify({
    productId: productId,
    quantity: quantity,
  });
  return fetch(`${ENDPOINT}/${cartID}/items`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiUpdateCartItem = (
  token: string,
  cartID: number,
  cartItemID: number,
  quantity: number
): Promise<CartItemType> => {
  const body = JSON.stringify({ quantity: quantity });
  return fetch(`${ENDPOINT}/${cartID}/items/${cartItemID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiRemoveCartItem = (
  token: string,
  cartID: number,
  cartItemID: number
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${cartID}/items/${cartItemID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiClearCartItems = (
  token: string,
  cartId: number
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${cartId}/items`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiGetShippingMethods = (
  token: string,
  cartID: number
): Promise<ShippingMethodType[]> => {
  return fetch(`${ENDPOINT}/${cartID}/shippingMethods`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetPaymentMethods = (
  token: string,
  cartID: number
): Promise<PaymentMethodsType[]> => {
  return fetch(`${ENDPOINT}/${cartID}/paymentMethods`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetCartLeadTime = (
  token: string,
  cartID: number
): Promise<LeadTimeSelectorType> => {
  return fetch(`${ENDPOINT}/${cartID}/leadtime`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => data.options);
};

export const apiUpdateCartLeadTime = (
  token: string,
  cartID: number,
  leadtime: LeadOptionsType
): Promise<CartType> => {
  const body = JSON.stringify({ leadtime: leadtime });
  return fetch(`${ENDPOINT}/${cartID}/leadtime`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    return res.json();
  });
};

export const apiUpdateCartInfo = (
  token: string,
  cartID: number,
  billingAddress?: AddressType,
  shippingAddress?: AddressType,
  shippingSameAsBilling?: boolean,
  poNumber?: string,
  shippingMethod?: string,
  invoiceEmail?: string
): Promise<CartType> => {
  const body = JSON.stringify({
    billingAddress: billingAddress,
    shippingAddress: shippingAddress,
    sameAsBilling: shippingSameAsBilling,
    poNumber: poNumber,
    shippingMethod: shippingMethod,
    invoiceEmail: invoiceEmail,
  });
  return fetch(`${ENDPOINT}/${cartID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiConfirmOrder = (
  token: string,
  cartId: number,
  paymentMethod: string,
  eanNumber?: string
): Promise<OrderType> => {
  const body = JSON.stringify({
    paymentMethod: paymentMethod,
    eanNumber: eanNumber,
  });
  return fetch(`${ENDPOINT}/${cartId}/order`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
