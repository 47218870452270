import React, { FunctionComponent } from "react";
import styles from "./cards.module.css";

type PropsType = {
  className?: string;
  children: React.ReactNode;
};

const CardsNote: FunctionComponent<PropsType> = ({ children, className }) => {
  return (
    <div className={`${styles.card__layout_note}`}>
      <div className={`${styles.card} ${className}`}>{children}</div>
    </div>
  );
};

export default CardsNote;
//${styles.card__login_note} ${styles.card__login_note_s}
