import { ModelType } from "types/ModelTypes";
import { SelectedThreadsInfoType } from "types/RenderTypes";

export enum ProductTypeEnum {
  FEE = "FEE",
  BASE = "BASE",
  CNC = "CNC",
  PRINT3D = "PRINT3D",
}

export type ProductVMType = {
  id: number;
  created: string;
  modified: string;
  name: string;
  productType: ProductTypeEnum;
  authorId: number;
  priceExpress: number;
  priceStandard: number;
  priceEconomic: number;
  weight: number;
  custom: boolean;
  details?: CNCProductDetailsType | Print3DProductDetailsType;
};

export type Print3DProductDetailsType = {
  id: number;
  created: string;
  modified: string;
  productId: number;
  modelId: number;
  model: ModelType;
  quantity: number;
  material: string;
  finish: string[];
  comment: string;
  threads: SelectedThreadsInfoType[];
};

export type CNCProductDetailsType = {
  id: number;
  created: string;
  modified: string;
  productId: number;
  modelId: number;
  model: ModelType;
  quantity: number;
  material: string;
  finish: string[];
  blueprint: string | null;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
};

export type EstimateProductType = {
  product: ProductVMType;
  leadTimeDays: {
    EXPRESS: number;
    STANDARD: number;
    ECONOMIC: number;
  };
};

export type CreateCNCProductType = {
  modelId: number;
  quantity: number;
  material: string;
  finish: string;
  blueprint: string | null;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
  productType: ProductTypeEnum;
};

export type UpdateCNCProductType = {
  quantity: number;
  material: string;
  finish: string;
  blueprint: string | null;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
};

export type CreatePrint3DProductType = {
  modelId: number;
  quantity: number;
  material: string;
  finish: string;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
  productType: ProductTypeEnum;
};

export type UpdatePrint3DProductType = {
  quantity: number;
  material: string;
  finish: string;
  comment: string | null;
  threads: SelectedThreadsInfoType[];
};
