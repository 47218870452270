import CompConfigContext from "contexts/CompConfigContext";
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import pulseEffect from "styles/components/PulseEffect.module.css";
import { ErrorType, ModelDataType, ReceivedModelType } from "types/RenderTypes";
import AppStateContext from "contexts/AppStateContext";
import PleaseLoginModal from "vaerksComponents/loginLogout/PleaseLoginModal";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import ComponentRender from "components/ModelRender";
import DFMContainer from "components/DFMControls/DFMContainer";
import { ThemeContext } from "@emotion/react";
import { DFMInfoType } from "types/ModelTypes";
import ModelUpload from "vaerksComponents/configuratorComponents/ModelUpload";
import stylesTab from "./threeDModalTab.module.css";

import { ReactComponent as UploadIcon } from "../../../assets/svg/dashboard/upload.svg";

type PropsType = {
  model?: ReceivedModelType;
  dfmModel?: GLTF;
  info?: ModelDataType;
  dfmInfo?: DFMInfoType | null;
  demo?: boolean;
  onUpload: (id: number) => void;
  disableUpload?: boolean;
  confReset: Function;
};

const ThreeDModelTab: FunctionComponent<PropsType> = ({
  model,
  dfmModel,
  info,
  dfmInfo,
  demo,
  onUpload,
  disableUpload,
  confReset,
}) => {
  const [selectedError, setSelectedError] = useState<ErrorType>();

  const { strings } = useContext(AppStateContext);
  const { pleaseLoginModal, setPleaseLoginModal } =
    useContext(CompConfigContext);
  const token = localStorage.getItem("token");

  const hiddenFileInput = React.useRef<any>(null);

  const handleClick = useCallback(() => {
    if (token) {
      hiddenFileInput.current?.click();
    } else {
      setPleaseLoginModal(true);
    }
  }, [setPleaseLoginModal, token]);

  const dfmContainer = useMemo(() => {
    return (
      dfmInfo !== null && (
        <DFMContainer
          dfmInfo={dfmInfo}
          selectedError={selectedError}
          setSelectedError={setSelectedError}
        />
      )
    );
  }, [dfmInfo, selectedError]);

  const reuploadButton = useMemo(() => {
    return (
      <div className={`${stylesTab.button__container}`}>
        {demo && (
          <p className={`${stylesTab.demo__text}`}>{strings.DemoModeText}</p>
        )}
        <button
          className={`${stylesTab.button__upload} ${demo && pulseEffect.pulse}`}
          onClick={() => {
            handleClick();
          }}
        >
          <div className={`${stylesTab.button__content}`}>
            <span>
              <UploadIcon />
            </span>
            <p className={`${stylesTab.button__text}`}>{strings.ChangeModel}</p>
          </div>
        </button>
      </div>
    );
  }, [demo, handleClick, strings]);
  return (
    <div>
      {model && (
        <div className={`${stylesTab.model__container}`}>
          <ThemeContext.Consumer>
            {(themeContext) => (
              <AppStateContext.Consumer>
                {(appContext) => (
                  <ComponentRender
                    gltf={model}
                    dfmGltf={dfmModel}
                    info={info}
                    selectedError={selectedError}
                    disableSelect
                    context={appContext}
                    themeContext={themeContext}
                  >
                    <>
                      {dfmContainer}
                      {!disableUpload && reuploadButton}
                      {
                        <div className={`${stylesTab.model__dimensions}`}>
                          {info?.bboxDx.toFixed(1)}mm x{" "}
                          {info?.bboxDy.toFixed(1)}mm x{" "}
                          {info?.bboxDz.toFixed(1)}mm
                        </div>
                      }
                    </>
                  </ComponentRender>
                )}
              </AppStateContext.Consumer>
            )}
          </ThemeContext.Consumer>
        </div>
      )}
      <ModelUpload
        hiddenFileInput={hiddenFileInput}
        onFileUploaded={onUpload}
        reuploadState={model != undefined}
        confReset={confReset}
      />
      {pleaseLoginModal && <PleaseLoginModal />}
    </div>
  );
};
export default ThreeDModelTab;
