import React, {
  useContext,
  FunctionComponent,
  useEffect,
  useState,
  FormEvent,
} from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import styles from "./css/newLoginPageStyles.module.css";
import stylesBtn from "./../../components/Buttons/customButton.module.css";
import stylesInput from "../../components/Input/customInput.module.css";
import stylesCard from "../../components/NewCard/cards.module.css";
import AppStateContext from "contexts/AppStateContext";
import Cards from "../../components/NewCard/Cards";
import CustomInput from "components/Input/CustomInput";
import { useHistory } from "react-router";
import { REGEX_PHONENUMBER } from "../../util/RegExUtil";
import { apiLogin, apiRegister } from "util/network/Auth";
import { apiGetUser, apiSubscribeNewsletter } from "util/network/Users";
import * as ReactGA from "react-ga";
import CustomCheckbox from "components/Checkbox/CustomCheckbox";
import CustomButton from "components/Buttons/CustomButton";
import { CircularProgress } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import NavigateButton from "components/Buttons/NavigateButton";

type PropsType = {};

const Register: FunctionComponent<PropsType> = () => {
  const { strings, setToken } = useContext(AppStateContext);
  const [registrationStatus, setRegistrationStatus] = useState();
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const { trackEvent, pushInstruction } = useMatomo();
  const history = useHistory();

  const [error, setError] = useState(false);
  const [newFirstName, setNewFirstName] = useState<string>("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [terms, setTerms] = useState<boolean>(false);
  const [newsletter, setNewsletter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registerText, setRegisterText] = useState<string>();

  const [upgradingGuest, setUpgradingGuest] = useState(false);

  const isPasswordLegit = (str: string) => {
    if (str.length >= 8 && str.toLowerCase() !== str) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setError(false);
    //todo add phone number
    // const sanitizedInput = (e.target as HTMLInputElement).value
    //   .replace(/\D/g, "")
    //   .slice(0, 8);
    // const formattedInput =
    //   sanitizedInput.length > 0 ? "+45 " + sanitizedInput : "";
    // setNewPhoneNumber(formattedInput);
    if (
      !newFirstName ||
      !newLastName ||
      !newEmail ||
      !newPassword ||
      !newPhoneNumber
    ) {
      setError(true);

      return;
    } else if (newPhoneNumber.length <= 0) {
      setError(true);
      setRegisterText(strings.PhoneInput);
    } else if (newFirstName.length <= 0) {
      setError(true);
      setRegisterText(strings.MissingFirstName);
    } else if (newLastName.length <= 0) {
      setError(true);
      setRegisterText(strings.MissingLastName);
    } else if (newEmail.length <= 0) {
      setError(true);
      setRegisterText(strings.MissingEmail);
    } else if (newPassword.length < 8) {
      setError(true);
      setRegisterText(strings.MissingConfirmPassword);
      return;
    } else if (newPassword !== newConfirmPassword) {
      setRegisterText(strings.ConfirmPasswordDifferent);
      setError(true);
      return;
    } else if (!terms) {
      setError(true);
      return;
    }

    setLoading(true);

    apiRegister(
      newEmail,
      newFirstName,
      newLastName,
      newPhoneNumber,
      newCompanyName,
      newPassword
    )
      .then((user) => {
        apiLogin(newEmail, newPassword).then((token) => {
          sessionStorage.clear();
          localStorage.setItem("token", token);
          setToken(token);
          pushInstruction("setUserId", newEmail);
          if (newsletter) {
            apiSubscribeNewsletter(token, user.id);
          }

          //@ts-ignore
          window.gtag("event", "sign_up");

          ReactGA.event({
            category: "event",
            action: "sign_up",
            label: newEmail,
          });
          setLoading(true);
          trackEvent({ category: "User Registration", action: "User created" });
          history.replace("ny-komponent");
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err.message);
        setError(true);
        setLoading(false);
        setRegistrationStatus(err.message);

        if (err.message === "UNAUTHORIZED") {
          setRegisterText(strings.LoginWrongCredentials);
        } else {
          setRegisterText(err.message);
          // gives error msg if email already exists
        }
      });
  };

  useEffect(() => {
    // Good!
    if (userCreated === true) {
      setLoading(false);
      setError(false);
      setNewFirstName("");
      setNewLastName("");
      setNewEmail("");
      setNewPassword("");
      setNewConfirmPassword("");
      setNewCompanyName("");
      setNewPhoneNumber("");
      setTerms(false);
      setUpgradingGuest(false);
    } else if (userCreated === false) {
      // setLoading(false);
      setUpgradingGuest(false);
    }
  }, [userCreated]);

  return (
    <>
      {/* {loading && (
        <SweetAlert
          custom
          style={{ position: "relative" }}
          title={strings.PleaseWait}
          allowEscape={false}
          showConfirm={false}
          onConfirm={() => {}}
          customIcon={
            <CircularProgress size={"80px"} style={{ marginLeft: "40%" }} />
          }
        >
          {strings.ConfirmingOrder}
        </SweetAlert>
      )} */}
      <Cards className={`${stylesCard.card__register}`}>
        {/* {loading && (
          <SweetAlert
            custom
            style={{ position: "relative" }}
            title={strings.PleaseWait}
            allowEscape={false}
            showConfirm={false}
            onConfirm={() => {}}
            customIcon={
              <CircularProgress size={"80px"} style={{ marginLeft: "40%" }} />
            }
          >
            {strings.ConfirmingOrder}
          </SweetAlert>
        )} */}
        <h1 className={`${styles.title__register}`}>
          {strings.CreateNewUserTitel}
        </h1>
        <div className={`${styles.form__container_register}`}>
          <form onSubmit={handleSubmit}>
            <div className={`${styles.input__container_register}`}>
              <span className={`${styles.input__validation_text}`}>
                {registerText}
              </span>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ paddingRight: "1rem" }}>
                  <CustomInput
                    label={`${strings.FirmNameInput}` + "*"}
                    type="text"
                    placeholder={strings.FirmNameInput}
                    id="firmname"
                    value={newCompanyName}
                    onChange={(e: any) => setNewCompanyName(e.target.value)}
                    error={error && newCompanyName.length === 0}
                    required={true}
                    inputStyle={`${stylesInput.input__reg}`}
                  />
                  {/* {error && newCompanyName.length === 0 ? (
                  <p className={`${styles.input__validation_text}`}>
                    {strings.FirmNameInput}
                  </p>
                ) : (
                  ""
                )} */}
                </div>
                <div>
                  <CustomInput
                    label={strings.PhoneInput + "*"}
                    type="number"
                    placeholder="+45 00 00 00 00"
                    id="phonenum"
                    error={
                      newPhoneNumber && !REGEX_PHONENUMBER.test(newPhoneNumber)
                    }
                    onChange={(e: any) => {
                      if (e.target.value.length < 9) {
                        setNewPhoneNumber(e.target.value);
                      }
                    }}
                    pattern={`${REGEX_PHONENUMBER}`}
                    maxLength={9}
                    value={newPhoneNumber}
                    required={true}
                    className={`${styles.label_firmname}`}
                    inputStyle={`${stylesInput.input__reg}`}
                  />
                  {(error && newPhoneNumber.length <= 0) ||
                  (error && !REGEX_PHONENUMBER.test(newPhoneNumber)) ? (
                    <p className={`${styles.input__validation_text}`}>
                      {strings.MissingPhone}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <div style={{ color: "red" }}>{registerText}</div> */}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ paddingRight: "1rem" }}>
                  <CustomInput
                    label={strings.FirstName + "*"}
                    id="firstname"
                    type="text"
                    value={newFirstName}
                    placeholder={strings.FirstName}
                    onChange={(e: any) => setNewFirstName(e.target.value)}
                    error={error && newFirstName.length === 0}
                    required={true}
                    inputStyle={`${stylesInput.input__reg}`}
                  />
                  {error && newFirstName.length <= 0 ? (
                    <p className={`${styles.input__validation_text}`}>
                      {strings.MissingFirstName}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <CustomInput
                    label={strings.LastName + "*"}
                    id="lastname"
                    type="text"
                    value={newLastName}
                    placeholder={strings.LastName}
                    onChange={(e: any) => setNewLastName(e.target.value)}
                    error={error && newLastName.length === 0}
                    required={true}
                    inputStyle={`${stylesInput.input__reg}`}
                  />
                  {error && newLastName.length <= 0 ? (
                    <p className={`${styles.input__validation_text}`}>
                      {strings.MissingLastName}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <CustomInput
                label={strings.Email + "*"}
                id="newEmail"
                type="email"
                value={newEmail}
                placeholder={strings.Email}
                onChange={(e: any) => setNewEmail(e.target.value)}
                error={
                  (error && !newEmail.includes("@")) || newEmail.length === 0
                }
                autoComplete="off"
                required={true}
                // maxLength={REGEX_EMAIL}
              />
              {(error && !newEmail.includes("@")) ||
              (error && newEmail.length === 0) ? (
                <p className={`${styles.input__validation_text}`}>
                  {strings.MissingEmail}
                </p>
              ) : (
                ""
              )}
              <CustomInput
                label={strings.Password + "*"}
                id="newPassword"
                type="password"
                value={newPassword}
                placeholder={strings.Password}
                onChange={(e: any) => setNewPassword(e.target.value)}
                error={error && !isPasswordLegit(newPassword)}
                autoComplete="off"
                required={true}
              />
              {error && !isPasswordLegit(newPassword) ? (
                <p className={`${styles.input__validation_text}`}>
                  {strings.MissingPassword}
                </p>
              ) : (
                ""
              )}
              <CustomInput
                label={strings.ConfirmPassword + "*"}
                id="newPasswordRepeat"
                type="password"
                value={newConfirmPassword}
                placeholder={strings.ConfirmPassword}
                onChange={(e: any) => setNewConfirmPassword(e.target.value)}
                error={
                  newConfirmPassword !== newPassword &&
                  newConfirmPassword !== ""
                }
                required={true}
              />
              {error && newConfirmPassword.length <= 0 ? (
                <p className={`${styles.input__validation_text}`}>
                  {strings.MissingConfirmPasswordField}
                </p>
              ) : (
                ""
              )}
              {error &&
              newConfirmPassword !== newPassword &&
              newConfirmPassword !== "" ? (
                <p className={`${styles.input__validation_text}`}>
                  {strings.ConfirmPasswordDifferent}
                </p>
              ) : (
                ""
              )}
            </div>
            <div
              className={`${styles.submit__forgotPassword}`}
              style={{ paddingTop: "2rem", paddingBottom: "1rem" }}
            >
              <CustomCheckbox
                label={
                  <>
                    {strings.IAcceptEasy}{" "}
                    <a
                      style={{ color: "#00b28d" }}
                      href="https://easypartz.com/index.php/privacy-policy-dk/"
                      target="_blank"
                    >
                      {strings.PrivacyPolicy}
                    </a>
                  </>
                }
                checked={terms}
                type={"checkbox"}
                onChange={(e) => setTerms(e.currentTarget.checked)}
                required={true}
              />

              <CustomCheckbox
                type={"checkbox"}
                label={strings.IJoinNews}
                checked={newsletter}
                onChange={(e) => setNewsletter(e.currentTarget.checked)}
                // required={true}
              />
            </div>
            {/* {loading && <CircularProgress />} */}
            <div style={{ marginLeft: "13rem" }}>
              {/* <CustomButton
                buttonText={strings.CreateUserButton}
                buttonClass={`${stylesBtn.button__login}`}
                iconClass={`${stylesBtn.login__icon}`}
                type="submit"
                id="registerSubmit"
                // disabled={!terms}
                // autoFocus={true}
              >
                <span>{loading && <CircularProgress />}</span>
              </CustomButton> */}
              <NavigateButton
                buttonText={strings.CreateUserButton}
                buttonClass={`${stylesBtn.button__login}`}
                iconClass={`${stylesBtn.login__icon}`}
                type="submit"
                id="registerSubmit"
                disabled={!terms}
                loading={loading}
              />

              <p>{error}</p>
            </div>
          </form>
        </div>
      </Cards>
    </>
  );
};
export default Register;
