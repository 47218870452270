import { Button, Menu, MenuItem } from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import styles from "../../assets/jss/material-dashboard-pro-react/creativeTim/views/selectorStyle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Materials } from "../../util/MaterialUtils";
import { ArrowDropDown } from "@mui/icons-material";
import { MaterialDataType, MaterialType } from "../../types/NetworkTypes";
import AppStateContext from "contexts/AppStateContext";
import { fetchPublic } from "util/network/Common";
import CustomSelect from "components/Select/CustomSelect";

const useStyles: any = makeStyles(styles);

type PropsType = {
  currentSel: string;
  disabled?: boolean;
  onChange: Function;
  materialFile?: string;
};

const MatSelectorLocal: FunctionComponent<PropsType> = ({
  currentSel,
  disabled,
  onChange,
  materialFile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState<string>(currentSel);
  const [options, setOptions] = useState<MaterialType[]>([]);
  const { strings } = useContext(AppStateContext);

  useEffect(() => {
    setSelectedMaterial(currentSel);
  }, [currentSel]);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (id: string) => {
    if (id !== selectedMaterial) {
      setSelectedMaterial(id);
      setAnchorEl(null);
      onChange(id);
    }
  };

  useEffect(() => {
    fetchPublic(materialFile ? `/${materialFile}` : "/cncMaterials.json").then(
      (res) => {
        setOptions(res);
      }
    );
  }, [materialFile]);

  return (
    <div style={{ display: "inline-block" }}>
      <CustomSelect
        disabled={disabled}
        //onChange={(e: any) => handleMenuItemClick(e.target.value)}
        // TODO CHECK THIS
      >
        {options.map((option, key) => {
          return [
            option.variants.length > 1 && (
              <option key={key} disabled>
                {option.title[strings.code]}
              </option>
            ),
            ...option.variants.map((variant, key) => (
              <option
                key={key}
                selected={selectedMaterial == variant.id}
                onClick={(event) => handleMenuItemClick(variant.id)}
                value={variant.id}
              >
                {variant[strings.code].materialTitle}
              </option>
            )),
          ];
        })}
      </CustomSelect>
    </div>
  );
};

export default MatSelectorLocal;
