import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { primaryColor } from "../../../assets/jss/material-dashboard-pro-react";
import { Button, CircularProgress } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import * as ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import ConfiguratorPriceSummary from "../../../vaerksComponents/configuratorComponents/ConfiguratorPriceSummary";
import CompConfigContext from "contexts/CompConfigContext";
import AppStateContext from "contexts/AppStateContext";
import { ModelDataType, SelectedThreadsInfoType } from "types/RenderTypes";
import {
  LeadOptionsType,
  LeadTimeSelectorType,
  ThreadType,
} from "types/ConfigTypes";
import {
  apiEstimateProductDemo,
  apiGetDemoModelInfo,
  apiGetDemoPreviewImage,
} from "util/network/Demo";
import EcommerceContext from "contexts/EcommerceContext";
import {
  apiEstimateProduct,
  apiGetModelInfo,
  apiGetPreviewImage,
  apiGetProduct,
  apiUpdateProduct,
} from "util/network/Products";
import { apiGetThreadsList } from "util/network/Models";
import Print3D from "components/Configurator/Print3D";
import {
  Print3DProductDetailsType,
  UpdatePrint3DProductType,
} from "types/products/ProductCommandsType";
import { ProductOptionsType } from "types/products/ProductTypes";
import styles from "./css/newComponentConfigurator.module.css";

const Print3DConfigurator = () => {
  const { strings, token } = useContext(AppStateContext);
  const {
    cartId,
    cartItems,
    leadtimeOption,
    leadTimeOptions,
    changeLeadTime,
    addCartItem,
    updateCartItem,
  } = useContext(EcommerceContext);
  const { state } = useLocation<any>();
  const history = useHistory();
  const { trackEvent } = useMatomo();

  const [selectedLeadTime, setSelectedLeadTime] = useState<LeadOptionsType>(
    leadtimeOption ?? "STANDARD"
  );

  const [priceLoading, setPriceLoading] = useState(false);

  const [configuration, setConfiguration] = useState<
    Print3DProductDetailsType & {
      name: string;
      weight: number;
    }
  >();

  const [options, setOptions] = useState<ProductOptionsType>();

  const [fileName, setFileName] = useState<string | null>(null);

  const {
    print3DProductId,
    setPrint3DProductId,
    setModelLoading,
    setDfmLoading,
    previewImageLoading,
    setPreviewImageLoading,
    showLogoutModal,
  } = useContext(CompConfigContext);

  const demo = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const demo = urlParams.get("demo");
    return demo !== null;
  }, []);

  const [prices, setPrices] = useState<{
    ECONOMIC: number;
    STANDARD: number;
    EXPRESS: number;
  }>({
    EXPRESS: 0,
    STANDARD: 0,
    ECONOMIC: 0,
  });

  const [totalLeadTimeOptions, setTotalLeadTimeOptions] =
    useState<LeadTimeSelectorType>({
      STANDARD: {
        days: 0,
        price: 0,
      },
    });

  const [previewImage, setPreviewImage] = useState<string>();

  const [addingToCart, setAddingtoCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);

  const [currentInfo, setCurrentInfo] = useState<ModelDataType>();
  const [threadList, setThreadList] = useState<ThreadType[]>([]);

  const [reconfiguration, setReconfiguration] = useState<boolean>(false);
  const [existingCartItemId, setExistingCartItemId] = useState<number>();

  const updateConfiguration = (
    newConfiguration: Print3DProductDetailsType & {
      name: string;
      weight: number;
    }
  ) => {
    if (print3DProductId) {
      setPriceLoading(true);
      const command = {
        quantity: newConfiguration.quantity,
        material: newConfiguration.material,
        finish: newConfiguration.finish.join(","),
        comment: newConfiguration.comment,
        threads: newConfiguration.threads,
      };
      // apiGetProductOptions(
      //   token,
      //   print3DProductId,
      //   newConfiguration.material
      // ).then((res) => {
      //   setOptions(res);
      // });
      apiEstimateProduct(token, print3DProductId, command, cartId).then(
        (res) => {
          const productDetails = {
            ...res.product.details,
            finish: (res.product.details?.finish as any).split(","),
          } as Print3DProductDetailsType;
          const newPrices = {
            EXPRESS: res.product.priceExpress,
            STANDARD: res.product.priceStandard,
            ECONOMIC: res.product.priceEconomic,
          };
          setPrices(newPrices);
          setTotalLeadTimeOptions({
            STANDARD: {
              days: res.leadTimeDays.STANDARD,
              price: res.product.priceStandard * productDetails.quantity,
            },
          });
          setConfiguration({
            ...productDetails,
            name: res.product.name,
            weight: res.product.weight,
          });
          setPriceLoading(false);
        }
      );
    }
  };

  const updateDemoConfiguration = (
    newConfiguration: Print3DProductDetailsType & {
      name: string;
      weight: number;
    }
  ) => {
    if (demo) {
      setPriceLoading(true);
      const command = {
        quantity: newConfiguration.quantity,
        material: newConfiguration.material,
        finish: newConfiguration.finish.join(","),
        comment: newConfiguration.comment,
        threads: newConfiguration.threads,
      };
      apiEstimateProductDemo(command).then((res) => {
        const productDetails = {
          ...res.product.details,
          finish: (res.product.details?.finish as any).split(","),
        } as Print3DProductDetailsType;
        const newPrices = {
          EXPRESS: res.product.priceExpress,
          STANDARD: res.product.priceStandard,
          ECONOMIC: res.product.priceEconomic,
        };
        setPrices(newPrices);
        // setTotalLeadTimeOptions({
        //   ECONOMIC: {
        //     days: res.leadTimeDays.ECONOMIC,
        //     price: res.product.priceEconomic * productDetails.quantity,
        //   },
        //   STANDARD: {
        //     days: res.leadTimeDays.STANDARD,
        //     price: res.product.priceStandard * productDetails.quantity,
        //   },
        //   EXPRESS: {
        //     days: res.leadTimeDays.EXPRESS,
        //     price: res.product.priceExpress * productDetails.quantity,
        //   },
        // });
        setConfiguration({
          ...productDetails,
          name: res.product.name,
          weight: res.product.weight,
        });
        setPriceLoading(false);
      });
    }
  };

  const onUnloadMsg = reconfiguration
    ? strings.WarningChangesNotSaved
    : strings.WarningLoseConfig;

  const resetState = useCallback(() => {
    setAddedToCart(false);
    setAddedToCart(false);
    setCurrentInfo(undefined);
    setPreviewImage("");
    setPriceLoading(false);
    setPreviewImageLoading(false);
    setFileName("");
    setConfiguration(undefined);
    sessionStorage.removeItem("configuration");
    sessionStorage.removeItem("reconfigSku");
    setPrint3DProductId(null);
  }, [setFileName, setPrint3DProductId]);

  // console.log(print3DProductId);

  const initModelDemo = useCallback(() => {
    setPriceLoading(true);
    setModelLoading(true);
    setDfmLoading(true);

    const command = {
      quantity: 1,
      material: "MDP-120",
      finish: "standard",
      blueprint: null,
      comment: null,
      threads: [],
    };
    apiEstimateProductDemo(command).then((res) => {
      setFileName(res.product.name);
      const productDetails = {
        ...res.product.details,
        finish: (res.product.details?.finish as any).split(","),
      } as Print3DProductDetailsType;
      const newPrices = {
        EXPRESS: res.product.priceExpress,
        STANDARD: res.product.priceStandard,
        ECONOMIC: res.product.priceEconomic,
      };
      setPrices(newPrices);
      // setTotalLeadTimeOptions({
      //   ECONOMIC: {
      //     days: res.leadTimeDays.ECONOMIC,
      //     price: res.product.priceEconomic * productDetails.quantity,
      //   },
      //   STANDARD: {
      //     days: res.leadTimeDays.STANDARD,
      //     price: res.product.priceStandard * productDetails.quantity,
      //   },
      //   EXPRESS: {
      //     days: res.leadTimeDays.EXPRESS,
      //     price: res.product.priceExpress * productDetails.quantity,
      //   },
      // });
      setConfiguration({
        ...productDetails,
        name: res.product.name,
        weight: res.product.weight,
      });

      apiGetDemoPreviewImage().then((img) => {
        setPreviewImage(img);
        setPreviewImageLoading(false);
      });
      apiGetDemoModelInfo().then((dat) => {
        setCurrentInfo(dat);
        setPriceLoading(false);
      });
    });
  }, [setPreviewImageLoading]);

  const initModel = useCallback(
    (id) => {
      // console.log("initModel run");
      if (cartId === 0) return;
      setPriceLoading(true);
      setModelLoading(true);
      setDfmLoading(true);
      apiGetProduct(token, id).then((product) => {
        setFileName(product.name);
        const productDetails = {
          ...product.details,
          finish: (product.details?.finish as any).split(","),
        } as Print3DProductDetailsType;
        setConfiguration({
          ...productDetails,
          name: product.name,
          weight: product.weight,
        });
        setPrices({
          ECONOMIC: product.priceEconomic,
          STANDARD: product.priceStandard,
          EXPRESS: product.priceExpress,
        });
        apiGetPreviewImage(token, productDetails.modelId).then((res) => {
          setPreviewImage(res);
          setPreviewImageLoading(false);
        });
        apiGetModelInfo(token, productDetails.modelId).then((dat) => {
          setCurrentInfo(dat);
        });
        // apiGetProductOptions(token, id, productDetails.material).then((res) => {
        //   setOptions(res);
        // });
        apiEstimateProduct(
          token,
          id,
          {
            quantity: productDetails.quantity,
            finish: productDetails.finish.join(","),
            material: productDetails.material,
            threads: productDetails.threads,
            comment: productDetails.comment,
          },
          cartId
        ).then((res) => {
          setTotalLeadTimeOptions({
            STANDARD: {
              days: res.leadTimeDays.STANDARD,
              price: res.product.priceStandard * productDetails.quantity,
            },
          });
          setPriceLoading(false);
        });
      });
    },
    [cartId, leadTimeOptions, setPreviewImageLoading, token]
  );

  // Initial load
  useEffect(() => {
    apiGetThreadsList().then((res) => setThreadList(res));
    // console.log(state);
    if (state?.reconfigure) {
      console.log("Reconfiguration");
      setReconfiguration(state?.reconfigure);
      setExistingCartItemId(state.cartItemId);
      setPrint3DProductId(state.productId);
    }
    // return () => resetState();
  }, [state]);

  // On productId change
  useEffect(() => {
    if (print3DProductId && !demo) {
      // console.log("Init with id: ", productId);
      initModel(print3DProductId);
    } else if (demo) {
      initModelDemo();
    }
  }, [demo, initModel, initModelDemo, print3DProductId, setPrint3DProductId]);

  // Used to prevent people from accidentially closing or leaving the page without saving
  useEffect(() => {
    const beforeUnload = (e: any) => {
      e.preventDefault();
      // Hacky solution to force chrome to show the alert when closing the tab or reloading the page
      e.returnValue = "";
      return true;
    };
    const onUnload = (e: any) => {
      e.preventDefault();
      resetState();
      return true;
    };
    if (
      print3DProductId &&
      print3DProductId !== 0 &&
      !demo &&
      !addedToCart &&
      !showLogoutModal
    ) {
      window.addEventListener("unload", onUnload);
      window.addEventListener("beforeunload", beforeUnload);
    }
    return () => {
      window.removeEventListener("unload", onUnload);
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [
    addedToCart,
    demo,
    print3DProductId,
    onUnloadMsg,
    resetState,
    showLogoutModal,
  ]);

  // Fires when the cartContent is updated due to a succesfully added configuration
  // useEffect(() => {
  //   if (cartStandardPrice !== 0 && addingToCart) {
  //     setAddingtoCart(false);
  //     setAddedToCart(true);
  //   }
  // }, [addingToCart, cartContent, cartStandardPrice]);

  // Stops the loading UI when data has been fetched
  // useEffect(() => {
  //   if (id && id !== 0 && configuration && configuration.model_id !== 0) {
  //     setPriceLoading(true);
  //     apiCalculateConfiguration(configuration).then((res) => {
  //       setLeadTimeInfo(res.lead_time);
  //       // setExpressPrice(res.lead_time.prices.EXPRESS);
  //       // setStandardPrice(res.lead_time.prices.STANDARD);
  //       // setEconomicPrice(res.lead_time.prices.ECONOMIC);
  //       setPriceLoading(false);
  //     });
  //   }
  // }, [id, configuration]);

  useEffect(() => {
    // Google Analytics Event
    console.log("Prices", prices);
    if (!demo && print3DProductId) {
      if (prices["STANDARD"] !== 0) {
        ReactGA.event({
          category: "Component Calculations",
          action: "Price Updated",
          label: print3DProductId + ":" + fileName,
          value: prices[selectedLeadTime],
          nonInteraction: true,
        });
        trackEvent({
          category: "Component Configuration",
          action: "Price Updated",
          name: print3DProductId + ":" + fileName,
          value: prices[selectedLeadTime],
        });
      }
    }
  }, [prices]);

  //New ComponentDidUnmount to delete in database during unmount (switching to different component)
  // useEffect(() => {
  //   return () => {
  //     clearModelContext();
  //   };
  // }, [addedToCart]);

  // Runs when the user is done configurering and decides to add it to the cart
  // - changes depending on being in "RECONFIGURE MODE" or not.
  const handleFinishedComponent = async () => {
    if (token && print3DProductId && configuration) {
      const finalThreads: SelectedThreadsInfoType[] = [];
      configuration.threads?.forEach((thread) => {
        if (thread.name !== "none") {
          const threadObj = threadList?.find((t) => t.name === thread.name);
          // if (!threadObj?.color) {
          //   console.log(threadObj, thread);
          // }
          finalThreads.push({
            ...thread,
            color: threadObj?.color,
            // {throw new FetchError(res.statusText, res.status);
            //   g: (threadObj?.color.g ?? -1) / 255,
            //   b: (threadObj?.color.b ?? -1) / 255,
            // },
          });
        }
      });
      setAddingtoCart(true);

      if (selectedLeadTime !== leadtimeOption) {
        try {
          await changeLeadTime(selectedLeadTime);
        } catch (e) {
          console.log(e);
        }
      }

      const command = {
        quantity: configuration.quantity,
        material: configuration.material,
        finish: configuration.finish.join(","),
        comment: configuration.comment,
        threads: finalThreads,
      } as UpdatePrint3DProductType;

      apiUpdateProduct(token, print3DProductId, command).then(
        (updatedProduct) => {
          if (updatedProduct.id) {
            if (reconfiguration) {
              let item = cartItems.find(
                (item) => item.id === existingCartItemId
              );
              if (item) {
                item.quantity = configuration.quantity;
                updateCartItem(item).then((res) => {
                  if (res) {
                    ReactGA.event({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      label: print3DProductId + ":" + fileName,
                    });
                    trackEvent({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      name: print3DProductId + ":" + fileName,
                    });
                    setAddingtoCart(false);
                    setAddedToCart(true);
                  }
                });
              }
            } else {
              addCartItem(updatedProduct.id, configuration.quantity).then(
                (res) => {
                  if (res) {
                    ReactGA.event({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      label: print3DProductId + ":" + fileName,
                    });
                    trackEvent({
                      category: "Component Configuration",
                      action: "Finish, Update Component",
                      name: print3DProductId + ":" + fileName,
                    });
                    setAddingtoCart(false);
                    setAddedToCart(true);
                  }
                }
              );
            }
          }
        }
      );
    }
  };

  // When a configuration has been added to the users cart,
  // they can either chose to go the their cart or create a new configuration in the <Sweetalert>.
  // The page reloads for a new config since this is easier.
  const handleGoToCart = () => {
    resetState();
    history.push("/indkoebskurv", undefined);
    //clearModelContext();
  };

  const handleNewComponent = () => {
    resetState();
    history.push("/ny-komponent/print3d", undefined);
    window.location.reload();
  };

  return (
    <div>
      {addedToCart ? (
        <SweetAlert
          success
          title={
            !reconfiguration
              ? strings.CompConfigSucces
              : strings.CompConfigUpdated
          }
          allowEscape={false}
          timeout={reconfiguration ? 2000 : undefined}
          showConfirm={!reconfiguration}
          customButtons={
            !reconfiguration ? (
              <p
                style={{
                  display: "flex",
                  zIndex: 1,
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Button color="info" onClick={handleGoToCart}>
                  <b>{strings.CompConfigUpdatedGoToBasketButton}</b>
                </Button>
                <Button color="primary" onClick={handleNewComponent}>
                  <b>{strings.AddNewCompButton?.toUpperCase()}</b>
                </Button>
              </p>
            ) : null
          }
          onConfirm={handleGoToCart}
        >
          {!reconfiguration
            ? strings.CompConfigCompAddedToBasket
            : strings.CompConfigReturningToBasket}
        </SweetAlert>
      ) : null}
      {addingToCart ? (
        <SweetAlert
          custom
          title={strings.PleaseWait}
          allowEscape={false}
          showConfirm={false}
          customIcon={
            <CircularProgress
              size={"80px"}
              style={{ marginLeft: "40%", color: primaryColor[0] }}
            />
          }
          onConfirm={() => {}}
        >
          {!reconfiguration
            ? strings.CompConfigAddedingToBasket
            : strings.CompConfigUpadateingComp}
        </SweetAlert>
      ) : null}
      <div className={`${styles.container}`}>
        <div>
          <Print3D
            productId={print3DProductId}
            onChange={demo ? updateDemoConfiguration : updateConfiguration}
            configuration={configuration}
            options={options}
            threadList={threadList}
            demo={demo}
            reconfiguration={reconfiguration}
            onUpload={(id) => {
              resetState();
              setPrint3DProductId(id);
            }}
            confReset={resetState}
          />
        </div>

        <div>
          <ConfiguratorPriceSummary
            disabled={
              !print3DProductId ||
              print3DProductId === 1 ||
              priceLoading ||
              addingToCart
            }
            isReconfiguration={reconfiguration}
            configuration={configuration}
            previewImage={previewImage}
            dimensions={
              (currentInfo?.bboxDx.toFixed(1) ?? 0) +
                "mm x " +
                (currentInfo?.bboxDy.toFixed(1) ?? 0) +
                "mm x " +
                (currentInfo?.bboxDz.toFixed(1) ?? 0) +
                "mm" ?? ""
            }
            priceLoading={priceLoading}
            loadingImages={previewImageLoading}
            addingToCart={addingToCart}
            prices={prices}
            setQuantity={(quantity: number) =>
              configuration &&
              (demo
                ? updateDemoConfiguration({ ...configuration, quantity })
                : updateConfiguration({ ...configuration, quantity }))
            }
            setComment={(comment: string) =>
              configuration &&
              (demo
                ? updateDemoConfiguration({ ...configuration, comment })
                : updateConfiguration({ ...configuration, comment }))
            }
            handleFinishedComponent={handleFinishedComponent}
            selectedLeadTime={selectedLeadTime}
            setSelectedLeadTime={setSelectedLeadTime}
            leadTimeOptions={totalLeadTimeOptions}
            resetState={resetState}
          />
        </div>
      </div>
    </div>
  );
};

export default Print3DConfigurator;
