import React, { FunctionComponent } from "react";
import styles from "./customCheckbox.module.css";

type PropsType = {
  label?: React.ReactNode;
  checked?: boolean;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  type?: string;
  boxStyle?: string;
};

const CustomCheckbox: FunctionComponent<PropsType> = ({
  label,
  checked,
  required,
  onChange,
  id,
  type,
  boxStyle,
}) => {
  return (
    <div className={`${styles.check_container} `}>
      <label
        // htmlFor="checkbox"
        className={`${styles.checkbox}`}
        id="checkbox-label"
      >
        <input
          type={type}
          // id="checkbox"
          checked={checked}
          onChange={onChange}
          required={required}
        />
        <span className={`${styles.checkmark} ${boxStyle || ""}`}></span>
        {label}
      </label>
    </div>
  );
};
export default CustomCheckbox;
