import { useEffect, useState, useContext } from "react";
import AppStateContext from "contexts/AppStateContext";
import { apiGetOrder } from "util/network/Orders";
import { OrderType } from "types/OrderTypes";
import { useParams } from "react-router";
import { Container } from "@mui/material";
import { stringConverter } from "util/TextUtil";
import OrderSummaryTable from "vaerksComponents/orderConfirmationComponents/OrderSummaryTable";
import Order from "components/Orders/Order";

type OrderConfirmationParams = {
  orderId: string;
};

const OrderConfirmationPage = () => {
  const { strings, token } = useContext(AppStateContext);

  let { orderId } = useParams<OrderConfirmationParams>();

  const [order, setOrder] = useState<OrderType>();

  useEffect(() => {
    if (orderId) {
      apiGetOrder(token, orderId).then((order) => setOrder(order));
    }
  }, [orderId, token]);

  return (
    <Container maxWidth="md" sx={{ marginBottom: "2em" }}>
      {order && <Order order={order} />}
    </Container>
  );
};

export default OrderConfirmationPage;
