import { createStyles } from "@material-ui/styles";
const selectorStyle = (theme) =>
  createStyles({
    selector: {
      fontWeight: "bold !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8em !important",
        //backgroundColor: "red !important",
      },
    },
  });

export default selectorStyle;
