import React, { useContext, useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { Route, Switch, useHistory } from "react-router";
import AppStateContext from "contexts/AppStateContext";
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer.js";
import LoginPage from "views/authPages/LoginPage";
import ResetPassword from "views/authPages/ResetPassword";
import Navbar from "../components/Navbars/Navbar";
import NewFooter from "components/Footer/NewFooter";
import RegisterPage from "views/authPages/RegisterPage";
import styles from "./css/layout.module.css";

const AuthLayout = () => {
  const history = useHistory();

  const { strings, token } = useContext(AppStateContext);
  let { path, url } = useRouteMatch();

  useEffect(() => {
    if (token) {
      history.replace("/ny-komponent");
    }
  });

  return (
    <div className={`${styles.layout__login}`}>
      <Switch>
        <Route exact path={path} component={LoginPage} />
        <Route path={`${path}/reset-password`} component={ResetPassword} />
      </Switch>
    </div>
  );
};
export default AuthLayout;
