import {
  ChangeEvent,
  FunctionComponent,
  useState,
  useContext,
  useMemo,
  useCallback,
  RefObject,
  ReactElement,
  useEffect,
  MutableRefObject,
  forwardRef,
  useReducer,
} from "react";
import { ThreadCardInfo, ThreadType } from "types/ConfigTypes";
import { SelectedThreadsInfoType } from "types/RenderTypes";
import AppStateContext from "contexts/AppStateContext";
import ListReducer, { ListReducerEnum } from "util/ListReducer";
import styles from "./ThreadCard.module.css";
import clsx from "clsx";
import { Button, Card, Collapse, TextField } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import CustomSelect from "components/Select/CustomSelect";
import selectStyles from "../../../components/Select/css/customSelect.module.css";

type IndividualHoleSelectorPropsType = {
  groupIds: number[];
  holeIds: number[];
  threadSelection: ReactElement[][];
  selectedOption: string;
  setSelectedOptions: (option: string, index?: number) => void;
  setHover?: (ids: number[]) => void;
};

const IndividualHoleSelector: FunctionComponent<
  IndividualHoleSelectorPropsType
> = ({
  groupIds,
  holeIds,
  threadSelection,
  selectedOption,
  setSelectedOptions,
  setHover,
}) => {
  const { strings } = useContext(AppStateContext);
  const [isHovered, setHovered] = useState<boolean>(false);

  const changeHover = useCallback(
    (ids: number[]) => {
      setHovered(ids.length > 0);
      if (setHover) {
        setHover(ids);
      }
    },
    [setHover]
  );

  return (
    // <TextField
    //   className={styles.formControlSecondary}
    //   InputProps={{
    //     className: isHovered ? styles.formControlSecondaryHover : "",
    //     classes: {
    //       notchedOutline: isHovered ? styles.formControlSecondaryHover : "",
    //     },
    //   }}
    //   select
    //   value={selectedOption}
    //   onChange={
    //     (event: any) => {
    //       setSelectedOptions(event.target.value);
    //     }
    //     //handleChangeForIndividual(event, hole)
    //   }
    //   SelectProps={{
    //     native: true,
    //   }}
    //   variant="outlined"
    //   size={"small"}
    //   margin={"dense"}
    //   onMouseEnter={(e: any) => {
    //     e.preventDefault();
    //     changeHover && changeHover(holeIds);
    //     //onMouseEnterOne(hole.uuids);
    //     //highlightsOnSingle(hole.uuids);
    //   }}
    //   onMouseLeave={
    //     (e: any) => {
    //       e.preventDefault();
    //       changeHover && changeHover(groupIds);
    //     }
    //     //onMouseLeaveOne(hole.uuids)
    //     //highlightsOn(flatHolesArr)
    //   }
    // >
    //   <option value={"none"}>{`\xa0 ${strings.ThreadCardNoThreads}`}</option>
    //   {threadSelection}
    // </TextField>
    <CustomSelect
      value={selectedOption}
      onChange={(event: any) => {
        setSelectedOptions(event.target.value);
      }}
      stylesSelect={`${selectStyles.select__finish}`}
    >
      {" "}
      <option value={"none"}>{`\xa0 ${strings.ThreadCardNoThreads}`}</option>
      {threadSelection}
    </CustomSelect>
  );
};

type PropsType = {
  data: ThreadCardInfo;
  hover?: boolean;
  setHover?: (ids: number[]) => void;
  onChange?: Function;
  init?: SelectedThreadsInfoType[];
  //selection:
};

const ThreadCard = forwardRef(
  ({ data, hover, setHover, onChange, init }: PropsType, ref) => {
    const { strings } = useContext(AppStateContext);

    const [refresh, setRefresh] = useState<boolean>(true);
    const singleHole = useMemo(() => data.holes.length <= 1, [data.holes]);
    const [expanded, setExpanded] = useState(false);
    const [isHovered, setHovered] = useState<boolean>(false);
    const [selectedOptions, selectedOptionsDispatch] = useReducer(
      ListReducer<SelectedThreadsInfoType>("id"),
      data.holes.map((hol) => {
        // Check if hol faceIds is in the init
        const existing = init?.find((val) => val.id === hol.id);
        if (existing) {
          return { ...hol, name: existing.name };
        }
        return { ...hol, name: "none" };
      })
    );

    const getSelectedOption = useCallback(
      (id: number) => {
        return selectedOptions.find((val) => val.id === id);
      },
      [selectedOptions]
    );

    const updateSelectedOptions = useCallback(
      (option: SelectedThreadsInfoType, id?: number) => {
        if (id !== undefined) {
          // Setting the Thread on one selection
          selectedOptionsDispatch({
            value: option,
            type: ListReducerEnum.UPDATE,
          });
          onChange && onChange(option);
        } else {
          // Setting the same thread to all the selections
          const newSelection = selectedOptions.map((op) => ({
            ...op,
            name: option.name,
          }));
          selectedOptionsDispatch({
            value: newSelection,
            type: ListReducerEnum.SET,
          });
          onChange && onChange(newSelection);
        }
      },
      [onChange, selectedOptions]
    );

    useEffect(() => {
      setHovered(hover ?? false);
    }, [hover]);

    const changeHover = useCallback(
      (ids: number[]) => {
        setHovered(ids.length > 0);
        if (setHover) {
          setHover(ids);
        }
        setRefresh((old) => !old);
      },
      [setHover]
    );

    const threadsByCategories = useMemo(() => {
      const threadsByCategories: { [key: string]: ThreadType[] } = {};
      data.threads.forEach((thread) => {
        if (!threadsByCategories[thread.category]) {
          threadsByCategories[thread.category] = [];
        }
        threadsByCategories[thread.category].push(thread);
      });
      return threadsByCategories;
    }, [data.threads]);

    const threadSelection = useMemo(() => {
      return Object.entries(threadsByCategories).map(([objKey, cat]) => [
        <option disabled={true} key={objKey} value={objKey}>
          {objKey}
        </option>,
        ...cat.map((val, key) => (
          <option key={key} value={val.name}>
            {`\xa0 ${val.name}`}
          </option>
        )),
      ]);
    }, [threadsByCategories]);

    const allSelector = useMemo(() => {
      const mixed =
        selectedOptions.find((val) => val.name !== selectedOptions[0].name) !==
        undefined;
      return (
        // <TextField
        //   id={"Selector"}
        //   className={styles.formControl}
        //   InputProps={{
        //     className: styles.formControlInput,
        //   }}
        //   select
        //   value={mixed ? "mixed" : selectedOptions[0].name}
        //   onChange={(event: ChangeEvent<{ value: string }>) => {
        //     updateSelectedOptions({
        //       id: 0,
        //       faceIds: data.holes.map((hol) => hol.faceIds).flat(),
        //       name: event.target.value,
        //     });
        //   }}
        //   SelectProps={{
        //     native: true,
        //   }}
        //   variant="outlined"
        //   size={"small"}
        // >
        //   {mixed ? (
        //     <option value={"mixed"} disabled={true}>
        //       {`\xa0 ${strings.ThreadCardMixedThreads}`}
        //     </option>
        //   ) : null}
        //   <option
        //     value={"none"}
        //   >{`\xa0 ${strings.ThreadCardNoThreads}`}</option>
        //   {threadSelection}
        // </TextField>
        <CustomSelect
          value={mixed ? "mixed" : selectedOptions[0].name}
          onChange={(event: ChangeEvent<{ value: string }>) => {
            updateSelectedOptions({
              id: 0,
              faceIds: data.holes.map((hol) => hol.faceIds).flat(),
              name: event.target.value,
            });
          }}
          // className={styles.formControl}
          stylesSelect={`${selectStyles.select__finish}`}
        >
          {mixed ? (
            <option value={"mixed"} disabled={true}>
              {`\xa0 ${strings.ThreadCardMixedThreads}`}
            </option>
          ) : null}
          <option
            value={"none"}
          >{`\xa0 ${strings.ThreadCardNoThreads}`}</option>
          {threadSelection}
        </CustomSelect>
      );
    }, [
      selectedOptions,
      strings.ThreadCardMixedThreads,
      strings.ThreadCardNoThreads,
      threadSelection,
      updateSelectedOptions,
      data.holes,
    ]);

    const individualHolesSelectors = useMemo(() => {
      return data.holes.map((hole, key) => (
        <IndividualHoleSelector
          key={key}
          groupIds={data.holes.map((hole) => hole.id)}
          holeIds={hole.faceIds}
          threadSelection={threadSelection}
          selectedOption={getSelectedOption(hole.id)?.name ?? "none"}
          setSelectedOptions={(val: string) => {
            updateSelectedOptions({ ...hole, name: val }, hole.id);
          }}
          setHover={setHover}
        />
      ));
    }, [
      data.holes,
      getSelectedOption,
      setHover,
      threadSelection,
      updateSelectedOptions,
    ]);

    return (
      <div
        className={`${styles.container} ${isHovered && styles.containerHover}`}
        onMouseEnter={(e: any) =>
          changeHover(data.holes.map((hole) => hole.faceIds).flat())
        }
        onMouseLeave={(e: any) => changeHover([])}
      >
        {/* Card div */}
        <div
          //@ts-ignore
          ref={ref}
          className={`${styles.root} ${isHovered && styles.rootHover}`}
        >
          <div className={styles.cardContent}>
            <div className={styles.cardHeader}>
              <div>
                <div className={styles.headerText}>
                  {strings.ThreadCardDiameter}
                </div>
                <div className={styles.headerValue}>
                  {data.diameter.toFixed(1)}
                  {strings.Milimeter}
                </div>
              </div>
              <div>
                <div className={styles.headerText}>
                  {strings.ThreadCardDepth}
                </div>
                <div className={styles.headerValue}>
                  {data.depth.toFixed(1)}
                  {strings.Milimeter}
                </div>
              </div>
              <div>
                <div className={styles.headerText}>
                  {strings.ThreadCardQuantity}
                </div>
                <div className={styles.headerValue}>
                  {0}/{data.holes.length}
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: "1rem", color: "black" }}>
              {allSelector}
            </div>
          </div>
          {!singleHole && (
            <>
              <div
                className={styles.expandContainer}
                onClick={() => setExpanded(!expanded)}
              >
                <Button
                  aria-expanded={expanded}
                  aria-label="show more"
                  // color={"primary"}
                  startIcon={
                    <ExpandMore
                      className={clsx(styles.expand, {
                        [styles.expandOpen]: expanded,
                      })}
                    />
                  }
                >
                  {strings.ThreadCardSpecify}
                </Button>
              </div>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={styles.formControlSecondaryContainer}>
                  {individualHolesSelectors}
                </div>
              </Collapse>
            </>
          )}
        </div>
      </div>
    );
  }
);
export default ThreadCard;
