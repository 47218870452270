import React, { useContext } from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  REGEX_ADDRESS,
  REGEX_CITY,
  REGEX_CVRNUMBER,
  REGEX_EMAIL,
  REGEX_FIRMNAME,
  REGEX_NAME,
  REGEX_PHONENUMBER,
  REGEX_ZIPCODE,
  REGEX_ADDRESSNUMBER,
} from "util/RegExUtil";
import AppStateContext from "contexts/AppStateContext";
import styles from "../checkoutComponents/styles/newInvoiceInfo.module.css";
import stylesInput from "../../components/Input/customInput.module.css";
import CustomInput from "components/Input/CustomInput";
import CustomCheckbox from "components/Checkbox/CustomCheckbox";

type PropsType = {
  handleSubmit: any;
  cvrState: [string, React.Dispatch<React.SetStateAction<string>>];
  sameAsBillingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  companyState: [string, React.Dispatch<React.SetStateAction<string>>];
  firstNameState: [string, React.Dispatch<React.SetStateAction<string>>];
  lastNameState: [string, React.Dispatch<React.SetStateAction<string>>];
  addressNameState: [string, React.Dispatch<React.SetStateAction<string>>];
  zipCodeState: [string, React.Dispatch<React.SetStateAction<string>>];
  cityState: [string, React.Dispatch<React.SetStateAction<string>>];
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  invoiceEmailState: [string, React.Dispatch<React.SetStateAction<string>>];
  phoneState: [string, React.Dispatch<React.SetStateAction<string>>];
  POState: [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>
  ];
  EANState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraCVRState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraFirstNameState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraLastNameState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraAddressNameState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraCompanyState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraZipCodeState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraCityState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraEmailState: [string, React.Dispatch<React.SetStateAction<string>>];
  extraPhoneState: [string, React.Dispatch<React.SetStateAction<string>>];
  paymentMethod?: string;
};

const InvoiceInfo: FunctionComponent<PropsType> = ({
  handleSubmit,
  cvrState,
  sameAsBillingState,
  companyState,
  firstNameState,
  lastNameState,
  addressNameState,
  zipCodeState,
  cityState,
  emailState,
  invoiceEmailState,
  phoneState,
  POState,
  extraCVRState,
  extraFirstNameState,
  extraLastNameState,
  extraAddressNameState,
  extraCompanyState,
  extraZipCodeState,
  extraCityState,
  extraEmailState,
  extraPhoneState,
  paymentMethod,
  EANState,
}) => {
  const [cvr, setCvr] = cvrState;
  const [sameAsBilling, setSameAsBilling] = sameAsBillingState;
  const [company, setCompany] = companyState;
  const [firstName, setFirstName] = firstNameState;
  const [lastName, setLastName] = lastNameState;
  const [addressName, setAddressName] = addressNameState;
  const [zipCode, setZipCode] = zipCodeState;
  const [city, setCity] = cityState;
  const [email, setEmail] = emailState;
  const [phone, setPhone] = phoneState;
  const [PO, setPO] = POState;
  const [invoiceEmail, setInvoiceEmail] = invoiceEmailState;
  const [extraCVR, setExtraCVR] = extraCVRState;
  const [extraFirstName, setExtraFirstName] = extraFirstNameState;
  const [extraLastName, setExtraLastName] = extraLastNameState;
  const [extraAddressName, setExtraAddressName] = extraAddressNameState;
  const [extraCompany, setExtraCompany] = extraCompanyState;
  const [extraZipCode, setExtraZipCode] = extraZipCodeState;
  const [extraCity, setExtraCity] = extraCityState;
  const [extraEmail, setExtraEmail] = extraEmailState;
  const [extraPhone, setExtraPhone] = extraPhoneState;
  const [EAN, setEAN] = EANState;

  const [inputErr, setInputErr] = useState(false);
  const { strings } = useContext(AppStateContext);
  const formErr = useCallback(() => {
    return (
      (company.match(REGEX_FIRMNAME) || company === "") &&
      (firstName.match(REGEX_NAME) || firstName === "") &&
      (lastName.match(REGEX_NAME) || lastName === "") &&
      (phone.match(REGEX_PHONENUMBER) || phone === "") &&
      (email.match(REGEX_EMAIL) || email === "") &&
      addressName.length > 0 &&
      (zipCode.match(REGEX_ZIPCODE) || zipCode === "") &&
      (city.match(REGEX_CITY) || city === "")
    );
  }, [addressName, city, company, email, firstName, lastName, phone, zipCode]);

  useEffect(() => {
    if (formErr()) {
      setInputErr(false);
    } else {
      setInputErr(true);
    }
    if (addressName === "Null") {
      setAddressName("");
    }
    if (extraPhone === "Null") {
      setExtraPhone("");
    }
  }, [formErr]);

  return (
    <div
      style={{
        boxShadow: "1px 1px 4px rgba(0,0,0,0.3)",
        background: "var(--background-dark)",
      }}
    >
      {/* <h4>{strings.InvoiceInfoTitel}</h4> */}
      <div>
        <div style={{ marginLeft: "1em" }}>
          <h3
            style={{ fontWeight: "bold", paddingTop: "1rem", fontSize: "2rem" }}
          >
            {strings.InvoiceInfoBillingAddress}
          </h3>
          <CustomCheckbox
            checked={!sameAsBilling}
            onChange={(e) => setSameAsBilling(!e.currentTarget.checked)}
            type={"checkbox"}
            label={strings.InvoiceInfoCheckboxText}
          />
          <form
            onSubmit={(e: any) => {
              console.log("Submit");
              handleSubmit(e);
            }}
            id={"addressSubmit"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: "70%",
                  // float: "",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ paddingRight: "1rem" }}>
                    <CustomInput
                      label={strings.FirmNameInput}
                      type="text"
                      placeholder={strings.FirmNameInput}
                      id="firmname"
                      value={company}
                      onChange={(e: any) => {
                        // if (e.target.value.length < 45) {
                        setCompany(e.target.value);
                        // }
                      }}
                      error={!REGEX_FIRMNAME.test(company) && company !== ""}
                      // error={company.length === 0}
                      required={true}
                      pattern={"[\\w ]{1,45}"}
                      inputStyle={`${stylesInput.input__two} ${stylesInput.input__validation}`}
                    />
                    {/* {company.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingCompany}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div>
                    <CustomInput
                      label={strings.CVRInput}
                      id="cvr"
                      required={true}
                      type="text"
                      value={cvr}
                      pattern="[0-9]*"
                      placeholder={strings.CVRInput}
                      error={cvr.length !== 0 && !REGEX_CVRNUMBER.test(cvr)}
                      onChange={(e: any) => {
                        if (e.target.value.length < 9) {
                          setCvr(e.target.value);
                        }
                      }}
                      inputStyle={`${stylesInput.input__two}`}
                    />
                    {/* {cvr.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingCVR}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ paddingRight: "1rem" }}>
                    <CustomInput
                      label={strings.FirstName}
                      placeholder={strings.FirstName}
                      id="fornavn"
                      required={true}
                      type="text"
                      value={firstName}
                      error={firstName !== "" && !firstName.match(REGEX_NAME)}
                      onChange={(e: any) => setFirstName(e.target.value)}
                      maxLength={30}
                      inputStyle={`${stylesInput.input__two} ${stylesInput.input__validation}`}
                      pattern={REGEX_NAME.source}
                    />
                    {/* {firstName.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingFirstName}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div>
                    <CustomInput
                      label={strings.LastName}
                      id="lastName"
                      placeholder={strings.LastName}
                      type="text"
                      value={lastName}
                      required={true}
                      error={lastName !== "" && !REGEX_NAME.test(lastName)}
                      onChange={(e: any) => setLastName(e.target.value)}
                      maxLength={30}
                      pattern={REGEX_NAME.source}
                      inputStyle={`${stylesInput.input__two} ${stylesInput.input__validation}`}
                    />
                    {/* {lastName.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingLastName}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ paddingRight: "1rem" }}>
                    <CustomInput
                      label={strings.RoadInput}
                      placeholder={strings.RoadInput}
                      id="vej"
                      required={true}
                      type="text"
                      value={addressName}
                      error={
                        addressName !== "" && !REGEX_ADDRESS.test(addressName)
                      }
                      onChange={(e: any) => setAddressName(e.target.value)}
                      maxLength={50}
                      inputStyle={`${stylesInput.input__three}`}
                    />
                    {/* {addressName.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingRoad}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div>
                    <CustomInput
                      label={strings.CityInput}
                      placeholder={strings.CityInput}
                      id="city"
                      required={true}
                      type="text"
                      value={city}
                      error={city !== "" && !REGEX_CITY.test(city)}
                      onChange={(e: any) => setCity(e.target.value)}
                      maxLength={50}
                      inputStyle={`${stylesInput.input__three}`}
                    />
                    {/* {city.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingCity}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div style={{ paddingLeft: "1rem" }}>
                    <CustomInput
                      label={strings.ZipInput}
                      placeholder={strings.ZipInput}
                      id="postnumber"
                      required={true}
                      value={zipCode}
                      type="number"
                      pattern="[0-9]*"
                      error={zipCode !== "" && !REGEX_ZIPCODE.test(zipCode)}
                      onChange={(e: any) => {
                        if (e.target.value.length < 5) {
                          setZipCode(e.target.value);
                        }
                      }}
                      className={`${styles.input_active}`}
                      inputStyle={`${stylesInput.input__three}`}
                    />
                    {/* {zipCode.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingZip}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <CustomInput
                      label={strings.Email}
                      placeholder={strings.Email}
                      id="email"
                      required={true}
                      type="email"
                      value={email}
                      error={email !== "" && !REGEX_EMAIL.test(email)}
                      onChange={(e: any) => setEmail(e.target.value)}
                      inputStyle={`${stylesInput.input__two}`}
                    />
                    {/* {(inputErr && !email.includes("@")) ||
                    (inputErr && email.length === 0) ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingEmail}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div style={{ paddingLeft: "1rem" }}>
                    <CustomInput
                      label={strings.PhoneInput}
                      placeholder={strings.PhoneInput}
                      id="phoneNum"
                      required={true}
                      value={phone}
                      type="number"
                      pattern="[0-9]*"
                      error={
                        phone.length !== 0 && !REGEX_PHONENUMBER.test(phone)
                      }
                      onChange={(e: any) => {
                        if (e.target.value.length < 9) {
                          setPhone(e.target.value);
                        }
                      }}
                      className={`${styles.input_active}`}
                      inputStyle={`${stylesInput.input__two}`}
                    />
                    {/* {phone.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingPhone}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: "2rem",
                  }}
                >
                  <div style={{ paddingRight: "1rem" }}>
                    <CustomInput
                      label={strings.EmailFaktura}
                      placeholder={strings.EmailFaktura}
                      id="invoicEmail"
                      type="email"
                      value={invoiceEmail}
                      error={
                        invoiceEmail !== "" && !REGEX_EMAIL.test(invoiceEmail)
                      }
                      onChange={(e: any) => {
                        setInvoiceEmail(e.target.value);
                      }}
                      inputStyle={`${stylesInput.input__two}`}
                    />
                    {/* {invoiceEmail.length <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingFakturaEmail}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div>
                    <CustomInput
                      label={strings.PONumber}
                      placeholder={strings.PONumber}
                      id="PO"
                      type="text"
                      value={PO}
                      onChange={(e: any) => {
                        setPO(e.target.value.trim());
                      }}
                      inputStyle={`${stylesInput.input__two}`}
                    />
                    {/* {inputErr PO <= 0 ? (
                      <p className={`${stylesInput.input__validation_text}`}>
                        {strings.MissingPhone}
                      </p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            {!sameAsBilling ? (
              <div>
                <h3 style={{ fontWeight: "bold", fontSize: "2rem" }}>
                  {strings.InvoiceInfoDeliveryAddress}
                </h3>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "70%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ paddingRight: "1rem" }}>
                        <CustomInput
                          label={strings.FirmNameInput}
                          type="text"
                          placeholder={strings.FirmNameInput}
                          id="firmName"
                          value={extraCompany}
                          onChange={(e: any) => {
                            if (e.target.value.length < 45) {
                              setExtraCompany(e.target.value);
                            }
                          }}
                          error={
                            !REGEX_FIRMNAME.test(extraCompany) &&
                            extraCompany !== ""
                          }
                          required={true}
                          pattern={REGEX_FIRMNAME.source}
                          inputStyle={`${stylesInput.input__two} ${stylesInput.input__validation}`}
                        />
                        {/* {extraCompany.length >= 1 && extraCompany.length < 2 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingCompany}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div>
                        <CustomInput
                          label={strings.CVRInput}
                          id="cvr"
                          required={true}
                          type="text"
                          value={extraCVR}
                          pattern="[0-9]*"
                          placeholder={strings.CVRInput}
                          error={
                            extraCVR.length !== 0 &&
                            !REGEX_CVRNUMBER.test(extraCVR)
                          }
                          onChange={(e: any) => {
                            if (e.target.value.length < 9) {
                              setExtraCVR(e.target.value);
                            }
                          }}
                          inputStyle={`${stylesInput.input__two}`}
                        />
                        {/* {extraCVR.length >= 1 && extraCVR.length < 8 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingCVR}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ paddingRight: "1rem" }}>
                        <CustomInput
                          label={strings.FirstName}
                          placeholder={strings.FirstName}
                          id="fornavn"
                          required={true}
                          type="text"
                          value={extraFirstName}
                          error={
                            true ||
                            (extraFirstName !== "" &&
                              !REGEX_NAME.test(extraFirstName))
                          }
                          // errorText={strings.MissingFirstName}
                          onChange={(e: any) =>
                            setExtraFirstName(e.target.value)
                          }
                          maxLength={30}
                          inputStyle={`${stylesInput.input__two}`}
                        />
                        {/* {extraFirstName.length >= 1 &&
                        extraFirstName.length < 2 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingFirstName}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div>
                        <CustomInput
                          label={strings.LastName}
                          id="lastName"
                          placeholder={strings.LastName}
                          type="text"
                          value={extraLastName}
                          required={true}
                          error={
                            extraLastName !== "" &&
                            !REGEX_NAME.test(extraLastName)
                          }
                          onChange={(e: any) =>
                            setExtraLastName(e.target.value)
                          }
                          maxLength={30}
                          inputStyle={`${stylesInput.input__two}`}
                        />
                        {/* {extraLastName.length >= 1 &&
                        extraLastName.length < 2 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingLastName}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ paddingRight: "1rem" }}>
                        <CustomInput
                          label={strings.RoadInput}
                          placeholder={strings.RoadInput}
                          id="address"
                          required={true}
                          type="text"
                          value={extraAddressName}
                          errorText={strings.MissingRoad}
                          error={
                            extraAddressName !== "" &&
                            !REGEX_ADDRESS.test(extraAddressName)
                          }
                          onChange={(e: any) =>
                            setExtraAddressName(e.target.value)
                          }
                          maxLength={50}
                          inputStyle={`${stylesInput.input__three}`}
                        />
                        {/* {extraAddressName.length >= 1 &&
                        extraAddressName.length < 2 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingRoad}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div style={{ paddingRight: "1rem" }}>
                        <CustomInput
                          label={strings.CityInput}
                          placeholder={strings.CityInput}
                          id="city"
                          required={true}
                          type="text"
                          value={extraCity}
                          error={
                            extraCity !== "" && !REGEX_CITY.test(extraCity)
                          }
                          errorText={strings.MissingCity}
                          onChange={(e: any) => setExtraCity(e.target.value)}
                          maxLength={50}
                          inputStyle={`${stylesInput.input__three}`}
                        />
                        {/* {extraCity.length >= 1 && extraCity.length < 2 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingCity}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div>
                        <CustomInput
                          label={strings.ZipInput}
                          placeholder={strings.ZipInput}
                          id="postNumber"
                          required={true}
                          value={extraZipCode}
                          type="number"
                          pattern="[0-9]*"
                          error={
                            extraZipCode !== "" &&
                            !REGEX_ZIPCODE.test(extraZipCode)
                          }
                          errorText={strings.MissingZip}
                          onChange={(e: any) => {
                            if (e.target.value.length < 5) {
                              setExtraZipCode(e.target.value);
                            }
                          }}
                          className={`${styles.input_active}`}
                          inputStyle={`${stylesInput.input__three}`}
                        />
                        {/* {extraZipCode.length >= 1 && extraZipCode.length < 2 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingZip}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingBottom: "2rem",
                      }}
                    >
                      <div style={{ paddingRight: "1rem" }}>
                        {" "}
                        <CustomInput
                          label={strings.Email}
                          placeholder={strings.Email}
                          id="email"
                          required={true}
                          type="email"
                          value={extraEmail}
                          error={
                            extraEmail !== "" && !REGEX_EMAIL.test(extraEmail)
                          }
                          errorText={strings.MissingEmail}
                          onChange={(e: any) => setExtraEmail(e.target.value)}
                          inputStyle={`${stylesInput.input__two}`}
                        />
                        {/* {(inputErr && !extraEmail.includes("@")) ||
                        (inputErr && extraEmail.length === 0) ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingEmail}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div>
                        <CustomInput
                          label={strings.PhoneInput}
                          placeholder={strings.PhoneInput}
                          id="phoneNum"
                          value={extraPhone}
                          required={true}
                          type="number"
                          pattern="[0-9]*"
                          error={
                            extraPhone.length !== 0 &&
                            !REGEX_PHONENUMBER.test(extraPhone)
                          }
                          errorText={strings.MissingPhone}
                          onChange={(e: any) => {
                            if (e.target.value.length < 9) {
                              setExtraPhone(e.target.value);
                            }
                          }}
                          className={`${styles.input_active}`}
                          inputStyle={`${stylesInput.input__two} `}
                        />
                        {/* {extraPhone.length >= 1 && extraPhone.length < 8 ? (
                          <p
                            className={`${stylesInput.input__validation_text}`}
                          >
                            {strings.MissingPhone}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};
export default InvoiceInfo;
