import React, { useContext } from "react";
import cx from "classnames";
import PropTypes, { string } from "prop-types";
import { NavLink, useHistory, useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/creativeTim/components/homeNavbarStyle.js";
import useWindowDimensions from "../../vaerksComponents/useWindowDimensions";
import AppStateContext from "contexts/AppStateContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles: any = makeStyles(styles as any);

const AuthNavbar = (props: any) => {
  const { strings } = useContext(AppStateContext);
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return window.location.href.indexOf(routeName) > -1;
  };
  const classes = useStyles();
  const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL ?? "/";

  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button
            //@ts-ignore
            onClick={() => (window.location.href = FRONTEND_URL)}
            className={classes.centerTitle}
            disableElevation={true}
            disableFocusRipple={true}
            disableRipple={true}
            color="white"
            simple={true}
          >
            <span className={classes.logoNEMtest}>Easy</span>
            <span className={classes.logoCNCtest}>Partz</span>
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

AuthNavbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "white",
  ]),
  brandText: PropTypes.string,
};

export default AuthNavbar;
