import { FunctionComponent, ReactElement, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./UploadDropzone.module.css";

type PropsType = {
  onSubmit: (files: File[]) => void;
  fileType?: string[] | string;
  children?: ReactElement;
  disabled?: boolean;
  //fileMax: number;
};

export const UploadDropzone: FunctionComponent<PropsType> = ({
  onSubmit,
  fileType,
  children,
  disabled,
  // fileMax,
}) => {
  const onDropRejected = useCallback((rejectedFile) => {}, []);

  const {
    isFocused,
    isDragReject,
    isDragAccept,
    isFileDialogActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: fileType,
    maxSize: 10000000,
    //maxFiles: fileMax,
    onDropAccepted: onSubmit,
    onDropRejected,
    disabled: disabled,
  });

  return (
    <div
      {...getRootProps()}
      className={`${styles.container} ${
        (isFocused || isFileDialogActive || isDragReject) && styles.focused
      } ${isDragAccept && styles.accept}`}
      style={{ flexDirection: "column", marginTop: "1em", cursor: "pointer" }}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
