import React, { FunctionComponent } from "react";
import styles from "./customInput.module.css";

type PropsType = {
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string | boolean;
  value?: string;
  id?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorText?: string;
  className?: string;
  autoComplete?: string;
  maxLength?: number;
  style?: React.CSSProperties | undefined;
  pattern?: string;
  required?: boolean;
  autoFocus?: boolean;
  inputStyle?: string;
};

const CustomInput: FunctionComponent<PropsType> = ({
  label,
  type,
  placeholder,
  error,
  value,
  id,
  onChange,
  errorText,
  className,
  autoComplete,
  maxLength,
  style,
  pattern,
  required,
  autoFocus,
  inputStyle,
}) => {
  const inputAutoComplete =
    autoComplete === "off" ? "new-password" : autoComplete;

  return (
    <div className={`${styles.input__container}`}>
      <label
        htmlFor={id}
        className={`${styles.label_email} ${styles.label_password} ${
          inputStyle || ""
        }`}
      >
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        id={id}
        name={id}
        onChange={onChange}
        autoComplete={inputAutoComplete}
        className={className}
        maxLength={maxLength}
        style={style}
        pattern={pattern}
        autoFocus={autoFocus}
        required={required}
      />
      {error && (
        <span className={`${styles.input__validation_text}`}>{errorText}</span>
      )}
    </div>
  );
};
export default CustomInput;
