import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import CardHeader from "../../components/Card/CardHeader";
import CardText from "../../components/Card/CardText";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/homePagesStyles/loginPageStyle";
import buttonStyles from "styles/components/Buttons.module.css";
import CustomInput from "../../components/CustomInput/CustomInput";
import { REGEX_EMAIL } from "util/RegExUtil";
import AppStateContext from "contexts/AppStateContext";
import { useHistory, useParams } from "react-router";
import { Button, Grid, InputAdornment, LinearProgress } from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import { apiCheckPasswordToken, apiResetPassword } from "util/network/Auth";
import useQuery from "hooks/UseQuery";
import { FetchError } from "exceptions/exceptions";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles: any = makeStyles(styles);

const ResetPassword = () => {
  const query = useQuery();

  const token = useMemo(() => {
    return query.get("token");
  }, [query]);

  const id = useMemo(() => {
    return query.get("id");
  }, [query]);

  const [showAlert, setShowAlert] = useState(false);

  const { strings } = useContext(AppStateContext);

  const history = useHistory();

  const [magentoEmail, setMagentoEmail] = useState("");
  const [magentoPassword, setMagentoPassword] = useState("");
  const [magentoPasswordConfirm, setMagentoPasswordConfirm] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [loginText, setLoginText] = useState<string>();
  const [alertText, setAlertText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (token && id) {
      apiCheckPasswordToken(token, id).catch((err) => {
        setAlertText(strings.InvalidPasswordToken);
        setError(true);
        setShowAlert(true);
      });
    }
  }, [history, id, strings.InvalidPasswordToken, token]);

  const onConfirm = useCallback(() => {
    if (token) {
      if (magentoPassword !== magentoPasswordConfirm) {
        setError(true);
        setLoginText(strings.ConfirmPasswordDifferent);
      } else {
        setLoading(true);
        apiResetPassword(magentoEmail, magentoPassword, token)
          .then((res) => {
            setLoading(false);
            setAlertText(strings.PasswordChangedSuccess);
            setShowAlert(true);
          })
          .catch((e: FetchError) => {
            setLoading(false);
            setAlertText(strings.PasswordChangedError);
            setShowAlert(true);
          });
      }
    }
  }, [
    magentoEmail,
    magentoPassword,
    magentoPasswordConfirm,
    strings.ConfirmPasswordDifferent,
    strings.PasswordChangedError,
    strings.PasswordChangedSuccess,
    token,
  ]);

  return (
    <div style={{ display: "flex", zIndex: 100 }}>
      {showAlert && (
        <SweetAlert
          success={!error}
          error={error}
          title={alertText}
          allowEscape={false}
          onConfirm={() => history.replace("/auth")}
          customButtons={
            <Button
              variant="contained"
              color="info"
              onClick={() => history.replace("/auth")}
            >
              <b>{strings.ButtonOk}</b>
            </Button>
          }
        ></SweetAlert>
      )}
      <Card>
        <CardHeader>
          <CardText color="info" className={classes.cardHeader}>
            <h3 className={classes.cardTitle} style={{ fontSize: "1.5em" }}>
              {strings.ResetPassword}
            </h3>
          </CardText>
        </CardHeader>
        <CardBody>
          <div style={{ paddingRight: "2em", paddingLeft: "2em" }}>
            {loginText}
          </div>
          {loading ? <LinearProgress /> : null}
          <CustomInput
            labelText={strings.Email}
            id="resetPasswordEmail"
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              type: "email",
              value: magentoEmail,
              onChange: (e: any) => setMagentoEmail(e.target.value),
              error: !REGEX_EMAIL.test(magentoEmail) && magentoEmail !== "",
            }}
          />
          <CustomInput
            labelText={strings.Password}
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Lock className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              type: "password",
              autoComplete: "off",
              value: magentoPassword,
              onChange: (e: any) => setMagentoPassword(e.target.value),
              error: error,
            }}
          />
          <CustomInput
            labelText={strings.ConfirmPassword}
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Lock className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              type: "password",
              autoComplete: "off",
              value: magentoPasswordConfirm,
              onChange: (e: any) => setMagentoPasswordConfirm(e.target.value),
              error: error,
            }}
          />
          <Button
            variant={"outlined"}
            sx={{ float: "right", fontWeight: "bold" }}
            color="primary"
            type={"submit"}
            onClick={onConfirm}
          >
            {strings.Confirm}
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default ResetPassword;
