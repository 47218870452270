import React, { useState } from "react";
import styles from "./customMultiSelect.module.css";
import { ReactComponent as ArrowDown } from "../../assets/svg/dashboard/dropDownArrow.svg";

type OptionType = {
  value: string;
  label: string;
  group: string;
  disabled?: boolean;
};

type PropsType = {
  options: OptionType[];
  onChange: (selectedValues: string[]) => void;
  value?: string[];
  disabled?: boolean;
};

const CustomMultiSelect: React.FunctionComponent<PropsType> = ({
  options,
  onChange,
  value = [],
  disabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOptionClick = (option: OptionType) => {
    if (value.includes(option.value)) {
      onChange(value.filter((v) => v !== option.value));
    } else {
      const newValues = value
        .filter((v) => {
          const otherOption = options.find((o) => o.value === v);
          return otherOption && otherOption.group !== option.group;
        })
        .concat(option.value);
      onChange(newValues);
    }
  };

  return (
    <div className={styles.dropdown}>
      <button
        className={styles.dropdownButton}
        disabled={disabled}
        onClick={() => setOpen(!open)}
      >
        {value.join(", ")}
        <span className={styles.dropdownArrow}>
          <ArrowDown />
        </span>
      </button>
      {open && (
        <div className={styles.dropdownContent}>
          {options.map((option) => (
            <div
              onClick={() => !option.disabled && handleOptionClick(option)}
              style={{ display: "flex", alignItems: "center" }}
            >
              {!option.disabled && (
                <input
                  type="checkbox"
                  checked={value.includes(option.value)}
                  readOnly
                />
              )}

              <span style={{ paddingLeft: "0.5rem" }}>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
