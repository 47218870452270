import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FunctionComponent, useEffect, useMemo } from "react";
import { createContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { LanguageFileType } from "types/Common";
import { UserType } from "types/users/UserTypes";
import da_dk from "util/LanguageUtil/da_dk";
import en_gb from "util/LanguageUtil/en_gb";
import { apiGetUser } from "util/network/Users";

type AppStateContextType = {
  token: string;
  setToken: Function;
  customerEmail: string;
  customer?: UserType;
  strings: LanguageFileType;
  updateLanguage: Function;
  showLoginModal: boolean;
  setShowLoginModal: Function;
  logUserOut: Function;
};

const contextDefaultValues: AppStateContextType = {
  token: "",
  setToken: () => {},
  customerEmail: " ",
  customer: undefined,
  strings: da_dk,
  updateLanguage: () => {},
  showLoginModal: false,
  setShowLoginModal: () => {},
  logUserOut: () => {},
};

const AppStateContext =
  createContext<AppStateContextType>(contextDefaultValues);

export const AppStateProvider: FunctionComponent = ({ children }) => {
  const [cookies, removeCookie] = useCookies();
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [customer, setCustomer] = useState<UserType>();
  const [token, setToken] = useState<string>(
    localStorage.getItem("token") ?? ""
  );
  const history = useHistory();
  //const [isGuest, setIsGuest] = useState<boolean>(true);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ?? "dk"
  );
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const { pushInstruction } = useMatomo();

  // useEffect(() => {
  //   setToken(localStorage.getItem("token"));
  // }, []);

  const logUserOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    setToken("");
    //history.replace("/auth");
    //return dispatch(setUserLogout());
  };

  useEffect(() => {
    if (token && token != "") {
      apiGetUser(token)
        .then((res) => {
          if (res.email) {
            pushInstruction("setUserId", res.email);
            setCustomer(res);
            setCustomerEmail(res.email);
          }
        })
        .catch((err) => {
          logUserOut();
        });
    }
  }, [token]);

  const strings = useMemo(() => {
    switch (language) {
      case "dk":
        return da_dk;
      case "gb":
        return en_gb;
      default:
        return da_dk;
    }
  }, [language]);

  useEffect(() => {
    localStorage.setItem("language", language);
    if (cookies.lan) {
      setLanguage(cookies.lan);
      removeCookie("lan", cookies.lan);
    }
  }, [cookies.lan, language, removeCookie]);

  const updateLanguage = (choice: string) => {
    setLanguage(choice);
  };

  return (
    <AppStateContext.Provider
      value={{
        token,
        setToken,
        customerEmail,
        customer,
        strings,
        updateLanguage,
        showLoginModal,
        setShowLoginModal,
        logUserOut,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
export default AppStateContext;
