import { FunctionComponent, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/vaerksComponents/selectionCardStyle";
import AppStateContext from "contexts/AppStateContext";
import { FinishDataType } from "types/NetworkTypes";
import CustomSelect from "components/Select/CustomSelect";
import stylesSelect from "../../../components/Select/css/customSelect.module.css";
import { ReactComponent as IconDown } from "../../../assets/svg/dashboard/extentBlackArrow.svg";
import { ReactComponent as IconUp } from "../../../assets/svg/dashboard/extentBlackArrowUp.svg";
import { ReactComponent as IconClose } from "../../../assets/svg/dashboard/deleteSmall.svg";
//@ts-ignore
import { SwipeableViewsContext } from "react-swipeable-views";
const useStyles: any = makeStyles(styles);

type PropsType = {
  finishTitle: string;
  finishDescription: string;
  finishImage: string;
  variants: FinishDataType[];
  value?: string;
  onClick: (fin: string) => void;
  disabled?: boolean;
};

const FinishCard: FunctionComponent<PropsType> = ({
  finishTitle,
  finishDescription,
  finishImage,
  variants,
  value,
  onClick,
  disabled,
}) => {
  const { strings } = useContext(AppStateContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showText, setShowText] = useState(false);

  //@ts-ignore
  const { slideUpdateHeight } = useContext(SwipeableViewsContext);

  useEffect(() => {
    slideUpdateHeight();
  }, [showText]);

  const classes = useStyles();
  return (
    <div
      style={{
        width: "180px",
        maxHeight: "60vh",
        minHeight: "30vh",
        //height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      className={
        disabled
          ? classes.cardDisabled
          : value
          ? classes.cardSelected
          : classes.cardNotSelected
      }
      onClick={(e) => {
        if (!isOpen && !disabled) {
          if (!value) {
            onClick(variants[0].id);
          } else {
            onClick(value);
          }
        }
      }}
    >
      <div>
        <div style={{ position: "relative", display: "inline-block" }}>
          {value ? (
            <i
              style={{
                position: "absolute",
                top: "5px",
                right: "6px",
              }}
            >
              <IconClose />
            </i>
          ) : (
            ""
          )}
          <img
            style={{ height: value ? 117 : 120 }}
            src={finishImage}
            alt={finishTitle}
          />
        </div>
        {/* <IconClose /> */}
        <div style={{ padding: "0.5rem" }}>
          <p
            style={{
              fontWeight: value ? "bold" : "normal",
              fontSize: "1rem",
              minHeight: "calc(2rem + 10px)",
            }}
          >
            {variants.length == 1
              ? `${variants[0][strings.code].finishTitle}`
              : finishTitle?.toUpperCase()}
          </p>
          <span>
            {variants.length > 1 && (
              <CustomSelect
                disabled={disabled}
                value={value ?? variants[0].id}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log("Selected: " + e.target.value);
                  onClick(e.target.value);
                }}
                onClick={(e) => e.stopPropagation()}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                stylesSelect={`${stylesSelect.select__finish}`}
              >
                {variants.map((variant) => (
                  <option value={variant.id}>
                    {variant[strings.code].finishTitle}
                  </option>
                ))}
              </CustomSelect>
            )}
          </span>
        </div>
      </div>
      <div>
        <p
          onClick={(e) => {
            e.stopPropagation();
            setShowText((old) => !old);
          }}
          style={{
            fontWeight: "400",
            fontSize: "0.8rem",
            display: "flex",
            cursor: "pointer",
            padding: "0.5rem 0.5rem 0 0.5rem",
            fontStyle: "italic",
          }}
        >
          Why {finishTitle}?
          {showText ? (
            <IconUp style={{ fill: value ? "#fff" : "#000" }} />
          ) : (
            <IconDown style={{ fill: value ? "#fff" : "#000" }} />
          )}
        </p>
        {showText ? (
          <p style={{ padding: "0 0.5rem 0.5rem 0.5rem", fontWeight: "400" }}>
            {finishDescription}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default FinishCard;
