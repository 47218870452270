import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import PostNord from "assets/img/postnord_erhverv.jpg";
import EasyPartz from "assets/img/easypartz.jpg";
import TNT from "assets/img/TNT.png";
import * as ReactGA from "react-ga";
import AppStateContext from "contexts/AppStateContext";
import {
  REGEX_CITY,
  REGEX_CVRNUMBER,
  REGEX_EMAIL,
  REGEX_FIRMNAME,
  REGEX_NAME,
  REGEX_PHONENUMBER,
  REGEX_ZIPCODE,
  REGEX_EAN,
} from "util/RegExUtil";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FetchError } from "exceptions/exceptions";
import {
  apiConfirmOrder,
  apiGetShippingMethods,
  apiUpdateCartInfo,
} from "util/network/Carts";
import { apiCreatePayment } from "util/network/Payment";
import { apiGetUser, apiSubscribeNewsletter } from "util/network/Users";
import { useHistory } from "react-router";
import EcommerceContext from "contexts/EcommerceContext";
import { ShippingMethodType } from "types/CartTypes";
import SweetAlert from "react-bootstrap-sweetalert";
import PaymentMethods from "./PaymentMethods";
import ShippingRow from "./ShippingRow";
import TermsAndConditions from "../TermsAndConditions";
import OrderDetails from "./OrderDetails";
import InvoiceInfo from "./InvoiceInfo";
import styles from "../checkoutComponents/styles/payment.module.css";
import buttonStyles from "../../components/Buttons/customButton.module.css";
import CustomCheckbox from "components/Checkbox/CustomCheckbox";
import CustomButton from "components/Buttons/CustomButton";
import { Button, CircularProgress, Grid } from "@mui/material";

type PropsType = {
  cancelPayment: Function;
  invoiceSubtotal: number;
  invoiceTaxes: number;
  invoiceShipping: number;
  invoiceTotal: number;
  onOrdered: Function;
};

const Payment: FunctionComponent<PropsType> = ({
  cancelPayment,
  invoiceSubtotal,
  invoiceTaxes,
  invoiceShipping,
  invoiceTotal,
  onOrdered,
}) => {
  const { trackEvent } = useMatomo();

  const {
    cartId,
    cartItems,
    shippingMethod,
    setShippingMethod,
    clearState,
    leadtimeOption,
    leadTimeOptions,
  } = useContext(EcommerceContext);

  const DEBUG = process.env.NODE_ENV === "development";

  const [paymentMethod, setPaymentMethod] = useState<string>("banktransfer");
  const history = useHistory();

  const { strings, token, customerEmail } = useContext(AppStateContext);
  const [userId, setUserId] = useState<number>();
  const [company, setCompany] = useState(DEBUG ? "LemonadeImperiet" : "");
  const [cvr, setCvr] = useState(DEBUG ? "73440447" : "");
  const [firstName, setFirstName] = useState(DEBUG ? "Test" : "");
  const [lastName, setLastName] = useState(DEBUG ? "sletmig" : "");
  const [email, setEmail] = useState(
    DEBUG ? `delete${Math.round(Math.random() * 1000)}@me.now` : ""
  );
  const [invoiceEmail, setInvoiceEmail] = useState(
    DEBUG ? `delete${Math.round(Math.random() * 1000)}@me.now` : ""
  );
  const [addressName, setAddressName] = useState(
    DEBUG ? "Lemoonadestrede 10" : ""
  );
  const [zipCode, setZipCode] = useState(DEBUG ? "8787" : "");
  const [city, setCity] = useState(DEBUG ? "SourCity" : "");
  const [phone, setPhone] = useState(DEBUG ? "73303163" : "");
  const [POnumber, setPOnumber] = useState<string>();
  const [EAN, setEAN] = useState<string>("");
  const [extraFirstName, setExtraFirstName] = useState("");
  const [extraLastName, setExtraLastName] = useState("");
  const [extraEmail, setExtraEmail] = useState("");
  const [extraAddressName, setExtraAddressName] = useState("");
  const [extraCompany, setExtraCompany] = useState("");
  const [extraCVR, setExtraCVR] = useState("");
  const [extraAddressNumber, setExtraAddressNumber] = useState("");
  const [extraZipCode, setExtraZipCode] = useState("");
  const [extraCity, setExtraCity] = useState("");
  const [extraPhone, setExtraPhone] = useState("");
  const [sameAsBilling, setSameAsBilling] = useState(true);

  const [userDataFetched, setUserDataFetched] = useState(false);

  const [terms, setTerms] = useState(process.env.NODE_ENV == "development");
  const [newsletter, setNewsletter] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [inputErr, setInputErr] = useState(false);

  const formErr = useCallback(() => {
    let validInvoice = true;
    let leveringsAdresse = false;
    if (paymentMethod === "ean") {
      if (EAN.match(REGEX_EAN) && EAN !== "") {
        validInvoice = true;
      }
    }

    if (!company.match(REGEX_FIRMNAME)) {
      console.log("Invalid company", company, REGEX_FIRMNAME.source);
      validInvoice = false;
    }
    if (!cvr.match(REGEX_CVRNUMBER)) {
      console.log("Invalid cvr", cvr);
      validInvoice = false;
    }
    if (!firstName.match(REGEX_NAME)) {
      console.log("Invalid first name", firstName);
      validInvoice = false;
    }
    if (!lastName.match(REGEX_NAME)) {
      console.log("Invalid last name", lastName);
      validInvoice = false;
    }
    if (!phone.match(REGEX_PHONENUMBER)) {
      console.log("Invalid phone", phone);
      validInvoice = false;
    }
    if (!email.match(REGEX_EMAIL)) {
      console.log("Invalid email", email);
      validInvoice = false;
    }
    if (addressName === "") {
      console.log("Invalid address name", addressName);
      validInvoice = false;
    }
    if (!zipCode.match(REGEX_ZIPCODE)) {
      console.log("Invalid zip code", zipCode);
      validInvoice = false;
    }
    if (!city.match(REGEX_CITY)) {
      console.log("Invalid city", city);
      validInvoice = false;
    }

    if (!sameAsBilling) {
      if (
        REGEX_FIRMNAME.test(extraCompany) &&
        extraCompany !== "" &&
        REGEX_CVRNUMBER.test(extraCVR) &&
        extraCVR !== "" &&
        REGEX_NAME.test(extraFirstName) &&
        extraFirstName !== "" &&
        REGEX_NAME.test(extraLastName) &&
        extraLastName !== "" &&
        REGEX_PHONENUMBER.test(extraPhone) &&
        extraPhone !== "" &&
        REGEX_EMAIL.test(extraEmail) &&
        extraEmail !== "" &&
        extraAddressName !== "" &&
        REGEX_ZIPCODE.test(extraZipCode) &&
        extraZipCode !== "" &&
        REGEX_CITY.test(extraCity) &&
        extraCity !== ""
      ) {
        leveringsAdresse = true;
      }
    } else {
      leveringsAdresse = true;
    }

    if (validInvoice && leveringsAdresse) {
      return true;
    } else {
      return false;
    }
  }, [
    paymentMethod,
    company,
    cvr,
    firstName,
    lastName,
    phone,
    email,
    addressName,
    zipCode,
    city,
    sameAsBilling,
    EAN,
    extraCompany,
    extraCVR,
    extraFirstName,
    extraLastName,
    extraPhone,
    extraEmail,
    extraAddressName,
    extraZipCode,
    extraCity,
  ]);

  useEffect(() => {
    if (formErr()) {
      setInputErr(false);
    } else {
      setInputErr(true);
    }
  }, [formErr]);

  const [confirmingOrder, setConfirmingOrder] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState<boolean | null>(null);
  const [newOrderId, setOrderId] = useState<number>();
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethodType[]>(
    []
  );

  // Init data
  useEffect(() => {
    apiGetShippingMethods(token, cartId).then((methods) => {
      setShippingMethods(methods);
    });
  }, [token, cartId]);

  const submitRef = React.useRef(null);

  // Function for opening and closing "Handelsbetingelser"
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (accept: boolean) => {
    setTerms(accept);
    setOpen(false);
  };

  useEffect(() => {
    if (orderConfirmed) {
      trackEvent({ category: "Sales", action: "ORDERED" });
      ReactGA.event({
        category: "USER",
        action: "ORDERED",
      });
    }
  }, [orderConfirmed]);

  const saveInfo = () => {
    let billingAddress = {
      countryId: "DK",
      street: addressName,
      postalCode: zipCode,
      city: city,
      firstName: firstName,
      lastName: lastName,
      vat: cvr,
      company: company,
      email: email,
      phone: phone,
    };
    let shippingAddress = {
      countryId: "DK",
      street: extraAddressName,
      postalCode: extraZipCode,
      city: extraCity,
      firstName: extraFirstName,
      lastName: extraLastName,
      vat: extraCVR,
      company: extraCompany,
      email: extraEmail,
      phone: extraPhone,
    };
    return apiUpdateCartInfo(
      token,
      cartId,
      billingAddress,
      shippingAddress,
      sameAsBilling,
      POnumber,
      shippingMethod,
      invoiceEmail
    );
  };

  // Submitting the form containing shipping details - changes if user want to specify
  // a different delivery address
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      zipCode.length === 4 &&
      phone.length >= 8 &&
      cvr.length === 8 &&
      token
    ) {
      setConfirmingOrder(true);

      if (newsletter && userId) {
        apiSubscribeNewsletter(token, userId);
        //signUpEmail({ email: email, firstName: firstName, lastName: lastName });
      }

      // Changes endpoint depending on user being a guest or not
      if (token) {
        saveInfo()
          .then((res) => {
            apiConfirmOrder(
              token,
              cartId,
              paymentMethod,
              EAN !== "" ? EAN : undefined
            )
              .then((order) => {
                setOrderId(order.id);
                if (paymentMethod === "card") {
                  apiCreatePayment(token, order.id).then((res) => {
                    console.log("createPaymentRes", res);
                    //@ts-ignore
                    let rp = new Reepay.ModalCheckout(res.id, {
                      showReceipt: false,
                    });
                    const cancelFunc = () => {
                      onOrdered("Temp");
                      setOrderConfirmed(false);
                    };
                    let accepted = false;
                    //@ts-ignore
                    rp.addEventHandler(Reepay.Event.Accept, function (data) {
                      console.log("accept", data);
                      accepted = true;
                      onOrdered("Init", order.id, false);
                      setOrderConfirmed(true);
                      rp.destroy();
                    });
                    //@ts-ignore
                    rp.addEventHandler(Reepay.Event.Error, function (data) {
                      console.log("error", data);
                    });
                    //@ts-ignore
                    rp.addEventHandler(Reepay.Event.Cancel, function (data) {
                      console.log("cancel", data);
                      cancelFunc();
                    });
                    //@ts-ignore
                    rp.addEventHandler(Reepay.Event.Close, function (data) {
                      if (!accepted) {
                        console.log("close", data);
                        cancelFunc();
                      }
                    });
                  });
                }
                if (paymentMethod === "banktransfer") {
                  onOrdered("Init", order.id);
                  setOrderConfirmed(true);
                }
                if (paymentMethod === "ean") {
                  onOrdered("Init", order.id);
                  setOrderConfirmed(true);
                }
                if (paymentMethod === "quote") {
                  setOrderConfirmed(true);
                  clearState();
                  history.push("/orders");
                }
              })
              .catch((e: FetchError) => {
                setSomethingWentWrong(true);
                //Do better error handling
                console.log("Error: ", e);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        window.alert(strings.ErrorMsg);
      }
    }
  };

  // Auto-fills the data found on the current user in the shipping details
  useEffect(() => {
    apiGetUser(token).then((user) => {
      setUserId(user.id);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      if (user.defaultBillingAddress) {
        setFirstName(user.defaultBillingAddress.firstName);
        setLastName(user.defaultBillingAddress.lastName);
        setCompany(
          user.defaultBillingAddress.company
            ? user.defaultBillingAddress.company
            : ""
        );
        setCvr(
          user.defaultBillingAddress.vat ? user.defaultBillingAddress.vat : ""
        );

        setPhone(
          user.defaultBillingAddress.phone
            ? user.defaultBillingAddress.phone
            : ""
        );

        setAddressName(
          user.defaultBillingAddress.street
            ? user.defaultBillingAddress.street
            : ""
        );

        setZipCode(
          user.defaultBillingAddress.postalCode
            ? user.defaultBillingAddress.postalCode
            : ""
        );
        setCity(
          user.defaultBillingAddress.city ? user.defaultBillingAddress.city : ""
        );
      }
    });
  }, [token]);

  const getShippingText = (id: string) => {
    if (id.includes("postnord")) {
      return {
        title: strings.PostNordDelivery,
        description: strings.PostNordDeliveryText,
        timeText: strings.PostNordDeliveryTime,
        logo: PostNord,
      };
    }
    if (id.includes("tnt_before_12")) {
      return {
        title: strings.TNT2Titel,
        description: strings.TNT2Body,
        timeText: strings.TNT2DeliveryTime,
        logo: TNT,
      };
    }
    if (id.includes("tnt_next_day")) {
      return {
        title: strings.TNT3Titel,
        description: strings.TNT3Body,
        timeText: strings.TNT3DeliveryTime,
        logo: TNT,
      };
    }
    return {
      title: strings.SelfCollect,
      description: strings.SelfCollectText,
      timeText: strings.SelfCollectTime,
      logo: EasyPartz,
    };
  };

  return (
    <div>
      <TermsAndConditions handleClose={handleClose} open={open} />
      {confirmingOrder && (
        <SweetAlert
          custom
          style={{ position: "relative" }}
          title={strings.PleaseWait}
          allowEscape={false}
          showConfirm={false}
          onConfirm={() => {}}
          customIcon={
            <CircularProgress size={"80px"} style={{ marginLeft: "40%" }} />
          }
        >
          {strings.ConfirmingOrder}
        </SweetAlert>
      )}
      {orderConfirmed && (
        <SweetAlert
          // success
          title={strings.PaymentThanks}
          allowEscape={false}
          onConfirm={() => {}}
          customButtons={
            <Button
              color="primary"
              onClick={() => {
                clearState();
                if (newOrderId) history.push(`/orderConfirm/${newOrderId}`);
                else history.push("/orders");
              }}
            >
              {strings.ButtonOk}
            </Button>
          }
        >
          <span style={{ fontWeight: "400" }}>
            {strings.PaymentOrderConfirmed}
          </span>
        </SweetAlert>
      )}
      {somethingWentWrong && (
        <SweetAlert
          error
          title={strings.SomethingWentWrong}
          allowEscape={false}
          onConfirm={() => {}}
          customButtons={
            <Button
              color="primary"
              onClick={() => {
                clearState();
                window.location.replace("/orders");
              }}
            >
              {strings.ButtonOk}
            </Button>
          }
        >
          {strings.SomethingWentWrongBody}
        </SweetAlert>
      )}

      {orderConfirmed === false && (
        <SweetAlert
          error
          title={strings.PaymentFailed}
          allowEscape={false}
          onConfirm={() => {}}
          customButtons={
            <Button
              color="primary"
              onClick={() => {
                clearState();
                window.location.replace("/orders");
              }}
            >
              {strings.ButtonOk}
            </Button>
          }
        ></SweetAlert>
      )}

      <Grid container spacing={4} style={{ justifyContent: "space-between" }}>
        <Grid item lg={6}>
          <InvoiceInfo
            handleSubmit={handleSubmit}
            cvrState={[cvr, setCvr]}
            sameAsBillingState={[sameAsBilling, setSameAsBilling]}
            companyState={[company, setCompany]}
            firstNameState={[firstName, setFirstName]}
            lastNameState={[lastName, setLastName]}
            addressNameState={[addressName, setAddressName]}
            zipCodeState={[zipCode, setZipCode]}
            cityState={[city, setCity]}
            emailState={[email, setEmail]}
            invoiceEmailState={[invoiceEmail, setInvoiceEmail]}
            POState={[POnumber, setPOnumber]}
            EANState={[EAN, setEAN]}
            phoneState={[phone, setPhone]}
            extraCVRState={[extraCVR, setExtraCVR]}
            extraFirstNameState={[extraFirstName, setExtraFirstName]}
            extraLastNameState={[extraLastName, setExtraLastName]}
            extraAddressNameState={[extraAddressName, setExtraAddressName]}
            extraCompanyState={[extraCompany, setExtraCompany]}
            extraZipCodeState={[extraZipCode, setExtraZipCode]}
            extraCityState={[extraCity, setExtraCity]}
            extraEmailState={[extraEmail, setExtraEmail]}
            extraPhoneState={[extraPhone, setExtraPhone]}
            paymentMethod={paymentMethod}
          />
          <div className={`${styles.delivery__container}`}>
            <h4 className={`${styles.delivery__title}`}>
              {strings.DeliveryMethodLower}
            </h4>
            <div>
              {shippingMethods.map((item) => {
                const shippingTexts = getShippingText(item.code ?? "");
                return (
                  <div onClick={() => setShippingMethod(item.code)}>
                    <ShippingRow
                      shippingTitle={shippingTexts.title}
                      shippingPrice={item.price}
                      shippingDescription={shippingTexts.description}
                      shippingTime={shippingTexts.timeText}
                      shippingLogo={shippingTexts.logo}
                      isSelected={shippingMethod == item.code}
                    />
                  </div>
                );
              })}
              <p className={`${styles.delivery__notice}`}>
                <span>{strings.PaymentDeliveryNotice}</span>
              </p>
            </div>
          </div>
          <div>
            <PaymentMethods
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </div>
        </Grid>
        <Grid item lg={6}>
          <OrderDetails
            items={cartItems}
            invoiceSubtotal={invoiceSubtotal}
            invoiceTotal={invoiceTotal}
            invoiceTaxes={invoiceTaxes}
            shippingPrice={invoiceShipping}
            leadTime={leadtimeOption}
            leadTimeDays={leadTimeOptions[leadtimeOption]?.days}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "6rem",
            }}
          >
            <div>
              <CustomCheckbox
                label={
                  <>
                    {strings.IAcceptEasy}{" "}
                    <a
                      style={{ color: "#00b28d" }}
                      href="https://easypartz.com/index.php/conditions-dk/"
                      target="_blank"
                    >
                      {strings.TermsOfTrade}
                    </a>
                  </>
                }
                type={"checkbox"}
                required={true}
                checked={terms}
                onChange={(e) => setTerms(e.currentTarget.checked)}
                boxStyle={`${styles.checkbox}`}
              />
            </div>
            <div>
              <CustomCheckbox
                label={strings.IJoinNews}
                type={"checkbox"}
                checked={newsletter}
                onChange={(e) => setNewsletter(e.currentTarget.checked)}
                boxStyle={`${styles.checkbox}`}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "22em",
              }}
            >
              <div style={{ width: "50%", paddingRight: "1rem" }}>
                <CustomButton
                  buttonText={strings.ButtonBack?.toUpperCase()}
                  buttonClass={`${buttonStyles.button__login}`}
                  iconClass={`${buttonStyles.login__icon}`}
                  onClick={() => cancelPayment()}
                />
              </div>
              <div style={{ width: "50%" }}>
                <CustomButton
                  buttonText={strings.ConfirmOrderButton.toUpperCase()}
                  buttonClass={`${buttonStyles.button__register}`}
                  iconClass={`${buttonStyles.register__icon}`}
                  form="addressSubmit"
                  type="submit"
                  // id="registerSubmit"
                  disabled={!terms || inputErr || !shippingMethod}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Payment;
