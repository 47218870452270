export const formatter = new Intl.NumberFormat("da-DK", {
  style: "currency",
  currency: "DKK",
});

export const stringConverter = (strings: any, string: String) => {
  if (string === "banktransfer") return strings.PaymentBankTransfer;
  if (string === "ean") return strings.PaymentEAN;
  if (string === "card") return strings.PaymentCardTransfer;
  if (string === "quote") return strings.PaymentOffer;
  if (string.includes("postnord")) return strings.PostNordDelivery;
  if (string.includes("tnt_before_12")) return strings.TNT2Titel;
  if (string.includes("tnt_next_day")) return strings.TNT3Titel;
  if (string.includes("pickup")) return strings.SelfCollect;
  else return " ";
};
