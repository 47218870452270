import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import {
  FunctionComponent,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { DFMInfoType } from "types/ModelTypes";
import { ErrorType } from "types/RenderTypes";
import styles from "./css/DFMControls.module.css";

type PropsType = {
  selectedError?: string;
  setSelectedError: Function;
  dfmInfo: DFMInfoType;
  style?: StyleSheet;
};

type DFMButtonPropsType = {
  value: string;
  title: string | ReactElement;
  description: string | ReactElement;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void;
};

const DFMButton: FunctionComponent<DFMButtonPropsType> = ({
  value,
  title,
  description,
  selected,
  disabled,
  onClick,
}) => {
  return (
    <Tooltip title={<div>{description}</div>} placement="right">
      <ToggleButton className={styles.button} value={value} onClick={onClick}>
        {title}
        {!disabled &&
          (selected ? (
            <RadioButtonChecked
              style={{ marginLeft: "4px" }}
              fontSize="small"
            />
          ) : (
            <RadioButtonUnchecked
              style={{ marginLeft: "4px" }}
              fontSize="small"
            />
          ))}
      </ToggleButton>
    </Tooltip>
  );
};

const DFMControls: FunctionComponent<PropsType> = ({
  selectedError,
  setSelectedError,
  dfmInfo,
}) => {
  const { strings } = useContext(AppStateContext);

  const oversized = useMemo(() => {
    // Yes, this is different from the backend, y and z are switched, because here y is the "up" direction.
    return (
      dfmInfo.boundingAnalysis.bboxDx > dfmInfo.boundingAnalysis.refDx ||
      dfmInfo.boundingAnalysis.bboxDy > dfmInfo.boundingAnalysis.refDy ||
      dfmInfo.boundingAnalysis.bboxDz > dfmInfo.boundingAnalysis.refDz
    );
  }, [dfmInfo.boundingAnalysis]);

  const undersized = useMemo(() => {
    return (
      dfmInfo.boundingAnalysis.bboxDx < 8 ||
      dfmInfo.boundingAnalysis.bboxDy < 8 ||
      dfmInfo.boundingAnalysis.bboxDz < 2
    );
  }, [dfmInfo.boundingAnalysis]);

  const thicknessProblems = useMemo(() => {
    return (
      dfmInfo.thicknessAnalysis.minThickness <
      dfmInfo.thicknessAnalysis.refThickness
    );
    // const thickness = dfmInfo.thicknessAnalysis.refThickness;
    // return (
    //   thickness > dfmInfo.thicknessAnalysis.maxThickness ||
    //   thickness < dfmInfo.thicknessAnalysis.minThickness
    // );
  }, [dfmInfo.thicknessAnalysis]);

  const clearanceProblems = useMemo(() => {
    return (
      dfmInfo.clearanceAnalysis.minClearance <
      dfmInfo.clearanceAnalysis.refClearance
    );
    // const clearance = dfmInfo.clearanceAnalysis.refClearance;
    // return (
    //   clearance > dfmInfo.clearanceAnalysis.maxClearance ||
    //   clearance < dfmInfo.clearanceAnalysis.minClearance
    // );
  }, [dfmInfo.clearanceAnalysis]);

  const btnClick = useCallback(
    (value) => {
      if (selectedError === value) {
        setSelectedError("");
      } else {
        setSelectedError(value);
      }
    },
    [selectedError, setSelectedError]
  );

  const noErrors = useMemo(() => {
    return (
      !oversized &&
      !undersized &&
      !thicknessProblems &&
      !clearanceProblems &&
      dfmInfo.edgeAccessibility.numInaccessibleEdges === 0 &&
      dfmInfo.faceAccessibility.numInaccessibleFaces === 0 &&
      dfmInfo.vertexAccessibility.numInaccessibleVertices === 0
    );
  }, [clearanceProblems, dfmInfo, oversized, thicknessProblems, undersized]);

  return noErrors ? null : (
    <ToggleButtonGroup
      orientation="vertical"
      color={"warning"}
      value={selectedError}
      size={"small"}
      exclusive
      sx={{
        backgroundColor: "#ededed",
        textTransform: "none",
        justifyContent: "space-between",
      }}
    >
      <ToggleButton
        style={{
          fontWeight: "bold",
        }}
        disabled
        value="error"
      >
        {strings.DFMErrorsFound}
      </ToggleButton>
      {false && (
        <DFMButton
          value={"nosolid"}
          title={strings.DFMErrorNoSolidsTitle}
          description={strings.DFMErrorNoSolidsDesc}
          selected={selectedError === "nosolid"}
        />
      )}
      {false && (
        <DFMButton
          value={"multiple"}
          title={strings.DFMErrorMultipleSolidsTitle}
          description={strings.DFMErrorMultipleSolidsDesc}
          selected={selectedError === "multiple"}
        />
      )}
      {oversized && (
        <DFMButton
          value={"overdimension"}
          title={strings.DFMErrorUnderdimensionTitle}
          description={
            <div>
              {strings.DFMErrorOverdimensionDesc1}
              <div>{strings.DFMErrorOverdimensionDesc2}</div>
            </div>
          }
          selected={selectedError === "overdimension"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
      {undersized && (
        <DFMButton
          value={"underdimension"}
          title={strings.DFMErrorOverdimensionTitle}
          description={
            <div>
              {strings.DFMErrorUnderdimensionDesc1}
              <div>{strings.DFMErrorUnderdimensionDesc2}</div>
            </div>
          }
          selected={selectedError === "underdimension"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
      {dfmInfo.vertexAccessibility.numInaccessibleVertices > 0 && (
        <DFMButton
          value={"vertex"}
          title={strings.DFMErrorPointTitle}
          description={strings.DFMErrorPointDesc}
          selected={selectedError === "vertex"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
      {dfmInfo.edgeAccessibility.numInaccessibleEdges > 0 && (
        <DFMButton
          value={"edge"}
          title={strings.DFMErrorEdgeTitle}
          description={strings.DFMErrorEdgeDesc}
          selected={selectedError === "edge"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
      {dfmInfo.faceAccessibility.numInaccessibleFaces > 0 && (
        <DFMButton
          value={"face"}
          title={strings.DFMErrorFaceTitle}
          description={strings.DFMErrorFaceDesc}
          selected={selectedError === "face"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
      {thicknessProblems && (
        <DFMButton
          value={"thickness"}
          title={strings.DFMErrorThinPlaneTitle}
          description={strings.DFMErrorThinPlaneDesc}
          selected={selectedError === "thickness"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
      {/* {true && (
        <DFMButton
          value={'deep'}
          title={strings.DFMErrorDeepTitle}
          description={strings.DFMErrorDeepDesc}
          selected={selectedError === 'deep'}
        />
      )} */}
      {clearanceProblems && (
        <DFMButton
          value={"clearance"}
          title={strings.DFMErrorClearanceTitle}
          description={strings.DFMErrorClearanceDesc}
          selected={selectedError === "clearance"}
          onClick={(event: React.MouseEvent<HTMLElement>, value: ErrorType) => {
            btnClick(value);
          }}
        />
      )}
    </ToggleButtonGroup>
  );
};

export default DFMControls;
