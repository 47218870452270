import { CircularProgress } from "@mui/material";
import Configurator from "components/Configurator";
import ButtonLoader from "components/Loaders/ButtonLoader";
import ConfiguratorLoader from "components/Loaders/ConfiguratorLoader";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import stylesLoader from "../../components/Loaders/buttonLoader.module.css";

type PropsType = {
  isLoading: boolean;
  isDone: Function;
  onChange?: (e: number) => void;
};

const LoaderComponent: FunctionComponent<PropsType> = ({
  isLoading,
  isDone,
  onChange,
}) => {
  const [percentDone, setPercentDone] = useState<number>(0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const loader = useMemo(() => {
    return (
      <>
        {/* <CircularProgress size={"2em"} style={{ marginRight: "1em" }} /> */}
        {/* <ConfiguratorLoader /> */}
        <span style={{ paddingRight: "1rem" }}>
          <ButtonLoader styleLoader={`${stylesLoader.loader__config}`} />
        </span>
        {percentDone}%
      </>
    );
  }, [percentDone]);

  const randomIncreasePercentage = () => {
    const increase = Math.floor(Math.random() * 7) + 1;
    setPercentDone((oldVal) => {
      if (oldVal + increase > 99) {
        return 99;
      }
      return oldVal + increase;
    });
  };

  useEffect(() => {
    onChange && onChange(percentDone);
  }, [onChange, percentDone]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    console.log("LoaderComp isLoading", isLoading);
    if (!isLoading && percentDone === 99) {
      setPercentDone(100);
      setTimeout(() => {
        console.log("LoaderComp Done loading");
        setPercentDone(0);
        isDone();
      }, 1000);
    }
    if (percentDone >= 99) return;
    if (timer) {
      clearTimeout(timer);
    }
    const timeout = setTimeout(
      () => {
        randomIncreasePercentage();
      },
      isLoading ? Math.floor(Math.random() * 3000) + 100 : 100
    );
    setTimer(timeout);
    return () => {
      clearTimeout(timeout);
    };
  }, [isDone, isLoading, percentDone]);

  return loader;
};

export default LoaderComponent;
