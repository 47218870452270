import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import CardHeader from "../../components/Card/CardHeader";
import CardText from "../../components/Card/CardText";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/homePagesStyles/loginPageStyle";
import { Document, Page, pdfjs } from "react-pdf";
import CustomInput from "../../components/CustomInput/CustomInput";

import AppStateContext from "contexts/AppStateContext";
import { useHistory, useParams } from "react-router";
import { Button, CircularProgress, Grid } from "@mui/material";
import { Check, Email, Lock } from "@mui/icons-material";

import useQuery from "hooks/UseQuery";

import SweetAlert from "react-bootstrap-sweetalert";
import PaymentMethods from "vaerksComponents/checkoutComponents/PaymentMethods";
import TermsAndConditions from "vaerksComponents/TermsAndConditions";
import { apiCreatePayment } from "util/network/Payment";
import { apiQuoteAccept, apiGetQuote } from "util/network/Quote";
import useWindowDimensions from "vaerksComponents/useWindowDimensions";
import { apiGetOrder } from "util/network/Orders";
import { OrderType } from "types/OrderTypes";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import { API_URL } from "util/network/Common";

const useStyles: any = makeStyles(styles);
type ParamsType = {
  quoteId?: string;
};

const AcceptQuote = () => {
  const { quoteId } = useParams<ParamsType>();
  const { height, width } = useWindowDimensions();
  const query = useQuery();
  const { token } = useContext(AppStateContext);
  const [quote, setQuote] = useState<any>();
  const history = useHistory();

  // const token = useMemo(() => {
  //   return query.get("token");
  // }, [query]);

  const id = useMemo(() => {
    return query.get("id");
  }, [query]);

  useEffect(() => {
    if (!quoteId) return;
    if (!token) {
      history.push("/auth", { sendBack: true });
      return;
    }

    apiGetQuote(token, quoteId).then((res) => {
      console.log(res);
      if (res === "True") {
        window.location.replace("/orders");
        return;
      }

      setQuote(res);
    });
  }, [token, quoteId]);

  const [showAlert, setShowAlert] = useState(false);

  const { strings } = useContext(AppStateContext);

  const [error, setError] = useState<boolean>(false);

  const [alertText, setAlertText] = useState<string>();

  const [paymentMethod, setPaymentMethod] = useState("banktransfer");

  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [terms, setTerms] = useState(false);
  const [eanNumber, setEanNumber] = useState("");
  const [orderConfirmed, setOrderConfirmed] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [order, setOrder] = useState<OrderType>();

  // Function for opening and closing "Handelsbetingelser"
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (accept: boolean) => {
    setTerms(accept);
    setOpen(false);
  };

  const payNow = useCallback(
    (order: OrderType) => {
      console.log(order);
      if (token && order?.id) {
        apiCreatePayment(token, order?.id).then((res) => {
          //@ts-ignore
          let rp = new Reepay.ModalCheckout(res.id, { showReceipt: false });
          const cancelFunc = () => {
            setLoading(false);
            setOrderConfirmed(false);
          };
          let accepted = false;
          //@ts-ignore
          rp.addEventHandler(Reepay.Event.Accept, function (data) {
            console.log("accept", data);
            accepted = true;
            setLoading(false);
            setOrderConfirmed(true);
            rp.destroy();
          });
          //@ts-ignore
          rp.addEventHandler(Reepay.Event.Error, function (data) {
            console.log("error", data);
            cancelFunc();
          });
          //@ts-ignore
          rp.addEventHandler(Reepay.Event.Cancel, function (data) {
            console.log("cancel", data);
            cancelFunc();
          });
          //@ts-ignore
          rp.addEventHandler(Reepay.Event.Close, function (data) {
            if (!accepted) {
              console.log("close", data);
              cancelFunc();
            }
          });
        });
      }
    },
    [token]
  );

  const handleSubmit = () => {
    if (!token || !quoteId) return;
    setLoading(true);
    let info = {};

    if (paymentMethod === "ean") {
      info = { method: "ean", ean: eanNumber };
    }
    if (paymentMethod === "banktransfer") {
      info = { method: "banktransfer" };
    }
    if (paymentMethod === "card") {
      return apiQuoteAccept(token, quoteId, { method: "card" }).then((res) => {
        setOrder(res);
        payNow(res);
      });
    }
    return apiQuoteAccept(token, quoteId, info).then((res) => {
      setOrder(res);
      setLoading(false);
      setOrderConfirmed(true);
    });
  };

  const pdfShower = useMemo(() => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Document
          file={{
            url: `${API_URL}/v1/ecommerce/quotes/${quoteId}/pdf`,
            httpHeaders: {
              Authorization: `Bearer ${token}`,
            },
          }}
          error={"Fejl ved indlæsning. Forsøg at genindlæs siden"}
        >
          <Page
            pageNumber={1}
            //height={height * 0.8}
            width={width * 0.45}
            loading={<div className={classes.pdfTemp} />}
          />
        </Document>
      </div>
    );
  }, [quoteId]);

  return (
    <div style={{ display: "flex", zIndex: 100 }}>
      {loading ? (
        <SweetAlert
          custom
          style={{ position: "relative" }}
          title={strings.PleaseWait}
          allowEscape={false}
          showConfirm={false}
          customIcon={
            <CircularProgress
              // left={-20}
              // top={10}
              size={"80px"}
              style={{ marginLeft: "40%", color: primaryColor[0] }}
            />
          }
          onConfirm={() => {}}
        >
          {strings.ConfirmingOrder}
        </SweetAlert>
      ) : null}
      {orderConfirmed && (
        <SweetAlert
          success
          title={strings.PaymentThanks}
          allowEscape={false}
          onConfirm={() => {}}
          customButtons={
            <Button
              color="primary"
              onClick={() => {
                //dispatch(clearState());
                if (order) {
                  history.push(`/orderConfirm/${order.id}`);
                }
                //history.push("orders");
              }}
            >
              {strings.ButtonOk}
            </Button>
          }
        >
          {strings.PaymentOrderConfirmed}
        </SweetAlert>
      )}
      <TermsAndConditions handleClose={handleClose} open={open} />
      <Card>
        <CardHeader>
          <CardText color="info">
            <h4 className={classes.cardTitle}>{quote?.orderNo}</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <Grid justifyContent={"center"} container>
            <Grid item xs={12} xl={6}>
              {pdfShower}
            </Grid>

            <Grid item xs={8} xl={6}>
              <PaymentMethods
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                hideOffer
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {paymentMethod === "ean" ? (
                  <CustomInput
                    labelText={strings.EANNumber}
                    id="EAN"
                    formControlProps={{
                      fullWidth: false,
                      required: paymentMethod === "ean",
                    }}
                    inputProps={{
                      type: "text",
                      value: eanNumber,

                      onChange: (e: any) => {
                        if (e.target.value.trim().length < 14)
                          setEanNumber(e.target.value.trim());
                      },
                      maxLength: 13,
                    }}
                  />
                ) : null}
                <div className={classes.confirmBox}>
                  <label style={{ color: "black" }}>
                    {strings.IAcceptEasy}
                    <a
                      href="https://easypartz.com/index.php/conditions-dk/"
                      target="_blank"
                    >
                      {strings.TermsOfTrade}
                    </a>
                  </label>
                  <input
                    type={"checkbox"}
                    checked={terms}
                    //value={terms}
                    onChange={(e) => setTerms(e.currentTarget.checked)}
                  />
                </div>

                <Button
                  variant={"contained"}
                  disabled={
                    !terms || (paymentMethod === "ean" && eanNumber === "")
                  }
                  onClick={() => handleSubmit()}
                >
                  {strings.OrderDetailsDialogPayNowBtn} <Check />
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
};

export default AcceptQuote;
