import {
  useEffect,
  useState,
  useContext,
  useReducer,
  FunctionComponent,
  useCallback,
} from "react";
import AppStateContext from "contexts/AppStateContext";
import ListReducer, { ListReducerEnum } from "util/ListReducer";
import { Grid } from "@mui/material";
import { apiCreatePayment } from "util/network/Payment";
import OrderSummaryTable from "vaerksComponents/orderConfirmationComponents/OrderSummaryTable";
import { OrderItemType, OrderType } from "types/OrderTypes";
import { stringConverter } from "util/TextUtil";
import { useHistory } from "react-router";
import {
  apiAddBlueprint,
  apiCreateProduct,
  apiGetBlueprint,
  apiGetProduct,
} from "util/network/Products";
import EcommerceContext from "contexts/EcommerceContext";
import { reformatDateTime } from "util/DateUtil";
import OrderHistoryDetailsInfo from "./OrderHistoryDetailsInfo";

import stylesTable from "../../vaerksComponents/checkoutComponents/styles/payment.module.css";
import styles from "../../components/Orders/css/order.module.css";
import CustomButton from "components/Buttons/CustomButton";
import stylesButton from "../../components/Buttons/customButton.module.css";
import {
  CNCProductDetailsType,
  CreateCNCProductType,
  CreatePrint3DProductType,
  Print3DProductDetailsType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import NavigateButton from "components/Buttons/NavigateButton";
import SweetAlert from "react-bootstrap-sweetalert";

type PropsType = {
  order: OrderType;
};

const OrderHistoryDetails: FunctionComponent<PropsType> = ({ order }) => {
  const { strings, token } = useContext(AppStateContext);
  const { addCartItem } = useContext(EcommerceContext);

  const [checked, checkedDispatch] = useReducer(
    ListReducer<OrderItemType>("id"),
    []
  );
  const [positiveAdd, setPositiveAdd] = useState<number>(0);
  const [itemsAdded, setItemsAdded] = useState<boolean>(false);
  const [addingToCart, setAddingtoCart] = useState<boolean>(false);
  const [unpaid, setUnpaid] = useState<boolean>(
    order?.status === "awaiting_payment"
  );
  const [orderConfirmed, setOrderConfirmed] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Controls if item is expected to be added to cart:
  const handleToggle = (item: OrderItemType) => {
    if (checked.find((i) => i.id === item.id)) {
      checkedDispatch({ value: item, type: ListReducerEnum.REMOVE });
    } else {
      checkedDispatch({
        value: item,
        type: ListReducerEnum.ADD,
      });
    }
  };

  const formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  // Fires a dispatch to add every checked item to cart:
  const finishDialog = async (addedItems: boolean) => {
    if (addedItems === false) {
      checkedDispatch({
        value: [],
        type: ListReducerEnum.CLEAR,
      });
      setLoading(false);
    } else if (addedItems === true) {
      setAddingtoCart(true);
      setLoading(true);
      Promise.all(
        checked.map((item) => {
          return apiGetProduct(token, item.productId).then((product) => {
            let command: CreateCNCProductType | CreatePrint3DProductType;
            if (product.productType === ProductTypeEnum.CNC) {
              const details = {
                ...product.details,
                finish: (product.details?.finish as any).split(","),
              } as CNCProductDetailsType;
              command = {
                modelId: details.modelId,
                quantity: details.quantity,
                material: details.material,
                finish: details.finish.join(","),
                blueprint: details.blueprint,
                comment: details.comment,
                threads: details.threads,
                productType: ProductTypeEnum.CNC,
              } as CreateCNCProductType;
            } else if (product.productType === ProductTypeEnum.PRINT3D) {
              const details = {
                ...product.details,
                finish: (product.details?.finish as any).split(","),
              } as Print3DProductDetailsType;
              command = {
                modelId: details.modelId,
                quantity: details.quantity,
                material: details.material,
                finish: details.finish.join(","),
                comment: details.comment,
                threads: details.threads,
                productType: ProductTypeEnum.PRINT3D,
              } as CreatePrint3DProductType;
            } else {
              throw new Error("Product type not supported");
            }

            return apiCreateProduct(token, command).then((createdProduct) => {
              if (product.productType === ProductTypeEnum.CNC) {
                const details = product.details as CNCProductDetailsType;
                apiGetBlueprint(token, product.id).then((blob) => {
                  const file = new File(
                    [blob],
                    details.blueprint ?? "blueprint",
                    {
                      type: "application/pdf",
                    }
                  );
                  apiAddBlueprint(token, createdProduct.id, file).then(
                    (res) => {
                      return addCartItem(createdProduct.id, item.quantity);
                    }
                  );
                });
              } else {
                return addCartItem(createdProduct.id, item.quantity);
              }
            });
          });
        })
      );

      setPositiveAdd(checked.length);
      setLoading(false);
    }
  };

  const payNow = useCallback(() => {
    if (token && order?.id) {
      apiCreatePayment(token, order?.id).then((res) => {
        //@ts-ignore
        let rp = new Reepay.ModalCheckout(res.id, { showReceipt: false });
        const cancelFunc = () => {
          setOrderConfirmed(false);
        };
        let accepted = false;
        //@ts-ignore
        rp.addEventHandler(Reepay.Event.Accept, function (data) {
          console.log("accept", data);
          accepted = true;
          setOrderConfirmed(true);
          rp.destroy();
          history.push(`/orderConfirm/${order.id}`);
        });
        //@ts-ignore
        rp.addEventHandler(Reepay.Event.Error, function (data) {
          console.log("error", data);
          cancelFunc();
        });
        //@ts-ignore
        rp.addEventHandler(Reepay.Event.Cancel, function (data) {
          console.log("cancel", data);
          cancelFunc();
        });
        //@ts-ignore
        rp.addEventHandler(Reepay.Event.Close, function (data) {
          if (!accepted) {
            console.log("close", data);
            cancelFunc();
          }
        });
        // var win = window.open(res.url, "_blank");
        // if (!win) return;
        // win.focus();
        // var timer = setInterval(function () {
        //   apiGetPayment(token, orderId).then((res) => {
        //     if (win && win.close) {
        //       if (res.state === "settled") {
        //         clearInterval(timer);
        //         //updateProductOrder();
        //         dispatch(setOrderConfirmed(true));
        //         win.close();
        //       } else if (win.closed) {
        //         clearInterval(timer);
        //         //dispatch(setOrderConfirmed(false));
        //       }
        //     }
        //   });
        // }, 1000);
      });
    }
  }, [order?.id, token]);

  useEffect(() => {
    if (!token || !order) return;
    setUnpaid(false);

    if (order?.status === "awaiting_payment") {
      setUnpaid(true);
    }
  }, [
    order,
    order?.items,
    strings.OrderDetailsDialogErr,
    strings.OrderDetailsDialogErrInternet,
    token,
  ]);
  console.log(order);

  const dismissAddedAlert = () => {
    setPositiveAdd(0);
    setItemsAdded(false);
    // handleClose(true);
  };

  // // Knows every item was added succesfully if the amount of Magento responses
  // // matches the amount of checked items
  useEffect(() => {
    if (checked.length !== 0) {
      if (positiveAdd === checked.length) {
        checkedDispatch({
          value: [],
          type: ListReducerEnum.CLEAR,
        });
        setAddingtoCart(false);
        setItemsAdded(true);
      }
    }
  }, [checked.length, positiveAdd]);

  // // Contains very shitty table by Creative Tim - when I have time it should be swapped out
  // // for a proper standard Material one equal to the ones used in Checkout and OrderHistory

  return (
    <div className={`${styles.container}`}>
      <div style={{ padding: "4em" }}>
        <div style={{ textAlign: "center" }}></div>
        {/* <br /> */}
        <h1 className={`${styles.details}`}>{strings.details}:</h1>
        <br />

        <Grid container spacing={12}>
          <Grid item xs>
            <table>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>{strings.orderNumber}:</p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>{order.orderNo}</p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>{strings.date}:</p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>
                    {reformatDateTime(order.created)}
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>{strings.email}:</p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>{order.email}</p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>
                    {strings.paymentMethod}:
                  </p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>
                    {stringConverter(strings, order.paymentMethod)}
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p className={`${styles.summary}`}>
                    {strings.deliveryMethod}:
                  </p>
                </td>
                <td align="center"></td>
                <td align="right">
                  <p className={`${styles.summary__info}`}>
                    {stringConverter(strings, order.shippingMethod)}
                  </p>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs>
            <p className={`${styles.summary}`}>{strings.invoiceAddress}:</p>
            <p className={`${styles.summary__info}`}>
              <b>Att:</b>{" "}
              {order.billingAddress.firstName +
                " " +
                order.billingAddress.lastName}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.phone}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.email}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.street}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.billingAddress.postalCode +
                " " +
                order.billingAddress.city}
            </p>
            <br />
            <p className={`${styles.summary}`}>{strings.deliveryAddress}:</p>
            <p className={`${styles.summary__info}`}>
              <b>Att:</b>{" "}
              {order.shippingAddress.firstName +
                " " +
                order.shippingAddress.lastName}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.phone}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.email}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.street}
            </p>
            <p className={`${styles.summary__info}`}>
              {order.shippingAddress.postalCode +
                " " +
                order.shippingAddress.city}
            </p>
          </Grid>
        </Grid>

        <div>
          <h2 className={`${styles.title}`}>{strings.products}:</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <th></th>
              <th></th>
              <th align="left">{strings.name}</th>
              <th align="left">
                {strings.material}/{strings.finish}
              </th>
              <th align="center">{strings.quantity}</th>
              <th align="right">{strings.price}</th>
            </tr>
            <tr>
              <td
                colSpan={6}
                align="left"
                className={`${stylesTable.line__container_solid}`}
              >
                <div className={`${stylesTable.line_solid}`}></div>
              </td>
            </tr>
            {order.items
              .filter((i) => i.product.productType !== ProductTypeEnum.FEE)
              .map((item) => (
                <OrderHistoryDetailsInfo
                  key={item.id}
                  item={item}
                  handleToggle={handleToggle}
                  isChecked={checked.includes(item)}
                />
              ))}
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <OrderSummaryTable order={order} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingRight: "5rem",
              paddingTop: "2rem",
            }}
          >
            <NavigateButton
              onClick={() => finishDialog(true)}
              buttonText={
                checked.length === 0
                  ? strings.OrderDetailsDialogMark
                  : strings.OrderDetailsDialogAddX1 +
                    checked.length +
                    strings.OrderDetailsDialogAddX2
              }
              buttonClass={`${stylesButton.button__login} ${stylesButton.history__row_info}`}
              iconClass={`${stylesButton.login__icon}`}
              loading={loading}
            />
          </div>
          {/* {handleToggle && (
            <SweetAlert
              success
              style={{ position: "relative" }}
              title={strings.PleaseWait}
              allowEscape={false}
              showConfirm={false}
              onConfirm={() => {}}
              // customIcon={
              //   <CircularProgress size={"80px"} style={{ marginLeft: "40%" }} />
              // }
            >
              <span style={{ fontWeight: "400" }}>
                {checked.length + strings.OrderDetailsDialogAddX2}
              </span>
            </SweetAlert>
          )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // paddingRight: "4rem",
              // paddingTop: "2rem",
            }}
          >
            {unpaid && (
              //@ts-ignore
              <CustomButton
                onClick={() => payNow()}
                buttonText={strings.OrderDetailsDialogPayNowBtn?.toUpperCase()}
                buttonClass={`${stylesButton.button__demo} ${stylesButton.history__row_info}`}
                iconClass={`${stylesButton.demo__icon}`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderHistoryDetails;
