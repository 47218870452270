import { FinishType, MaterialDataType, MaterialType } from "types/NetworkTypes";
import { fetchPublic } from "./network/Common";

export class Materials {
  static cncCategories: MaterialType[];
  static print3dCategories: MaterialType[];

  static loadMaterials() {
    if (Materials.cncCategories === undefined) {
      // Load material data from json
      fetchPublic("/cncMaterials.json").then((res) => {
        Materials.cncCategories = res;
      });
    }
    if (Materials.print3dCategories === undefined) {
      // Load material data from json
      fetchPublic("/print3DMaterials.json").then((res) => {
        Materials.print3dCategories = res;
      });
    }
  }

  // Function to get material object from materialCategories
  static getById(materialId: string) {
    if (Materials.cncCategories !== undefined && materialId) {
      for (const materialCategory of Materials.cncCategories) {
        for (const materialItem of materialCategory.variants) {
          if (materialItem.id === materialId) {
            return materialItem;
          }
        }
      }
    }
    if (Materials.print3dCategories !== undefined && materialId) {
      for (const materialCategory of Materials.print3dCategories) {
        for (const materialItem of materialCategory.variants) {
          if (materialItem.id === materialId) {
            return materialItem;
          }
        }
      }
    }
    return null;
  }
}

export class Finishes {
  static cncCategories: FinishType[];
  static print3DCategories: FinishType[];

  static loadFinishes() {
    if (Finishes.cncCategories === undefined) {
      // Load finish data from json
      fetchPublic("/cncFinish.json").then((res) => {
        Finishes.cncCategories = res;
      });
    }
    if (Finishes.print3DCategories === undefined) {
      // Load finish data from json
      fetchPublic("/print3DFinish.json").then((res) => {
        Finishes.print3DCategories = res;
      });
    }
  }

  // Function to get finish object from FinishData
  static getById(finishId: string) {
    if (Finishes.cncCategories !== undefined && finishId) {
      for (const finishCategory of Finishes.cncCategories) {
        for (const finishItem of finishCategory.variants) {
          if (finishItem.id === finishId) {
            return finishItem;
          }
        }
      }
    }
    if (Finishes.print3DCategories !== undefined && finishId) {
      for (const finishCategory of Finishes.print3DCategories) {
        for (const finishItem of finishCategory.variants) {
          if (finishItem.id === finishId) {
            return finishItem;
          }
        }
      }
    }
    return null;
  }
}
