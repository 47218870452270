import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { DFMInfoType } from "types/ModelTypes";
import { ErrorType } from "types/RenderTypes";
import LoaderComponent from "vaerksComponents/configuratorComponents/LoaderComponent";
import DFMControls from "./DFMControls";
import styles from "./css/DFMContainer.module.css";

type PropsType = {
  dfmInfo?: DFMInfoType;
  selectedError?: ErrorType;
  setSelectedError: Function;
};

const DFMContainer: FunctionComponent<PropsType> = ({
  dfmInfo,
  selectedError,
  setSelectedError,
}) => {
  const [uploadText, setUploadText] = useState<string>("Analyzing");
  const [dfmLoading, setDfmLoading] = useState<boolean>(true);

  const [showControls, setShowControls] = useState<boolean>(false);

  const onUpload = (number: number) => {
    if (number > 5 && number <= 12) setUploadText("Analyzing geometry");
    else if (number > 13 && number <= 55) setUploadText("Analyzing weaknesses");
    else if (number > 56) setUploadText(" Finishing analysis");
    else if (number >= 99) setUploadText("Done!");
  };

  useEffect(() => {
    if (dfmInfo !== undefined) {
      setDfmLoading(false);
    }
  }, [dfmInfo]);

  const loaderMemo = useMemo(() => {
    return (
      <LoaderComponent
        isLoading={dfmLoading}
        isDone={() => setShowControls(true)}
        onChange={onUpload}
      />
    );
  }, [dfmLoading]);
  return (
    <div className={`${styles.container}`}>
      {!dfmLoading && showControls && dfmInfo ? (
        <DFMControls
          selectedError={selectedError}
          setSelectedError={setSelectedError}
          dfmInfo={dfmInfo}
        />
      ) : (
        <div className={`${styles.container__text}`}>
          <p className={`${styles.loader}`}>{loaderMemo}</p>
          <p className={`${styles.loader__text}`}>{uploadText}</p>
        </div>
      )}
    </div>
  );
};
export default DFMContainer;
