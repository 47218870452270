import React, { FunctionComponent, useMemo, useContext } from "react";
import { SelectedThreadsInfoType } from "types/RenderTypes";
import { ReactComponent as ThreadsIconUploaded } from "../../assets/svg/dashboard/threadsUploaded.svg";
import { ReactComponent as ThreadCircleIcon } from "../../assets/svg/dashboard/threadCircle.svg";
import CustomIconButton from "../../components/Buttons/CustomIconButton";
import AppStateContext from "contexts/AppStateContext";

type PropsType = {
  specs: SelectedThreadsInfoType[];
};

const ThreadDisplay: FunctionComponent<PropsType> = ({ specs }) => {
  const { strings } = useContext(AppStateContext);
  const uniqueThreads = useMemo(() => {
    const res: { [key: string]: any } = {};
    specs.forEach(
      (spec) =>
        (res[spec.name] = {
          color: `rgb(${spec.color?.r}, ${spec.color?.g}, ${spec.color?.b})`,
          count: (res[spec.name]?.count ?? 0) + 1,
        })
    );
    return res;
  }, [specs]);

  return specs.length > 0 ? (
    <table style={{ overflowY: "scroll" }}>
      {Object.keys(uniqueThreads).map((key, index) => (
        <tr
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0.2rem",
          }}
        >
          {/* <td style={{ paddingRight: "0.3rem" }}> */}
          {/* <ThreadCircleIcon
              style={{
                width: "15px",
                height: "15px",
                color: `${uniqueThreads[key].color}`,
                backgroundColor: `${uniqueThreads[key].color}`,
              }}
            /> */}
          {/* </td> */}
          <td style={{ fontSize: "0.7rem" }}>
            {key} ({uniqueThreads[key].count})
          </td>
        </tr>
      ))}
    </table>
  ) : (
    <tr>
      <td>{strings.ThreadCardNoThreads}</td>
    </tr>
  );
};
export default ThreadDisplay;
