import * as THREE from "three";
import {
  Box3,
  BufferGeometry,
  Color,
  Group,
  InstancedMesh,
  Line,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Points,
  Vector3,
} from "three";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Canvas,
  extend,
  Object3DNode,
  RootState,
  useFrame,
  useThree,
} from "@react-three/fiber";
import styles from "./SimpleComponentRender.module.css";
import { ReactElement } from "react";
import { CustomTrackballControls } from "../ThreeJS/CustomTrackBallControls";
import RenderLoader from "../RenderLoader";
import RenderControls from "../RenderControls";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import Stats from "../ThreeJS/Stats";
import {
  ErrorType,
  CylinderMeshGroupType,
  ModelDataType,
  ReceivedModelType,
} from "types/RenderTypes";
import RegularModel from "../Models/RegularModel";
import HoleModel from "../Models/HoleModel";
import DFMErrorsModels from "../Models/DFMErrorsModels";
import Effects from "../ThreeJS/Effects";
import { staticColors } from "util/ColorUtil";
import AppStateContext from "contexts/AppStateContext";
import { ThemeContext } from "@emotion/react";
import RegularModel3DPrint from "components/Models/RegularModel3DPrint";
// extend({ CustomOrbitControls });

// declare global {
//   namespace JSX {
//     interface IntrinsicElements {
//       customOrbitControls: Object3DNode<
//         CustomOrbitControls,
//         typeof CustomOrbitControls
//       >;
//     }
//   }
// }

extend({ CustomTrackballControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      customTrackballControls: Object3DNode<
        CustomTrackballControls,
        typeof CustomTrackballControls
      >;
    }
  }
}

extend({ Line_: THREE.Line });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      line_: Object3DNode<THREE.Line, typeof THREE.Line>;
    }
  }
}

type PropTypes = {
  model: Mesh;
  modelEdges: Line[];
  modelHolesGroups: CylinderMeshGroupType[];
  modelShaftsGroups: CylinderMeshGroupType[];
  modelCenter?: Vector3;
  context?: any;
  disableSelect?: boolean;

  isTransparent?: boolean;
  //showErrorModels: boolean;
  setCenterCamFunc: Function;
  setLoading: Function;
  hovered?: number[];
  setHovered?: Function;
  selection?: number[];
  setSelection?: (selection: number[]) => void;
  style?: CSSProperties;
  className?: string;
  children?: ReactElement;
};

const RenderLogic: FunctionComponent<PropTypes> = ({
  model,
  modelEdges,
  modelHolesGroups,
  modelShaftsGroups,
  disableSelect,
  modelCenter = new Vector3(),
  isTransparent,
  setCenterCamFunc,
  setLoading,
  hovered,
  setHovered,
  selection,
  setSelection,
  children,
}) => {
  const controlRef = useRef<CustomTrackballControls>();
  const modelGroupRef = useRef<Group>();
  const edgeGroupRef = useRef<Group>();
  const holesGroupRef = useRef<Group>();
  const shaftsGroupRef = useRef<Group>();
  // const { scene, camera, gl } = useThree();
  const { camera, gl, scene, invalidate } = useThree();

  useFrame(({ gl, scene, camera }) => {
    controlRef.current?.update();
    //effect.render(scene, camera);
    //setRadius(12 - camera.zoom);
    gl.render(scene, camera);
  });

  // useEffect(() => {
  //   controlRef.current?.addEventListener('change', invalidate);
  //   return () => controlRef.current?.removeEventListener('change', invalidate);
  // }, []);

  // useEffect(() => {
  //   const modelGroup = modelGroupRef.current;
  //   const edgeGroup = edgeGroupRef.current;
  //   if (isTransparent === undefined) return;
  //   if (modelGroup) {
  //     modelGroup.visible = !isTransparent;
  //   }
  //   if (edgeGroup) {
  //     edgeGroup.visible = isTransparent;
  //   }
  // }, [isTransparent]);

  const prepModelEdges = useMemo(() => {
    if (modelEdges) {
      // let points: any[] = [];
      // let index: any[] = [];
      // let colors: any[] = [];
      // let division = 50;
      // let indexCounterStart = 0;
      // const test = new THREE.CubicBezierCurve3(
      //   new Vector3(1, 2, 3),
      //   new Vector3(4, 5, 6),
      //   new Vector3(7, 8, 9),
      //   new Vector3(10, 11, 12)
      // );

      // const test = test.getPoints()
      // console.log(points.concat(test.getPoints());)
      // modelEdges.forEach((c, idx) => {
      //   const positions
      //   const vectorList: Vector3[] = []

      //   points = ;
      //   if (idx < 10) {
      //     console.log(points);
      //   }
      //   // position
      //   //points = points.concat(positions);

      //   // index
      //   for (let i = 0; i < division; i++) {
      //     index.push(i + indexCounterStart, i + 1 + indexCounterStart);
      //   }
      //   indexCounterStart += division + 1;

      //   // color
      //   let r = Math.random(),
      //     g = Math.random(),
      //     b = Math.random();
      //   for (let i = 0; i < division + 1; i++) colors.push(r, g, b);
      // });

      // console.log(index.length, index);

      // var geometry = new THREE.BufferGeometry().setFromPoints(points);
      // console.log(geometry.attributes.position.count);
      // geometry.addAttribute(
      //   'color',
      //   new THREE.BufferAttribute(new Float32Array(colors), 3)
      // );
      // geometry.setIndex(index);

      // var line = new THREE.LineSegments(
      //   geometry,
      //   new THREE.LineBasicMaterial({
      //     vertexColors: true
      //   })
      // );
      // scene.add(line);
      // return <lineSegments {...line} />;

      return (
        <group name="edges" ref={edgeGroupRef} matrixAutoUpdate={false}>
          {modelEdges.map((edge) => {
            // if (modelCenter)
            //   edge.position.set(
            //     1 - modelCenter?.x,
            //     1 - modelCenter?.y,
            //     1 - modelCenter?.z
            //   );
            return (
              <line_ key={edge.id} {...edge} matrixAutoUpdate={false}>
                <bufferGeometry {...edge.geometry} />
                <lineBasicMaterial
                  color={staticColors.edge}
                  polygonOffset
                  polygonOffsetFactor={1}
                  polygonOffsetUnits={8}
                />
              </line_>
            );
          })}
        </group>
      );
    }
  }, [modelEdges]);

  useEffect(() => {
    if (modelGroupRef.current || edgeGroupRef.current) {
      setLoading(false);
    }
  }, [edgeGroupRef.current, modelGroupRef.current, model]);

  // const errorPoints = useMemo(() => {
  //   if (!showPointErrors) return null;
  //   const points = modelErrors?.points;
  //   const threeJSPoints = points?.map((point, key) => (
  //     <mesh key={key} position={new Vector3(point.x, point.y, point.z)}>
  //       <meshBasicMaterial
  //         transparent={true}
  //         opacity={0.6}
  //         color={new Color(0xff0000)}
  //       />
  //       <sphereBufferGeometry args={[5, 20, 20]} />
  //     </mesh>
  //   ));
  //   return threeJSPoints;
  // }, [modelErrors, showPointErrors]);

  /*const errorModel = useMemo(() => {
    return (
      <group>
        {(modelErrorsModel?.model.children as Mesh[])?.map((child: Mesh) => {
          const pos = modelCenter
            ? new Vector3(
                0 - modelCenter.x,
                0 - modelCenter.y,
                0 - modelCenter.z
              )
            : new Vector3(0, 0, 0);
          return (
            <ComponentModel
              key={child.uuid}
              model={{
                uuid: child.uuid,
                geometry: child.geometry,
                position: pos,
              }}
              setSelectedFace={setSelectedFace}
              selectedFace={selectedFace}
              opacity={!isOpaque ? 0.7 : 1}
              isMouseDown={isMouseDown}
              polygonOffset={true}
              polygonOffsetFactor={1}
              polygonOffsetUnits={4}
            />
          );
        })}
        <mesh
          position={
            modelCenter
              ? new Vector3(
                  0 - modelCenter.x,
                  0 - modelCenter.y,
                  0 - modelCenter.z
                )
              : new Vector3(0, 0, 0)
          }
          //rotation={[Math.PI / 2, 0, 0]}
        >
          <meshPhongMaterial
            attach="material"
            color={new Color(0xff0000)}
            specular={new Color(0xf3f3f3)}
            shininess={200}
            wireframe={false}
            polygonOffset={true}
            polygonOffsetFactor={-1}
            polygonOffsetUnits={-4}
          />
          <bufferGeometry {...(modelErrorsModel?.issues as Mesh)?.geometry} />
        </mesh>
      </group>
    );
  }, [
    isMouseDown,
    isOpaque,
    modelCenter,
    modelErrorsModel?.issues,
    modelErrorsModel?.model.children,
    selectedFace,
    setSelectedFace,
  ]);*/

  /**
   * The following is used to center the camera and controls on the middle of box containing all the given models.
   * The reason for this is to have a visually satisfying start point for the camera, such it already will correctly
   * display the rendered models to the user.
   */
  const centerCamera = useCallback(() => {
    if (modelGroupRef.current && controlRef.current) {
      controlRef.current.rotateSpeed = 2;
      /*const children = modelGroupRef.current.children as Mesh[];
      const completeBox = new Box3();
      const points = [] as Vector3[];

      children.forEach((child) => {
        child.geometry.computeBoundingBox();
        const childBox = child.geometry.boundingBox?.clone();
        if (!childBox) return;
        childBox?.translate(child.position);
        points.push(childBox?.max);
        points.push(childBox?.min);
      });

      if (points.length === 0) return;

      completeBox.setFromPoints(points);

      const center = new Vector3();
      completeBox.getCenter(center);

      console.log("center", center);

      setModelOffset(center);

      modelGroupRef.current.position.set(0, 0, 0);
      children.forEach((child) => {
        const currentPos = child.position.clone();
        child.position.set(
          currentPos.x - center.x,
          currentPos.y - center.y,
          currentPos.z - center.z
        );
      });*/
      controlRef.current.reset();
      controlRef.current.update();

      const completeBox = new Box3();
      completeBox.setFromObject(modelGroupRef.current);

      const maxSize = completeBox.max.clone();
      const minSize = completeBox.min.clone();
      maxSize.sub(minSize);
      //Math.max(maxSize.,minSize,completeBox.getSize())
      const size = maxSize.length(); //Math.max(maxSize.x, maxSize.y, minSize.z); // maxSize.length(); //Math.max(maxSize.length(), 500);
      //const height = size / 1.5 + 20; // + center.z;

      // Inspired from https://stackoverflow.com/questions/55310424/three-jsversion-102-how-to-set-default-position-rotation-of-camera-with-orbi
      // First we set the camera position
      camera.position.set(size * 2, size * 2, size);

      // Then we get the direction of the camera to later set the controls.
      //const direction = new Vector3();
      //camera.getWorldDirection(direction);

      // Now we convert the controls target position to a real world position
      // and add a scaled vector to it, using the direction of our camera,
      // and a unit describing the length from the camera to the object we wish to rotate about.
      //camera.getWorldPosition(controlRef.current.target);

      if (modelCenter) {
        controlRef.current.target.set(
          modelCenter?.x,
          modelCenter?.y,
          modelCenter?.z
        );
      } else {
        controlRef.current.target.set(0, 0, 0);
      }
      //controlRef.current.up0.set(0, 0, 1);
      controlRef.current.update();

      camera.zoom = 500 / size;
      camera.updateProjectionMatrix();
    }
  }, [scene, camera, modelCenter, modelGroupRef.current]);

  useEffect(() => {
    centerCamera();
    setCenterCamFunc(() => centerCamera);
  }, [centerCamera, model, setCenterCamFunc]);

  const modelMemo = useMemo(() => {
    if (modelHolesGroups.length == 0 && modelShaftsGroups.length == 0) {
      return (
        <RegularModel3DPrint
          transparent={isTransparent}
          mesh={model}
          edgeMeshes={modelEdges}
          modelGroupRef={modelGroupRef}
        />
      );
    }
    return (
      <RegularModel
        transparent={isTransparent}
        mesh={model}
        edgeMeshes={modelEdges}
        modelGroupRef={modelGroupRef}
      />
    );
  }, [
    isTransparent,
    model,
    modelEdges,
    modelHolesGroups.length,
    modelShaftsGroups.length,
  ]);

  // const uniqueHoles = useMemo(() => {
  //   const existingGroups: UniqueHoleType[] = [];
  //   modelHolesGroups.forEach(group => {
  //     let existing = undefined;
  //     existingGroups.forEach((ex, index) => {
  //       if (compareHoleFeature(ex.info, group.info)) {
  //         existing = index;
  //       }
  //     });

  //     if (!existing) {
  //       existingGroups.push({
  //         ids: group.info.faceIds,
  //         info: group.info,
  //         mesh: group.mesh
  //       });
  //     } else {
  //       existingGroups[existing].ids.push(...group.info.faceIds);
  //     }
  //   });
  //   return existingGroups;
  // }, [modelHolesGroups]);

  // const optimizedHoleMeshes =

  // useEffect(() => {
  //   const existingGroups: { [key: string]: HoleMeshGroupType } = {};
  //   modelHolesGroups.forEach(group => {
  //     modelHolesGroups.map(test => test.meshes.filter)
  //   });
  // }, [modelHolesGroups]);
  const memoHoles = useMemo(() => {
    return (
      <group name={"holesGroup"} ref={holesGroupRef}>
        {modelHolesGroups.map((group, key) => {
          return (
            <HoleModel
              key={key}
              transparent={isTransparent}
              disableSelect={disableSelect}
              mesh={group.mesh}
              hover={
                hovered?.find((id) => group.info.faceIds.includes(id)) !==
                undefined
              }
              select={
                selection?.find((id) => group.info.faceIds.includes(id)) !==
                undefined
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelection && setSelection(group.info.faceIds);
              }}
              onPointerMissed={(e) => {
                //e.stopPropagation();
                if (
                  selection?.find((id) => group.info.faceIds.includes(id)) !==
                  undefined
                ) {
                  const holeSelectionGroup =
                    scene.getObjectByName("holeSelection");
                  if (holeSelectionGroup) {
                    holeSelectionGroup.clear();
                  }
                  const holeHoverGroup = scene.getObjectByName("holeHover");
                  if (holeHoverGroup) {
                    holeHoverGroup.clear();
                  }
                  setSelection && setSelection([]);
                }
              }}
              onPointerEnter={(e) => {
                e.stopPropagation();
                setHovered && setHovered(group.info.faceIds);
              }}
              onPointerLeave={(e) => {
                e.stopPropagation();
                setHovered && setHovered([]);
              }}
            />
          );
        })}
      </group>
    );
  }, [
    modelHolesGroups,
    selection,
    isTransparent,
    disableSelect,
    hovered,
    setSelection,
    scene,
  ]);

  const memoShafts = useMemo(() => {
    return (
      <group name={"shaftsGroup"} ref={shaftsGroupRef}>
        {modelShaftsGroups.map((group, key) => {
          return (
            <HoleModel
              key={key}
              showEdges
              disableSelect={disableSelect}
              transparent={isTransparent}
              mesh={group.mesh}
              hover={
                hovered?.find((id) => group.info.faceIds.includes(id)) !==
                undefined
              }
              select={
                selection?.find((id) => group.info.faceIds.includes(id)) !==
                undefined
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelection && setSelection(group.info.faceIds);
              }}
              onPointerMissed={(e) => {
                //e.stopPropagation();
                if (
                  selection?.find((id) => group.info.faceIds.includes(id)) !==
                  undefined
                ) {
                  const holeSelectionGroup =
                    scene.getObjectByName("holeSelection");
                  if (holeSelectionGroup) {
                    holeSelectionGroup.clear();
                  }
                  const holeHoverGroup = scene.getObjectByName("holeHover");
                  if (holeHoverGroup) {
                    holeHoverGroup.clear();
                  }
                  setSelection && setSelection([]);
                }
              }}
            />
          );
        })}
      </group>
    );
  }, [
    disableSelect,
    hovered,
    isTransparent,
    modelShaftsGroups,
    selection,
    setSelection,
  ]);

  // console.log(
  //   `Total holes: ${modelHolesGroups.length}` //, unique holes: ${uniqueHoles.length}`
  // );

  // useEffect(() => {
  //   if (edgeGroupRef.current) {
  //     edgeGroupRef.current.visible = !isTransparent;
  //   }
  // }, [isTransparent]);

  return (
    <>
      {/* <ambientLight color={0xffffff} intensity={1} /> */}
      {/* <hemisphereLight
        args={[0xffffff, 0x080820]}
        intensity={0.1}
        position={new Vector3(0, 0, 500)}
      />
      <hemisphereLight
        args={[0xffffff, 0x080820]}
        intensity={0.1}
        position={new Vector3(0, 0, -500)}
      /> */}
      <customTrackballControls
        ref={controlRef}
        args={[camera, gl.domElement]}
      />
      {/*<customOrbitControls ref={controlRef} args={[camera, gl.domElement]} />*/}
      {/* <gridHelper args={[1000, 100]} rotation={[0, 0, 0]} />
      <axesHelper args={[100]} /> */}
      <group name={"models"}>
        {isTransparent && <Effects />}
        {modelMemo}
        {memoHoles}
        {memoShafts}
      </group>
      {children}

      {/* {prepModelEdges} */}
      {window.sessionStorage.getItem("debug") && <Stats />}
    </>
  );
};

type SecondScenePropsType = {
  dfmErrorModels?: {
    face: null | Mesh;
    edge: null | LineSegments;
    vertex: null | Points;
    thickness: null | Mesh;
    clearance: null | Mesh;
  };
  modelCenter?: Vector3;
  selectedError?: ErrorType;
};

const SecondScene: FunctionComponent<SecondScenePropsType> = ({
  dfmErrorModels,
  modelCenter,
  selectedError,
}) => {
  const { scene, camera } = useThree();
  useFrame(
    ({ gl }) =>
      void ((gl.autoClear = false),
      gl.clearDepth(),
      gl.render(scene.children[1], camera)),
    11
  );

  return (
    <scene name={"secondScene"}>
      {dfmErrorModels && (
        <DFMErrorsModels
          models={dfmErrorModels}
          modelCenter={modelCenter}
          showError={selectedError}
        />
      )}
      <group name="holeHover" />
      <group name="holeSelection" />
    </scene>
  );
};

type ComponentRenderPropTypes = {
  gltf: ReceivedModelType;
  dfmGltf?: GLTF;
  info?: ModelDataType;
  selectedError?: ErrorType;
  style?: CSSProperties;
  context?: any;
  themeContext?: any;
  className?: string;
  children?: ReactElement;
  disableSelect?: boolean;
  selection?: number[];
  setSelection?: (selection: number[]) => void;
  hovered?: number[];
  setHovered?: Function;
  /*{
    face: boolean;
    edge: boolean;
    vertex: boolean;
    overdimension: boolean;
    underdimension: boolean;
  };*/
  //highlights?: HighlightType[];
  //holeSelection?: HoleSelectionType[];
};

const ComponentRender: FunctionComponent<ComponentRenderPropTypes> = ({
  gltf,
  dfmGltf,
  info,
  selectedError,
  style,
  context,
  themeContext,
  children,
  disableSelect,
  selection,
  setSelection,
  hovered,
  setHovered,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [isTransparent, setIsTransparent] = useState<boolean>(false);
  const [centerCamFunc, setCenterCamFunc] = useState(null);

  // Used to set different parameters for the rendering,
  // such as background color.
  const onCreated = useCallback((state: RootState) => {
    // Setting scene color
    state.scene.background = new Color(0xffffff); //new THREE.Color(0xf7f7ff);
    //state.gl.setSize(window.innerWidth, window.innerHeight);
  }, []);

  const holeMeshGroups: CylinderMeshGroupType[] = useMemo(() => {
    console.log(info);
    if (!info || !gltf || !info.features) return [];
    const res: CylinderMeshGroupType[] = [];
    info.features.holes.forEach((hole) => {
      const collectiveBores = [
        ...hole.bores,
        ...hole.counterbores,
        //...hole.countersinks,
      ];

      const material = new MeshBasicMaterial();
      collectiveBores.forEach((bore) => {
        //const group: Mesh[] = [];
        const geoGroup: BufferGeometry[] = [];
        bore.faceIds.forEach((id) => {
          const item = gltf.models.find(
            (model) => Number(model.name.split("_")[1]) === id
          );
          if (item) {
            geoGroup.push(item.geometry);
          }
        });
        if (geoGroup.length > 0) {
          const combinedGeo = mergeBufferGeometries(geoGroup);
          const mesh = new InstancedMesh(
            combinedGeo,
            material,
            geoGroup.length
          );
          res.push({ mesh: mesh, info: { ...bore, id: bore.faceIds[0] } });
        }
      });
    });
    return res;
  }, [gltf?.models, info]);

  const shaftMeshGroups: CylinderMeshGroupType[] = useMemo(() => {
    if (!info || !gltf || !info.features) return [];
    const res: CylinderMeshGroupType[] = [];
    const material = new MeshBasicMaterial();
    info.features?.shafts.forEach((shaft) => {
      const geoGroup: BufferGeometry[] = [];
      shaft.faceIds.forEach((id) => {
        const item = gltf.models.find(
          (model) => Number(model.name.split("_")[1]) === id
        );
        if (item) {
          geoGroup.push(item.geometry);
        }
      });
      const combinedGeo = mergeBufferGeometries(geoGroup);
      const mesh = new InstancedMesh(combinedGeo, material, geoGroup.length);
      res.push({
        mesh: mesh,
        info: { ...shaft, id: shaft.faceIds[0] },
      });
    });
    return res;
  }, [gltf?.models, info]);

  const memoModel: Mesh = useMemo(() => {
    const modelGeos = gltf.models
      .filter(
        (model) =>
          [...holeMeshGroups, ...shaftMeshGroups].find((group) =>
            group.info.faceIds.includes(Number(model.name.split("_")[1]))
          ) === undefined
        // &&
        // shaftMeshGroups.find(group => group.mesh.name === model.name) ===
        //   undefined
      )
      .map((item) => item.geometry);
    const mergedGeo = mergeBufferGeometries(modelGeos);
    const res = new Mesh(mergedGeo, gltf.models[0].material);

    return res;
  }, [gltf?.models, holeMeshGroups, shaftMeshGroups]);

  const edges: Line[] = useMemo(() => {
    return gltf?.edges;
  }, [gltf?.edges]);

  const modelCenter = useMemo(() => {
    if (memoModel) {
      setLoading(true);
      const completeBox = new Box3();
      const points = [] as Vector3[];

      memoModel.geometry.computeBoundingBox();
      const childBox = memoModel.geometry.boundingBox?.clone();
      if (!childBox) return;
      //const box = new THREE.Box3Helper(childBox, new Color(0xff0000));
      //scene.add(box);
      //childBox?.translate(child.position);
      points.push(childBox?.max);
      points.push(childBox?.min);

      if (points.length === 0) return;

      completeBox.setFromPoints(points);

      const center = new Vector3();
      completeBox.getCenter(center);
      return center;
    }
    return new Vector3(0, 0, 0);
  }, [memoModel]);

  const dfmErrorModels = useMemo(() => {
    let res = {
      face: null,
      edge: null,
      vertex: null,
      thickness: null,
      clearance: null,
    } as {
      face: null | Mesh;
      edge: null | LineSegments;
      vertex: null | Points;
      thickness: null | Mesh;
      clearance: null | Mesh;
    };
    if (!dfmGltf) return;
    dfmGltf.scene.children.forEach((mod) => {
      if (mod.name === "DFM_Analysis") {
        mod.children.forEach((child) => {
          if (child.name === "Face_issues") {
            res.face = child as Mesh;
            //res.rotation.set(0, 0, Math.PI / 2);
          }
          if (child.name === "Vertex_issues") {
            res.vertex = child as Points;
            //res.rotation.set(0, 0, Math.PI / 2);
          }
          if (child.name === "Edge_issues") {
            res.edge = child as LineSegments;
            //res.rotation.set(0, 0, Math.PI / 2);
          }
          if (child.name === "Thickness_mesh") {
            res.thickness = child as Mesh;
          }
          if (child.name === "Thickness_issues") {
            res.thickness = child as Mesh;
          }
          if (child.name === "Clearance_issues") {
            res.clearance = child as Mesh;
          }
        });
      }
    });
    return res;
  }, [dfmGltf]);

  /*useEffect(() => {
    if (models && models.length > 10) {
      const numOfPlanes = Math.floor((Math.random() * models.length) / 2) + 3;
      const planes = [];
      for (let i = 0; i < numOfPlanes; i++) {
        const rand = Math.floor(Math.random() * models.length) + 1;
        planes.push(models[rand]?.uuid);
      }
      setModelErrors({
        points: [
          {
            x: 65,
            y: -64.5,
            z: 12,
          },
        ],
        planes: planes,
      });
    }
  }, [models, setModelErrors]);*/

  //console.log(dfmErrorModels);

  return (
    <div className={`${styles.container}`}>
      <Canvas
        //mode="concurrent"
        id={"container"}
        style={style}
        className={`${styles.canvasContainer}`}
        orthographic
        camera={{
          far: 10000,
          near: 0.0000000000000001,
        }}
        onCreated={onCreated}
      >
        <ThemeContext.Provider value={themeContext}>
          <AppStateContext.Provider value={context}>
            <scene name={"mainScene"}>
              <RenderLogic
                model={memoModel}
                modelEdges={edges}
                modelHolesGroups={holeMeshGroups}
                modelShaftsGroups={shaftMeshGroups}
                modelCenter={modelCenter}
                setCenterCamFunc={setCenterCamFunc}
                setLoading={setLoading}
                isTransparent={isTransparent}
                selection={selection}
                setSelection={setSelection}
                hovered={hovered}
                setHovered={setHovered}
                disableSelect={disableSelect}
                //showErrorModels={showErrorModels}
              ></RenderLogic>
            </scene>
            <SecondScene
              dfmErrorModels={dfmErrorModels}
              modelCenter={modelCenter}
              selectedError={selectedError}
            />
          </AppStateContext.Provider>
        </ThemeContext.Provider>
      </Canvas>
      {loading && <RenderLoader />}
      {children}
      <RenderControls
        isTransparent={isTransparent}
        setIsTransparent={setIsTransparent}
        centerCamFunc={centerCamFunc}
      />
    </div>
  );
};

export default ComponentRender;
