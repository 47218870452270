import React, { useState, useContext } from "react";
import styles from "./newFooter.module.css";
import { ReactComponent as Brand } from "../../assets/img/logoWhite.svg";
import AppStateContext from "contexts/AppStateContext";
import CookieBot from "react-cookiebot";
import {
  COMPANY_NAME,
  COUNTRY_SHORT,
  CVR,
  ADDRESS,
  ZIP,
  CITY,
  COMPANY_MAIL_CAPS,
  COMPANY_MAIL,
} from "util/CompanyInfoUtil";
import { ReactComponent as LogoFb } from "../../assets/svg/footer/fb.svg";
import { ReactComponent as LogoLnk } from "../../assets/svg/footer/LinkedIn.svg";

const NewFooter = () => {
  const { strings, updateLanguage } = useContext(AppStateContext);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.footer}`}>
        <div className={`${styles.footer__content}`}>
          <span className={`${styles.footer__logo}`}>
            <Brand />
          </span>
          <p>
            <a
              href={
                "https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=39408872&soeg=easypartz"
              }
              target={"_blank"}
              rel="noreferrer"
              style={{ color: "white" }}
            >
              {" "}
              {ADDRESS}, {ZIP} {CITY}, {COUNTRY_SHORT}
            </a>
          </p>
          <p>
            <a
              href={
                "https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=39408872&soeg=easypartz"
              }
              target={"_blank"}
              rel="noreferrer"
              style={{ color: "white" }}
            >
              CVR: {CVR}
            </a>
          </p>
          <p>
            <a href="mailto: info@easypartz.com" style={{ color: "white" }}>
              {COMPANY_MAIL}
            </a>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <LogoFb
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://www.facebook.com/EasyPartz",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
              style={{ cursor: "pointer" }}
            />
            &nbsp;&nbsp;&nbsp;
            <LogoLnk
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://www.linkedin.com/company/easypartz",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            />
          </div>
          <hr />
          <p className={`${styles.footer__below}`}>
            <a
              // className={classes.cookiebot}
              id="cookieSettings"
              title={strings.CookiePreferencesChange}
              href="#"
              onClick={() => CookieBot}
              style={{ color: "white" }}
            >
              {strings.CookiePreferences}
            </a>
            <p>
              &copy; {new Date().getFullYear() + "\xa0"}
              {COMPANY_NAME}
            </p>
          </p>

          <p></p>
        </div>
      </div>
    </div>
  );
};
export default NewFooter;
