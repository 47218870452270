import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Finishes } from "../../util/MaterialUtils";
import { FinishDataType, FinishType } from "../../types/NetworkTypes";
import AppStateContext from "contexts/AppStateContext";
import CustomSelect from "components/Select/CustomSelect";
import { fetchPublic } from "util/network/Common";
import CustomMultiSelect from "components/Select/CustomMultiSelect";

type PropsType = {
  material: string;
  currentSel: string[];
  onChange: Function;
  disabled?: boolean;
  finishFile?: string;
};

const FinishSelectorLocal: FunctionComponent<PropsType> = ({
  material,
  currentSel,
  onChange,
  disabled,
  finishFile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFinishes, setSelectedFinishes] = useState<string[]>(
    currentSel ?? []
  );

  console.log(selectedFinishes);
  const [options, setOptions] = useState<FinishType[]>([]);
  const { strings } = useContext(AppStateContext);
  //const [data, setData] = useState();

  useEffect(() => {
    fetchPublic(finishFile ? `/${finishFile}` : "/cncFinish.json").then(
      (res) => {
        setOptions(res);
      }
    );
  }, [finishFile]);

  useEffect(() => {
    setSelectedFinishes(currentSel);
  }, [currentSel]);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (cat: FinishType, id: string) => {
    let newSelectedFinish = [...selectedFinishes];
    if (newSelectedFinish.includes(id)) {
      // If it is, remove it
      newSelectedFinish = newSelectedFinish.filter((i) => i !== id);
    } else {
      // Check if any of the variants are selected
      const selected = cat.variants.find((i) =>
        newSelectedFinish.includes(i.id)
      );
      if (selected) {
        // If they are, remove them
        newSelectedFinish = newSelectedFinish.filter((i) => i !== selected.id);
      }
      newSelectedFinish = [...newSelectedFinish, id];
    }
    setSelectedFinishes(newSelectedFinish);
    onChange(newSelectedFinish);
  };

  const finishOptions = options
    .map((option, key) => {
      if (material.charAt(0) === "s" && option.id.toLowerCase().includes("ano"))
        return [];
      if (!material.includes("stainless") && option.id.includes("polished")) {
        return [];
      }
      if (
        !material.includes("steel") &&
        option.title.dk.includes("Galvanisering")
      )
        return [];
      const selected = option.variants.find((i) =>
        selectedFinishes.includes(i.id)
      );
      let disabled = false;
      if (!selected) {
        selectedFinishes.forEach((fin) => {
          let finCat = options.find((i) =>
            i.variants.find((j) => j.id === fin)
          );
          if (finCat) {
            if (!option.compatibleFinishes.includes(finCat?.id ?? "")) {
              disabled = true;
            }
          }
        });
      }
      const list = [];
      if (option.variants.length > 1) {
        list.push({
          value: option.title[strings.code],
          label: option.title[strings.code],
          group: option.id,

          disabled: true,
        });
      }
      option.variants.forEach((variant, key) =>
        list.push({
          value: variant.id,
          label: variant[strings.code].finishTitle,
          group: option.id,
          disabled: disabled,
        })
      );
      return list;
    })
    .flat();

  const setNewFinishes = (newFinishes: string[]) => {
    console.log(newFinishes);
    if (newFinishes.length === 0) newFinishes.push("standard");
    setSelectedFinishes(newFinishes);
    onChange(newFinishes);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <CustomMultiSelect
        disabled={disabled}
        options={finishOptions}
        value={selectedFinishes}
        onChange={setNewFinishes} //onChange={(e: any) => handleMenuItemClick(e.target.value)}
      />
    </div>
  );
};
export default FinishSelectorLocal;
