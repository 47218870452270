import React, { FunctionComponent, useContext } from "react";

import styles from "../checkoutComponents/styles/payment.module.css";

type PropsType = {
  title: string;
  subtitle?: string;
  active?: boolean;
  onClick?: Function;
  disabled?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyles?: string;
  rowText?: string;
  rowTextTitle?: string;
  rowTextSum?: string;
};

const PaymentCard: FunctionComponent<PropsType> = ({
  title,
  subtitle,
  active,
  onClick,
  disabled,
  icon: IconComponent,
  iconStyles,
  rowText,
  rowTextTitle,
  rowTextSum,
}) => {
  return (
    <div
      style={{
        background: active ? "#008A80" : "white",
      }}
      className={`${styles.payment__container_row}`}
      onClick={() => !disabled && onClick && onClick()}
    >
      <div
        style={{
          display: "flex",
        }}
        className={`${styles.payment__row}`}
      >
        <span className={`${styles.icon__container} ${iconStyles || ""}`}>
          {IconComponent && <IconComponent />}
        </span>
      </div>

      <div className={`${styles.payment__row_text} ${rowText || ""}`}>
        <p
          style={{
            color: active ? "white" : "black",
          }}
          //   className={`${styles.payment_method}`}
          className={`${styles.payment__row_text_title} ${rowTextTitle || ""}`}
        >
          {title.toUpperCase()}
        </p>

        <p
          style={{
            color: active ? "white" : "black",
          }}
          className={`${styles.payment__row_text_sum} ${rowTextSum || ""}`}
        >
          {subtitle ? subtitle : null}
        </p>
      </div>
    </div>
  );
};
export default PaymentCard;
