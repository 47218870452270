import { FunctionComponent, useContext, useEffect, useState } from "react";
import FinishCard from "./FinishCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/dashboardPagesStyles/quotePageStyle";
import AppStateContext from "contexts/AppStateContext";
import * as ReactGA from "react-ga";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { fetchPublic } from "util/network/Common";
import { FinishType } from "types/NetworkTypes";
import { Grid } from "@mui/material";

const useStyles: any = makeStyles(styles);

type PropsType = {
  material: string;
  finish: string[];
  setFinish: Function;
};

const FinishSelectionLocal: FunctionComponent<PropsType> = ({
  material,
  finish,
  setFinish,
}) => {
  const classes = useStyles();
  const { strings } = useContext(AppStateContext);
  const [data, setData] = useState<FinishType[]>([]);
  const { trackEvent } = useMatomo();

  // const [selectedFinishes, finishesDispatch] = useReducer(ListReducer<string>(""), finish ?? [])
  useEffect(() => {
    fetchPublic("/cncFinish.json").then((res) => {
      setData(res);
    });
  }, []);

  return (
    <Grid container columns={5} columnSpacing={2} margin={1}>
      {data?.map((finishType: FinishType, key) => {
        if (
          material.charAt(0) === "s" &&
          finishType.id.toLowerCase().includes("ano")
        )
          return null;
        if (
          !material.includes("stainless") &&
          finishType.id.includes("polished")
        ) {
          return null;
        }
        if (
          !material.includes("steel") &&
          finishType.title.dk.includes("Galvanisering")
        )
          return null;
        const selected = finishType.variants.find((i) => finish.includes(i.id));
        let disabled = false;
        if (!selected) {
          finish.forEach((fin) => {
            console.log(fin);
            let finCat = data.find((i) => i.variants.find((j) => j.id === fin));
            if (finCat) {
              if (!finishType.compatibleFinishes.includes(finCat?.id ?? "")) {
                disabled = true;
              }
            }
          });
        }
        return (
          <Grid
            item
            key={finishType.title[strings.code]}
            xs={1}
            sm={1}
            md={1}
            lg={1}
          >
            <div>
              <FinishCard
                key={`${finishType.id}${key}`}
                finishTitle={finishType.title[strings.code]}
                finishDescription={""}
                finishImage={`${
                  finishType.variants.find((i) => finish.includes(i.id))
                    ?.finishImageURL ?? finishType.variants[0].finishImageURL
                }`}
                disabled={disabled}
                variants={finishType.variants}
                value={selected?.id}
                onClick={(fin: string) => {
                  // Check if the finish is already selected
                  if (finish.includes(fin)) {
                    // If it is, remove it
                    setFinish(finish.filter((i) => i !== fin));
                  } else {
                    // Check if any of the variants are selected
                    const selected = finishType.variants.find((i) =>
                      finish.includes(i.id)
                    );
                    let newFinishes = [...finish];
                    if (selected) {
                      // If they are, remove them
                      newFinishes = finish.filter((i) => i !== selected.id);
                    }
                    // Add the new one
                    setFinish([...newFinishes, fin]);
                  }

                  // Google Analytics Event
                  ReactGA.event({
                    category: "Component Configuration",
                    action: "Finish Change",
                    label: fin,
                  });
                  trackEvent({
                    category: "Component Configuration",
                    action: "Finish Change",
                    name: fin,
                  });
                }}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FinishSelectionLocal;
