import { createStyles } from "@mui/material";
import {
  container,
  defaultFont,
  cardTitle,
  roseColor,
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
  dangerColor,
  infoColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/creativeTim/components/modalStyle.js";

const quotePageStyle = (theme) =>
  createStyles({
    container: {
      ...container,
      zIndex: "4",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "100px",
      },
    },
    center: {
      textAlign: "center",
    },
    right: {
      textAlign: "right",
    },
    left: {
      textAlign: "left",
    },
    marginRight: {
      marginRight: "5px",
    },
    modalSectionTitle: {
      marginTop: "30px",
    },
    title: {
      ...defaultFont,
      color: whiteColor,
      marginTop: "5vh",
      marginBottom: "30px",
      textAlign: "center",
    },
    description: {
      fontSize: "18px",
      color: whiteColor,
      textAlign: "center",
    },
    cardTitle: {
      ...cardTitle,
      color: whiteColor,
      marginBottom: "-5px",
      fontWeight: "bold",
    },
    boldAndCenterText: {
      textAlign: "center",
      fontWeight: "bold",
    },
    fileNameText: {
      textAlign: "right",
      fontWeight: "bold",
      float: "right",
      marginTop: "-1px",
    },
    amountInput: {
      display: "block",
      marginLeft: "2%",
    },
    cardHeaderBackground: {
      background: "linear-gradient(to bottom , #00b28d 7px, #607d8b 8px)",
    },
    cardHeaderMargin: {
      marginBottom: "-40px",
    },
    cardCategory: {
      color: grayColor[0],
      marginTop: "10px",
    },
    cardCategoryWhite: {
      color: whiteColor,
      marginTop: "10px",
    },
    configTitle: {
      float: "left",
      fontWeight: "bold",
      display: "inline-block",
      marginBottom: "-26px",
      color: primaryColor[0],
    },
    configTitleLoader: {
      marginBottom: "-4%",
      display: "inline-block",
      width: "100%",
    },
    summaryDivider: {
      marginBottom: "-4%",
      backgroundColor: primaryColor[0],
      color: primaryColor[0],
      display: "inline-block",
      width: "100%",
      height: "1px",
    },
    summaryBottomDivider: {
      marginBottom: "0px",
      marginTop: "0px",
      backgroundColor: primaryColor[0],
      color: primaryColor[0],
      display: "inline-block",
      width: "100%",
      height: "1px",
    },
    icon: {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
      margin: "10px auto 0",
      width: "130px",
      height: "130px",
      border: "1px solid " + grayColor[11],
      borderRadius: "50%",
      lineHeight: "174px",
      "& svg": {
        width: "55px",
        height: "55px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "55px",
        fontSize: "55px",
      },
    },
    iconWhite: {
      color: whiteColor,
    },
    iconRose: {
      color: roseColor[0],
    },
    marginTop30: {
      marginTop: "30px",
    },
    textFloatLeft: {
      //float: "left",
    },
    summaryTextLabel: {
      fontSize: "1.0em",
      marginBottom: "0px",
      //fontStyle: "italic",
    },
    summaryDetail: {
      fontSize: "1.0em",
      marginTop: "-0.7em",
      fontStyle: "italic",
      marginLeft: "2%",
    },
    summaryTextValue: {
      fontSize: "1.825em",
      marginLeft: "2%",
    },
    textFloatRight: {
      float: "right",
    },
    square: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      height: "160px",
      width: "100%",
      borderRadius: "5px",
      marginBottom: "10px",
      backgroundColor: "#fff",
    },
    squareIcon: {
      fontSize: "10em !important",
      color: primaryColor[0],
      opacity: "0.6",
    },
    previewImage: {
      width: "70%",
      borderRadius: "5px",
      marginBottom: "10px",
      transition: "transform .2s",
      margin: "auto",
      display: "block",
    },
    root: {
      position: "relative",
    },
    dropdown: {
      position: "absolute",
      width: "100%",
      marginLeft: "auto",
      top: 28,
      right: 0,
      left: 0,
      zIndex: 1,
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    // threeDMargin: {
    //   marginLeft: "5%",
    //   marginRight: "5%",
    // },
    threeDToUploadDivdier: {
      backgroundColor: primaryColor[0],
      color: primaryColor[0],
      display: "inline-block",
      width: "100%",
      height: "2px",
    },
    commentDivider: {
      backgroundColor: primaryColor[0],
      color: primaryColor[0],
      display: "inline-block",
      width: "97%",
      height: "2px",
      marginRight: "1.5%",
      marginLeft: "1.5%",
    },
    selectionDivider: {
      marginBottom: "-1%",
      backgroundColor: "#008A80",
      color: "#008A80",
      display: "inline-block",
      width: "100%",
      height: "2px",
    },
    summaryContainer: {
      padding: "3% 3% 0% 3%",
      margin: "0 -3% -5px -3%",
    },
    componentSpecContainer: {
      display: "inline-block",
      width: "100%",
      //marginBottom: "5px",
    },
    buttonContainer: {
      display: "flex",
      float: "right",
    },
    quantityTitle: {
      float: "left",
      fontWeight: "400",
    },
    quantityButtonText: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    priceHeader: {
      display: "block",
      float: "left",
      position: "absolute",
      fontWeight: "bold",
      color: primaryColor[0],
    },
    priceLoader: {
      marginTop: "36px",
      marginBottom: "-15px",
      display: "inline-block",
      width: "100%",
    },
    priceDivider: {
      marginTop: "36px",
      marginBottom: "-15px",
      backgroundColor: primaryColor[0],
      color: primaryColor[0],
      display: "inline-block",
      width: "100%",
      height: "1px",
    },
    priceBreakdownContainer: {
      lineHeight: "6px",
      fontSize: "12px",
    },
    priceBreakdownContainerLoading: {
      lineHeight: "6px",
      fontSize: "12px",
      marginTop: "-4%",
    },
    expenseContainer: {
      display: "inline-block",
      width: "100%",
    },
    pricePerQuantity: {
      float: "right",
      marginBottom: "-4%",
      fontSize: "12px",
    },
    dfmCheckboxLoading: {
      float: "left",
      background: infoColor[0],
      fontWeight: "bold",
    },
    dfmCheckboxValid: {
      float: "left",
      background: primaryColor[0],
      fontWeight: "bold",
    },
    dfmCheckboxInvalid: {
      float: "left",
      fontWeight: "bold",
      margin: "0 3px 3px 0",
    },
    dfmCheckboxBackground: {
      background: dangerColor[0],
    },
    noMaxWidth: {
      maxWidth: "400px",
    },
    totalPrice: {
      float: "right",
      marginTop: "-1px",
      marginBottom: "-15px",
    },
    priceBottomDivider: {
      marginBottom: "1%",
      backgroundColor: primaryColor[0],
      color: primaryColor[0],
      display: "inline-block",
      width: "100%",
      height: "1px",
    },
    canvasContainer: {
      //padding: "1px",
      borderRadius: "5px",
      border: "1px solid #d0d0d0",
      marginBottom: "5px",
      width: "100%",
      overflow: "hidden",
    },
    canvas: {
      height: "40em !important",
      width: "100%",
    },
    canvasThread: {
      borderRadius: "5px",
      border: "1px solid transparent",
      marginBottom: "5px",
      height: "40em", //"550px !important",
      width: "100%",
    },
    threadContainer: {
      maxHeight: "550px",
      padding: "5px 0 5px 0",
      overscrollBehavior: "none",
      overflowX: "hidden",
      overflowY: "scroll",
      position: "sticky",
    },

    textField: {
      transition: "200ms",
    },

    /* number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    width: 50,
  },*/
    ...modalStyle(theme),
  });

export default quotePageStyle;
