import React, {
  FocusEventHandler,
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import styles from "./css/customSelect.module.css";

type PropsType = {
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  disabled?: boolean;
  stylesSelect?: string;
  children?: any;
  onClick?: MouseEventHandler;
  onBlur?: FocusEventHandler;
  onOpen?: () => void;
  onClose?: () => void;
  multiple?: boolean;
};

const CustomSelect: FunctionComponent<PropsType> = ({
  onChange,
  value,
  children,
  disabled,
  onClick,
  onBlur,
  onOpen,
  onClose,
  stylesSelect,
  multiple,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
    }
  }, [open]);

  return (
    <div style={{ paddingBottom: "0.5rem" }}>
      <select
        value={value}
        onChange={onChange}
        onClick={(e) => {
          setOpen((old) => !old);
          onClick && onClick(e);
        }}
        onBlur={(e) => {
          setOpen(false);
          onBlur && onBlur(e);
        }}
        className={`${styles.select} ${stylesSelect || ""}`}
        disabled={disabled}
        multiple={multiple}
      >
        {children}
      </select>
    </div>
  );
};
export default CustomSelect;
