import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import Button from "../components/CustomButtons/Button";
import * as PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppStateContext from "contexts/AppStateContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  niceText: {
    hyphens: "auto",
    wordWrap: "break-word",
  },
  boldButtons: {
    fontWeight: "bold",
  },
}));

const TermsAndConditions = ({ open, handleClose }) => {
  TermsAndConditions.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
  };
  const { strings } = useContext(AppStateContext);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        maxWidth={"lg"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {strings.TermsAndConditionsTitel}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.niceText}
            id="alert-dialog-slide-description"
          >
            <b>{strings.TermsAndConditionsSub1}</b>
            <br />
            {strings.TermsAndConditionsSubText1}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub2}</b>
            <br />
            {strings.TermsAndConditionsSubText2}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub3}</b>
            <br />
            {strings.TermsAndConditionsSubText3}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub4}</b>
            <br />
            {strings.TermsAndConditionsSubText4}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub5}</b>
            <br />
            {strings.TermsAndConditionsSubText5}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub6}</b>
            <br />
            {strings.TermsAndConditionsSubText6}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub7}</b>
            <br />
            {strings.TermsAndConditionsSubText7}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub8}</b>
            <br />
            {strings.TermsAndConditionsSubText8}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub9}</b>
            <br />
            {strings.TermsAndConditionsSubText9}
            retur.
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub10}</b>
            <br />
            {strings.TermsAndConditionsSubText10}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub11}</b>
            <br />
            {strings.TermsAndConditionsSubText11}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub12}</b>
            <br />
            {strings.TermsAndConditionsSubText12}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub13}</b>
            <br />
            {strings.TermsAndConditionsSubText13}
            <br />
            <br />
            <b>{strings.TermsAndConditionsSub14}</b>
            <br />
            {strings.TermsAndConditionsSubText14}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            color="danger"
            className={classes.boldButtons}
          >
            {strings.DisagreeButton?.toUpperCase()}
          </Button>
          <Button
            onClick={() => handleClose(true)}
            color="primary"
            className={classes.boldButtons}
          >
            {strings.AgreeButton?.toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsAndConditions;
